import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames'
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Color from '@/business/dto/types/color'
import { use } from 'i18next'
import useAuth from '@/contexts/use-auth'

const bgShade = 500
const ringShade = 500

type UiColorPickerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  label: string

  control: Control<TFieldValues>
  name: TName
  rules?: RegisterOptions<TFieldValues, TName>
  description?: string
}

const UiColorPicker = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UiColorPickerProps<TFieldValues, TName>
) => {
  const auth = useAuth()
  const color = auth?.company?.appearance?.color || 'rose'
  const { t } = useTranslation()

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => (
        <RadioGroup value={value} onChange={onChange}>
          {props.label && (
            <RadioGroup.Label className="block text-sm font-medium text-gray-700">
              {props.label}
            </RadioGroup.Label>
          )}
          <div className="mt-1 flex flex-wrap items-center gap-2 py-1">
            {[
              {
                label: t('common.rose'),
                value: Color.rose,
                bgColor: `bg-rose-${bgShade}`,
                selectedColor: `ring-rose-${ringShade}`,
              },
              {
                label: t('common.red'),
                value: Color.red,
                bgColor: `bg-red-${bgShade}`,
                selectedColor: `ring-red-${ringShade}`,
              },
              {
                label: t('common.orange'),
                value: Color.orange,
                bgColor: `bg-orange-${bgShade}`,
                selectedColor: `ring-orange-${ringShade}`,
              },
              {
                label: t('common.amber'),
                value: Color.amber,
                bgColor: `bg-amber-${bgShade}`,
                selectedColor: `ring-amber-${ringShade}`,
              },
              {
                label: t('common.yellow'),
                value: Color.yellow,
                bgColor: `bg-yellow-${bgShade}`,
                selectedColor: `ring-yellow-${ringShade}`,
              },
              {
                label: t('common.lime'),
                value: Color.lime,
                bgColor: `bg-lime-${bgShade}`,
                selectedColor: `ring-lime-${ringShade}`,
              },
              {
                label: t('common.green'),
                value: Color.green,
                bgColor: `bg-green-${bgShade}`,
                selectedColor: `ring-green-${ringShade}`,
              },
              {
                label: t('common.emerald'),
                value: Color.emerald,
                bgColor: `bg-emerald-${bgShade}`,
                selectedColor: `ring-emerald-${ringShade}`,
              },
              {
                label: t('common.teal'),
                value: Color.teal,
                bgColor: `bg-teal-${bgShade}`,
                selectedColor: `ring-teal-${ringShade}`,
              },
              {
                label: t('common.cyan'),
                value: Color.cyan,
                bgColor: `bg-cyan-${bgShade}`,
                selectedColor: `ring-cyan-${ringShade}`,
              },
              {
                label: t('common.sky'),
                value: Color.sky,
                bgColor: `bg-sky-${bgShade}`,
                selectedColor: `ring-sky-${ringShade}`,
              },
              {
                label: t('common.blue'),
                value: Color.blue,
                bgColor: `bg-blue-${bgShade}`,
                selectedColor: `ring-blue-${ringShade}`,
              },
              {
                label: t('common.indigo'),
                value: Color.indigo,
                bgColor: `bg-indigo-${bgShade}`,
                selectedColor: `ring-indigo-${ringShade}`,
              },
              {
                label: t('common.violet'),
                value: Color.violet,
                bgColor: `bg-violet-${bgShade}`,
                selectedColor: `ring-violet-${ringShade}`,
              },
              {
                label: t('common.purple'),
                value: Color.purple,
                bgColor: `bg-purple-${bgShade}`,
                selectedColor: `ring-purple-${ringShade}`,
              },
              {
                label: t('common.fuchsia'),
                value: Color.fuchsia,
                bgColor: `bg-fuchsia-${bgShade}`,
                selectedColor: `ring-fuchsia-${ringShade}`,
              },
              {
                label: t('common.pink'),
                value: Color.pink,
                bgColor: `bg-pink-${bgShade}`,
                selectedColor: `ring-pink-${ringShade}`,
              },
              {
                label: t('common.gray'),
                value: Color.gray,
                bgColor: `bg-gray-${bgShade}`,
                selectedColor: `ring-gray-${ringShade}`,
              },
            ].map((color) => (
              <RadioGroup.Option
                key={color.value}
                value={color.value}
                className={({ active, checked }) =>
                  classNames(
                    color.selectedColor,
                    active && checked ? 'ring ring-offset-1' : '',
                    !active && checked ? 'ring-2' : '',
                    '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none'
                  )
                }
              >
                <RadioGroup.Label as="span" className="sr-only">
                  {color.label}
                </RadioGroup.Label>
                <span
                  aria-hidden="true"
                  className={classNames(
                    color.bgColor,
                    'h-6 w-6 border border-black border-opacity-10 rounded-full'
                  )}
                />
              </RadioGroup.Option>
            ))}
          </div>
          {props.description && (
            <RadioGroup.Description className="mt-1 text-xs text-gray-500">
              {props.description}
            </RadioGroup.Description>
          )}
        </RadioGroup>
      )}
    />
  )
}

export default UiColorPicker
