enum SubscriptionStatus {
  active = 'active',
  pastDue = 'past_due',
  unpaid = 'unpaid',
  canceled = 'canceled',
  incomplete = 'incomplete',
  incompleteExpired = 'incomplete_expired',
  trialing = 'trialing',
  paused = 'paused',
}

export default SubscriptionStatus
