import UiAvatar from '../ui-kit/layout/UiAvatar'
import {
  CalendarDaysIcon,
  ChatBubbleBottomCenterIcon,
  EllipsisVerticalIcon,
  EnvelopeIcon,
  MapPinIcon,
  PencilIcon,
  PencilSquareIcon,
  PhoneIcon,
  TrashIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline'
import { formatDistanceToNow } from 'date-fns'
import { getCurrentFnsLocale } from '@/business/date-utils'
import { useRef, useState } from 'react'
import classNames from 'classnames'
import UiButton from '../ui-kit/actions/UiButton'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form'
import ContactPopulatedResponse from '@/business/dto/responses/contact-populated.response'
import ReservationPopulatedResponse from '@/business/dto/responses/reservation-populated.response'
import UserResponse from '@/business/dto/responses/user.response'
import { Link } from 'react-router-dom'
import {
  createContactNote,
  deleteContactNote,
  updateContactNote,
} from '@/business/api/contact.service'
import CreateOrUpdateNoteRequest from '@/business/dto/responses/create-or-update-note.request'
import { AxiosError } from 'axios'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import { handleValidationError } from '@/business/error-handler'
import NotePopulated from '@/business/dto/embeddeds/note-populated.response'
import DangerModal from '../modals/DangerModal'
import UiDateTime from '../ui-kit/typography/UiDateTime'
import { format, utcToZonedTime } from 'date-fns-tz'
import useAuth from '@/contexts/use-auth'
import UiMenu from '../ui-kit/actions/UiMenu'
import Role from '@/business/dto/types/role'
import AdminLink from '../ui-kit/typography/AdminLink'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Textarea,
} from '@headlessui/react'

type ContactTimelineProps = {
  children: React.ReactNode
}

const ContactTimeline = (props: ContactTimelineProps) => {
  return (
    <>
      <div className='flow-root'>
        <ul role='list' className='-mb-8'>
          {props.children}
        </ul>
      </div>
    </>
  )
}

type TimelineItemProps = {
  children: React.ReactNode
  end: boolean
}

const TimelineItem = (props: TimelineItemProps) => {
  return (
    <li>
      <div className='relative pb-8'>
        {!props.end ? (
          <span
            className='absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200'
            aria-hidden='true'
          />
        ) : null}
        <div className='relative flex items-start space-x-3'>
          {props.children}
        </div>
      </div>
    </li>
  )
}

const moods = [
  {
    name: 'E-Mail',
    value: 'thumbsy',
    icon: EnvelopeIcon,
    iconColor: 'text-white',
    bgColor: 'bg-blue-500',
  },
  {
    name: 'Telefonat',
    value: 'happy',
    icon: PhoneIcon,
    iconColor: 'text-white',
    bgColor: 'bg-green-500',
  },
  {
    name: 'Chat',
    value: 'excited',
    icon: ChatBubbleBottomCenterIcon,
    iconColor: 'text-white',
    bgColor: 'bg-pink-500',
  },
  {
    name: 'Persönlich',
    value: 'sad',
    icon: MapPinIcon,
    iconColor: 'text-white',
    bgColor: 'bg-red-500',
  },
  {
    name: 'Kein Kontakt',
    value: null,
    icon: PencilSquareIcon,
    iconColor: 'text-white',
    bgColor: 'bg-gray-400',
  },
]

type TimelineItemTextFieldProps = {
  contact: ContactPopulatedResponse
  setContact: React.Dispatch<React.SetStateAction<ContactPopulatedResponse>>
  user: UserResponse
}

const TimelineItemTextField = (props: TimelineItemTextFieldProps) => {
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<CreateOrUpdateNoteRequest>({
    defaultValues: {
      description: '',
    },
  })

  const onSubmit: SubmitHandler<CreateOrUpdateNoteRequest> = (data) => {
    setLoading(true)
    createContactNote(props.contact._id, data)
      .then((response) => {
        props.setContact((contact) => {
          return {
            ...contact,
            notes: [response, ...contact.notes],
          }
        })
        form.reset({
          description: '',
        })
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.created')}
            description={t('toast.description.noteCreated')}
          />
        ))
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
      .finally(() => setLoading(false))
  }

  return (
    <TimelineItem end={false}>
      <div className='relative'>
        <div className='ring-8 ring-white'>
          <UiAvatar
            name={props.user.name}
            color={props.user.color}
            shape='circle'
            size={10}
            image={props.user.image}
          />
        </div>
      </div>
      <TimelineTextarea
        form={form}
        onSubmit={onSubmit}
        loading={loading}
        canCancel={form.formState.isDirty}
        onCancel={() => form.reset({ description: '' })}
      />
    </TimelineItem>
  )
}

type TimelineItemNoteProps = {
  contact: ContactPopulatedResponse
  setContact: React.Dispatch<React.SetStateAction<ContactPopulatedResponse>>
  note: NotePopulated
}

const TimelineItemNote = (props: TimelineItemNoteProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const form = useForm<CreateOrUpdateNoteRequest>({
    defaultValues: {
      description: props.note.description,
    },
  })
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)

  const onSubmit: SubmitHandler<CreateOrUpdateNoteRequest> = (data) => {
    setLoading(true)
    updateContactNote(props.contact._id, props.note._id, data)
      .then((response) => {
        // props.setContact((contact) => {
        //   return {
        //     ...contact,
        //     notes: [response, ...contact.notes],
        //   };
        // });
        props.setContact((contact) => {
          return {
            ...contact,
            notes: contact.notes.map((note) =>
              note._id === response._id ? response : note
            ),
          }
        })
        setEditMode(false)
        form.reset({
          description: response.description,
        })
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.updated')}
            description={t('toast.description.noteUpdated')}
          />
        ))
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
      .finally(() => setLoading(false))
  }

  const onDelete = () =>
    deleteContactNote(props.contact._id, props.note._id).then((_) => {
      // setDeleteModalOpen(false)
      props.setContact((contact) => {
        return {
          ...contact,
          notes: contact.notes.filter((n) => n._id !== props.note._id),
        }
      })
      toast.custom((toast) => (
        <UiToast
          toast={toast}
          type='success'
          title={t('toast.title.deleted')}
          description={t('toast.description.availabilityDeleted')}
        />
      ))
    })

  return (
    <>
      <TimelineItem end={false}>
        <div>
          <div className='relative'>
            <div className='ring-8 ring-white'>
              {props.note.createdBy && (
                <UiAvatar
                  name={props.note.createdBy.name}
                  color={props.note.createdBy.color}
                  shape='circle'
                  size={10}
                  image={props.note.createdBy.image}
                />
              )}
            </div>
          </div>
        </div>
        <div className='min-w-0 flex-1'>
          <div>
            {props.note.createdBy && (
              <div className='text-sm'>
                <AdminLink
                  to={`/users/${props.note.createdBy._id}`}
                  className='font-medium text-gray-900'
                >
                  {props.note.createdBy.name}
                </AdminLink>
              </div>
            )}
            <p className='mt-0.5 text-sm text-gray-500 space-x-2'>
              <span>
                Commented{' '}
                {formatDistanceToNow(
                  new Date(props.note.createdDateTimeInUtc),
                  {
                    locale: getCurrentFnsLocale(),
                    addSuffix: true,
                  }
                )}
              </span>
              {props.note.createdDateTimeInUtc !==
                props.note.lastModifiedDateTimeInUtc && (
                <>
                  <span className='text-gray-500' aria-hidden='true'>
                    &middot;
                  </span>
                  <span>
                    Edited{' '}
                    {formatDistanceToNow(
                      new Date(props.note.lastModifiedDateTimeInUtc),
                      {
                        locale: getCurrentFnsLocale(),
                        addSuffix: true,
                      }
                    )}{' '}
                    {props.note.createdBy &&
                      props.note.lastModifiedBy &&
                      props.note.lastModifiedBy._id !==
                        props.note.createdBy._id && (
                        <>
                          by{' '}
                          <AdminLink
                            to={`/users/${props.note.lastModifiedBy._id}`}
                            className='font-medium text-gray-900'
                          >
                            {props.note.lastModifiedBy.name}
                          </AdminLink>
                        </>
                      )}
                  </span>
                </>
              )}
            </p>
          </div>
          <div className='mt-2 text-sm text-gray-700'>
            {editMode ? (
              <TimelineTextarea
                form={form}
                onSubmit={onSubmit}
                loading={loading}
                canCancel={true}
                onCancel={() => setEditMode(false)}
                autoFocus={true}
              />
            ) : (
              <p className='whitespace-pre-wrap'>{props.note.description}</p>
            )}
          </div>
        </div>
        {!editMode && (
          <div className='flex gap-3'>
            <Menu as='div' className='relative flex-none'>
              <MenuButton className='group flex rounded-md focus:outline-none p-2 -mb-2.5'>
                <span className='sr-only'>Open options</span>
                <EllipsisVerticalIcon
                  className='transition ease-in-out text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6'
                  aria-hidden='true'
                />
              </MenuButton>
              <MenuItems
                transition
                className='absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in'
              >
                <MenuItem>
                  <button
                    onClick={() => setEditMode(true)}
                    className='flex w-full px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50'
                  >
                    {t('action.editNote')}
                  </button>
                </MenuItem>
                <MenuItem>
                  <button
                    onClick={() => {
                      onDelete()
                      // setDeleteModalOpen(true)
                    }}
                    className='flex w-full px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50'
                  >
                    {t('action.deleteNote')}
                  </button>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
        )}
      </TimelineItem>
      <DangerModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        title={t('card.title.deleteAvailability')}
        description={t('card.description.deleteAvailability')}
        button={t('action.deleteAvailability')}
        action={onDelete}
      />
    </>
  )
}

type TimelineItemReservationProps = {
  reservation: ReservationPopulatedResponse
}

const TimelineItemReservation = (props: TimelineItemReservationProps) => {
  const auth = useAuth()

  return (
    <TimelineItem end={false}>
      <div>
        <div className='relative px-1'>
          <div className='flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white'>
            <CalendarDaysIcon
              className='h-5 w-5 text-gray-500'
              aria-hidden='true'
            />
          </div>
        </div>
      </div>
      <div className='min-w-0 flex-1 py-0'>
        <div className='text-sm leading-8 text-gray-500'>
          <span className='mr-0.5'>
            {props.reservation.createdBy ? (
              <>
                <AdminLink
                  to={`/users/${props.reservation.createdBy._id}`}
                  className='font-medium text-gray-900'
                >
                  {props.reservation.createdBy?.name}
                </AdminLink>{' '}
                hat eine
              </>
            ) : (
              <>Hat eine</>
            )}{' '}
            <Link
              to={`/reservations/${props.reservation._id}`}
              className='font-medium text-gray-900'
            >
              Reservierung
            </Link>{' '}
            {props.reservation.createdBy ? 'erstellt' : 'vorgenommen'}
          </span>{' '}
          <span className='mr-0.5'>
            <Link
              to={`/reservations/${props.reservation._id}`}
              className='inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200'
            >
              <UiDateTime
                dateTimeInUtc={props.reservation.startDateTimeInUtc}
                format='PPPP'
              />
            </Link>
            <Link
              to={`/reservations/${props.reservation._id}`}
              className='inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200'
            >
              {format(
                utcToZonedTime(
                  new Date(props.reservation.startDateTimeInUtc),
                  auth.company.localization.timeZone
                ),
                'p',
                {
                  locale: getCurrentFnsLocale(),
                  weekStartsOn: auth.company.localization.weekStartsOn,
                }
              )}{' '}
              -{' '}
              {format(
                utcToZonedTime(
                  new Date(props.reservation.endDateTimeInUtc),
                  auth.company.localization.timeZone
                ),
                'p',
                {
                  locale: getCurrentFnsLocale(),
                  weekStartsOn: auth.company.localization.weekStartsOn,
                }
              )}
            </Link>
            <Link
              to={`/reservations/${props.reservation._id}`}
              className='inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200'
            >
              <svg
                className={classNames('fill-red-500', 'h-1.5 w-1.5')}
                viewBox='0 0 6 6'
                aria-hidden='true'
              >
                <circle cx={3} cy={3} r={3} />
              </svg>
              {props.reservation.status}
            </Link>{' '}
          </span>
          <span className='whitespace-nowrap'>
            {formatDistanceToNow(
              new Date(props.reservation.createdDateTimeInUtc),
              {
                locale: getCurrentFnsLocale(),
                addSuffix: true,
              }
            )}
          </span>
        </div>
      </div>
    </TimelineItem>
  )
}

type TimelineItemCreatedProps = {
  contact: ContactPopulatedResponse
}

const TimelineItemCreated = (props: TimelineItemCreatedProps) => {
  return (
    <TimelineItem end={true}>
      <div>
        <div className='relative px-1'>
          <div className='flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white'>
            <UserCircleIcon
              className='h-5 w-5 text-gray-500'
              aria-hidden='true'
            />
          </div>
        </div>
      </div>
      <div className='min-w-0 flex-1 py-1.5'>
        <div className='text-sm text-gray-500'>
          {/* <a
            href={activityItem.person.href}
            className="font-medium text-gray-900"
          >
            {activityItem.person.name}
          </a>{' '}
          assigned{' '}
          <a
            href={activityItem.assigned.href}
            className="font-medium text-gray-900"
          >
            {activityItem.assigned.name}
          </a>{' '} */}
          <AdminLink
            to={`/users/${props.contact.createdBy._id}`}
            className='font-medium text-gray-900'
          >
            {props.contact.createdBy.name}
          </AdminLink>
          <span> created {props.contact.name} </span>
          <span className='whitespace-nowrap'>
            {/* <UiDateTime
              dateTimeInUtc={props.contact.createdDateTimeInUtc}
              format="PPP"
            /> */}
            {formatDistanceToNow(new Date(props.contact.createdDateTimeInUtc), {
              locale: getCurrentFnsLocale(),
              addSuffix: true,
            })}
          </span>
        </div>
      </div>
    </TimelineItem>
  )
}

type TimelineTextareaProps = {
  form: UseFormReturn<CreateOrUpdateNoteRequest, any, undefined>
  onSubmit: SubmitHandler<CreateOrUpdateNoteRequest>
  loading: boolean
  canCancel: boolean
  onCancel: () => void
  autoFocus?: boolean
}

const TimelineTextarea = (props: TimelineTextareaProps) => {
  const { t, i18n } = useTranslation()

  return (
    <div className='min-w-0 flex-1'>
      <form
        onSubmit={props.form.handleSubmit(props.onSubmit)}
        className='relative'
      >
        <div className='border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-rose-500 focus-within:ring-1 focus-within:ring-rose-500'>
          <label htmlFor='comment' className='sr-only'>
            Notiz hinzufügen
          </label>
          <Textarea
            autoFocus={props.autoFocus}
            rows={3}
            className='block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm'
            placeholder='Notiz hinzufügen...'
            {...props.form.register('description')}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey && 'form' in e.target) {
                e.preventDefault()
                ;(e.target.form as HTMLFormElement).requestSubmit()
              }
            }}
          />
          {/* Spacer element to match the height of the toolbar */}
          <div className='bg-white py-2' aria-hidden='true'>
            {/* Matches height of button in toolbar (1px border + 36px content height) */}
            <div className='py-px'>
              <div className='h-9' />
            </div>
          </div>
        </div>
        <div className='absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between'>
          <div className='flex items-center space-x-5'>
            {/* <div className="flex items-center">
                <button
                  type="button"
                  className="-m-2.5 w-10 h-10 rounded-full flex items-center justify-center text-gray-400 hover:text-gray-500"
                >
                  <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Attach a file</span>
                </button>
              </div>
              <div className="flex items-center">
                <Listbox value={selected} onChange={setSelected}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="sr-only">
                        Your mood
                      </Listbox.Label>
                      <div className="relative">
                        <Listbox.Button className="relative -m-2.5 w-10 h-10 rounded-full flex items-center justify-center text-gray-400 hover:text-gray-500">
                          <span className="flex items-center justify-center">
                            {selected.value === null ? (
                              <span>
                                <FaceSmileIcon
                                  className="flex-shrink-0 h-5 w-5"
                                  aria-hidden="true"
                                />
                                <span className="sr-only">Add your mood</span>
                              </span>
                            ) : (
                              <span>
                                <div
                                  className={classNames(
                                    selected.bgColor,
                                    'w-8 h-8 rounded-full flex items-center justify-center'
                                  )}
                                >
                                  <selected.icon
                                    className="flex-shrink-0 h-5 w-5 text-white"
                                    aria-hidden="true"
                                  />
                                </div>
                                <span className="sr-only">{selected.name}</span>
                              </span>
                            )}
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 -ml-6 w-60 bg-white shadow rounded-lg py-3 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:ml-auto sm:w-64 sm:text-sm">
                            {moods.map((mood) => (
                              <Listbox.Option
                                key={mood.value}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'bg-gray-100' : 'bg-white',
                                    'cursor-default select-none relative py-2 px-3'
                                  )
                                }
                                value={mood}
                              >
                                <div className="flex items-center">
                                  <div
                                    className={classNames(
                                      mood.bgColor,
                                      'w-8 h-8 rounded-full flex items-center justify-center'
                                    )}
                                  >
                                    <mood.icon
                                      className={classNames(
                                        mood.iconColor,
                                        'flex-shrink-0 h-5 w-5'
                                      )}
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <span className="ml-3 block font-medium truncate">
                                    {mood.name}
                                  </span>
                                </div>
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div> */}
          </div>
          <div className='flex-shrink-0 flex items-center justify-end space-x-3'>
            <UiButton
              label={t('action.cancel')}
              variant='flat'
              type='button'
              disabled={!props.canCancel}
              onClick={props.onCancel}
            />
            <UiButton
              label={t('action.save')}
              type='submit'
              variant='dark'
              loading={props.loading}
              disabled={!props.form.formState.isDirty}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default Object.assign(ContactTimeline, {
  TextField: TimelineItemTextField,
  Note: TimelineItemNote,
  Reservation: TimelineItemReservation,
  Created: TimelineItemCreated,
})
