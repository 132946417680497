import CompanyResponse from './dto/responses/company.response'

export const updatePageTitle = async (
  pageTitle: string,
  company?: CompanyResponse
) => {
  document.title = `${pageTitle}${
    company && company.name ? ` - ${company.name} ` : ' '
  }- resflow`
}
