import { HeartIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import UiContainer from './ui-kit/layout/UiContainer';

const GlobalLayout = () => {
  const { t, i18n } = useTranslation();
  const accessToken = localStorage.getItem('access_token');

  return (
    <>
      <div className='relative overflow-hidden flex flex-col justify-between min-h-screen'>
        <div>
          <svg
            className='absolute inset-0 -z-10 opacity-20 h-full w-full stroke-gray-300'
            aria-hidden='true'
          >
            <defs>
              <pattern
                id='p'
                width={32}
                height={32}
                x='50%'
                y={-1}
                patternUnits='userSpaceOnUse'
              >
                <path d='M.5 200V.5H200' fill='none' />
              </pattern>
            </defs>
            <rect
              x={0.5}
              y={0}
              width='100%'
              height='100%'
              strokeWidth={0}
              fill='url(#p)'
              transform='translate(-0.5, 0)'
            />
          </svg>
          <Outlet />
        </div>
        <footer
          className={classNames('py-2', accessToken && 'hidden md:block')}
        >
          <UiContainer>
            <div className='flex flex-col md:flex-row justify-between items-center'>
              <ul role='list' className='flex items-center space-x-2'>
                <li>
                  <a
                    href={`https://resflow.com/${i18n.language}/${t(
                      'href.legal'
                    )}/`}
                    target='_blank'
                    rel='noopener'
                    className='text-xs leading-6 text-gray-500 hover:text-gray-900'
                  >
                    {t('common.legal')}
                  </a>
                </li>
                <li className='text-gray-500' aria-hidden='true'>
                  &middot;
                </li>
                <li>
                  <a
                    href={`https://resflow.com/${i18n.language}/${t(
                      'href.terms'
                    )}/`}
                    target='_blank'
                    rel='noopener'
                    className='text-xs leading-6 text-gray-500 hover:text-gray-900'
                  >
                    {t('common.terms')}
                  </a>
                </li>
                <li className='text-gray-500' aria-hidden='true'>
                  &middot;
                </li>
                <li>
                  <a
                    href={`https://resflow.com/${i18n.language}/${t(
                      'href.privacy'
                    )}/`}
                    target='_blank'
                    rel='noopener'
                    className='text-xs leading-6 text-gray-500 hover:text-gray-900'
                  >
                    {t('common.privacy')}
                  </a>
                </li>
              </ul>
              <ul role='list' className='flex items-center space-x-2'>
                <li>
                  <a
                    href={`https://resflow.com/${i18n.language}/${t(
                      'href.changelog'
                    )}/`}
                    target='_blank'
                    rel='noopener'
                    className='text-xs leading-6 text-gray-500 hover:text-gray-900'
                  >
                    v{process.env.REACT_APP_VERSION ?? '127.0.0.1'}
                  </a>
                </li>
                <li className='text-gray-500' aria-hidden='true'>
                  &middot;
                </li>
                <li>
                  <span className='flex items-center text-xs leading-6 text-gray-500'>
                    © {new Date().getFullYear()}, made with
                    <HeartIcon className='mx-1 w-4 h-4 text-rose-600' />
                    in Germany
                  </span>
                </li>
              </ul>
            </div>
          </UiContainer>
        </footer>
      </div>
      <ScrollRestoration />
    </>
  );
};

export default GlobalLayout;
