import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiCard from '@/components/ui-kit/layout/UiCard'
import useAuth from '@/contexts/use-auth'
import { toast } from 'react-hot-toast'
import UiToast from '@/components/ui-kit/presentation/UiToast'
import {
  CalendarIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  PlusSmallIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import Exception from '@/business/dto/embeddeds/exception'
import DangerModal from '@/components/modals/DangerModal'
import CreateOrUpdateExceptionRequest from '@/business/dto/requests/create-or-update-exception.request'
import { Link } from 'react-router-dom'
import UiDateTime from '../ui-kit/typography/UiDateTime'
import { prettyPrintException } from '@/business/date-utils'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'

type ExceptionCardProps = {
  entityUri: string
  exceptions: Exception[] | undefined
  createFn: (data: CreateOrUpdateExceptionRequest) => Promise<any>
  updateFn: (
    exceptionId: string,
    data: CreateOrUpdateExceptionRequest
  ) => Promise<any>
  deleteFn: (exceptionId: string) => Promise<any>
}

const ExceptionCard = (props: ExceptionCardProps) => {
  const auth = useAuth()
  const { t } = useTranslation()
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [currentException, setCurrentException] = useState<Exception>()

  const onDelete = () =>
    currentException
      ? props.deleteFn(currentException._id).then((_) => {
          setDeleteModalOpen(false)
          props.exceptions?.splice(
            props.exceptions?.findIndex(
              (exception) => exception._id === currentException._id
            ),
            1
          )
          toast.custom((toast) => (
            <UiToast
              toast={toast}
              type='success'
              title={t('toast.title.deleted')}
              description={t('toast.description.exceptionDeleted')}
            />
          ))
        })
      : Promise.resolve()

  return (
    <>
      <UiCard>
        <UiCard.Header
          title={t('common.exceptions')}
          description='Fügen Sie Termine hinzu, an denen Ihre Verfügbarkeit von Ihren üblichen Geschäftszeiten abweicht.'
          icon={CalendarIcon}
          // description={formatAvailability(
          //   props.service.availability,
          //   auth.company.weekStartsOn,
          //   t
          // ).join(', ')}
        >
          <UiButton
            as={Link}
            label={t('action.newException')}
            variant='default'
            icon={PlusSmallIcon}
            to={`${props.entityUri}/exceptions`}
          />
        </UiCard.Header>
        <UiCard.Body>
          <dl className='space-y-5'>
            {props.exceptions?.map((exception) => (
              <div className='flex items-center justify-between'>
                <div className='w-full overflow-hidden space-y-1'>
                  <dt className='text-gray-700 text-sm truncate font-medium'>
                    {exception.reason}
                  </dt>
                  <dd className='text-gray-500 text-sm'>
                    {prettyPrintException(
                      exception,
                      auth.company.localization.weekStartsOn,
                      t
                    )}
                    {/* <div className='grid grid-cols-12 gap-x-1'>
                      

                      {/* {exception.dates.map((date) => (
                        <>
                          <div className='col-span-5 truncate'>
                            <UiDateTime dateTimeInUtc={date} format='P' />
                          </div>
                          {exception.timeIntervals.length > 0 ? (
                            exception.timeIntervals.map((interval) => (
                              <>
                                <div className='col-span-3 truncate'>
                                  {interval.startTime}
                                </div>
                                <div className='col-span-1 truncate'>-</div>
                                <div className='col-span-3 truncate'>
                                  {interval.endTime}
                                </div>
                              </>
                            ))
                          ) : (
                            <div className='col-span-7 truncate'>
                              nicht verfügbar
                            </div>
                          )}
                        </>
                      ))} }
                    </div> */}
                  </dd>
                </div>
                <div className='flex gap-3'>
                  <Menu as='div' className='relative flex-none'>
                    <MenuButton className='group flex rounded-md focus:outline-none p-2 -mb-2.5'>
                      <span className='sr-only'>Open options</span>
                      <EllipsisVerticalIcon
                        className='transition ease-in-out text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6'
                        aria-hidden='true'
                      />
                    </MenuButton>
                    <MenuItems
                      transition
                      className='absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in'
                    >
                      <MenuItem>
                        <Link
                          to={`${props.entityUri}/exceptions/${exception._id}`}
                          className='flex w-full px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50'
                        >
                          {t('action.edit')}
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <button
                          onClick={() => {
                            setCurrentException(exception)
                            setDeleteModalOpen(true)
                          }}
                          className='flex w-full px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50'
                        >
                          {t('action.delete')}
                        </button>
                      </MenuItem>
                    </MenuItems>
                  </Menu>
                </div>
              </div>
            ))}
          </dl>

          {false && (
            <ul role='list' className='my-2 divide-y divide-gray-100'>
              {props.exceptions?.map((exception) => (
                <li
                  key={exception.reason}
                  className='flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-2 sm:flex-nowrap'
                >
                  <div>
                    <div className='text-sm font-medium leading-6 text-gray-700'>
                      {exception.dates.map((date) => (
                        <div className='flex'>
                          <p className='w-32'>{date}</p>
                          <div>
                            {exception.timeIntervals.length > 0 ? (
                              exception.timeIntervals.map((interval) => (
                                <div className='flex'>
                                  <div className='w-24'>
                                    {interval.startTime}
                                  </div>
                                  <div className='w-8'>-</div>
                                  <div className='w-24'>{interval.endTime}</div>
                                </div>
                              ))
                            ) : (
                              <p>nicht verfügbar</p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className='truncate text-xs leading-5 text-gray-500'>
                      {exception.reason}
                    </div>
                  </div>
                  {/* <div className="mt-1 text-xs text-gray-500">
                    {prettyPrintException(
                      exception,
                      auth.company.localization.weekStartsOn,
                      t
                    )}
                    {false &&
                      (exception.timeIntervals
                        ? exception.timeIntervals.map((timeInterval) => (
                            <div key={timeInterval.startTime}>
                              {timeInterval.startTime} - {timeInterval.endTime}
                            </div>
                          ))
                        : 'Nicht verfügbar')}
                  </div> */}
                  <div className='flex w-full flex-none justify-between gap-x-3 sm:w-auto'>
                    <UiButton
                      as={Link}
                      label='Edit'
                      variant='light-gray'
                      icon={PencilIcon}
                      iconOnly={true}
                      to={`${props.entityUri}/exceptions/${exception._id}`}
                    />
                    <UiButton
                      label='Delete'
                      variant='danger'
                      icon={TrashIcon}
                      iconOnly={true}
                      onClick={() => {
                        setCurrentException(exception)
                        setDeleteModalOpen(true)
                      }}
                    />
                  </div>
                </li>
              ))}
            </ul>
          )}
        </UiCard.Body>
        <UiCard.Footer />
      </UiCard>
      {/* <ExceptionCreateAndEditModal
        createFn={props.createFn}
        updateFn={props.updateFn}
        open={createEditModalOpen}
        setOpen={setCreateEditModalOpen}
        exception={currentException}
      /> */}
      <DangerModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        title={t('card.title.deleteResource')}
        description={t('card.description.deleteResource')}
        button={t('action.deleteException')}
        action={onDelete}
      />
    </>
  )
}

export default ExceptionCard
