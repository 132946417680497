import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useState } from 'react'
import UpdateCompanyRequest from '@/business/dto/requests/update-company.request'
import { updateCompany } from '@/business/api/company.service'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import { BellIcon } from '@heroicons/react/24/outline'
import UiToggle from '../ui-kit/input/basic/UiToggle'
import CompanyPopulatedResponse from '@/business/dto/responses/company-populated.response'
import UiInput from '../ui-kit/input/basic/UiInput'

const mapToFormValue = (
  company: CompanyPopulatedResponse
): UpdateCompanyRequest => {
  return {
    service: {
      duration: company.service.duration,
      availability: company.service.availability,
      timeIncrements: company.service.timeIncrements,
      minimumLeadTime: company.service.minimumLeadTime / 60, // in Hours
      maximumLeadTime: company.service.maximumLeadTime / 60 / 24, // in Days
      minCapacity: company.service.minCapacity,
      maxCapacity: company.service.maxCapacity,
    },
  }
}

export type CompanyDefaultsServiceCardProps = {
  company: CompanyPopulatedResponse
  setCompany: React.Dispatch<React.SetStateAction<CompanyPopulatedResponse>>
}

const CompanyDefaultsServiceCard = (props: CompanyDefaultsServiceCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<UpdateCompanyRequest>({
    defaultValues: mapToFormValue(props.company),
  })

  const onSubmit: SubmitHandler<UpdateCompanyRequest> = (data) => {
    setLoading(true)
    updateCompany(data)
      .then((response) => {
        props.setCompany(response)
        form.reset(mapToFormValue(response))
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.updated')}
            description={t('toast.description.companyUpdated')}
          />
        ))
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <UiCard>
          <UiCard.Header
            title={t('common.serviceDefaults')}
            description={t('page.description.serviceDefaults')}
            icon={BellIcon}
          />
          <UiCard.Body>
            <div className='divide-y divide-gray-200 space-y-4'>
              {/* <div>
                <UiToggle
                  label={'Service'}
                  description='Enable service for this company'
                  name='category.enabled'
                  control={form.control}
                />
              </div> */}
              <div className='max-w-md space-y-6'>
                <UiInput
                  label={t('common.duration')}
                  type='number'
                  name='service.duration'
                  description='Legen Sie fest, wie lange Ihr Service dauert. Wird zur Berechnung der Verfügbarkeiten verwendet.'
                  trailingText={t('common.minutes')!}
                  control={form.control}
                  rules={{
                    valueAsNumber: true,
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                    maxLength: {
                      value: 64,
                      message: t('form.validation.maxLength', { x: 64 }),
                    },
                  }}
                />
                <UiInput
                  label={t('common.timeIncrements')}
                  type='number'
                  name='service.timeIncrements'
                  trailingText={t('common.minutes')!}
                  control={form.control}
                  rules={{
                    valueAsNumber: true,
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                    maxLength: {
                      value: 64,
                      message: t('form.validation.maxLength', { x: 64 }),
                    },
                  }}
                />
                <hr className='bg-gray-100' />
                <UiInput
                  label={t('common.minimumLeadTime')}
                  type='number'
                  name='service.minimumLeadTime'
                  trailingText={t('common.hours')!}
                  control={form.control}
                  rules={{
                    valueAsNumber: true,
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                    maxLength: {
                      value: 64,
                      message: t('form.validation.maxLength', { x: 64 }),
                    },
                  }}
                />
                <UiInput
                  label={t('common.maximumLeadTime')}
                  type='number'
                  name='service.maximumLeadTime'
                  trailingText={t('common.days')!}
                  control={form.control}
                  rules={{
                    valueAsNumber: true,
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                    maxLength: {
                      value: 64,
                      message: t('form.validation.maxLength', { x: 64 }),
                    },
                  }}
                />
                <hr className='bg-gray-100' />
                <UiInput
                  label={t('common.minCapacity')}
                  type='number'
                  name='service.minCapacity'
                  control={form.control}
                  rules={{
                    valueAsNumber: true,
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                    maxLength: {
                      value: 64,
                      message: t('form.validation.maxLength', { x: 64 }),
                    },
                  }}
                />
                <UiInput
                  label={t('common.maxCapacity')}
                  type='number'
                  name='service.maxCapacity'
                  control={form.control}
                  rules={{
                    valueAsNumber: true,
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                    maxLength: {
                      value: 64,
                      message: t('form.validation.maxLength', { x: 64 }),
                    },
                  }}
                />
              </div>
            </div>
          </UiCard.Body>
          <UiCard.Footer>
            <UiButton
              label={t('action.cancel')}
              variant='flat'
              type='button'
              disabled={!form.formState.isDirty}
              onClick={() => form.reset(mapToFormValue(props.company))}
            />
            <UiButton
              label={t('action.save')}
              variant='dark'
              type='submit'
              loading={loading}
              disabled={!form.formState.isDirty}
            />
          </UiCard.Footer>
        </UiCard>
      </form>
    </>
  )
}

export default CompanyDefaultsServiceCard
