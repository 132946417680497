import { useTranslation } from 'react-i18next'
import {
  CubeTransparentIcon,
  PlusSmallIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import { useState } from 'react'
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import { useNavigate } from 'react-router-dom'
import CreateOrUpdateExceptionRequest from '@/business/dto/requests/create-or-update-exception.request'
import Exception from '@/business/dto/embeddeds/exception'
import UiCalendar from '../ui-kit/input/UiCalendar'
import UiTimeCombobox from '../ui-kit/input/UiTimeCombobox'
import { Switch } from '@headlessui/react'
import classNames from 'classnames'
import UiInput from '../ui-kit/input/basic/UiInput'

export type ExceptionCreateCardProps = {
  createFn: (data: CreateOrUpdateExceptionRequest) => Promise<any>
  backHref: string
}

export const ExceptionCreateCard = (props: ExceptionCreateCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)

  const form = useForm<CreateOrUpdateExceptionRequest>({
    defaultValues: {
      dates: [],
      timeIntervals: [],
      reason: '',
    },
  })

  const onSubmit: SubmitHandler<CreateOrUpdateExceptionRequest> = (data) => {
    setLoading(true)
    props
      .createFn(data)
      .then((_) => {
        navigate(props.backHref)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type="success"
            title={t('toast.title.created')}
            description={t('toast.description.exceptionCreated')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        handleValidationError(err, i18n)
      })
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ExceptionCreateUpdateCard
          loading={loading}
          exception={undefined}
          backHref={props.backHref}
        />
      </form>
    </FormProvider>
  )
}

export type ExceptionUpdateCardProps = {
  exception: Exception
  updateFn: (
    exceptionId: string,
    data: CreateOrUpdateExceptionRequest
  ) => Promise<any>
  backHref: string
}

export const ExceptionUpdateCard = (props: ExceptionUpdateCardProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<CreateOrUpdateExceptionRequest>({
    defaultValues: {
      dates: props.exception.dates,
      timeIntervals: props.exception.timeIntervals,
      reason: props.exception.reason,
    },
  })

  const onSubmit: SubmitHandler<CreateOrUpdateExceptionRequest> = (data) => {
    props
      .updateFn(props.exception._id, data)
      .then((_) => {
        navigate(props.backHref)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type="success"
            title={t('toast.title.updated')}
            description={t('toast.description.exceptionUpdated')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        handleValidationError(err, i18n)
      })
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ExceptionCreateUpdateCard
          loading={loading}
          exception={props.exception}
          backHref={props.backHref}
        />
      </form>
    </FormProvider>
  )
}

type ExceptionCreateUpdateCardProps = {
  loading: boolean
  exception?: Exception
  backHref: string
}

const ExceptionCreateUpdateCard = (props: ExceptionCreateUpdateCardProps) => {
  const auth = useAuth()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const form = useFormContext<CreateOrUpdateExceptionRequest>()
  const timeIntervalsFieldArray = useFieldArray({
    control: form.control,
    name: 'timeIntervals',
  })

  return (
    <UiCard>
      {/* <UiCard.Header
        title={'General'}
        description={t('page.contacts.properties.description')}
        icon={CubeTransparentIcon}
      /> */}
      <UiCard.Body>
        <Controller
          name="timeIntervals"
          control={form.control}
          rules={{
            required: false,
          }}
          render={({ field: timeIntervalField }) => (
            <div className="md:grid md:grid-cols-5 md:divide-x md:divide-gray-200">
              <div className="md:pr-6 md:col-span-2">
                <Controller
                  name="dates"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                  }}
                  render={({
                    field: daysField,
                    fieldState: daysFieldState,
                  }) => (
                    <>
                      <UiCalendar
                        value={daysField.value}
                        onChanged={daysField.onChange}
                      />
                      {daysFieldState.error && (
                        <span
                          id={`${daysField.name}-error`}
                          role="alert"
                          className="mt-2 text-xs text-red-600"
                        >
                          {daysFieldState.error.message}
                        </span>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="mt-12 md:mt-0 md:pl-6 md:col-span-3 space-y-6">
                <UiInput
                  label={'Grund der Ausnahme'}
                  type="text"
                  placeholder='z. B. "Betriebsferien"'
                  name={`reason`}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                    maxLength: {
                      value: 32,
                      message: t('form.validation.maxLength', {
                        x: 32,
                      }),
                    },
                  }}
                />
                <Switch.Group as="div" className="flex items-center">
                  <Switch
                    checked={timeIntervalField.value.length > 0}
                    onChange={(e: boolean) => {
                      if (e) {
                        timeIntervalsFieldArray.append({
                          startTime: '08:00',
                          endTime: '17:00',
                        })
                      } else {
                        timeIntervalsFieldArray.remove()
                      }
                    }}
                    className={classNames(
                      timeIntervalField.value.length > 0
                        ? `bg-${auth.company.appearance.color}-600`
                        : 'bg-gray-200',
                      `relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-${auth.company.appearance.color}-500 focus:ring-offset-2`
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        timeIntervalField.value.length > 0
                          ? 'translate-x-5'
                          : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                  <span className="ml-3 flex flex-grow flex-col">
                    <Switch.Label
                      as="span"
                      className="text-sm font-medium text-gray-700"
                    >
                      Verfügbar
                    </Switch.Label>
                    <Switch.Description
                      as="span"
                      className="text-sm text-gray-500"
                    >
                      Abweichende Verfügbarkeit einstellen.
                    </Switch.Description>
                  </span>
                </Switch.Group>
                <div className="flex flex-col items-start">
                  {timeIntervalsFieldArray.fields.map(
                    (field: any, index: number) => (
                      <div
                        key={field.id}
                        className={`flex items-center space-x-3 ${
                          index === 0 ? 'py-2' : 'pb-2'
                        }`}
                      >
                        <UiTimeCombobox
                          label={''}
                          control={form.control}
                          name={`timeIntervals.${index}.startTime`}
                        />
                        <div> - </div>
                        <UiTimeCombobox
                          label={''}
                          control={form.control}
                          name={`timeIntervals.${index}.endTime`}
                        />
                        {index === 0 && (
                          <UiButton
                            type="button"
                            label="Edit"
                            variant="light-gray"
                            icon={PlusSmallIcon}
                            iconOnly={true}
                            onClick={() =>
                              timeIntervalsFieldArray.append({
                                startTime: '08:00',
                                endTime: '17:00',
                              })
                            }
                          />
                        )}
                        {index !== 0 && (
                          <UiButton
                            type="button"
                            label="Delete"
                            variant="danger"
                            icon={TrashIcon}
                            iconOnly={true}
                            onClick={() => {
                              timeIntervalsFieldArray.remove(index)
                            }}
                          />
                        )}
                      </div>
                    )
                  )}
                  {timeIntervalsFieldArray.fields.length === 0 && (
                    <div className="h-14 flex items-center">
                      <span className="text-sm text-gray-500 italic opacity-50">
                        Nicht verfügbar
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        />
      </UiCard.Body>
      <UiCard.Footer>
        <UiButton
          label={t('action.cancel')}
          type="button"
          variant="flat"
          onClick={() => navigate(props.backHref)}
        />
        <UiButton
          label={props.exception ? t('action.save') : t('action.create')}
          type="submit"
          variant={props.exception ? 'dark' : 'primary'}
          loading={props.loading}
          disabled={!form.formState.isDirty}
        />
      </UiCard.Footer>
    </UiCard>
  )
}
