import { findAllServices } from '@/business/api/service.service'
import Paginated from '@/business/dto/responses/paginated.response'
import ServiceResponse from '@/business/dto/responses/service.response'
import { ReservationCreateCard } from '@/components/modals/ReservationCreateCard'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import { LoaderFunctionArgs, defer, useLoaderData } from 'react-router-dom'

export const reservationNewPageLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const [servicePaginated] = await Promise.all([
    findAllServices({ page: 1, limit: 50, search: '' }),
  ])
  return defer({ servicePaginated })
}

const ReservationNewPage = () => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const { servicePaginated } = useLoaderData() as {
    servicePaginated: Paginated<ServiceResponse>
  }

  return (
    <UiContainer size="medium">
      <UiHeader
        title={t('page.title.reservationNew')}
        description={t('page.description.reservationNew')}
        desktopBackHref="/reservations"
        mobileBackHref="/reservations"
      />
      <div className="py-6 md:py-8">
        <div className="space-y-6">
          <ReservationCreateCard servicePaginated={servicePaginated} />
        </div>
      </div>
    </UiContainer>
  )
}

export default ReservationNewPage
