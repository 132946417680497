import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import toast, { Toast } from 'react-hot-toast'

type UiToastProps = {
  toast: Toast
  type: 'success' | 'error'
  title: string
  description: string
}

const UiToast = (props: UiToastProps) => {
  return (
    <button
      type="button"
      className={`text-left pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 ${
        props.toast.visible ? 'animate-enter' : 'animate-leave'
      }`}
      onClick={() => toast.dismiss(props.toast.id)}
    >
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">
            {props.type === 'success' && (
              <CheckCircleIcon
                className="h-6 w-6 text-green-400"
                aria-hidden="true"
              />
            )}
            {props.type === 'error' && (
              <XCircleIcon
                className="h-6 w-6 text-red-400"
                aria-hidden="true"
              />
            )}
          </div>
          <div className="ml-3 w-0 flex-1 pt-0.5">
            <p className="text-sm font-medium text-gray-900">{props.title}</p>
            {props.description && (
              <p className="mt-1 text-sm text-gray-500">{props.description}</p>
            )}
          </div>
          <div className="ml-4 flex flex-shrink-0"></div>
        </div>
      </div>
    </button>
  )
}

export default UiToast
