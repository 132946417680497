import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import {
  ExclamationTriangleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { handleValidationError } from '@/business/error-handler';
import { deleteCategoryById } from '@/business/api/category.service';
import useAuth from '@/contexts/use-auth';
import UiCard from '@/components/ui-kit/layout/UiCard';
import UiButton from '@/components/ui-kit/actions/UiButton';
import DangerModal from '../modals/DangerModal';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import UiToast from '../ui-kit/presentation/UiToast';
import CategoryPopulatedResponse from '@/business/dto/responses/category-populated.response';

export type CategoryDangerzoneCardProps = {
  category: CategoryPopulatedResponse;
  setCategoryDetails: React.Dispatch<
    React.SetStateAction<CategoryPopulatedResponse>
  >;
};

const CategoryDangerzoneCard = (props: CategoryDangerzoneCardProps) => {
  const auth = useAuth();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const onDelete = () =>
    deleteCategoryById(props.category._id)
      .then((_) => {
        setOpen(false);
        navigate('/categories');
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.deleted')}
            description={t('toast.description.categoryDeleted')}
          />
        ));
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n));

  return (
    <>
      <UiCard>
        <UiCard.Header
          title={t('common.dangerZone')}
          description={
            'Destructive actions below can result in irrecoverable data loss. Be careful.'
          }
          icon={ExclamationTriangleIcon}
        />
        <UiCard.Body>
          <UiButton
            label={t('action.deleteCategory')}
            variant='danger-outline'
            type='button'
            icon={TrashIcon}
            onClick={() => setOpen(true)}
          />
        </UiCard.Body>
        <UiCard.Footer />
      </UiCard>
      <DangerModal
        open={open}
        setOpen={setOpen}
        title={t('card.title.deleteCategory')}
        description={t('card.description.deleteCategory')}
        button={t('action.deleteCategory')}
        action={onDelete}
      />
    </>
  );
};

export default CategoryDangerzoneCard;
