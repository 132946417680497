import ct from 'countries-and-timezones'
import { enGB, de } from 'date-fns/locale'
import Country from './dto/types/country'
import countryStuff from './dto/utils/country-stuff'
import TimeZone from './dto/types/time-zone'

const locales: { [key: string]: any } = { en: enGB, de }

export const getCountryByZimezone = (timezone: TimeZone): Country => {
  return ct.getCountriesForTimezone(timezone)[0].id as Country
}

export const getWeekStartsOnByCountry = (
  country: Country
): 0 | 1 | 2 | 3 | 4 | 5 | 6 => {
  return countryStuff.find((x) => x.country === country)?.weekStartsOn || 0
}
