import { useTranslation } from 'react-i18next'
import { CubeTransparentIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiAvatar from '../ui-kit/layout/UiAvatar'
import CategoryPopulatedResponse from '@/business/dto/responses/category-populated.response'

export type CategoryGeneralCardProps = {
  category: CategoryPopulatedResponse
}

const CategoryGeneralCard = (props: CategoryGeneralCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  return (
    <UiCard>
      <UiCard.Header
        title={t('common.general')}
        description={t('card.description.categoryGeneral')}
        icon={CubeTransparentIcon}
      />
      <UiCard.Body>
        <dl className="space-y-5">
          {false && (
            <div className="w-full overflow-hidden space-y-1">
              <dt className="text-gray-700 text-sm truncate font-medium">
                {t('common.image')}
              </dt>
              <dd className="text-gray-500 text-sm truncate">
                <UiAvatar
                  name={props.category.name}
                  color={props.category.color}
                  image={props.category.image}
                  shape="rounded"
                  size={32}
                />
              </dd>
            </div>
          )}
          <div className="w-full overflow-hidden space-y-1">
            <dt className="text-gray-700 text-sm truncate font-medium">
              {t('common.name')}
            </dt>
            <dd className="text-gray-500 text-sm truncate">
              {props.category.name}
            </dd>
          </div>
          <div className="w-full overflow-hidden space-y-1">
            <dt className="text-gray-700 text-sm truncate font-medium">
              {t('common.description')}
            </dt>
            <dd className="text-gray-500 text-sm truncate">
              {props.category.description ? props.category.description : '-'}
            </dd>
          </div>
          {props.category.position && (
            <div className="w-full overflow-hidden space-y-1">
              <dt className="text-gray-700 text-sm truncate font-medium">
                {t('common.position')}
              </dt>
              <dd className="text-gray-500 text-sm truncate">
                {props.category.position}
              </dd>
            </div>
          )}
        </dl>
      </UiCard.Body>
      <UiCard.Footer />
    </UiCard>
  )
}

export default CategoryGeneralCard
