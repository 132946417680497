import { AxiosError } from 'axios'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { SubmitHandler, useForm, ValidateResult } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { updateCompany } from '@/business/api/company.service'
import { validateCompanyPublicId } from '@/business/api/public.service'
import UpdateCompanyRequest from '@/business/dto/requests/update-company.request'
import Industry from '@/business/dto/types/industry'
import { handleValidationError } from '@/business/error-handler'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiInput from '@/components/ui-kit/input/basic/UiInput'
import useAuth from '@/contexts/use-auth'
import UiIndustryCombobox from '@/components/ui-kit/input/UiIndustryCombobox'
import { updatePageTitle } from '@/business/utils'
import CompanyResponse from '@/business/dto/responses/company.response'
import Color from '@/business/dto/types/color'

const mapToFormValue = (company: CompanyResponse): UpdateCompanyRequest => {
  return {
    name: company.name || '',
    publicId: company.publicId || '',
    industry: company.industry || Industry.restaurant,
  }
}

export type Step1Props = {
  currentStep: number
  setCurrentStep: Dispatch<SetStateAction<number>>
}

const Step1 = (props: Step1Props) => {
  const auth = useAuth()
  const color = auth.company.appearance?.color || Color.rose
  const { t, i18n } = useTranslation()
  const form = useForm<UpdateCompanyRequest>({
    defaultValues: mapToFormValue(auth.company),
  })
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit: SubmitHandler<UpdateCompanyRequest> = (data) => {
    setLoading(true)
    updateCompany(data, true)
      .then((response) => {
        auth.setCompany(response)
        form.reset(mapToFormValue(response))
        props.setCurrentStep(1)
      })
      .catch((err: AxiosError) => {
        handleValidationError(err, i18n)
        setLoading(false)
      })
  }

  useEffect(() => {
    updatePageTitle(t('page.title.introStep1'), auth.company)

    if (!form.getFieldState('publicId').isDirty) {
      form.setValue(
        'publicId',
        form
          .watch('name')
          ?.toLowerCase()
          .replaceAll(' ', '-')
          .replaceAll('_', '-')
          .replaceAll('ä', 'ae')
          .replaceAll('ö', 'oe')
          .replaceAll('ü', 'ue')
          .replaceAll('ß', 'ss')
          .replaceAll(/[^a-z0-9-]/g, '') || ''
      )
    }
  }, [form.watch('name')])

  const validatePublicId = async (
    publicId: string | undefined
  ): Promise<ValidateResult> => {
    try {
      const response = await validateCompanyPublicId(publicId!)
      if (response.alreadyUsed) {
        return t('form.validation.alreadyUsed')!
      }

      return true
    } catch (err: any) {
      return t('form.validation.unexpectedError')!
    }
  }

  return (
    <>
      <div className='relative sm:mx-auto sm:w-full sm:max-w-md'>
        <img
          className='mx-auto h-6 w-auto'
          src='/img/resflow-logo.svg'
          alt={t('common.resflowLogo')!}
        />
        <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
          {t('page.title.introStep1')}
        </h2>
        <p className='mt-2 text-center text-base text-gray-600'>
          {t('page.description.introStep1')}
        </p>
      </div>

      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white px-6 shadow rounded-lg py-8 sm:px-10'>
          <form className='space-y-6' onSubmit={form.handleSubmit(onSubmit)}>
            <UiIndustryCombobox
              label={t('common.industry')}
              control={form.control}
              name='industry'
            />
            <UiInput
              label={t('common.companyName')}
              type='text'
              control={form.control}
              name='name'
              description='Bezeichnung Ihres Unternehmens.'
              autoFocus
              rules={{
                required: {
                  value: true,
                  message: t('form.validation.required'),
                },
                minLength: {
                  value: 3,
                  message: t('form.validation.minLength', { x: 3 }),
                },
                maxLength: {
                  value: 32,
                  message: t('form.validation.maxLength', { x: 32 }),
                },
              }}
            />
            <UiInput
              label={t('common.bookingPage')}
              type='text'
              leadingText={`resflow.com/`}
              description={`Die URL ist der Reservierungslink.`}
              control={form.control}
              name='publicId'
              rules={{
                required: {
                  value: true,
                  message: t('form.validation.required'),
                },
                pattern: {
                  value: /^[a-z0-9-]+$/,
                  message: t('form.validation.lowercaseAlphanumericAndHyphen'),
                },
                maxLength: {
                  value: 32,
                  message: t('form.validation.maxLength', { x: 32 }),
                },
                validate: validatePublicId,
              }}
            />
            <UiButton
              label='Weiter'
              variant='primary'
              block={true}
              loading={loading}
            />
            <nav
              className='flex items-center justify-center'
              aria-label='Progress'
            >
              <p className='text-sm font-medium'>Schritt 1 von 4</p>
              <ol role='list' className='ml-8 flex items-center space-x-5'>
                <li>
                  <div
                    className='relative flex items-center justify-center'
                    aria-current='step'
                  >
                    <span
                      className='absolute flex h-5 w-5 p-px'
                      aria-hidden='true'
                    >
                      <span
                        className={`h-full w-full rounded-full bg-${color}-200`}
                      />
                    </span>
                    <span
                      className={`relative block h-2.5 w-2.5 rounded-full bg-${color}-600`}
                      aria-hidden='true'
                    />
                    <span className='sr-only'>Schritt 1</span>
                  </div>
                </li>
                <li>
                  <div className='block h-2.5 w-2.5 rounded-full bg-gray-200'>
                    <span className='sr-only'>Schritt 2</span>
                  </div>
                </li>
                <li>
                  <div className='block h-2.5 w-2.5 rounded-full bg-gray-200'>
                    <span className='sr-only'>Schritt 3</span>
                  </div>
                </li>
                <li>
                  <div className='block h-2.5 w-2.5 rounded-full bg-gray-200'>
                    <span className='sr-only'>Schritt 4</span>
                  </div>
                </li>
              </ol>
            </nav>
          </form>
        </div>
      </div>
    </>
  )
}

export default Step1
