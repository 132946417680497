import axios from 'axios'
import CreateResourceRequest from '@/business/dto/requests/create-resource.request'
import QueryPaginationRequest from '@/business/dto/requests/query-pagination.request'
import UpdateResourceRequest from '@/business/dto/requests/update-resource.request'
import Paginated from '@/business/dto/responses/paginated.response'
import CreateOrUpdateAvailabilityRequest from '@/business/dto/requests/create-or-update-availability.request'
import CreateOrUpdateExceptionRequest from '@/business/dto/requests/create-or-update-exception.request'
import ResourceResponse from '../dto/responses/resource.response'
import ServiceResponse from '../dto/responses/service.response'
import ResourcePopulatedResponse from '../dto/responses/resource-populated.response'

const ressourceUrl = `/api/resources`

export const createResource = async (
  data: CreateResourceRequest
): Promise<ResourceResponse> => {
  return axios.post(ressourceUrl, data).then((response) => response.data)
}

export const findAllResources = async (
  query: QueryPaginationRequest
): Promise<Paginated<ResourceResponse>> => {
  return axios
    .get(ressourceUrl, {
      params: query,
    })
    .then((response) => response.data)
}

export const findResourceById = async (
  resourceId: string
): Promise<ResourceResponse> => {
  return axios
    .get(`${ressourceUrl}/${resourceId}`, {
      params: { populate: false },
    })
    .then((response) => response.data)
}

export const findResourceByIdPopulated = async (
  resourceId: string
): Promise<ResourcePopulatedResponse> => {
  return axios
    .get(`${ressourceUrl}/${resourceId}`, {
      params: { populate: true },
    })
    .then((response) => response.data)
}

export const updateResourceById = async (
  resourceId: string,
  data: UpdateResourceRequest
): Promise<ResourceResponse> => {
  return axios
    .patch(`${ressourceUrl}/${resourceId}`, data)
    .then((response) => response.data)
}

export const deleteResourceById = async (resourceId: string): Promise<void> => {
  return axios
    .delete(`${ressourceUrl}/${resourceId}`)
    .then((response) => response.data)
}

export const createResourceAvailability = async (
  resourceId: string,
  data: CreateOrUpdateAvailabilityRequest
): Promise<ResourceResponse> => {
  return axios
    .post(`${ressourceUrl}/${resourceId}/availabilities`, data)
    .then((response) => response.data)
}

export const updateResourceAvailability = async (
  resourceId: string,
  availabilityId: string,
  data: CreateOrUpdateAvailabilityRequest
): Promise<ResourceResponse> => {
  return axios
    .put(`${ressourceUrl}/${resourceId}/availabilities/${availabilityId}`, data)
    .then((response) => response.data)
}

export const deleteResourceAvailability = async (
  resourceId: string,
  availabilityId: string
): Promise<ResourceResponse> => {
  return axios
    .delete(`${ressourceUrl}/${resourceId}/availabilities/${availabilityId}`)
    .then((response) => response.data)
}

export const createResourceException = async (
  resourceId: string,
  data: CreateOrUpdateExceptionRequest
): Promise<ResourceResponse> => {
  return axios
    .post(`${ressourceUrl}/${resourceId}/exceptions`, data)
    .then((response) => response.data)
}

export const updateResourceException = async (
  resourceId: string,
  exceptionId: string,
  data: CreateOrUpdateExceptionRequest
): Promise<ResourceResponse> => {
  return axios
    .put(`${ressourceUrl}/${resourceId}/exceptions/${exceptionId}`, data)
    .then((response) => response.data)
}

export const deleteResourceException = async (
  resourceId: string,
  exceptionId: string
): Promise<ResourceResponse> => {
  return axios
    .delete(`${ressourceUrl}/${resourceId}/exceptions/${exceptionId}`)
    .then((response) => response.data)
}

export const findResourceServices = async (
  resourceId: string,
  query: QueryPaginationRequest
): Promise<Paginated<ServiceResponse>> => {
  return axios
    .get(`${ressourceUrl}/${resourceId}/services`, {
      params: query,
    })
    .then((response) => response.data)
}
