import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import UpdateCompanyRequest from '@/business/dto/requests/update-company.request'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { useCompany } from './Company'
import { CubeTransparentIcon } from '@heroicons/react/24/outline'
import CompanyPopulatedResponse from '@/business/dto/responses/company-populated.response'
import CompanyNomenclatureCard from '@/components/cards/CompanyNomenclature'

const mapToFormValue = (
  company: CompanyPopulatedResponse
): UpdateCompanyRequest => {
  return {}
}

const CompanyBookingPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { company, setCompany } = useCompany()

  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<UpdateCompanyRequest>({
    defaultValues: mapToFormValue(company),
  })

  const onSubmit: SubmitHandler<UpdateCompanyRequest> = (data) => {
    setLoading(true)
    // updateCompany(data).then((response) => {
    //   setCompany(response)
    //   form.reset(mapToFormValue(response))
    //   toast.custom((t) => (
    //     <UiToast
    //       toast={t}
    //       type="success"
    //       title="Erfolgreich gespeichert"
    //       description={`Account wurde erfolgreich gespeichert.`}
    //     />
    //   ))
    // }).catch((err: AxiosError) => handleValidationError(err, i18n))
    // .finally(() => setLoading(false))
  }

  return (
    <>
      <UiHeader
        className='md:hidden'
        title={t('common.defaults')}
        description=''
        mobileBackHref='/company'
      />
      <div className='py-6 md:py-0'>
        <div className='space-y-6'>
          <CompanyNomenclatureCard company={company} setCompany={setCompany} />
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <UiCard>
              <UiCard.Header
                title={t('common.bookingPage')}
                description={t('page.description.bookingPage')}
                icon={CubeTransparentIcon}
              />
              <UiCard.Body>
                <p>
                  Hier angeben, welche Felder für einen Kontakt auf der
                  Buchungsseite abgefragt werden sollen und welche Pflicht sind
                  (cal.com).
                </p>
                <p>Ggf. Disable Category Image </p>
                <p>Ggf. Disable Services Image </p>
                <p>Ggf. Disable Resources Image</p>
                <p>Ggf. Disable Event Image</p>
              </UiCard.Body>
              <UiCard.Footer>
                <UiButton
                  label={t('action.cancel')}
                  variant='flat'
                  type='button'
                  disabled={!form.formState.isDirty}
                  onClick={() => form.reset(mapToFormValue(company))}
                />
                <UiButton
                  label={t('action.save')}
                  variant='dark'
                  type='submit'
                  loading={loading}
                  disabled={!form.formState.isDirty}
                />
              </UiCard.Footer>
            </UiCard>
          </form>
        </div>
      </div>
    </>
  )
}

export default CompanyBookingPage
