import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import {
  LoaderFunctionArgs,
  defer,
  useLoaderData,
  useParams,
} from 'react-router-dom'
import {
  findServiceById,
  updateServiceAvailability,
} from '@/business/api/service.service'
import { AvailabilityUpdateCard } from '@/components/modals/AvailabilityCreateUpdateCard'
import CreateOrUpdateAvailabilityRequest from '@/business/dto/requests/create-or-update-availability.request'
import ServiceResponse from '@/business/dto/responses/service.response'

export const serviceAvailabilityEditPageLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const [service] = await Promise.all([findServiceById(params.serviceId!)])
  return defer({ service })
}

const ServiceAvailabilityEditPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { availabilityId } = useParams()
  const { service } = useLoaderData() as { service: ServiceResponse }

  return (
    <UiContainer size='medium'>
      <UiHeader
        title={t('page.title.serviceAvailabilityEdit')}
        description={t('page.description.serviceAvailabilityEditX', {
          x: service.name,
        })}
        desktopBackHref={`/services/${service._id}`}
        mobileBackHref={`/services/${service._id}`}
      />
      <div className='py-6 md:py-8'>
        <div className='space-y-6'>
          <AvailabilityUpdateCard
            availability={
              service.availabilities?.find((a) => a._id === availabilityId)!
            }
            updateFn={(
              exceptionId: string,
              data: CreateOrUpdateAvailabilityRequest
            ) => updateServiceAvailability(service._id, exceptionId, data)}
            backHref={`/services/${service._id}`}
          />
        </div>
      </div>
    </UiContainer>
  )
}

export default ServiceAvailabilityEditPage
