import axios from 'axios'
import ValidationResponse from '../dto/responses/validation.response'

const ressourceUrl = `/api/public`

export const validateCompanyPublicId = async (
  publicId: string
): Promise<ValidationResponse> => {
  return axios
    .get(`${ressourceUrl}/publicId-validator/${publicId}`)
    .then((response) => response.data)
}
