import UiHeader from '@/components/ui-kit/layout/UiHeader'
import { useCompany } from './Company'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import CompanyLanguageCard from '@/components/cards/CompanyLanguageCard'

const CompanyLanguagePage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { company, setCompany } = useCompany()

  return (
    <>
      <UiHeader
        className="md:hidden"
        title={t('common.languageAndRegion')}
        description=""
        mobileBackHref="/company"
      />
      <div className="py-6 md:py-0">
        <div className="space-y-6">
          <CompanyLanguageCard
            company={company}
            setCompany={setCompany}
          />
        </div>
      </div>
    </>
  )
}

export default CompanyLanguagePage
