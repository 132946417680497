import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { handleValidationError } from '@/business/error-handler'
import { deleteContactById } from '@/business/api/contact.service'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import DangerModal from '../modals/DangerModal'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import ContactPopulatedResponse from '@/business/dto/responses/contact-populated.response'

export type ContactDangerzoneCardProps = {
  contact: ContactPopulatedResponse
  setContact: React.Dispatch<React.SetStateAction<ContactPopulatedResponse>>
}

const ContactDangerzoneCard = (props: ContactDangerzoneCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [open, setOpen] = useState<boolean>(false)

  const onDelete = () =>
    deleteContactById(props.contact._id)
      .then((_) => {
        setOpen(false)
        navigate('/contacts')
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type="success"
            title={t('toast.title.deleted')}
            description={t('toast.description.contactDeleted')}
          />
        ))
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))

  return (
    <>
      <UiCard>
        <UiCard.Header
          title={t('common.dangerZone')}
          description={
            'Destructive actions below can result in irrecoverable data loss. Be careful.'
          }
          icon={ExclamationTriangleIcon}
        />
        <UiCard.Body>
          <UiButton
            label={t('action.deleteContact')}
            variant="danger"
            type="button"
            onClick={() => setOpen(true)}
          />
        </UiCard.Body>
        <UiCard.Footer />
      </UiCard>
      <DangerModal
        open={open}
        setOpen={setOpen}
        title={t('card.title.deleteContact')}
        description={t('card.description.deleteContact')}
        button={t('action.deleteContact')}
        action={onDelete}
      />
    </>
  )
}

export default ContactDangerzoneCard
