import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import UpdateCompanyRequest from '@/business/dto/requests/update-company.request'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { useCompany } from './Company'
import {
  CubeTransparentIcon,
  FaceSmileIcon,
  FolderIcon,
} from '@heroicons/react/24/outline'
import UiToggle from '@/components/ui-kit/input/basic/UiToggle'
import CompanyPopulatedResponse from '@/business/dto/responses/company-populated.response'
import CompanyDefaultsCategoryCard from '@/components/cards/CompanyDefaultsCategory'
import CompanyDefaultsServiceCard from '@/components/cards/CompanyDefaultsService'
import CompanyDefaultsResourceCard from '@/components/cards/CompanyDefaultsResource'

const mapToFormValue = (
  company: CompanyPopulatedResponse
): UpdateCompanyRequest => {
  return {}
}

const CompanyDefaultsPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { company, setCompany } = useCompany()

  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<UpdateCompanyRequest>({
    defaultValues: mapToFormValue(company),
  })

  const onSubmit: SubmitHandler<UpdateCompanyRequest> = (data) => {
    setLoading(true)
    // updateCompany(data).then((response) => {
    //   setCompany(response)
    //   form.reset(mapToFormValue(response))
    //   toast.custom((t) => (
    //     <UiToast
    //       toast={t}
    //       type="success"
    //       title="Erfolgreich gespeichert"
    //       description={`Account wurde erfolgreich gespeichert.`}
    //     />
    //   ))
    // }).catch((err: AxiosError) => handleValidationError(err, i18n))
    // .finally(() => setLoading(false))
  }

  return (
    <>
      <UiHeader
        className='md:hidden'
        title={t('common.defaults')}
        description=''
        mobileBackHref='/company'
      />
      <div className='py-6 md:py-0'>
        <div className='space-y-6'>
          <CompanyDefaultsCategoryCard
            company={company}
            setCompany={setCompany}
          />
          <CompanyDefaultsServiceCard
            company={company}
            setCompany={setCompany}
          />
          <CompanyDefaultsResourceCard
            company={company}
            setCompany={setCompany}
          />
        </div>
      </div>
    </>
  )
}

export default CompanyDefaultsPage
