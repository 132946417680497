import SubscriptionStatus from '@/business/dto/types/subscription-status'
import useAuth from '@/contexts/use-auth'
import UiImpersonatedBanner from './ui-kit/presentation/UiImpersonatedBanner'
import UiSubscriptionCanceledBanner from './ui-kit/presentation/UiSubscriptionCanceledBanner'
import UiSubscriptionTrialingBanner from './ui-kit/presentation/UiSubscriptionTrialingBanner'

const Topbar = () => {
  const auth = useAuth()

  return (
    <>
      {auth.impersonated && <UiImpersonatedBanner />}
      {auth.company.subscription && (
        <>
          {auth.company.subscription.cancelAtDateTimeInUtc ? (
            <UiSubscriptionCanceledBanner
              subscription={auth.company.subscription}
            />
          ) : (
            auth.company.subscription.status === SubscriptionStatus.trialing &&
            !auth.company.hasPaymentMethod && (
              <UiSubscriptionTrialingBanner
                subscription={auth.company.subscription}
              />
            )
          )}
        </>
      )}
    </>
  )
}

export default Topbar
