import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
} from '@headlessui/react'
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
  PlusSmallIcon,
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { ReactNode, useEffect, useState } from 'react'
import {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  RegisterOptions,
  useController,
} from 'react-hook-form'
import useAuth from '@/contexts/use-auth'
import Paginated from '@/business/dto/responses/paginated.response'
import { useTranslation } from 'react-i18next'
import UiAvatar from '../../layout/UiAvatar'
import Color from '@/business/dto/types/color'

type Entity = {
  _id: string
}

type UiComboboxLazyProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TEntity extends Entity
> = {
  control: Control<TFieldValues>
  name: TName
  rules?: RegisterOptions<TFieldValues, TName>
  onChange?: (value: PathValue<TFieldValues, TName>) => void

  label?: string | null
  disabled?: boolean
  className?: string

  onSearchChanged: (search: string) => void
  paginated: Paginated<TEntity> | undefined
  view: (entity: TEntity) => ReactNode
  createEmptyState?: boolean
}

const UiComboboxLazy = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TEntity extends Entity = Entity
>(
  props: UiComboboxLazyProps<TFieldValues, TName, TEntity>
) => {
  const auth = useAuth()
  const color = auth?.company?.appearance?.color || 'rose'
  const { t, i18n } = useTranslation()

  const {
    field: { onChange, onBlur, value, name, ref },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name: props.name,
    control: props.control,
    rules: props.rules,
  })

  const [search, setSearch] = useState<string>('')
  const [loadingTimeout, setLoadingTimeout] = useState<NodeJS.Timeout | null>(
    null
  )

  const handleSearchChanged = (search: string) => {
    if (loadingTimeout) {
      clearTimeout(loadingTimeout)
    }

    setLoadingTimeout(setTimeout(() => props.onSearchChanged(search), 200))
  }

  useEffect(() => {
    if (loadingTimeout) {
      clearTimeout(loadingTimeout)
      setLoadingTimeout(null)
    }

    if (props.createEmptyState && props.paginated?.totalResults === 0) {
      const entity = {
        _id: null!,
        name: props.paginated.search,
      }

      props.paginated.results = [entity as any as TEntity]
    }
  }, [props.paginated])

  return (
    <Combobox
      as='div'
      className={props.className}
      value={value}
      onChange={(e: PathValue<TFieldValues, TName>) => {
        props.onChange?.(e)
        onChange(e as any)
      }}
      disabled={props.disabled}
      nullable={false}
      multiple={false}
    >
      {props.label && (
        <Label className='mb-1 block text-sm font-medium text-gray-700'>
          {props.label}
        </Label>
      )}
      <div className='relative'>
        <ComboboxButton
          className={classNames(
            'relative w-full rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm border border-gray-300 focus:outline-none focus:ring-2 text-sm leading-6',
            `focus:ring-${auth.company.appearance.color}-600`
          )}
        >
          {false && (
            <span className='block truncate'>{value?.name || <>&nbsp;</>}</span>
          )}
          {value ? (
            <>
              {(value as TEntity)._id ? (
                props.view(value as TEntity)
              ) : (
                <div className='flex items-center space-x-4'>
                  <div className='flex-shrink-0'>
                    <UiAvatar
                      name={''}
                      color={Color.gray}
                      shape='circle'
                      size={10}
                      image={null}
                    />
                  </div>
                  <div className='min-w-0 flex-1'>
                    <div className='truncate text-sm font-medium text-gray-900'>
                      Neuer Kontakt
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div>&nbsp;</div>
          )}
          <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
            <ChevronUpDownIcon
              className='h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          </span>
        </ComboboxButton>

        <ComboboxOptions
          className='absolute z-50 mt-1 overflow-hidden w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
          modal={false}
        >
          <div className='sticky top-0 bg-white w-full border-b border-gray'>
            <label htmlFor='search' className='sr-only'>
              Search
            </label>
            <div className='relative flex rounded-md shadow-none'>
              <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                <MagnifyingGlassIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              </div>
              <ComboboxInput
                className='bg-transparent block w-full rounded-md border border-transparent py-3 px-10 focus:border-transparent focus:ring-0 sm:text-sm'
                value={search}
                displayValue={(_: string) => search}
                onChange={(event) => {
                  setSearch(event.target.value)
                  handleSearchChanged(event.target.value)
                }}
              />
              {loadingTimeout && (
                <div
                  className={classNames(
                    true
                      ? 'absolute top-1/2 right-4 transform -translate-y-1/2'
                      : 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
                  )}
                >
                  <svg
                    className={`animate-spin h-4 w-4 text-${color}-500`}
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                    ></path>
                  </svg>
                </div>
              )}
            </div>
          </div>
          <div className='max-h-60 w-full overflow-auto p-2 text-base sm:text-sm space-y-0.5'>
            {props.paginated && (
              <>
                {props.paginated.results.length === 0 ? (
                  <>
                    {props.paginated?.search ? (
                      <>
                        <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                          Keine Treffer
                        </div>
                      </>
                    ) : (
                      <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                        Keine Elemente
                      </div>
                    )}
                  </>
                ) : (
                  props.paginated.results.map((option) => (
                    <ComboboxOption
                      key={option._id}
                      value={option}
                      // disabled={option.disabled}
                      className={({ active, selected }) =>
                        classNames(
                          'group relative cursor-pointer select-none rounded-md py-2 px-3',
                          selected
                            ? `text-white bg-${color}-500`
                            : 'text-gray-900',
                          active && !selected && 'bg-gray-100'
                        )
                      }
                    >
                      {
                        ({ active, selected, disabled }) => (
                          <>
                            {(option as TEntity)._id ? (
                              props.view(option as TEntity)
                            ) : (
                              <div className='flex items-center text-sm'>
                                <PlusSmallIcon
                                  className='mr-3 h-5 w-5 text-gray-500'
                                  aria-hidden='true'
                                />
                                <span className='truncate'>
                                  '{(option as any).name}' anlegen
                                </span>
                              </div>
                            )}
                          </>
                        )
                        // (
                        //   <>
                        //     <span
                        //       className={classNames(
                        //         'block truncate',
                        //         selected && !disabled && 'font-semibold',
                        //         disabled && 'text-gray-400'
                        //       )}
                        //     >
                        //       <div className="flex items-center space-x-4">
                        //         <div className="flex-shrink-0">
                        //           <UiAvatar
                        //             name={option.name}
                        //             color={option.color}
                        //             image={option.image}
                        //             shape="rounded"
                        //             size={10}
                        //           />
                        //         </div>
                        //         <div className="min-w-0 flex-1 pr-8 overflow-hidden">
                        //           <p className="truncate text-sm font-semibold text-gray-700">
                        //             {option.name}
                        //           </p>
                        //           {/* <p className="truncate text-sm text-gray-500">
                        //             {option.duration} {t('common.minutes')},{' '}
                        //             {new Intl.NumberFormat(i18n.language, {
                        //               style: 'currency',
                        //               currency:
                        //                 auth.company.localization.currency,
                        //             }).format(option.price || 0)}
                        //           </p> */}
                        //         </div>
                        //       </div>
                        //     </span>
                        //     {selected && (
                        //       <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                        //         <CheckCircleIcon
                        //           className="h-5 w-5"
                        //           aria-hidden="true"
                        //         />
                        //       </span>
                        //     )}
                        //   </>
                        // )
                      }
                    </ComboboxOption>
                  ))
                )}
              </>
            )}
          </div>
        </ComboboxOptions>
      </div>
      {error && (
        <span
          id={`${name}-error`}
          role='alert'
          className='mt-2 text-xs text-red-600'
        >
          {error.message}
        </span>
      )}
    </Combobox>
  )
}

export default UiComboboxLazy
