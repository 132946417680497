import { KeyIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { SubmitHandler, ValidateResult, useForm } from 'react-hook-form'
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { resetPassword } from '@/business/api/auth.service'
import UiInput from '@/components/ui-kit/input/basic/UiInput'
import { toast } from 'react-hot-toast'
import UiToast from '@/components/ui-kit/presentation/UiToast'
import { useTranslation } from 'react-i18next'
import { updatePageTitle } from '@/business/utils'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import UiButton from '@/components/ui-kit/actions/UiButton'
import ResetPasswordRequest from '@/business/dto/requests/reset-password.request'
import PublicLayout from '@/components/PublicLayout'

const ResetPasswordPage = () => {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<ResetPasswordRequest>({
    defaultValues: {
      email: searchParams.get('email') || '',
      token: searchParams.get('token') || '',
      password: '',
      passwordConfirmation: '',
    },
  })

  useEffect(() => {
    updatePageTitle(t('page.title.resetPassword'))
  }, [])

  const [formPassword, formPasswordConfirmation] = form.watch([
    'password',
    'passwordConfirmation',
  ])

  const onSubmit: SubmitHandler<ResetPasswordRequest> = (data) => {
    setLoading(true)
    resetPassword(data)
      .then((response) => {
        localStorage.setItem('token_type', response.tokenType)
        localStorage.setItem('access_token', response.accessToken)
        localStorage.setItem('refresh_token', response.refreshToken)
        navigate('/', { replace: true })
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type="success"
            title={t('toast.title.reset')}
            description={t('toast.description.passwordReset')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          toast.custom((toast) => (
            <UiToast
              toast={toast}
              type="error"
              title={t('toast.title.badRequest')}
              description={t('toast.description.badRequest')}
            />
          ))
        } else {
          handleValidationError(err, i18n)
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      {searchParams.get('email') && searchParams.get('token') ? (
        <>
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-6 w-auto"
              src="/img/resflow-logo.svg"
              alt={t('common.resflowLogo')!}
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              {t('page.title.resetPassword')}
            </h2>
            <p className="mt-2 text-center text-base text-gray-600">
              {t('page.description.resetPassword')}
            </p>
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white px-6 shadow rounded-lg py-8 sm:px-10">
              <form
                className="space-y-6"
                onSubmit={form.handleSubmit(onSubmit)}
              >
                <UiInput
                  label={t('common.password')}
                  type="password"
                  icon={KeyIcon}
                  placeholder={t('form.placeholder.password')!}
                  autoComplete="password"
                  autoFocus
                  name="password"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                    minLength: {
                      value: 8,
                      message: t('form.validation.minLength', { x: 8 }),
                    },
                    maxLength: {
                      value: 64,
                      message: t('form.validation.maxLength', { x: 64 }),
                    },
                  }}
                />
                <UiInput
                  label={t('common.passwordConfirmation')}
                  type="password"
                  icon={KeyIcon}
                  placeholder={t('form.placeholder.password')!}
                  autoComplete="password"
                  name="passwordConfirmation"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                    validate: (value: string | undefined): ValidateResult => {
                      return (
                        value === formPassword ||
                        t('form.validation.passwordsDoNotMatch')!
                      )
                    },
                  }}
                />
                <UiButton
                  type="submit"
                  label={t('action.resetPassword')}
                  variant="primary"
                  block={true}
                  loading={loading}
                />
              </form>
            </div>
          </div>
        </>
      ) : (
        <Navigate to="/sign-in" replace />
      )}
    </>
  )
}

export default ResetPasswordPage
