import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import { LoaderFunctionArgs, defer, useLoaderData } from 'react-router-dom'
import { findCategoryById } from '@/business/api/category.service'
import { CategoryUpdateCard } from '@/components/modals/CategoryCreateUpdateCard'

export const categoryEditLoader = async ({ params }: LoaderFunctionArgs) => {
  const [category] = await Promise.all([
    findCategoryById(params.categoryId!),
  ])
  return defer({ category })
}

const CategoryEditPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { category } = useLoaderData() as any

  return (
    <UiContainer size="small">
      <UiHeader
        title={t('page.title.categoryEdit')}
        description={t('page.description.categoryEditX', {
          x: category.name,
        })}
        desktopBackHref={`/categories/${category._id}`}
        mobileBackHref={`/categories/${category._id}`}
      />
      <div className="py-6 md:py-8">
        <div className="space-y-6">
          <CategoryUpdateCard category={category} />
        </div>
      </div>
    </UiContainer>
  )
}

export default CategoryEditPage
