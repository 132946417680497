import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import classNames from 'classnames'

export type UiSearchProps = {
  value: string
  onChanged: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder: string
  loadingTimeout?: NodeJS.Timeout | null
}

const UiSearch = (props: UiSearchProps) => {
  const auth = useAuth()
  const color = auth?.company?.appearance?.color || 'rose'
  const ref = React.useRef<HTMLInputElement>(null)

  useHotkeys('shift+7', (e) => setTimeout(() => ref?.current?.focus()))

  return (
    <div className="min-w-0 flex-1">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="relative flex rounded-md shadow-none">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          type="text"
          name="search"
          id="search"
          ref={ref}
          value={props.value}
          onChange={(e) => props.onChanged(e)}
          placeholder={props.placeholder}
          className="bg-transparent block w-full rounded-md border border-transparent pl-10 pr-16 focus:border-transparent focus:ring-0 sm:text-sm sm:leading-6"
        />
        {props.loadingTimeout && (
          <div
            className={classNames(
              true
                ? 'absolute top-1/2 right-4 md:right-10 transform -translate-y-1/2'
                : 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            )}
          >
            <svg
              className={`animate-spin h-4 w-4 text-${color}-500`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        )}
        <div className="absolute inset-y-0 right-0 py-1.5 pr-1.5 hidden md:flex">
          <kbd className="inline-flex items-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400">
            /
          </kbd>
        </div>
      </div>
    </div>
  )
}

export default UiSearch
