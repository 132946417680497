import { useTranslation } from 'react-i18next'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiDateTime from '../ui-kit/typography/UiDateTime'
import UserPopulatedResponse from '@/business/dto/responses/user-populated.response'

export type UserMetadataCardProps = {
  user: UserPopulatedResponse
}

const UserMetadataCard = (props: UserMetadataCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  return (
    <UiCard>
      <UiCard.Header
        title={t('common.metadata')}
        description={
          props.user._id === auth.user._id
            ? t('card.description.accountMetadata')
            : t('card.description.userMetadata')
        }
        icon={InformationCircleIcon}
      />
      <UiCard.Body>
        <dl className="space-y-5">
          <div className="w-full overflow-hidden space-y-1">
            <dt className="text-gray-700 text-sm truncate font-medium">
              {t('common.lastSignIn')}
            </dt>
            <dd className="text-gray-500 text-sm truncate">
              {props.user.lastLoginDateTimeInUtc ? (
                <UiDateTime
                  dateTimeInUtc={props.user.lastLoginDateTimeInUtc}
                  format="PPPP"
                />
              ) : (
                '-'
              )}
            </dd>
          </div>
          <div className="w-full overflow-hidden space-y-1">
            <dt className="text-gray-700 text-sm truncate font-medium">
              {t('common.lastModified')}
            </dt>
            <dd className="text-gray-500 text-sm truncate">
              {props.user.lastModifiedDateTimeInUtc ? (
                <UiDateTime
                  dateTimeInUtc={props.user.lastModifiedDateTimeInUtc}
                  format="PPPP"
                />
              ) : (
                '-'
              )}
            </dd>
          </div>
          {props.user.createdDateTimeInUtc && (
            <div className="w-full overflow-hidden space-y-1">
              <dt className="text-gray-700 text-sm truncate font-medium">
                {t('common.created')}
              </dt>
              <dd className="text-gray-500 text-sm truncate">
                <UiDateTime
                  dateTimeInUtc={props.user.createdDateTimeInUtc}
                  format="PPPP"
                />
              </dd>
            </div>
          )}
        </dl>
      </UiCard.Body>
      <UiCard.Footer />
    </UiCard>
  )
}

export default UserMetadataCard
