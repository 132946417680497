import { useMatch, useResolvedPath } from 'react-router-dom'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import {
  UserGroupIcon as UserGroupIconOutline,
  BuildingOffice2Icon as BuildingOffice2IconOutline,
} from '@heroicons/react/24/outline'
import {
  UserGroupIcon as UserGroupIconSolid,
  BuildingStorefrontIcon as BuildingStorefrontIconSolid,
} from '@heroicons/react/24/solid'
import UiMobileBottomNavigation from '../../components/ui-kit/layout/UiMobileBottomNavigation'

const StaffBottomNavigation = () => {
  const auth = useAuth()
  const color = auth?.company?.appearance?.color || 'rose'
  const { t } = useTranslation()

  let isEmployees = !!useMatch({
    path: useResolvedPath('/employees').pathname,
    end: false,
  })
  let isCompanies = !!useMatch({
    path: useResolvedPath('/companies').pathname,
    end: true,
  })

  return (
    <UiMobileBottomNavigation>
      <UiMobileBottomNavigation.Item
        label="resflower"
        to="/employees"
        icon={UserGroupIconOutline}
        active={isEmployees}
        iconActive={UserGroupIconSolid}
        activeClass={`text-${color}-500`}
      />
      <UiMobileBottomNavigation.Item
        label={t('common.companies')}
        to="/companies"
        icon={BuildingOffice2IconOutline}
        active={isCompanies}
        iconActive={BuildingStorefrontIconSolid}
        activeClass={`text-${color}-500`}
      />
    </UiMobileBottomNavigation>
  )
}

export default StaffBottomNavigation
