import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import {
  LoaderFunctionArgs,
  defer,
  useLoaderData,
  useParams,
} from 'react-router-dom'
import {
  findResourceById,
  updateResourceAvailability,
} from '@/business/api/resource.service'
import { AvailabilityUpdateCard } from '@/components/modals/AvailabilityCreateUpdateCard'
import CreateOrUpdateAvailabilityRequest from '@/business/dto/requests/create-or-update-availability.request'
import ResourceResponse from '@/business/dto/responses/resource.response'

export const resourceAvailabilityEditPageLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const [resource] = await Promise.all([findResourceById(params.resourceId!)])
  return defer({ resource })
}

const ResourceAvailabilityEditPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { availabilityId } = useParams()
  const { resource } = useLoaderData() as { resource: ResourceResponse }

  return (
    <UiContainer size='medium'>
      <UiHeader
        title={t('page.title.resourceAvailabilityEdit')}
        description={t('page.description.resourceAvailabilityEditX', {
          x: resource.name,
        })}
        desktopBackHref={`/resources/${resource._id}`}
        mobileBackHref={`/resources/${resource._id}`}
      />
      <div className='py-6 md:py-8'>
        <div className='space-y-6'>
          <AvailabilityUpdateCard
            availability={
              resource.availabilities?.find((a) => a._id === availabilityId)!
            }
            updateFn={(
              exceptionId: string,
              data: CreateOrUpdateAvailabilityRequest
            ) => updateResourceAvailability(resource._id, exceptionId, data)}
            backHref={`/resources/${resource._id}`}
          />
        </div>
      </div>
    </UiContainer>
  )
}

export default ResourceAvailabilityEditPage
