import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import AvailabiltiesCard from './AvailabilitiesCard'
import CreateOrUpdateAvailabilityRequest from '@/business/dto/requests/create-or-update-availability.request'
import {
  createServiceAvailability,
  deleteServiceAvailability,
  updateServiceAvailability,
} from '@/business/api/service.service'
import ServicePopulatedResponse from '@/business/dto/responses/service-populated.response'

export type ServiceAvailabilitiesCardProps = {
  service: ServicePopulatedResponse
  setService: React.Dispatch<React.SetStateAction<ServicePopulatedResponse>>
}

const ServiceAvailabilitiesCard = (props: ServiceAvailabilitiesCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  return (
    <AvailabiltiesCard
      entityUri={`/services/${props.service._id}`}
      availabilities={props.service.availabilities}
      createFn={(data: CreateOrUpdateAvailabilityRequest) =>
        createServiceAvailability(props.service._id, data)
      }
      updateFn={(
        exceptionId: string,
        data: CreateOrUpdateAvailabilityRequest
      ) =>
        updateServiceAvailability(props.service._id, exceptionId, data)
      }
      deleteFn={(exceptionId: string) =>
        deleteServiceAvailability(props.service._id, exceptionId)
      }
    />
  )
}

export default ServiceAvailabilitiesCard
