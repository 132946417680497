import React, { HTMLAttributes } from 'react'
import {
  ArrowTopRightOnSquareIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'
import { Link, LinkProps } from 'react-router-dom'
import UiAvatar from './UiAvatar'
import Color from '@/business/dto/types/color'
import classNames from 'classnames'

type UiListProps = {
  children: React.ReactNode
  title?: string
  description?: string
}

const UiList = (props: UiListProps) => {
  return (
    <div className="space-y-1">
      {props.title && (
        <div className="text-xs mx-3 text-gray-500 uppercase">
          {props.title}
        </div>
      )}
      <div className="overflow-hidden rounded-md bg-white shadow">
        <ul role="list" className="divide-y divide-gray-200">
          {props.children}
        </ul>
      </div>
      {props.description && (
        <div className="text-xs mx-3 text-gray-500">{props.description}</div>
      )}
    </div>
  )
}

type UiListItemProps = {
  title: string
  subtitle?: string | null
  extra?: string
  icon?: React.ElementType
  href?: string
  onClick?: () => void
  color?: Color
  avatar?: {
    name: string
    color: Color
    shape: 'circle' | 'rounded'
    image: string | null
  }
}

const UiListItem = (props: UiListItemProps) => {
  let Component: React.ElementType
  let attributes: any // LinkProps | React.HTMLAttributes<HTMLDivElement>
  let className: string

  if (props.href && !props.href.startsWith('http')) {
    Component = Link
    attributes = { to: props.href, replace: true, 'aria-hidden': 'true' }
    className = 'hover:bg-gray-50'
  } else if (props.href && props.href.startsWith('http')) {
    Component = 'a'
    attributes = {
      href: props.href,
      target: '_blank',
      rel: 'noopener',
      'aria-hidden': 'true',
    }
    className = 'hover:bg-gray-50'
  } else if (props.onClick) {
    Component = 'button'
    attributes = { onClick: props.onClick, 'aria-hidden': 'true' }
    className = 'text-left block w-full hover:bg-gray-50'
  } else {
    Component = 'div' as React.ElementType
    attributes = {}
    className = ''
  }

  return (
    <li className="relative overflow-hidden">
      <Component
        className={`flex items-center min-h-[44px] w-full px-4 py-2 ${className}`}
        {...attributes}
      >
        {props.icon && (
          <div className="mr-3">
            <props.icon className="text-gray-400 h-6 w-6" />
          </div>
        )}
        {props.avatar && (
          <div className="mr-3">
            <UiAvatar
              name={props.avatar.name}
              color={props.avatar.color || Color.gray}
              image={props.avatar.image}
              shape={props.avatar.shape}
              size={12}
            />
          </div>
        )}
        <div className="flex-1 py-2 overflow-hidden">
          <div className="text-gray-900 font-medium truncate">
            {props.title}
          </div>
          {props.subtitle && (
            <p className="text-sm text-gray-500 truncate">{props.subtitle}</p>
          )}
        </div>
        {(props.extra || props.href) && (
          <div className="flex-shrink-0 flex items-center pl-3">
            {props.extra && (
              <div className="text-gray-500 mr-1">{props.extra}</div>
            )}
            {props.href && !props.href.startsWith('http') && (
              <ChevronRightIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            )}
            {props.href && props.href.startsWith('http') && (
              <ArrowTopRightOnSquareIcon
                className="text-gray-400 h-5 pb-[2px] w-5 pl-[2px]"
                aria-hidden="true"
              />
            )}
          </div>
        )}
      </Component>
    </li>
  )
}

export default Object.assign(UiList, {
  Item: UiListItem,
})
