enum Color {
  gray = 'gray',
  red = 'red',
  orange = 'orange',
  amber = 'amber',
  yellow = 'yellow',
  lime = 'lime',
  green = 'green',
  emerald = 'emerald',
  teal = 'teal',
  cyan = 'cyan',
  sky = 'sky',
  blue = 'blue',
  indigo = 'indigo',
  violet = 'violet',
  purple = 'purple',
  fuchsia = 'fuchsia',
  pink = 'pink',
  rose = 'rose',
}

export default Color
