import Color from '@/business/dto/types/color'

const ressourceUrl = `/api/images`

const alphabet = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
]

export const toImagePath = (image: string): string => {
  return `${ressourceUrl}/${image}`
}

const colors = Object.values(Color).filter((x) => x !== Color.gray)

export const calcColor = (name: string): Color => {
  if (name) {
    let sum = 0

    for (let index = 0; index < name.length; index++) {
      let number = alphabet.indexOf(name.toLowerCase().charAt(index)) + 1
      if (number) {
        sum += number
      }
    }

    if (sum) {
      return colors[sum % colors.length]
    }
  }

  return Color.gray
}

export const calcInitials = (name: string): string => {
  if (name) {
    const segments = name
      .toLowerCase()
      .replaceAll('ä', 'ae')
      .replaceAll('ö', 'oe')
      .replaceAll('ü', 'ue')
      .replaceAll('ß', 'ss')
      .replaceAll(/[^a-z\s]/g, '')
      .toUpperCase()
      .split(' ')
      .filter((x) => x && x.length > 0)
    if (segments) {
      if (segments.length === 1) {
        return segments[0].charAt(0)
      }

      if (segments.length > 1) {
        return `${segments[0].charAt(0)}${segments[segments.length - 1].charAt(
          0
        )}`
      }
    }
  }

  return ''
}
