import classNames from 'classnames'
import React from 'react'

type UiContainerProps = {
  children?: React.ReactNode
  className?: string
  size?: 'small' | 'medium' | 'large'
}

const UiContainer = (props: UiContainerProps) => {
  return (
    <div
      className={classNames(
        props.size === 'small' && 'max-w-2xl',
        props.size == 'medium' && 'max-w-screen-lg',
        (!props.size || props.size == 'large') &&
          'max-w-screen-xl 2xl:max-w-screen-2xl',
        'mx-auto px-4 sm:px-6',
        props.className
      )}
    >
      {props.children}
    </div>
  )
}

export default UiContainer
