import {
  createResourceException,
  findResourceById,
} from '@/business/api/resource.service'
import CreateOrUpdateExceptionRequest from '@/business/dto/requests/create-or-update-exception.request'
import ResourceResponse from '@/business/dto/responses/resource.response'
import { ExceptionCreateCard } from '@/components/modals/ExceptionCreateUpdateCard'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import { LoaderFunctionArgs, defer, useLoaderData } from 'react-router-dom'

export const resourceExceptionNewPageLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const [resource] = await Promise.all([
    findResourceById(params.resourceId!),
  ])
  return defer({ resource })
}

const ResourceExceptionNewPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { resource } = useLoaderData() as { resource: ResourceResponse }

  return (
    <UiContainer size="medium">
      <UiHeader
        title={t('page.title.resourceExceptionNew')}
        description={t('page.description.resourceExceptionNewX', {
          x: resource.name,
        })}
        desktopBackHref={`/resources/${resource._id}`}
        mobileBackHref={`/resources/${resource._id}`}
      />
      <div className="py-6 md:py-8">
        <div className="space-y-6">
          <ExceptionCreateCard
            createFn={(data: CreateOrUpdateExceptionRequest) =>
              createResourceException(resource._id, data)
            }
            backHref={`/resources/${resource._id}`}
          />
        </div>
      </div>
    </UiContainer>
  )
}

export default ResourceExceptionNewPage
