import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import UiAvatar from './UiAvatar'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiMobileNavigationBar from './UiMobileNavigationBar'
import { useTranslation } from 'react-i18next'
import Color from '@/business/dto/types/color'
import classNames from 'classnames'
import useAuth from '@/contexts/use-auth'
import { updatePageTitle } from '@/business/utils'

type UiHeaderProps = {
  className?: string
  title: string
  description: string
  avatar?: {
    name: string
    color: Color
    shape: 'circle' | 'rounded'
    image: string | null
  }
  mobileBackHref?: string
  mobileAction?: {
    icon: React.ElementType
    onClick?: () => void
    to?: string
  }
  desktopBackHref?: string
  desktopActions?: React.ReactNode
  search?: React.ReactNode
  skipPageTitleUpdate?: boolean
}

const UiHeader = (props: UiHeaderProps) => {
  const auth = useAuth()
  const { t } = useTranslation()

  useEffect(() => {
    if (!props.skipPageTitleUpdate) {
      updatePageTitle(props.title, auth.company)
    }
  }, [])

  return (
    <div className={props.className}>
      <div
        className={classNames(
          'block md:hidden',
          auth.impersonated ? 'pb-16' : 'pb-12'
        )}
      >
        <UiMobileNavigationBar
          title={props.title}
          backHref={props.mobileBackHref}
          action={props.mobileAction}
          search={props.search}
          avatar={props.avatar}
        />
      </div>

      {props.search && (
        <div className="md:hidden pt-4 -mb-2">{props.search}</div>
      )}

      <div className="hidden md:flex items-center justify-between gap-6 pt-8">
        <div
          className={`flex ${
            props.avatar
              ? 'flex-col items-center text-center md:flex-row md:items-start md:text-left'
              : 'flex-row items-start'
          }`}
        >
          {props.desktopBackHref && (
            <div className="flex items-center h-14 mr-3.5">
              <UiButton
                variant="flat"
                as={Link}
                label={t('action.back')}
                to={props.desktopBackHref}
                icon={ChevronLeftIcon}
                iconOnly={true}
              />
            </div>
          )}
          {props.avatar && (
            <div className="flex-shrink-0 mb-5 md:mb-0 md:mr-5">
              <UiAvatar {...props.avatar} size={14} />
            </div>
          )}
          <div>
            <h2 className="text-gray-900 tracking-tight text-3xl font-bold leading-7 space-x-3">
              {props.title}
            </h2>
            {props.description && (
              <p className="text-sm text-gray-500 mt-2">{props.description}</p>
            )}
          </div>
        </div>

        <div className="flex justify-end gap-3">
          {props.search}
          {props.desktopActions}
        </div>
      </div>
    </div>
  )
}

export default UiHeader
