import { getCurrentFnsLocale } from '@/business/date-utils'
import useAuth from '@/contexts/use-auth'
import { addDays, formatISO, isSameDay } from 'date-fns'
import { format, toDate, utcToZonedTime } from 'date-fns-tz'

type UiDateProps = {
  date: string
  format: string
}

const UiDate = (props: UiDateProps) => {
  const auth = useAuth()
  const zonedNow = utcToZonedTime(
    new Date(),
    auth.company.localization.timeZone
  )
  const isoDateTime = toDate(props.date, {
    timeZone: auth.company.localization.timeZone,
  })
  const zonedDateTime = utcToZonedTime(
    isoDateTime,
    auth.company.localization.timeZone
  )

  console.log('isoDateTime', isoDateTime)

  return (
    <time dateTime={formatISO(isoDateTime)}>
      {format(isoDateTime, props.format, {
        locale: getCurrentFnsLocale(),
        weekStartsOn: auth.company.localization.weekStartsOn,
        timeZone: auth.company.localization.timeZone,
      })}
      {/* {isSameDay(addDays(zonedDateTime, -2), zonedNow) && <> - vorgestern</>}
      {isSameDay(addDays(zonedDateTime, -1), zonedNow) && <> - gestern</>}
      {isSameDay(zonedDateTime, zonedNow) && <> - heute</>}
      {isSameDay(addDays(zonedDateTime, 1), zonedNow) && <> - morgen</>}
      {isSameDay(addDays(zonedDateTime, 2), zonedNow) && <> - übermorgen</>} */}
    </time>
  )
}

export default UiDate

// 'PPPPpp'
