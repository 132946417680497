import axios from 'axios'
import CreateReservationRequest from '@/business/dto/requests/create-reservation.request'
import UpdateReservationRequest from '@/business/dto/requests/update-reservation.request'
import QueryReservationsRequest from '@/business/dto/requests/query-reservations.request'
import ReservationsPaginated from '../dto/responses/reservations-paginated'
import ReservationResponse from '../dto/responses/reservation.response'
import ReservationPopulatedResponse from '../dto/responses/reservation-populated.response'

const ressourceUrl = `/api/reservations`

export const createReservation = async (
  data: CreateReservationRequest
): Promise<ReservationResponse> => {
  return axios.post(ressourceUrl, data).then((response) => response.data)
}

export const findAllReservations = async (
  query: QueryReservationsRequest
): Promise<ReservationsPaginated> => {
  return axios
    .get(ressourceUrl, {
      params: query,
    })
    .then((response) => response.data)
}

export const findReservationById = async (
  reservationId: string
): Promise<ReservationPopulatedResponse> => {
  return axios
    .get(`${ressourceUrl}/${reservationId}`, {
      params: { populate: false },
    })
    .then((response) => response.data)
}

export const findReservationByIdPopulated = async (
  resourceId: string
): Promise<ReservationPopulatedResponse> => {
  return axios
    .get(`${ressourceUrl}/${resourceId}`, {
      params: { populate: true },
    })
    .then((response) => response.data)
}

export const updateReservationById = async (
  reservationId: string,
  data: UpdateReservationRequest
): Promise<ReservationResponse> => {
  return axios
    .patch(`${ressourceUrl}/${reservationId}`, data)
    .then((response) => response.data)
}

export const deleteReservationById = async (
  reservationId: string
): Promise<void> => {
  return axios
    .delete(`${ressourceUrl}/${reservationId}`)
    .then((response) => response.data)
}
