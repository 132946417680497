import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { handleValidationError } from '@/business/error-handler'
import { AxiosError } from 'axios'
import {
  createServiceQuestion,
  updateServiceQuestion,
} from '@/business/api/service.service'
import UiCombobox from '../ui-kit/input/basic/UiCombobox'
import CustomQuestionType from '@/business/dto/types/custom-question-type'
import UiInput from '../ui-kit/input/basic/UiInput'
import CreateOrUpdateQuestionRequest from '@/business/dto/requests/create-or-update-question.request'
import CustomQuestion from '@/business/dto/embeddeds/custom-question'
import UiToggle from '../ui-kit/input/basic/UiToggle'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import ServiceResponse from '@/business/dto/responses/service.response'

const mapToFormValue = (
  question?: CustomQuestion
): CreateOrUpdateQuestionRequest => {
  return {
    name: (question && question.name) || '',
    type: (question && question.type) || CustomQuestionType.singleLine,
    enabled: (question && question.enabled) || true,
    required: (question && question.required) || false,
    placeholder: (question && question.placeholder) || '',
    options: (question && question.options) || [
      { name: 'Option 1' },
      { name: 'Option 2' },
    ],
  }
}

type ServiceQuestionCreateUpdateCardProps = {
  service: ServiceResponse
  question?: CustomQuestion
}

const ServiceQuestionCreateUpdateCard = (
  props: ServiceQuestionCreateUpdateCardProps
) => {
  const auth = useAuth()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<CreateOrUpdateQuestionRequest>({
    defaultValues: mapToFormValue(props.question),
  })
  const optionsFieldArray = useFieldArray({
    control: form.control,
    name: 'options',
  })

  const onSubmit: SubmitHandler<CreateOrUpdateQuestionRequest> = (data) => {
    setLoading(true)
    if (props.question) {
      updateServiceQuestion(props.service._id, props.question._id, data)
        .then((response) => {
          navigate(`/services/${response._id}`)
          toast.custom((toast) => (
            <UiToast
              toast={toast}
              type="success"
              title={t('toast.title.updated')}
              description={t('toast.description.questionUpdated')}
            />
          ))
        })
        .catch((err: AxiosError) => {
          setLoading(false)
          handleValidationError(err, i18n)
        })
    } else {
      createServiceQuestion(props.service._id, data)
        .then((response) => {
          navigate(`/services/${response._id}`)
          toast.custom((toast) => (
            <UiToast
              toast={toast}
              type="success"
              title={t('toast.title.created')}
              description={t('toast.description.questionCreated')}
            />
          ))
        })
        .catch((err: AxiosError) => {
          setLoading(false)
          handleValidationError(err, i18n)
        })
    }
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <UiCard>
        {/* <UiCard.Header
          title={'General'}
          description={t('page.contacts.properties.description')}
          icon={CubeTransparentIcon}
        /> */}
        <UiCard.Body>
          <Controller
            name="options"
            control={form.control}
            rules={{
              required: false,
            }}
            render={({ field }) => (
              <div className="space-y-6">
                <UiCombobox
                  label="Fragetyp"
                  options={[
                    {
                      label: 'Single Line',
                      value: CustomQuestionType.singleLine,
                    },
                    {
                      label: 'Multi Line',
                      value: CustomQuestionType.multiLine,
                    },
                    {
                      label: 'Single Select',
                      value: CustomQuestionType.singleSelect,
                    },
                    {
                      label: 'Multi Select',
                      value: CustomQuestionType.multiSelect,
                    },
                  ]}
                  name="type"
                  control={form.control}
                />
                <UiInput
                  label="Titel"
                  type="text"
                  placeholder="Bla"
                  name={`name`}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                    maxLength: {
                      value: 32,
                      message: t('form.validation.maxLength', {
                        x: 32,
                      }),
                    },
                  }}
                />
                {(form.watch('type') === CustomQuestionType.singleLine ||
                  form.watch('type') === CustomQuestionType.multiLine) && (
                  <UiInput
                    label="Platzhalter"
                    type="text"
                    placeholder="Bla"
                    name={`placeholder`}
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: t('form.validation.required'),
                      },
                      maxLength: {
                        value: 32,
                        message: t('form.validation.maxLength', {
                          x: 32,
                        }),
                      },
                    }}
                  />
                )}
                {(form.watch('type') === CustomQuestionType.singleSelect ||
                  form.watch('type') === CustomQuestionType.multiSelect) &&
                  optionsFieldArray.fields.map((field, index) => (
                    <>
                      <UiInput
                        label={`Option ${index + 1}`}
                        type="text"
                        placeholder="Bla"
                        name={`options.${index}.name`}
                        control={form.control}
                        rules={{
                          required: {
                            value: true,
                            message: t('form.validation.required'),
                          },
                          maxLength: {
                            value: 32,
                            message: t('form.validation.maxLength', {
                              x: 32,
                            }),
                          },
                        }}
                      />
                    </>
                  ))}
                <UiToggle
                  label="Erforderlich"
                  description="Diese Frage muss beantwortet werden."
                  name="required"
                  control={form.control}
                />
              </div>
            )}
          />
        </UiCard.Body>
        <UiCard.Footer>
          <UiButton
            label={t('action.cancel')}
            type="button"
            variant="flat"
            onClick={() => navigate(`/services/${props.service._id}`)}
          />
          <UiButton
            label={t('action.save')}
            type="submit"
            variant="dark"
            loading={loading}
            disabled={!form.formState.isDirty}
          />
        </UiCard.Footer>
      </UiCard>
    </form>
  )
}

export default ServiceQuestionCreateUpdateCard
