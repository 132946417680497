import { useState } from 'react'
import {
  Link,
  LoaderFunctionArgs,
  defer,
  useLoaderData,
} from 'react-router-dom'
import classNames from 'classnames'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import {
  ArrowsPointingOutIcon,
  BanknotesIcon,
  ClockIcon,
  CubeTransparentIcon,
  FaceSmileIcon,
  FolderIcon,
  PlusSmallIcon,
} from '@heroicons/react/24/outline'
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import UiSearch from '@/components/ui-kit/input/UiSearch'
import UiAvatar from '@/components/ui-kit/layout/UiAvatar'
import {
  findAllServices,
  updateServiceById,
} from '@/business/api/service.service'
import useAuth from '@/contexts/use-auth'
import UiBadge from '@/components/ui-kit/presentation/UiBadge'
import UiButton from '@/components/ui-kit/actions/UiButton'
import Paginated from '@/business/dto/responses/paginated.response'
import { handleValidationError } from '@/business/error-handler'
import { toast } from 'react-hot-toast'
import UiToast from '@/components/ui-kit/presentation/UiToast'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiPagination from '@/components/ui-kit/presentation/UiPagination'
import UiNoSearchResults from '@/components/ui-kit/presentation/UiNoSearchResults'
import UiEmptyState from '@/components/ui-kit/presentation/UiEmptyState'
import ServiceResponse from '@/business/dto/responses/service.response'

const loadPage = (page: number, search?: string) =>
  findAllServices({ page, limit: 10, search })

export const searvicesPageLoader = async ({ params }: LoaderFunctionArgs) => {
  const [services] = await Promise.all([loadPage(1)])

  return defer({
    services,
  })
}

const ServicesPage = () => {
  const auth = useAuth()
  const color = auth?.company?.appearance?.color || 'rose'
  const { t, i18n } = useTranslation()
  const loaderData = useLoaderData() as any
  const [paginated, setPaginated] = useState<Paginated<ServiceResponse>>(
    loaderData.services
  )
  const [search, setSearch] = useState('')
  const [loadingTimeout, setLoadingTimeout] = useState<NodeJS.Timeout | null>(
    null
  )

  const reload = (page: number, search: string) => {
    loadPage(page, search)
      .then((response) => {
        setPaginated(response)
        setLoadingTimeout(null)
        window.scrollTo(0, 0)
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  const handleSearchChanged = (search: string) => {
    setSearch(search)

    if (loadingTimeout) {
      clearTimeout(loadingTimeout)
    }

    setLoadingTimeout(setTimeout(() => reload(1, search), 200))
  }

  const handlePageChanged = (page: number) => {
    reload(page, search)
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 2,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 2,
      },
    })
  )

  const handleDragEnd = (event: any) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const index = paginated.results.findIndex((x) => x._id === active.id)
      const overIndex = paginated.results.findIndex((x) => x._id === over.id)

      if (index !== -1 && overIndex !== -1) {
        setPaginated((paginated) => {
          return {
            ...paginated,
            results: arrayMove(paginated.results, index, overIndex),
          }
        })

        updateServiceById(active.id, {
          position: paginated.results[overIndex]?.position,
        })
          .then(() => {
            toast.custom((toast) => (
              <UiToast
                toast={toast}
                type='success'
                title={t('toast.title.updated')}
                description={t('toast.description.positionUpdated')}
              />
            ))
          })
          .catch((err: AxiosError) => {
            setPaginated((paginated) => {
              return {
                ...paginated,
                results: arrayMove(paginated.results, overIndex, index),
              }
            })
            handleValidationError(err, i18n)
          })
      }
    }
  }

  return (
    <UiContainer>
      <UiHeader
        title={`${t('common.services')} (${paginated.totalResults})`}
        description={t('page.description.services')}
        mobileBackHref='/settings'
        mobileAction={{ icon: PlusSmallIcon, to: '/services/new' }}
        desktopActions={
          <div>
            {(paginated.totalResults > 0 || paginated.search) && (
              <>
                <UiButton
                  as={Link}
                  variant='primary'
                  label={t('action.newService')}
                  icon={PlusSmallIcon}
                  type='button'
                  to='/services/new'
                />
              </>
            )}
          </div>
        }
        search={
          <div>
            {(paginated.totalResults > 0 || paginated.search) && (
              <>
                <UiSearch
                  value={search}
                  onChanged={(e) => handleSearchChanged(e.target.value)}
                  placeholder={t('form.placeholder.searchService')}
                  loadingTimeout={loadingTimeout}
                />
              </>
            )}
          </div>
        }
      />
      <div className='py-6 space-y-6 md:py-8 md:space-y-8'>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={paginated.results.map((x) => {
              return { id: x._id, ...x }
            })}
            strategy={rectSortingStrategy}
          >
            <ul
              role='list'
              className='grid grid-cols-1 gap-2 md:gap-6 sm:grid-cols-2 lg:grid-cols-3'
            >
              {paginated.results.map((service) => (
                <SortableService service={service} key={service._id} />
              ))}
            </ul>
          </SortableContext>
        </DndContext>
        {paginated.totalResults > 0 && (
          <p className='flex items-center text-xs text-gray-500'>
            <ArrowsPointingOutIcon className='mx-1 w-4 h-4 text-gray-600' />
            {t('hint.description.servicesDragAndDrop')}
          </p>
        )}
        {paginated.totalPages > 1 && (
          <div className='flex justify-center'>
            <UiPagination
              page={paginated.page}
              totalPages={paginated.totalPages}
              onPageChanged={handlePageChanged}
            />
          </div>
        )}
        {paginated.totalResults === 0 && !paginated.search && (
          <UiEmptyState
            icon={FaceSmileIcon}
            title={t('hint.title.serviceEmptyState')}
            description={t('hint.description.serviceEmptyState')}
            // description={`Bei einem ${
            //   t('common.service')
            // } handelt es sich um eine bestimmte Leistung oder Aktivität,
            //       die von Ihrem Unternehmen angeboten und von Kunden gebucht
            //       oder reserviert werden kann.`}
            button={{
              label: t('action.newService'),
              to: '/services/new',
            }}
          />
        )}
        {paginated.totalResults === 0 && paginated.search && (
          <UiNoSearchResults />
        )}
      </div>
    </UiContainer>
  )
}

const SortableService = (props: { service: ServiceResponse }) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const sortable = useSortable({ id: props.service._id })
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = sortable

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={classNames(
        'relative col-span-1 bg-white shadow rounded-lg hover:bg-gray-50',
        isDragging && 'z-50'
      )}
      // onContextMenu={(e) => {
      //   e.preventDefault() // prevent the default behaviour when right clicked
      //   console.log('Right Click')
      // }}
    >
      <div className='flex items-center space-x-4 p-4 sm:px-6'>
        <div className='flex-shrink-0'>
          <UiAvatar
            name={props.service.name}
            color={props.service.color}
            image={props.service.image}
            shape='rounded'
            size={14}
          />
        </div>
        <div className='min-w-0 flex-1 overflow-hidden'>
          <p className='truncate text-sm font-semibold text-gray-700'>
            {props.service.name}
          </p>
          {props.service.description && (
            <p className='truncate text-sm text-gray-500'>
              {props.service.description}
            </p>
          )}
          {!props.service.description && (
            <p className='truncate text-sm text-gray-500 italic'>
              {t('common.noDescription')}
            </p>
          )}
          <ul className='mt-2 flex flex-wrap gap-1'>
            <li>
              <UiBadge
                icon={ClockIcon}
                label={t('common.countMinutes', {
                  count: props.service.duration,
                })}
              />
            </li>
            <li>
              {props.service.price && (
                <UiBadge icon={BanknotesIcon} label={props.service.price} />
              )}
            </li>
            <li>
              <UiBadge
                icon={FolderIcon}
                label={t('common.countCategories', {
                  count: props.service.categoryIds?.length || 0,
                })}
              />
            </li>
            <li>
              <UiBadge
                icon={CubeTransparentIcon}
                label={t('common.countResources', {
                  count: props.service.resourceIds?.length || 0,
                })}
              />
            </li>
          </ul>
        </div>
      </div>
      {!isDragging && (
        <Link
          to={`/services/${props.service._id}`}
          className='absolute top-0 right-0 bottom-0 left-0'
        />
      )}
    </li>
  )
}

export default ServicesPage
