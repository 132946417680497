import { useTranslation } from 'react-i18next'
import { FaceSmileIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import ServicePopulatedResponse from '@/business/dto/responses/service-populated.response'

export type ServiceGeneralCardProps = {
  service: ServicePopulatedResponse
}

const ServiceGeneralCard = (props: ServiceGeneralCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  return (
    <UiCard>
      <UiCard.Header
        title={t('common.general')}
        description={t('card.description.serviceGeneral')}
        icon={FaceSmileIcon}
      />
      <UiCard.Body>
        <dl className="space-y-5">
          <div className="w-full overflow-hidden space-y-1">
            <dt className="text-gray-700 text-sm truncate font-medium">
              {t('common.name')}
            </dt>
            <dd className="text-gray-500 text-sm truncate">
              {props.service.name}
            </dd>
          </div>
          <div className="w-full overflow-hidden space-y-1">
            <dt className="text-gray-700 text-sm truncate font-medium">
              {t('common.description')}
            </dt>
            <dd className="text-gray-500 text-sm truncate">
              {props.service.description
                ? props.service.description
                : '-'}
            </dd>
          </div>
          {props.service.duration && (
            <div className="w-full overflow-hidden space-y-1">
              <dt className="text-gray-700 text-sm truncate font-medium">
                {t('common.duration')}
              </dt>
              <dd className="text-gray-500 text-sm truncate">
                {props.service.duration} Minuten
              </dd>
            </div>
          )}
          {props.service.price && (
            <div className="w-full overflow-hidden space-y-1">
              <dt className="text-gray-700 text-sm truncate font-medium">
                {t('common.price')}
              </dt>
              <dd className="text-gray-500 text-sm truncate">
                {props.service.price}
              </dd>
            </div>
          )}
          {props.service.position && (
            <div className="w-full overflow-hidden space-y-1">
              <dt className="text-gray-700 text-sm truncate font-medium">
                {t('common.position')}
              </dt>
              <dd className="text-gray-500 text-sm truncate">
                {props.service.position}
              </dd>
            </div>
          )}
        </dl>
      </UiCard.Body>
      <UiCard.Footer />
    </UiCard>
  )
}

export default ServiceGeneralCard
