import { useTranslation } from 'react-i18next'
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiInput from '../ui-kit/input/basic/UiInput'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useState } from 'react'
import UpdateCompanyRequest from '@/business/dto/requests/update-company.request'
import { updateCompany } from '@/business/api/company.service'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import CompanyPopulatedResponse from '@/business/dto/responses/company-populated.response'

const mapToFormValue = (company: CompanyPopulatedResponse): UpdateCompanyRequest => {
  return {
    // name: company.name,
    // publicId: company.publicId,
    // image: company.image,
    // description: company.description,
    // industry: company.industry,
  }
}

export type CompanyContactOptionCardProps = {
  company: CompanyPopulatedResponse
  setCompany: React.Dispatch<React.SetStateAction<CompanyPopulatedResponse>>
}

const CompanyContactOptionCard = (props: CompanyContactOptionCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<UpdateCompanyRequest>({
    defaultValues: mapToFormValue(props.company),
  })

  const onSubmit: SubmitHandler<UpdateCompanyRequest> = (data) => {
    setLoading(true)
    updateCompany(data)
      .then((response) => {
        props.setCompany(response)
        form.reset(mapToFormValue(response))
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type="success"
            title={t('toast.title.updated')}
            description={t('toast.description.companyUpdated')}
          />
        ))
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
      .finally(() => setLoading(false))
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <UiCard>
        <UiCard.Header
          title={t('common.contactOptions')}
          description={t('card.description.companyContactOptions')}
          icon={ChatBubbleLeftEllipsisIcon}
        />
        <UiCard.Body>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 sm:col-span-6 space-y-6">
              <UiInput
                label={t('common.emailAddress')}
                type="email"
                name="email"
                placeholder={
                  t('form.placeholder.companyEmailAddressX', {
                    x: auth.company.publicId,
                  })!
                }
                control={form.control}
                rules={{
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: t('form.validation.email'),
                  },
                  maxLength: {
                    value: 64,
                    message: t('form.validation.maxLength', { x: 64 }),
                  },
                }}
              />
              <UiInput
                label={t('common.phoneNumber')}
                type="tel"
                name="phone"
                placeholder={t('form.placeholder.companyPhone')!}
                control={form.control}
                rules={{
                  maxLength: {
                    value: 64,
                    message: t('form.validation.maxLength', { x: 64 }),
                  },
                }}
              />
              <UiInput
                label={t('common.website')}
                type="text"
                name="website"
                placeholder={
                  t('form.placeholder.companyWebsiteX', {
                    x: auth.company.publicId,
                  })!
                }
                control={form.control}
                rules={{
                  maxLength: {
                    value: 64,
                    message: t('form.validation.maxLength', { x: 64 }),
                  },
                }}
              />
            </div>
          </div>
        </UiCard.Body>
        <UiCard.Footer>
          <UiButton
            label={t('action.cancel')}
            variant="flat"
            type="button"
            disabled={!form.formState.isDirty}
            onClick={() => form.reset(mapToFormValue(props.company))}
          />
          <UiButton
            label={t('action.save')}
            variant="dark"
            type="submit"
            loading={loading}
            disabled={!form.formState.isDirty}
          />
        </UiCard.Footer>
      </UiCard>
    </form>
  )
}

export default CompanyContactOptionCard
