import {
  createServiceAvailability,
  findServiceById,
} from '@/business/api/service.service'
import CreateOrUpdateAvailabilityRequest from '@/business/dto/requests/create-or-update-availability.request'
import ServiceResponse from '@/business/dto/responses/service.response'
import { AvailabilityCreateCard } from '@/components/modals/AvailabilityCreateUpdateCard'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import { LoaderFunctionArgs, defer, useLoaderData } from 'react-router-dom'

export const serviceAvailabilityNewPageLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const [service] = await Promise.all([findServiceById(params.serviceId!)])
  return defer({ service })
}

const ServiceAvailabilityNewPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { service } = useLoaderData() as { service: ServiceResponse }

  return (
    <UiContainer size='medium'>
      <UiHeader
        title={t('page.title.serviceAvailabilityNew')}
        description={t('page.description.serviceAvailabilityNewX', {
          x: service.name,
        })}
        desktopBackHref={`/services/${service._id}`}
        mobileBackHref={`/services/${service._id}`}
      />
      <div className='py-6 md:py-8'>
        <div className='space-y-6'>
          <AvailabilityCreateCard
            createFn={(data: CreateOrUpdateAvailabilityRequest) =>
              createServiceAvailability(service._id, data)
            }
            backHref={`/services/${service._id}`}
          />
        </div>
      </div>
    </UiContainer>
  )
}

export default ServiceAvailabilityNewPage
