import { useTranslation } from 'react-i18next'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiDateTime from '../ui-kit/typography/UiDateTime'
import CompanyPopulatedResponse from '@/business/dto/responses/company-populated.response'
import UiAvatar from '../ui-kit/layout/UiAvatar'

export type CompanyMetadataCardProps = {
  company: CompanyPopulatedResponse
}

const CompanyMetadataCard = (props: CompanyMetadataCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  return (
    <UiCard>
      <UiCard.Header
        title={t('common.metadata')}
        description={t('card.description.companyMetadata')}
        icon={InformationCircleIcon}
      />
      <UiCard.Body>
        <dl className="space-y-5">
          <div className="w-full overflow-hidden space-y-1">
            <dt className="text-gray-700 text-sm truncate font-medium">
              {t('common.lastModified')}{' '}
            </dt>
            <dd className="text-gray-500 text-sm truncate">
              {props.company.lastModifiedDateTimeInUtc ? (
                <UiDateTime
                  dateTimeInUtc={props.company.lastModifiedDateTimeInUtc}
                  format="PPPP"
                />
              ) : (
                '-'
              )}
            </dd>
          </div>
          {props.company.createdDateTimeInUtc && (
            <div className="w-full overflow-hidden space-y-1">
              <dt className="text-gray-700 text-sm truncate font-medium">
                {t('common.created')}{' '}
              </dt>
              <dd className="text-gray-500 text-sm truncate">
                <UiDateTime
                  dateTimeInUtc={props.company.createdDateTimeInUtc}
                  format="PPPP"
                />
              </dd>
            </div>
          )}
        </dl>
      </UiCard.Body>
      <UiCard.Footer />
    </UiCard>
  )
}

export default CompanyMetadataCard
