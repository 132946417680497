import { AxiosError } from 'axios'
import { toast } from 'react-hot-toast'
import UiToast from '@/components/ui-kit/presentation/UiToast'
import { i18n } from 'i18next'

export const handleValidationError = (err: AxiosError, i18n: i18n): void => {
  if (err.response?.status === 400) {
    toast.custom((toast) => (
      <UiToast
        toast={toast}
        type='error'
        title={i18n.t('toast.title.badRequest')}
        description={i18n.t('toast.description.badRequest')}
      />
    ))
  } else {
    toast.custom((toast) => (
      <UiToast
        toast={toast}
        type='error'
        title={i18n.t('toast.title.unknownError')}
        description={i18n.t('toast.description.unknownError')}
      />
    ))
  }
}
