import React from 'react'
import { useTranslation } from 'react-i18next'
import paginated from '@/business/dto/responses/paginated.response'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'

type UiNoSearchResultsProps = {}

const UiNoSearchResults = (props: UiNoSearchResultsProps) => {
  const { t, i18n } = useTranslation()
  const auth = useAuth()
  const color = auth?.company?.appearance?.color || 'rose'

  return (
    <div className="text-center max-w-2xl mx-auto">
      <MagnifyingGlassIcon className="mx-auto h-12 w-12 text-gray-400" />
      <div className="mt-2 text-sm font-semibold text-gray-900">
        {t('hint.title.noSearchResults')}
      </div>
      <p className="mt-1 text-sm text-gray-500">
        {t('hint.description.noSearchResults')}
      </p>
    </div>
  )
}

export default UiNoSearchResults
