import { useTranslation } from 'react-i18next'
import { CubeTransparentIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import Paginated from '@/business/dto/responses/paginated.response'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import { findServiceResources } from '@/business/api/service.service'
import UiButton from '../ui-kit/actions/UiButton'
import UiAvatar from '../ui-kit/layout/UiAvatar'
import UiPagination from '../ui-kit/presentation/UiPagination'
import UiEmptyState from '../ui-kit/presentation/UiEmptyState'
import UiNoSearchResults from '../ui-kit/presentation/UiNoSearchResults'
import { Link } from 'react-router-dom'
import ServicePopulatedResponse from '@/business/dto/responses/service-populated.response'
import ResourceResponse from '@/business/dto/responses/resource.response'

const loadPage = (serviceId: string, page: number) =>
  findServiceResources(serviceId, { page, limit: 10 })

export type ServiceResourcesCardProps = {
  service: ServicePopulatedResponse
  setServiceDetails: React.Dispatch<
    React.SetStateAction<ServicePopulatedResponse>
  >
  paginatedResources: Paginated<ResourceResponse>
  setPaginatedResources: React.Dispatch<
    React.SetStateAction<Paginated<ResourceResponse>>
  >
}

const ServiceResourcesCard = (props: ServiceResourcesCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  const reload = (page: number) => {
    loadPage(props.service._id, page)
      .then((response) => {
        props.setPaginatedResources(response)
        window.scrollTo(0, 0)
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  const handlePageChanged = (page: number) => {
    reload(page)
  }

  return (
    <UiCard>
      <UiCard.Header
        title={`Zugewiesene ${t('common.resources')} (${
          props.paginatedResources.totalResults
        })`}
        description={t('page.description.serviceResources')}
        icon={CubeTransparentIcon}
      >
        <>
          {(props.paginatedResources.totalResults > 0 ||
            props.paginatedResources.search) && (
            <>
              <UiButton
                as={Link}
                label={`${t('common.resource')} zuweisen`}
                variant="default"
                icon={PlusSmallIcon}
                to={`/services/${props.service._id}/resources`}
              />
            </>
          )}
        </>
      </UiCard.Header>
      <UiCard.Body>
        <div className="space-y-6 md:space-y-8">
          <ul role="list" className="-my-2">
            {props.paginatedResources.results.map((resource) => (
              <li key={resource._id} className="py-2">
                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    <UiAvatar
                      name={resource.name}
                      color={resource.color}
                      image={resource.image}
                      shape="circle"
                      size={10}
                    />
                  </div>
                  <div className="min-w-0 flex-1">
                    <p className="truncate text-sm font-medium text-gray-900">
                      {resource.name}
                    </p>
                    <p className="truncate text-sm text-gray-500">
                      {resource.capacity} Person(en)
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {props.paginatedResources.totalPages > 1 && (
            <div className="flex justify-center w-full">
              <UiPagination
                page={props.paginatedResources.page}
                totalPages={props.paginatedResources.totalPages}
                onPageChanged={handlePageChanged}
              />
            </div>
          )}
          {props.paginatedResources.totalResults === 0 &&
            !props.paginatedResources.search && (
              <UiEmptyState
                icon={CubeTransparentIcon}
                title={`Es wurden noch keine ${t(
                  'common.resources'
                )} hinzugefügt`}
                description={`Bei einer ${t(
                  'common.resource'
                )} handelt es sich um ...`}
                button={{
                  label: `${t('common.resource')} zuweisen`,
                  to: `/services/${props.service._id}/resources`,
                }}
              />
            )}
          {props.paginatedResources.totalResults === 0 &&
            props.paginatedResources.search && <UiNoSearchResults />}
        </div>
      </UiCard.Body>
      <UiCard.Footer />
    </UiCard>
  )
}

export default ServiceResourcesCard
