import { useTranslation } from 'react-i18next'
import {
  ChatBubbleBottomCenterIcon,
  EnvelopeIcon,
  FaceSmileIcon,
  MapPinIcon,
  PaperClipIcon,
  PencilSquareIcon,
  PhoneIcon,
  SignalIcon,
  TagIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import classNames from 'classnames'
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import ContactTimeLine from './ContactTimeLine'
import ContactPopulatedResponse from '@/business/dto/responses/contact-populated.response'
import ContactResponse from '@/business/dto/responses/contact.response'
import { getCurrentFnsLocale } from '@/business/date-utils'
import { formatDistanceToNow } from 'date-fns'
import NotePopulated from '@/business/dto/embeddeds/note-populated.response'
import ReservationPopulatedResponse from '@/business/dto/responses/reservation-populated.response'
import ReservationResponse from '@/business/dto/responses/reservation.response'

const activity = [
  {
    id: 1,
    type: 'comment',
    person: { name: 'Eduardo Benz', href: '#' },
    image:
      'https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. ',
    date: '6d ago',
  },
  {
    id: 2,
    type: 'assignment',
    person: { name: 'Hilary Mahy', href: '#' },
    assigned: { name: 'Kristin Watson', href: '#' },
    date: '2d ago',
  },
  {
    id: 3,
    type: 'tags',
    person: { name: 'Hilary Mahy', href: '#' },
    tags: [
      { name: 'Bug', href: '#', color: 'bg-rose-500' },
      { name: 'Accessibility', href: '#', color: 'bg-rose-500' },
    ],
    date: '6h ago',
  },
  {
    id: 4,
    type: 'comment',
    person: { name: 'Jason Meyers', href: '#' },
    image:
      'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.',
    date: '2h ago',
  },
]

const moods = [
  {
    name: 'E-Mail',
    value: 'thumbsy',
    icon: EnvelopeIcon,
    iconColor: 'text-white',
    bgColor: 'bg-blue-500',
  },
  {
    name: 'Telefonat',
    value: 'happy',
    icon: PhoneIcon,
    iconColor: 'text-white',
    bgColor: 'bg-green-500',
  },
  {
    name: 'Chat',
    value: 'excited',
    icon: ChatBubbleBottomCenterIcon,
    iconColor: 'text-white',
    bgColor: 'bg-pink-500',
  },
  {
    name: 'Persönlich',
    value: 'sad',
    icon: MapPinIcon,
    iconColor: 'text-white',
    bgColor: 'bg-red-500',
  },
  {
    name: 'Kein Kontakt',
    value: null,
    icon: PencilSquareIcon,
    iconColor: 'text-white',
    bgColor: 'bg-gray-400',
  },
]

export type ContactActivityCardProps = {
  contact: ContactPopulatedResponse
  setContact: React.Dispatch<React.SetStateAction<ContactPopulatedResponse>>
}

const ContactActivityCard = (props: ContactActivityCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  /*
  - Kontakt wurde erstellt
  - Reservierungen, wann sie erstellt / vorgenommen wurden. 2 Badges (Datum, Stauts)
  */

  const activities: {
    type: ActivityType
    entity: ReservationPopulatedResponse | NotePopulated
  }[] = [
    ...props.contact.reservations.map((reservation) => ({
      type: ActivityType.reservation,
      entity: reservation,
    })),
    ...props.contact.notes.map((note) => ({
      type: ActivityType.note,
      entity: note,
    })),
  ].sort((a, b) => {
    if (a.entity.createdDateTimeInUtc > b.entity.createdDateTimeInUtc) return -1
    if (a.entity.createdDateTimeInUtc < b.entity.createdDateTimeInUtc) return 1
    return 0
  })

  return (
    <UiCard>
      <UiCard.Header
        title={t('card.title.activity')}
        description={t('card.description.activity')}
        icon={SignalIcon}
      />
      <UiCard.Body>
        <ContactTimeLine>
          <ContactTimeLine.TextField
            contact={props.contact}
            setContact={props.setContact}
            user={auth.user}
          />
          {activities.map((activity) => (
            <Fragment key={activity.entity._id}>
              {activity.type === ActivityType.note && (
                <ContactTimeLine.Note
                  contact={props.contact}
                  setContact={props.setContact}
                  note={activity.entity as NotePopulated}
                />
              )}
              {activity.type === ActivityType.reservation && (
                <ContactTimeLine.Reservation
                  reservation={activity.entity as ReservationPopulatedResponse}
                />
              )}
            </Fragment>
          ))}
          <ContactTimeLine.Created contact={props.contact} />
        </ContactTimeLine>
      </UiCard.Body>
      <UiCard.Footer>
        {/* <div className="flex w-full justify-center">
          <UiButton variant="flat" type="button" label="Alle anzeigen" />
        </div> */}
      </UiCard.Footer>
    </UiCard>
  )
}

export default ContactActivityCard

enum ActivityType {
  reservation = 'reservation',
  note = 'note',
}
