import { useTranslation } from 'react-i18next'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiDateTime from '../ui-kit/typography/UiDateTime'
import ContactPopulatedResponse from '@/business/dto/responses/contact-populated.response'
import ReservationStatus from '@/business/dto/types/reservation-status'

export type ContactMetadataCardProps = {
  contact: ContactPopulatedResponse
}

const ContactMetadataCard = (props: ContactMetadataCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  const noShows = props.contact.reservations.filter(
    (x) => x.status === ReservationStatus.noShow
  ).length

  const percentageFormatter = new Intl.NumberFormat(
    auth.company.localization.language,
    {
      style: 'percent',
      maximumFractionDigits: 2,
    }
  )

  return (
    <UiCard>
      <UiCard.Header
        title={t('common.metadata')}
        description={t('card.description.contactMetadata')}
        icon={InformationCircleIcon}
      />
      <UiCard.Body>
        <dl className='space-y-5'>
          <div className='w-full overflow-hidden space-y-1'>
            <dt className='text-gray-700 text-sm truncate font-medium'>
              {t('common.reservations')}
            </dt>
            <dd className='text-gray-500 text-sm truncate'>
              {props.contact.reservations.length}
            </dd>
          </div>
          <div className='w-full overflow-hidden space-y-1'>
            <dt className='text-gray-700 text-sm truncate font-medium'>
              {t('common.walkIns')}
            </dt>
            <dd className='text-gray-500 text-sm truncate'>
              {/* todo: that's no walk-in */}
              {
                props.contact.reservations.filter(
                  (x) => x.status === ReservationStatus.checkIn
                ).length
              }
            </dd>
          </div>
          <div className='w-full overflow-hidden space-y-1'>
            <dt className='text-gray-700 text-sm truncate font-medium'>
              {t('common.noShows')}
            </dt>
            <dd className='text-gray-500 text-sm truncate'>
              {noShows} (
              {percentageFormatter.format(
                props.contact.reservations.length > 0
                  ? (noShows / props.contact.reservations.length) * 100
                  : 0
              )}
              )
            </dd>
          </div>
          <div className='w-full overflow-hidden space-y-1'>
            <dt className='text-gray-700 text-sm truncate font-medium'>
              {t('common.lastModified')}
            </dt>
            <dd className='text-gray-500 text-sm truncate'>
              {props.contact.lastModifiedDateTimeInUtc ? (
                <UiDateTime
                  dateTimeInUtc={props.contact.lastModifiedDateTimeInUtc}
                  format='PPPP'
                />
              ) : (
                '-'
              )}
            </dd>
          </div>
          {props.contact.createdDateTimeInUtc && (
            <div className='w-full overflow-hidden space-y-1'>
              <dt className='text-gray-700 text-sm truncate font-medium'>
                {t('common.created')}{' '}
              </dt>
              <dd className='text-gray-500 text-sm truncate'>
                <UiDateTime
                  dateTimeInUtc={props.contact.createdDateTimeInUtc}
                  format='PPPP'
                />
              </dd>
            </div>
          )}
        </dl>
      </UiCard.Body>
      <UiCard.Footer />
    </UiCard>
  )
}

export default ContactMetadataCard
