import { useTranslation } from 'react-i18next'
import { FaceSmileIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import React from 'react'
import ContactPopulatedResponse from '@/business/dto/responses/contact-populated.response'

export type ContactGeneralCardProps = {
  contact: ContactPopulatedResponse
}

const ContactGeneralCard = (props: ContactGeneralCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  const renderTextWithLinks = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g

    const parts = text.split(urlRegex)
    console.log('parts', parts)

    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a
            key={index}
            href={part}
            className={`text-${auth.company.appearance.color}-600 hover:text-${auth.company.appearance.color}-500 text-sm truncate`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {part}
          </a>
        )
      } else {
        return <React.Fragment key={index}>{part}</React.Fragment>
      }
    })
  }

  return (
    <UiCard>
      <UiCard.Header
        title={t('common.general')}
        description={t('card.description.contactGeneral')}
        icon={FaceSmileIcon}
      />
      <UiCard.Body>
        <dl className="space-y-5">
          <div className="w-full overflow-hidden space-y-1">
            <dt className="text-gray-700 text-sm truncate font-medium">
              {t('common.name')}
            </dt>
            <dd className="text-gray-500 text-sm truncate">
              {props.contact.name}
            </dd>
          </div>
          <div className="w-full overflow-hidden space-y-1">
            <dt className="text-gray-700 text-sm truncate font-medium">
              {t('common.description')}
            </dt>
            <dd className="text-gray-500 text-sm truncate">
              {props.contact.description ? props.contact.description : '-'}
            </dd>
          </div>
          {props.contact.identificationNumber && (
            <div className="w-full overflow-hidden space-y-1">
              <dt className="text-gray-700 text-sm truncate font-medium">
                {t('common.identificationNumber')}
              </dt>
              <dd className="text-gray-500 text-sm truncate">
                {props.contact.identificationNumber}
              </dd>
            </div>
          )}
          {props.contact.email && (
            <div className="w-full overflow-hidden space-y-1">
              <dt className="text-gray-700 text-sm truncate font-medium">
                {t('common.emailAddress')}
              </dt>
              <dd className="text-gray-500 text-sm truncate">
                <a
                  href={`mailto:${props.contact.email}`}
                  className={`text-${auth.company.appearance.color}-600 hover:text-${auth.company.appearance.color}-500 text-sm truncate`}
                >
                  {props.contact.email}
                </a>
              </dd>
            </div>
          )}
          {props.contact.phone && (
            <div className="w-full overflow-hidden space-y-1">
              <dt className="text-gray-700 text-sm truncate font-medium">
                {t('common.phoneNumber')}
              </dt>
              <dd className="text-gray-500 text-sm truncate">
                <a
                  href={`tel:${props.contact.phone}`}
                  className={`text-${auth.company.appearance.color}-600 hover:text-${auth.company.appearance.color}-500 text-sm truncate`}
                >
                  {props.contact.phone}
                </a>
              </dd>
            </div>
          )}
          {props.contact.address && (
            <div className="w-full overflow-hidden space-y-1">
              <dt className="text-gray-700 text-sm truncate font-medium">
                {t('common.address')}
              </dt>
              <dd className="text-gray-500 text-sm truncate">
                <ul>
                  <li className="truncate">{props.contact.address.line1}</li>
                  <li className="truncate">{props.contact.address.line2}</li>
                  <li className="truncate">
                    {props.contact.address.postalCode}{' '}
                    {props.contact.address.city}
                  </li>
                  <li className="truncate">
                    {new Intl.DisplayNames([i18n.language], {
                      type: 'region',
                    }).of(props.contact.address.country) || ''}
                  </li>
                </ul>
              </dd>
            </div>
          )}
          {props.contact.customFields?.map((customField) => (
            <div className="w-full overflow-hidden space-y-1">
              <dt className="text-gray-700 text-sm truncate font-medium">
                {customField.key}
              </dt>
              <dd className="text-gray-500 text-sm truncate">
                {renderTextWithLinks(customField.value)}
              </dd>
            </div>
          ))}
        </dl>
      </UiCard.Body>
      <UiCard.Footer />
    </UiCard>
  )
}

export default ContactGeneralCard
