import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import useAuth from '@/contexts/use-auth'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'

const WelcomePage = () => {
  const auth = useAuth()
  const [loading, setLoading] = useState<boolean>(false)

  const [currentStep, setCurrentStep] = useState<number>(
    0
    // auth.company
    //   ? auth.company.location.line1 &&
    //     auth.company.location.postalCode &&
    //     auth.company.location.city &&
    //     auth.company.location.country
    //     ? auth.company.billingInformation.name &&
    //       auth.company.billingInformation.email
    //       ? 3
    //       : 2
    //     : 1
    //   : 0
  )

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 px-4 sm:px-6">
        <div className="absolute -z-10 inset-0 top-96 skew-y-[-16deg]">
          <div className="absolute top-0 left-0 right-0 h-[48px] bg-gray-200" />
          <div
            className={`absolute top-8 left-0 bg-${auth.company.appearance.color}-500 right-[calc(60%+256px)] h-[32px] shadow sm:rounded-r-sm`}
          />
          <div
            className={`absolute -top-4 right-0 bg-${auth.company.appearance.color}-500 left-[calc(60%+256px)] h-[32px] shadow sm:rounded-l-sm`}
          />
        </div>
        <div className="relative">
          <AnimatePresence initial={false}>
            <motion.div
              key={currentStep}
              className="absolute inset-0"
              initial={{
                opacity: 0,
                x: '50%',
              }}
              animate={{
                x: '0%',
                opacity: 1,
                scale: 1,
                filter: 'blur(0px)',
                transition: { duration: 0.6 },
              }}
              exit={{
                opacity: 0,
                scale: 0.8,
                zIndex: 0,
                filter: 'blur(4px)',
                transition: { duration: 0.6 },
              }}
            >
              {currentStep === 0 && (
                <Step1
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                />
              )}
              {currentStep === 1 && (
                <Step2
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                />
              )}
              {currentStep === 2 && (
                <Step3
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                />
              )}
              {currentStep === 3 && (
                <Step4
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                />
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </>
  )
}

export default WelcomePage
