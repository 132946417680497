import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoaderFunctionArgs, defer, useLoaderData } from 'react-router-dom'
import { findCategoryById } from '@/business/api/category.service'
import CategoryServicesAddCard from '@/components/modals/CategoryServicesAddCard'
import { findAllServices } from '@/business/api/service.service'
import Paginated from '@/business/dto/responses/paginated.response'
import CategoryResponse from '@/business/dto/responses/category.response'
import ServiceResponse from '@/business/dto/responses/service.response'

export const categoryServicesAddPageLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const [category, paginatedServices] = await Promise.all([
    findCategoryById(params.categoryId!),
    findAllServices({ page: 1, limit: 10 }),
  ])

  return defer({ category, paginatedServices })
}

const CategoryServicesAddPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { category, paginatedServices } = useLoaderData() as {
    category: CategoryResponse
    paginatedServices: Paginated<ServiceResponse>
  }

  return (
    <UiContainer size="small">
      <UiHeader
        title={t('page.title.categoryServicesAdd')}
        description={t('page.description.categoryServicesAddX', {
          x: category.name,
        })}
        desktopBackHref={`/categories/${category._id}`}
        mobileBackHref={`/categories/${category._id}`}
      />
      <div className="py-6 md:py-8">
        <div className="space-y-6">
          <CategoryServicesAddCard
            category={category}
            paginatedServices={paginatedServices}
          />
        </div>
      </div>
    </UiContainer>
  )
}

export default CategoryServicesAddPage
