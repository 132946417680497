import useAuth from '@/contexts/use-auth'
import { Transition } from '@headlessui/react'
import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Color from '@/business/dto/types/color'
import UiImpersonatedBanner from '../presentation/UiImpersonatedBanner'

export type UiMobileNavigationBarProps = {
  title: string
  children?: React.ReactNode
  backHref?: string
  search?: React.ReactNode
  action?: {
    icon: React.ElementType
    onClick?: () => void
    to?: string
  }
  avatar?: {
    name: string
    color: Color
    shape: 'circle' | 'rounded'
    image: string | null
  }
}

const UiMobileNavigationBar = (props: UiMobileNavigationBarProps) => {
  const auth = useAuth()
  const color = auth?.company?.appearance?.color || 'rose'
  const { t } = useTranslation()
  const [offset, setOffset] = useState<number>(0)

  useEffect(() => {
    const onScroll = () => {
      setOffset(window.scrollY)
    }
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-40">
        {auth.impersonated && <UiImpersonatedBanner />}
        <div className="flex items-center justify-between h-12 px-2 border-b border-gray-200 bg-gray-100">
          {(props.backHref || props.action || props.avatar) && (
            <div className="flex shrink-0 w-20 justify-start sm:w-40">
              {props.backHref && (
                <div className="overflow-hidden rounded-md">
                  <Link
                    className={`inline-flex whitespace-nowrap rounded-md border border-transparent bg-transparent px-3 py-2 text-sm font-medium leading-4 text-gray-500 transition hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-${color}-500 focus:ring-offset-2 disabled:bg-gray-100 disabled:text-gray-100`}
                    to={props.backHref}
                  >
                    <ArrowSmallLeftIcon
                      className="mr-2 -ml-0.5 hidden h-4 w-4 sm:block"
                      aria-hidden="true"
                    />
                    {t('action.back')}
                  </Link>
                </div>
              )}
            </div>
          )}
          <div className="relative grow overflow-hidden">
            <div className="text-base font-semibold text-gray-900 truncate text-center">
              {props.title}
            </div>
          </div>
          {(props.backHref || props.action || props.avatar) && (
            <div className="flex shrink-0 w-20 justify-end sm:w-40">
              {props.action && (
                <div className="overflow-hidden rounded-full">
                  {props.action.onClick && (
                    <button
                      type="button"
                      className={`inline-flex items-center whitespace-nowrap rounded-full border border-transparent bg-transparent p-2 text-sm font-medium leading-4 text-gray-500 transition hover:bg-gray-200 focus:outline-none disabled:bg-gray-100 disabled:text-gray-100`}
                      onClick={props.action.onClick}
                    >
                      <props.action.icon
                        className={`h-5 w-5 text-${color}-500`}
                        aria-hidden="true"
                      />
                    </button>
                  )}
                  {props.action.to && (
                    <Link
                      type="button"
                      className={`inline-flex items-center whitespace-nowrap rounded-full border border-transparent bg-transparent p-2 text-sm font-medium leading-4 text-gray-500 transition hover:bg-gray-200 focus:outline-none disabled:bg-gray-100 disabled:text-gray-100`}
                      to={props.action.to}
                    >
                      <props.action.icon
                        className={`h-5 w-5 text-${color}-500`}
                        aria-hidden="true"
                      />
                    </Link>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {false && (
          <div className="">
            <Transition
              show={offset < 36}
              enter="transition-all duration-100"
              enterFrom="opacity-0 h-0"
              enterTo="opacity-100 h-12"
              leave="transition-all duration-200"
              leaveFrom="opacity-100 h-12"
              leaveTo="opacity-0 h-0"
            >
              {props.search}
            </Transition>
          </div>
        )}
      </div>
      {/* {false && (
        <div className="block md:hidden">
          <div className="fixed top-0 left-0 right-0 z-40">
            <div
              className={`h-12 flex border-b backdrop-blur bg-gray-100/75 transition-all items-center justify-between px-2 ${
                true ? 'border-gray-200' : 'border-transparent'
              }`}
            >
              <div className="flex w-32 justify-start sm:w-40">
                {props.back && (
                  <div className="overflow-hidden rounded-md">
                    <Link
                      to={props.back}
                      className={`inline-flex whitespace-nowrap rounded-md border border-transparent bg-transparent px-3 py-2 text-sm font-medium leading-4 text-gray-500 transition hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2 disabled:bg-gray-100 disabled:text-gray-100`}
                    >
                      <ChevronLeftIcon
                        className="mr-1 -ml-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      {t('action.back')}
                    </Link>
                  </div>
                )}
              </div>
              <div className="relative grow">
                <div
                  className={`flex h-full w-full items-center justify-center text-sm font-semibold text-gray-900 ${
                    true ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <div className="w-full overflow-hidden text-center">
                    <div className="truncate">{props.title}</div>
                  </div>
                </div>
              </div>
              <div className="flex w-32 justify-end sm:w-40">
                {false && (
                  <div className="overflow-hidden rounded-md">
                    <button
                      type="button"
                      className={`inline-flex items-center whitespace-nowrap rounded-md border border-transparent bg-transparent px-3 py-2 text-sm font-medium leading-4 text-gray-500 transition hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2 disabled:bg-gray-100 disabled:text-gray-100`}
                    >
                      Weiter
                      <ArrowSmallRightIcon
                        className="ml-1 -mr-2 hidden h-4 w-4 sm:block"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="h-14"></div>
        </div>
      )} */}
    </>
  )
}

export default UiMobileNavigationBar
