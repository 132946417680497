import {
  findCategoryById,
  findCategoryByIdPopulated,
  findCategoryServices,
} from '@/business/api/category.service'
import CategoryPopulatedResponse from '@/business/dto/responses/category-populated.response'
import Paginated from '@/business/dto/responses/paginated.response'
import ServiceResponse from '@/business/dto/responses/service.response'
import CategoryDangerzoneCard from '@/components/cards/CategoryDangerzoneCard'
import CategoryGeneralCard from '@/components/cards/CategoryGeneralCard'
import CategoryMetadataCard from '@/components/cards/CategoryMetadataCard'
import CategoryServicesCard from '@/components/cards/CategoryServicesCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { PencilIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Link,
  LoaderFunctionArgs,
  defer,
  useLoaderData,
} from 'react-router-dom'

export const categoryPageLoader = async ({ params }: LoaderFunctionArgs) => {
  const [category, paginatedServices] = await Promise.all([
    findCategoryByIdPopulated(params.categoryId!),
    findCategoryServices(params.categoryId!, { page: 1, limit: 10 }),
  ])
  return defer({ category, paginatedServices })
}

const CategoryPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const loaderData = useLoaderData() as any
  const [category, setCategory] = useState<CategoryPopulatedResponse>(
    loaderData.category
  )
  const [paginatedServices, setPaginatedServices] = useState<
    Paginated<ServiceResponse>
  >(loaderData.paginatedServices)

  return (
    <UiContainer>
      <UiHeader
        avatar={{
          name: category.name,
          color: category.color,
          image: category.image,
          shape: 'rounded',
        }}
        title={category.name}
        description={t('page.description.category')}
        desktopBackHref="/categories"
        mobileBackHref="/categories"
        mobileAction={{
          icon: PencilSquareIcon,
          to: `/categories/${category._id}/edit`,
        }}
        desktopActions={
          <UiButton
            as={Link}
            variant="primary"
            label={t('action.editCategory')}
            icon={PencilIcon}
            to={`/categories/${category._id}/edit`}
          />
        }
      />
      <div className="py-6 md:py-8">
        <div className="space-y-6">
          <div className="grid grid-cols-1 grid-rows-1 items-start md:gap-6 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
            <div className="lg:col-start-1">
              <CategoryGeneralCard category={category} />
            </div>
            <div className="lg:col-start-2 lg:col-span-2 lg:row-span-2 lg:row-end-2 2xl:col-start-2 2xl:col-span-3">
              <div className="space-y-6">
                <CategoryServicesCard
                  category={category}
                  setCategory={setCategory}
                  paginatedServices={paginatedServices}
                  setPaginatedServices={setPaginatedServices}
                />
                <CategoryDangerzoneCard
                  category={category}
                  setCategoryDetails={setCategory}
                />
              </div>
            </div>
            <div className="lg:col-start-1">
              <CategoryMetadataCard category={category} />
            </div>
          </div>
        </div>
      </div>
    </UiContainer>
  )
}

export default CategoryPage
