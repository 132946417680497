import { useMatch, useResolvedPath } from 'react-router-dom'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import {
  HomeIcon as HomeIconOutline,
  CalendarDaysIcon as CalendarDaysIconOutline,
  UserGroupIcon as UserGroupIconOutline,
  ChartBarIcon as ChartBarIconOutline,
  Cog6ToothIcon as Cog6ToothIconOutline,
} from '@heroicons/react/24/outline'
import {
  HomeIcon as HomeIconSolid,
  CalendarDaysIcon as CalendarDaysIconSolid,
  UserGroupIcon as UserGroupIconSolid,
  Cog6ToothIcon as Cog6ToothIconSolid,
} from '@heroicons/react/24/solid'
import UiMobileBottomNavigation from './ui-kit/layout/UiMobileBottomNavigation'

const InternBottomNavigation = () => {
  const auth = useAuth()
  const color = auth?.company?.appearance?.color || 'rose'
  const { t } = useTranslation()

  let isDashboard = !!useMatch({
    path: useResolvedPath('/').pathname,
    end: true,
  })
  let isReservations = !!useMatch({
    path: useResolvedPath('/reservations').pathname,
    end: false,
  })
  let isContacts = !!useMatch({
    path: useResolvedPath('/contacts').pathname,
    end: false,
  })
  let isSettings = !isDashboard && !isReservations && !isContacts

  return (
    <UiMobileBottomNavigation>
      <UiMobileBottomNavigation.Item
        label={t('common.dashboard')}
        to="/"
        icon={HomeIconOutline}
        active={isDashboard}
        iconActive={HomeIconSolid}
        activeClass={`text-${color}-500`}
      />
      <UiMobileBottomNavigation.Item
        label={t('common.reservations')}
        to="/reservations"
        icon={CalendarDaysIconOutline}
        active={isReservations}
        iconActive={CalendarDaysIconSolid}
        activeClass={`text-${color}-500`}
      />
      <UiMobileBottomNavigation.Item
        label={t('common.contacts')}
        to="/contacts"
        icon={UserGroupIconOutline}
        active={isContacts}
        iconActive={UserGroupIconSolid}
        activeClass={`text-${color}-500`}
      />
      <UiMobileBottomNavigation.Item
        label={t('common.settings')}
        to="/settings"
        icon={Cog6ToothIconOutline}
        active={isSettings}
        iconActive={Cog6ToothIconSolid}
        activeClass={`text-${color}-500`}
      />
    </UiMobileBottomNavigation>
  )
}

export default InternBottomNavigation
