import { SubmitHandler, useForm } from 'react-hook-form'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { useCompany } from './Company'
import { useTranslation } from 'react-i18next'
import UpdateCompanyRequest from '@/business/dto/requests/update-company.request'
import { useState } from 'react'
import { updateCompany } from '@/business/api/company.service'
import { toast } from 'react-hot-toast'
import UiToast from '@/components/ui-kit/presentation/UiToast'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import CompanyNotificationsCard from '@/components/cards/CompanyNotificationsCard'
import CompanyPopulatedResponse from '@/business/dto/responses/company-populated.response'

const mapToFormValue = (
  company: CompanyPopulatedResponse
): UpdateCompanyRequest => {
  return {
    notifications: {
      sendConfirmation: company.notifications?.sendConfirmation || false,
      sendReminder: company.notifications?.sendReminder || false,
      sendCancelation: company.notifications?.sendCancelation || false,
      sendFollowUp: company.notifications?.sendFollowUp || false,
    },
  }
}

const CompanyNotificationsPage = () => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const { company, setCompany } = useCompany()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<UpdateCompanyRequest>({
    defaultValues: mapToFormValue(company),
  })

  const onSubmit: SubmitHandler<any> = (data) => {
    setLoading(true)
    updateCompany(data)
      .then((response) => {
        setCompany(response)
        form.reset(mapToFormValue(response))
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.updated')}
            description={t('toast.description.companyUpdated')}
          />
        ))
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <UiHeader
        className='md:hidden'
        title={t('common.notifications')}
        description={t('page.description.companyNotifications')}
        mobileBackHref='/company'
      />
      <div className='py-6 md:py-0'>
        <div className='space-y-6'>
          <CompanyNotificationsCard company={company} setCompany={setCompany} />
        </div>
      </div>
    </>
  )
}

export default CompanyNotificationsPage
