import { Link } from 'react-router-dom'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'

export type UiMobileBottomNavigationProps = {
  children?: React.ReactNode
}

const UiMobileBottomNavigation = (props: UiMobileBottomNavigationProps) => {
  const auth = useAuth()
  const color = auth?.company?.appearance?.color || 'rose'
  const { t } = useTranslation()

  return (
    <div className="block md:hidden pt-12">
      <nav className="fixed bottom-0 left-0 right-0 z-40">
        <div className="h-12 max-w-4xl mx-auto grid grid-cols-4 backdrop-blur bg-gray-50/75 border-t border-gray-200">
          {props.children}
        </div>
      </nav>
    </div>
  )
}

export type UiMobileBottomNavigationItemProps = {
  label: string
  to: string
  icon: React.ElementType
  active: boolean
  iconActive: React.ElementType
  activeClass?: string
}

const UiMobileBottomNavigationItem = (
  props: UiMobileBottomNavigationItemProps
) => {
  return (
    <div className="col-span-1">
      <Link
        className="flex flex-col justify-center items-center w-full h-full"
        to={props.to}
        replace={true}
      >
        {!props.active && (
          <props.icon className="transition flex-shrink-0 h-6 w-6 text-gray-400" />
        )}
        {props.active && (
          <props.iconActive
            className={`transition flex-shrink-0 h-6 w-6 ${props.activeClass}`}
          />
        )}
        <div
          className={`transition flex justify-center w-full text-xs px-1 ${
            props.active ? props.activeClass : 'text-gray-400'
          }`}
        >
          <span className="truncate">{props.label}</span>
        </div>
      </Link>
    </div>
  )
}

export default Object.assign(UiMobileBottomNavigation, {
  Item: UiMobileBottomNavigationItem,
})
