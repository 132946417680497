import { differenceInDays, format, formatDistance } from 'date-fns'
import { TimeInterval } from '@/business/dto/embeddeds/time-interval'
import Exception from '@/business/dto/embeddeds/exception'
import { enUS, de } from 'date-fns/locale'
import i18n from '@/i18n'
import Subscription from './dto/embeddeds/subscription'

const locales: { [key: string]: any } = { en: enUS, de }

export const getCurrentFnsLocale = () => {
  const preffered = locales[i18n.language]
  return preffered || locales.en
}

export const getSubscriptionEndsString = (subscription: Subscription) => {
  const dateInUtc = new Date(subscription.currentPeriodEnd)
  const nowInUtc = new Date()

  return `${formatDistance(dateInUtc, nowInUtc, {
    locale: getCurrentFnsLocale(),
    addSuffix: true,
  })}`
}

export const prettyPrintAvailability = (
  availability: any,
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6,
  t: any
) => {
  const start = new Date()
  const weekdays = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ]
  const availabilities = [
    availability.sunday,
    availability.monday,
    availability.tuesday,
    availability.wednesday,
    availability.thursday,
    availability.friday,
    availability.saturday,
  ]
  const availabilityString: string[] = []
  // console.log("Starting Day: " + startingDay);
  const outWeekdayIndecies: number[][] = []
  const outHours: TimeInterval[][] = []
  for (let i = 0; i < 7; i++) {
    let same = false
    var thisDay = (weekStartsOn + i) % 7
    // console.log("thisDay: " + thisDay);
    // console.log(
    //   "availabilities for thisday: " + JSON.stringify(availabilities[thisDay])
    // );
    for (let j = 0; j < outHours.length; j++) {
      if (
        outHours[j].length > 0 &&
        outHours[j].length === availabilities[thisDay].length
      ) {
        same = true
        for (let k = 0; k < outHours[j].length; k++) {
          if (
            outHours[j][k].startTime !== availabilities[thisDay][k].startTime ||
            outHours[j][k].endTime !== availabilities[thisDay][k].endTime
          ) {
            same = false
            break
          }
        }
        if (same) {
          outWeekdayIndecies[j].push(thisDay)
          break
        }
      }
    }
    if (!same) {
      outWeekdayIndecies.push([])
      outWeekdayIndecies[outWeekdayIndecies.length - 1].push(thisDay)
      outHours.push(availabilities[thisDay])
    }
  }

  // console.log("weekdays: " + JSON.stringify(outWeekdayIndecies));
  // console.log("hours: " + JSON.stringify(outHours));

  // build availability string
  for (let i = 0; i < outWeekdayIndecies.length; i++) {
    if (outHours[i].length > 0) {
      let a = ''
      if (outWeekdayIndecies[i].length === 1) {
        a = t(`common.${weekdays[outWeekdayIndecies[i][0]]}`) + ': '
      } else if (outWeekdayIndecies[i].length === 2) {
        a =
          t(`common.${weekdays[outWeekdayIndecies[i][0]]}`) +
          ', ' +
          t(`common.${weekdays[outWeekdayIndecies[i][1]]}`) +
          ': '
      } else {
        let start = null
        let end = null
        var mid = ', '
        for (let j = 0; j < outWeekdayIndecies[i].length; j++) {
          let thisDay = outWeekdayIndecies[i][j]
          // console.log("building - thisDay: " + thisDay);
          if (start === null) {
            start = thisDay
            a += t(`common.${weekdays[thisDay]}`)
          } else if (start === (thisDay + 6) % 7) {
            end = thisDay
            mid = ', '
          } else if (end === (thisDay + 6) % 7) {
            end = thisDay
            if (start === (thisDay + 5) % 7) {
              mid = ' - '
            }
          } else {
            if (end !== null) {
              a += mid + t(`common.${weekdays[end]}`)
              mid = ', '
              end = null
            }
            start = thisDay
            a += mid + t(`common.${weekdays[start]}`)
          }
        }
        if (end !== null) {
          a += mid + t(`common.${weekdays[end]}`)
        }
        a += ': '
        // console.log(a);
      }

      for (let j = 0; j < outHours[i].length; j++) {
        if (j > 0) {
          a += ', '
        }
        a += outHours[i][j].startTime + ' - ' + outHours[i][j].endTime
      }
      availabilityString.push(a)
    }
  }
  // console.log(availabilityString);
  console.log(new Date().getTime() - start.getTime())
  return availabilityString
}

export const prettyPrintException = (
  exception: Exception,
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6,
  t: any
) => {
  const days = exception.dates
    .map((day) => new Date(day))
    .sort((a, b) => a.getTime() - b.getTime())
    .reduce((acc, value, i, array) => {
      if (i === 0 || differenceInDays(value, array[i - 1]) > 1) {
        acc.push([value])
      } else {
        acc[acc.length - 1][1] = value
      }

      return acc
    }, [] as Date[][])
    .map((block) => {
      if (block.length === 2 && differenceInDays(block[1], block[0]) === 1) {
        return block
          .map((day) =>
            format(day, 'P', {
              locale: getCurrentFnsLocale(),
              weekStartsOn,
            })
          )
          .join(', ')
      } else {
        return block
          .map((day) =>
            format(day, 'P', {
              locale: getCurrentFnsLocale(),
              weekStartsOn,
            })
          )
          .join(' - ')
      }
    })
    .join(', ')

  console.log('days', days)

  const availability =
    exception.timeIntervals && exception.timeIntervals.length > 0
      ? exception.timeIntervals
          .map(
            (timeInterval) =>
              `${timeInterval.startTime} - ${timeInterval.endTime}`
          )
          .join(', ')
      : 'Nicht verfügbar'

  return `${days}: ${availability}`
}
