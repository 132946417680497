import { useTranslation } from 'react-i18next'
import {
  BriefcaseIcon,
  FaceSmileIcon,
  UserIcon,
  WrenchIcon,
} from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import Role from '@/business/dto/types/role'
import UiBadge from '../ui-kit/presentation/UiBadge'
import UserPopulatedResponse from '@/business/dto/responses/user-populated.response'

export type UserGeneralCardProps = {
  user: UserPopulatedResponse
}

const UserGeneralCard = (props: UserGeneralCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  return (
    <UiCard>
      <UiCard.Header
        title={t('common.general')}
        description={
          props.user._id === auth.user._id
            ? t('card.description.accountGeneral')
            : t('card.description.userGeneral')
        }
        icon={FaceSmileIcon}
      />
      <UiCard.Body>
        <dl className="space-y-5">
          <div className="w-full overflow-hidden space-y-1">
            <dt className="text-gray-700 text-sm truncate font-medium">
              {t('common.name')}
            </dt>
            <dd className="text-gray-500 text-sm truncate">
              {props.user.name}
            </dd>
          </div>
          <div className="w-full overflow-hidden space-y-1">
            <dt className="text-gray-700 text-sm truncate font-medium">
              {t('common.description')}
            </dt>
            <dd className="text-gray-500 text-sm truncate">
              {props.user.description
                ? props.user.description
                : '-'}
            </dd>
          </div>
          {props.user.email && (
            <div className="w-full overflow-hidden space-y-1">
              <dt className="text-gray-700 text-sm truncate font-medium">
                {t('common.emailAddress')}
              </dt>
              <dd className="text-gray-500 text-sm truncate">
                <a
                  href={`mailto:${props.user.email}`}
                  className={`text-${auth.company.appearance.color}-600 hover:text-${auth.company.appearance.color}-500 text-sm truncate`}
                >
                  {props.user.email}
                </a>
              </dd>
            </div>
          )}
          <div className="w-full overflow-hidden space-y-1">
            <dt className="text-gray-700 text-sm truncate font-medium">
              {t('common.role')}
            </dt>
            <dd className="text-sm truncate">
              {props.user.role === Role.owner && (
                <UiBadge
                  icon={BriefcaseIcon}
                  bgClass="bg-rose-100"
                  textClass="text-rose-800"
                  label={t('common.owner')}
                />
              )}
              {props.user.role === Role.admin && (
                <UiBadge
                  icon={WrenchIcon}
                  bgClass="bg-sky-100"
                  textClass="text-sky-800"
                  label={t('common.admin')}
                />
              )}
              {props.user.role === Role.member && (
                <UiBadge icon={UserIcon} label={t('common.member')} />
              )}
            </dd>
          </div>
          {props.user.resflowStaff && (
            <div className="w-full overflow-hidden space-y-1">
              <dt className="text-gray-700 text-sm truncate font-medium">
                {t('common.resflowStaff')}
              </dt>
              <dd className="text-gray-500 text-sm truncate">
                {t('common.yes')}
              </dd>
            </div>
          )}
        </dl>
      </UiCard.Body>
      <UiCard.Footer />
    </UiCard>
  )
}

export default UserGeneralCard
