import { AxiosError } from 'axios'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { updateCompany } from '@/business/api/company.service'
import UpdateCompanyRequest from '@/business/dto/requests/update-company.request'
import { handleValidationError } from '@/business/error-handler'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiInput from '@/components/ui-kit/input/basic/UiInput'
import UiCountryCombobox from '@/components/ui-kit/input/UiCountryCombobox'
import UiToast from '@/components/ui-kit/presentation/UiToast'
import useAuth from '@/contexts/use-auth'
import { updatePageTitle } from '@/business/utils'
import CompanyResponse from '@/business/dto/responses/company.response'
import Color from '@/business/dto/types/color'

const mapToFormValue = (company: CompanyResponse): UpdateCompanyRequest => {
  return {
    location: {
      line1: company.location.line1 || '',
      line2: company.location.line2 || '',
      postalCode: company.location.postalCode || '',
      city: company.location.city || '',
      country: company.location.country || '',
    },
  }
}

export type Step3Props = {
  currentStep: number
  setCurrentStep: Dispatch<SetStateAction<number>>
}

const Step3 = (props: Step3Props) => {
  const auth = useAuth()
  const color = auth.company.appearance?.color || Color.rose
  const { t, i18n } = useTranslation()
  const form = useForm<UpdateCompanyRequest>({
    defaultValues: mapToFormValue(auth.company),
  })
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    updatePageTitle(t('page.title.introStep3'), auth.company)
  }, [])

  const onSubmit: SubmitHandler<UpdateCompanyRequest> = (data) => {
    setLoading(true)
    updateCompany(data, true)
      .then((response) => {
        auth.setCompany(response)
        form.reset(mapToFormValue(response))
        props.setCurrentStep(3)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type="success"
            title={t('toast.title.updated')}
            description={t('toast.description.companyUpdated')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        handleValidationError(err, i18n)
        setLoading(false)
      })
  }

  return (
    <>
      <div className="relative sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-6 w-auto"
          src="/img/resflow-logo.svg"
          alt={t('common.resflowLogo')!}
        />
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
          {t('page.title.introStep3')}
        </h2>
        <p className="mt-2 text-center text-base text-gray-600">
          {t('page.description.introStep3')}
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-6 shadow rounded-lg py-8 sm:px-10">
          <form className="space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <UiInput
                  label={t('common.streetAndHouseNumber')}
                  type="text"
                  name="location.line1"
                  autoFocus
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                    minLength: {
                      value: 3,
                      message: t('form.validation.minLength', { x: 3 }),
                    },
                    maxLength: {
                      value: 64,
                      message: t('form.validation.maxLength', { x: 64 }),
                    },
                  }}
                />
              </div>
              <div className="sm:col-span-6">
                <UiInput
                  label={t('common.addressLine2')}
                  type="text"
                  name="location.line2"
                  control={form.control}
                  rules={{
                    maxLength: {
                      value: 64,
                      message: t('form.validation.maxLength', { x: 64 }),
                    },
                  }}
                />
              </div>
              <div className="sm:col-span-3">
                <UiInput
                  label={t('common.zipCode')}
                  type="text"
                  name="location.postalCode"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                    minLength: {
                      value: 3,
                      message: t('form.validation.minLength', { x: 3 }),
                    },
                    maxLength: {
                      value: 64,
                      message: t('form.validation.maxLength', { x: 64 }),
                    },
                  }}
                />
              </div>
              <div className="sm:col-span-3">
                <UiInput
                  label={t('common.city')}
                  type="text"
                  name="location.city"
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                    minLength: {
                      value: 3,
                      message: t('form.validation.minLength', { x: 3 }),
                    },
                    maxLength: {
                      value: 64,
                      message: t('form.validation.maxLength', { x: 64 }),
                    },
                  }}
                />
              </div>
              <div className="sm:col-span-6">
                <UiCountryCombobox
                  label={t('common.country')}
                  control={form.control}
                  name="location.country"
                />
              </div>
            </div>

            <UiButton
              label="Weiter"
              variant="primary"
              block={true}
              loading={loading}
            />
            <nav
              className="flex items-center justify-center"
              aria-label="Progress"
            >
              <p className="text-sm font-medium">Schritt 3 von 4</p>
              <ol role="list" className="ml-8 flex items-center space-x-5">
                <li>
                  <button
                    type="button"
                    onClick={() => props.setCurrentStep(0)}
                    className={`block h-2.5 w-2.5 rounded-full bg-${color}-600 hover:bg-${color}-900`}
                  >
                    <span className="sr-only">Schritt 1</span>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => props.setCurrentStep(1)}
                    className={`block h-2.5 w-2.5 rounded-full bg-${color}-600 hover:bg-${color}-900`}
                  >
                    <span className="sr-only">Schritt 2</span>
                  </button>
                </li>
                <li>
                  <div
                    className="relative flex items-center justify-center"
                    aria-current="step"
                  >
                    <span
                      className="absolute flex h-5 w-5 p-px"
                      aria-hidden="true"
                    >
                      <span
                        className={`h-full w-full rounded-full bg-${color}-200`}
                      />
                    </span>
                    <span
                      className={`relative block h-2.5 w-2.5 rounded-full bg-${color}-600`}
                      aria-hidden="true"
                    />
                    <span className="sr-only">Schritt 3</span>
                  </div>
                </li>
                <li>
                  <div className="block h-2.5 w-2.5 rounded-full bg-gray-200">
                    <span className="sr-only">Schritt 4</span>
                  </div>
                </li>
              </ol>
            </nav>
          </form>
        </div>
      </div>
    </>
  )
}

export default Step3
