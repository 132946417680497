import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import {
  LoaderFunctionArgs,
  defer,
  useLoaderData,
  useParams,
} from 'react-router-dom'
import {
  findResourceById,
  updateResourceException,
} from '@/business/api/resource.service'
import { ExceptionUpdateCard } from '@/components/modals/ExceptionCreateUpdateCard'
import CreateOrUpdateExceptionRequest from '@/business/dto/requests/create-or-update-exception.request'
import ResourceResponse from '@/business/dto/responses/resource.response'

export const resourceExceptionEditPageLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const [resource] = await Promise.all([findResourceById(params.resourceId!)])
  return defer({ resource })
}

const ResourceExceptionEditPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { exceptionId } = useParams()
  const { resource } = useLoaderData() as { resource: ResourceResponse }

  return (
    <UiContainer size='medium'>
      <UiHeader
        title={t('page.title.resourceExceptionEdit')}
        description={t('page.description.resourceExceptionEditX', {
          x: resource.name,
        })}
        desktopBackHref={`/resources/${resource._id}`}
        mobileBackHref={`/resources/${resource._id}`}
      />
      <div className='py-6 md:py-8'>
        <div className='space-y-6'>
          <ExceptionUpdateCard
            exception={resource.exceptions?.find((a) => a._id === exceptionId)!}
            updateFn={(
              exceptionId: string,
              data: CreateOrUpdateExceptionRequest
            ) => updateResourceException(resource._id, exceptionId, data)}
            backHref={`/resources/${resource._id}`}
          />
        </div>
      </div>
    </UiContainer>
  )
}

export default ResourceExceptionEditPage
