import UiContainer from '@/components/ui-kit/layout/UiContainer';
import useAuth from '@/contexts/use-auth';
import Role from '@/business/dto/types/role';
import { Link } from 'react-router-dom';

type UiContractBannerProps = {
  message: string;
  action?: string;
};

const UiContractBanner = (props: UiContractBannerProps) => {
  const auth = useAuth();

  return (
    <div className='relative flex items-center h-12 bg-gray-700'>
      <UiContainer>
        <div className='pr-16 sm:px-16 sm:text-center'>
          <p className='font-medium text-white'>
            {props.message}
            {props.action && (
              <span className='block sm:ml-2 sm:inline-block'>
                <Link
                  className='font-bold text-white hover:text-gray-200 underline'
                  to='/company/contract'
                >
                  {props.action}
                  <span aria-hidden='true'> &rarr;</span>
                </Link>
              </span>
            )}
          </p>
        </div>
      </UiContainer>
    </div>
  );
};

export default UiContractBanner;
