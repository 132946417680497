import CompanyResponse from '@/business/dto/responses/company.response'
import UserResponse from '@/business/dto/responses/user.response'
import React from 'react'

interface AuthContextType {
  user: UserResponse
  setUser: React.Dispatch<React.SetStateAction<UserResponse>>
  company: CompanyResponse
  setCompany: React.Dispatch<React.SetStateAction<CompanyResponse>>
  signOut: () => void
  impersonated: boolean
}

const AuthContext = React.createContext<AuthContextType>(null!)
export default AuthContext
