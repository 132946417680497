enum Language {
  // bg = 'bg',
  // cs = 'cs',
  // da = 'da',
  de = 'de',
  // el = 'el',
  en = 'en',
  // enGB = 'en-GB',
  // es = 'es',
  // es419 = 'es-419',
  // et = 'et',
  // fi = 'fi',
  // fil = 'fil',
  // fr = 'fr',
  // frCA = 'fr-CA',
  // hr = 'hr',
  // hu = 'hu',
  // id = 'id',
  // it = 'it',
  // ja = 'ja',
  // ko = 'ko',
  // lt = 'lt',
  // lv = 'lv',
  // ms = 'ms',
  // mt = 'mt',
  // nb = 'nb',
  // nl = 'nl',
  // pl = 'pl',
  // pt = 'pt',
  // ptBR = 'pt-BR',
  // ro = 'ro',
  // ru = 'ru',
  // sk = 'sk',
  // sl = 'sl',
  // sv = 'sv',
  // th = 'th',
  // tr = 'tr',
  // vi = 'vi',
  // zh = 'zh',
  // zhHK = 'zh-HK',
  // zhTW = 'zh-TW',
}

export default Language
