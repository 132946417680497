import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import { LoaderFunctionArgs, defer, useLoaderData } from 'react-router-dom'
import { findContactById } from '@/business/api/contact.service'
import { ContactUpdateCard } from '@/components/modals/ContactCreateUpdateCard'
import ContactResponse from '@/business/dto/responses/contact.response'

export const contactEditPageLoader = async ({ params }: LoaderFunctionArgs) => {
  const [contact] = await Promise.all([findContactById(params.contactId!)])
  return defer({ contact })
}

const ContactEditPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { contact } = useLoaderData() as { contact: ContactResponse }

  return (
    <UiContainer size="small">
      <UiHeader
        title={t('page.title.contactEdit')}
        description={t('page.description.contactEditX', {
          x: contact.name,
        })}
        desktopBackHref={`/contacts/${contact._id}`}
        mobileBackHref={`/contacts/${contact._id}`}
      />
      <div className="py-6 md:py-8">
        <div className="space-y-6">
          <ContactUpdateCard contact={contact} />
        </div>
      </div>
    </UiContainer>
  )
}

export default ContactEditPage
