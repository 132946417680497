import { useTranslation } from 'react-i18next'
import { CubeTransparentIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiAvatar from '../ui-kit/layout/UiAvatar'
import ResourcePopulatedResponse from '@/business/dto/responses/resource-populated.response'

export type ResourceGeneralCardProps = {
  resource: ResourcePopulatedResponse
}

const ResourceGeneralCard = (props: ResourceGeneralCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  return (
    <UiCard>
      <UiCard.Header
        title={t('common.general')}
        description={t('card.description.resourceGeneral')}
        icon={CubeTransparentIcon}
      />
      <UiCard.Body>
        <dl className="space-y-5">
          {false && (
            <div className="w-full overflow-hidden space-y-1">
              <dt className="text-gray-700 text-sm truncate font-medium">
                {t('common.image')}
              </dt>
              <dd className="text-gray-500 text-sm truncate">
                <UiAvatar
                  name={props.resource.name}
                  color={props.resource.color}
                  image={props.resource.image}
                  shape="rounded"
                  size={14}
                />
              </dd>
            </div>
          )}
          <div className="w-full overflow-hidden space-y-1">
            <dt className="text-gray-700 text-sm truncate font-medium">
              {t('common.name')}
            </dt>
            <dd className="text-gray-500 text-sm truncate">
              {props.resource.name}
            </dd>
          </div>
          <div className="w-full overflow-hidden space-y-1">
            <dt className="text-gray-700 text-sm truncate font-medium">
              {t('common.description')}
            </dt>
            <dd className="text-gray-500 text-sm truncate">
              {props.resource.description
                ? props.resource.description
                : '-'}
            </dd>
          </div>
          {props.resource.capacity && (
            <div className="w-full overflow-hidden space-y-1">
              <dt className="text-gray-700 text-sm truncate font-medium">
                {t('common.capacity')}
              </dt>
              <dd className="text-gray-500 text-sm truncate">
                {props.resource.capacity} Person
              </dd>
            </div>
          )}
          {props.resource.position && (
            <div className="w-full overflow-hidden space-y-1">
              <dt className="text-gray-700 text-sm truncate font-medium">
                {t('common.position')}
              </dt>
              <dd className="text-gray-500 text-sm truncate">
                {props.resource.position}
              </dd>
            </div>
          )}
        </dl>
      </UiCard.Body>
      <UiCard.Footer />
    </UiCard>
  )
}

export default ResourceGeneralCard
