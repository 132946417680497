import React from 'react'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'

type UiHorizonNavigationProps = {
  children: React.ReactNode
}

const UiHorizonNavigation = (props: UiHorizonNavigationProps) => {
  return (
    <nav className="flex space-x-4" aria-label="Navbar">
      {props.children}
    </nav>
  )
}

type UiHorizonNavigationItemProps = {
  title: string
  subtitle?: string
  icon?: React.ElementType
  href: string
  onClick?: () => void
  end: boolean
}

const UiHorizonNavigationItem = (props: UiHorizonNavigationItemProps) => {
  let resolved = useResolvedPath(props.href)
  let match = useMatch({ path: resolved.pathname, end: props.end })

  return (
    <Link
      to={props.href}
      className={`group rounded-md px-3 py-2 flex items-center ${
        match
          ? 'bg-gray-200 text-gray-900'
          : 'text-gray-700 hover:bg-gray-200 hover:text-gray-900'
      }`}
      onClick={props.onClick}
      aria-current={match ? 'page' : undefined}
    >
      {props.icon && (
        <props.icon
          className={`flex-shrink-0 -ml-1 mr-3 h-6 w-6 ${
            match ? 'text-gray-700' : 'text-gray-400 group-hover:text-gray-700'
          }`}
          aria-hidden="true"
        />
      )}
      <div className="overflow-hidden">
        <p className="text-sm font-medium truncate">{props.title}</p>
        {props.subtitle && (
          <p className="mt-1 text-xs truncate">{props.subtitle}</p>
        )}
      </div>
    </Link>
  )
}

export default Object.assign(UiHorizonNavigation, {
  Item: UiHorizonNavigationItem,
})
