import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import { useEffect, useState } from 'react'
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form'
import UpdateResourceRequest from '@/business/dto/requests/update-resource.request'
import {
  createResource,
  updateResourceById,
} from '@/business/api/resource.service'
import { calcColor } from '@/business/api/image.service'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import UiImageUpload from '../ui-kit/input/basic/UiImageUpload'
import UiInput from '../ui-kit/input/basic/UiInput'
import { useNavigate } from 'react-router-dom'
import Color from '@/business/dto/types/color'
import CreateResourceRequest from '@/business/dto/requests/create-resource.request'
import UiColorPicker from '../ui-kit/input/UiColorPicker'
import ResourceResponse from '@/business/dto/responses/resource.response'

const mapToFormValue = (
  resource?: ResourceResponse
): CreateResourceRequest | UpdateResourceRequest => {
  return {
    name: resource?.name || '',
    description: resource?.description || '',
    image: resource?.image || null,
    color: resource?.color || Color.gray,
    capacity: resource?.capacity || 1,
    position: resource?.position || undefined,
  }
}

export const ResourceCreateCard = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<CreateResourceRequest>({
    defaultValues: mapToFormValue(undefined),
  })
  const name = form.watch('name')

  useEffect(() => {
    form.setValue('color', calcColor(name))
  }, [name])

  const onSubmit: SubmitHandler<CreateResourceRequest> = (data) => {
    setLoading(true)
    createResource(data)
      .then((response) => {
        navigate(`/resources/${response._id}`)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.created')}
            description={t('toast.description.resourceCreated')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        handleValidationError(err, i18n)
      })
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ResourceCreateUpdateCard loading={loading} resource={undefined} />
      </form>
    </FormProvider>
  )
}

export type ResourceUpdateCardProps = {
  resource: ResourceResponse
}

export const ResourceUpdateCard = (props: ResourceUpdateCardProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<UpdateResourceRequest>({
    defaultValues: mapToFormValue(props.resource),
  })

  const onSubmit: SubmitHandler<UpdateResourceRequest> = (data) => {
    setLoading(true)
    updateResourceById(props.resource._id, data)
      .then((response) => {
        navigate(`/resources/${response._id}`)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.updated')}
            description={t('toast.description.resourceUpdated')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        handleValidationError(err, i18n)
        setLoading(false)
      })
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ResourceCreateUpdateCard loading={loading} resource={props.resource} />
      </form>
    </FormProvider>
  )
}

type ResourceCreateUpdateCardProps = {
  loading: boolean
  resource?: ResourceResponse
}

const ResourceCreateUpdateCard = (props: ResourceCreateUpdateCardProps) => {
  const auth = useAuth()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const form = useFormContext<CreateResourceRequest | UpdateResourceRequest>()

  return (
    <UiCard>
      {/* <UiCard.Header
        title={'General'}
        description={t('page.contacts.properties.description')}
        icon={CubeTransparentIcon}
      /> */}
      <UiCard.Body>
        <div className='space-y-6'>
          <UiImageUpload
            label={t('common.image')}
            avatar={{
              name: form.watch('name')!,
              color: form.watch('color')!,
              shape: 'rounded',
            }}
            name='image'
            control={form.control}
          />
          <UiInput
            label={t('common.name')}
            type='text'
            name='name'
            control={form.control}
            autoFocus={true}
            rules={{
              required: {
                value: true,
                message: t('form.validation.required'),
              },
              minLength: {
                value: 3,
                message: t('form.validation.minLength', { x: 3 }),
              },
              maxLength: {
                value: 32,
                message: t('form.validation.maxLength', { x: 32 }),
              },
            }}
          />
          <UiInput
            label={t('common.description')}
            type='text'
            description='Schreibe eine kurze Beschreibung, die unter dem Namen erscheint. Hierbei kann es sich um eine Jobbezeichnung handeln.'
            name='description'
            control={form.control}
            rules={{
              maxLength: {
                value: 64,
                message: t('form.validation.maxLength', { x: 64 }),
              },
            }}
          />
          <UiColorPicker
            label={t('common.color')}
            control={form.control}
            name='color'
            rules={{ required: true }}
          />
          <hr className='bg-gray-100' />
          <UiInput
            label={t('common.capacity')}
            type='number'
            name='capacity'
            description='Die Kapazität gibt an, wie viele Personen maximal von einer Resource aufgenommen werden können. Ein Tisch mit 4 Stühlen hat beispielsweise eine Kapazität von 4 und ein Mitarbeiter für gewöhnlich von 1.'
            control={form.control}
            rules={{
              valueAsNumber: true,
              required: {
                value: true,
                message: t('form.validation.required'),
              },
              maxLength: {
                value: 64,
                message: t('form.validation.maxLength', { x: 64 }),
              },
            }}
          />
          {props.resource && (
            <UiInput
              label={t('common.position')}
              type='number'
              name='position'
              control={form.control}
              rules={{
                valueAsNumber: true,
                required: {
                  value: true,
                  message: t('form.validation.required'),
                },
                maxLength: {
                  value: 64,
                  message: t('form.validation.maxLength', { x: 64 }),
                },
              }}
            />
          )}
        </div>
      </UiCard.Body>
      <UiCard.Footer>
        <UiButton
          label={t('action.cancel')}
          type='button'
          variant='flat'
          onClick={
            props.resource
              ? () => navigate(`/resources/${props.resource?._id}`)
              : () => navigate('/resources')
          }
        />
        <UiButton
          label={props.resource ? t('action.save') : t('action.create')}
          type='submit'
          variant={props.resource ? 'dark' : 'primary'}
          loading={props.loading}
          disabled={!form.formState.isDirty}
        />
      </UiCard.Footer>
    </UiCard>
  )
}
