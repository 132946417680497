import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoaderFunctionArgs, defer, useLoaderData } from 'react-router-dom'
import { findResourceById } from '@/business/api/resource.service'
import ResourceServicesAddCard from '@/components/modals/ResourceServicesAddCard'
import { findAllServices } from '@/business/api/service.service'
import Paginated from '@/business/dto/responses/paginated.response'
import ResourceResponse from '@/business/dto/responses/resource.response'
import ServiceResponse from '@/business/dto/responses/service.response'

export const resourceServicesAddPageLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const [resource, paginatedServices] = await Promise.all([
    findResourceById(params.resourceId!),
    findAllServices({ page: 1, limit: 10 }),
  ])

  return defer({ resource, paginatedServices })
}

const ResourceServicesAddPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { resource, paginatedServices } = useLoaderData() as {
    resource: ResourceResponse
    paginatedServices: Paginated<ServiceResponse>
  }

  return (
    <UiContainer size="small">
      <UiHeader
        title={t('page.title.resourceServiceAdd')}
        description={t('page.description.resourceServiceAddX', {
          x: resource.name,
        })}
        desktopBackHref={`/resources/${resource._id}`}
        mobileBackHref={`/resources/${resource._id}`}
      />
      <div className="py-6 md:py-8">
        <div className="space-y-6">
          <ResourceServicesAddCard
            resource={resource}
            paginatedServices={paginatedServices}
          />
        </div>
      </div>
    </UiContainer>
  )
}

export default ResourceServicesAddPage
