import { calcInitials, toImagePath } from '@/business/api/image.service'
import Color from '@/business/dto/types/color'
import useAuth from '@/contexts/use-auth'

export type UiAvatarProps = {
  name: string
  color: Color
  shape: 'circle' | 'rounded'
  size: 8 | 10 | 12 | 14 | 16 | 32
  image: string | null
}

const UiAvatar = (props: UiAvatarProps) => {
  const auth = useAuth()
  const shapeClass = props.shape === 'circle' ? 'rounded-full' : 'rounded-lg'
  const imgClass = `w-${props.size} h-${props.size}`
  const spanClass =
    props.size === 8
      ? 'text-xs'
      : props.size === 10
      ? 'text-sm'
      : props.size === 12
      ? 'text-base'
      : props.size === 14
      ? 'text-lg'
      : props.size === 32
      ? 'text-7xl'
      : 'text-xl'

  return (
    <div className={`${imgClass} p-1`}>
      <div
        className={`relative w-full h-full ${shapeClass} outline-none ring-2 ring-${props.color}-500 ring-offset-2 ring-offset-gray-50`}
      >
        {!props.image && (
          <span
            className={`inline-flex w-full h-full items-center justify-center ${shapeClass} bg-${props.color}-500`}
          >
            <span className={`${spanClass} text-white`}>
              {props.name ? calcInitials(props.name) : <>&nbsp;</>}
            </span>
          </span>
        )}
        {props.image && (
          <img
            className={`${shapeClass} w-full h-full object-cover`}
            src={
              props.image.startsWith('blob:') || props.image.startsWith('data:')
                ? props.image
                : toImagePath(props.image)
            }
            alt={`Avatar of ${props.name}`}
          />
        )}
        <span
          className={`absolute inset-0 ${shapeClass} shadow-inner`}
          aria-hidden="true"
        />
      </div>
    </div>
  )
}

export default UiAvatar
