import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import AvailabiltiesCard from './AvailabilitiesCard'
import CreateOrUpdateAvailabilityRequest from '@/business/dto/requests/create-or-update-availability.request'
import {
  createResourceAvailability,
  deleteResourceAvailability,
  updateResourceAvailability,
} from '@/business/api/resource.service'
import ResourcePopulatedResponse from '@/business/dto/responses/resource-populated.response'

export type ResourceAvailabilitiesCardProps = {
  resource: ResourcePopulatedResponse
  setResourceDetails: React.Dispatch<React.SetStateAction<ResourcePopulatedResponse>>
}

const ResourceAvailabilitiesCard = (props: ResourceAvailabilitiesCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  return (
    <AvailabiltiesCard
      entityUri={`/resources/${props.resource._id}`}
      availabilities={props.resource.availabilities}
      createFn={(data: CreateOrUpdateAvailabilityRequest) =>
        createResourceAvailability(props.resource._id, data)
      }
      updateFn={(
        exceptionId: string,
        data: CreateOrUpdateAvailabilityRequest
      ) =>
        updateResourceAvailability(props.resource._id, exceptionId, data)
      }
      deleteFn={(exceptionId: string) =>
        deleteResourceAvailability(props.resource._id, exceptionId)
      }
    />
  )
}

export default ResourceAvailabilitiesCard
