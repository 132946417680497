import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoaderFunctionArgs, defer, useLoaderData } from 'react-router-dom'
import { findServiceById } from '@/business/api/service.service'
import ServiceResourcesAddCard from '@/components/modals/ServiceResourcesAddCard'
import { findAllResources } from '@/business/api/resource.service'
import Paginated from '@/business/dto/responses/paginated.response'
import ServiceResponse from '@/business/dto/responses/service.response'
import ResourceResponse from '@/business/dto/responses/resource.response'

export const serviceResourcesAddPageLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const [service, paginatedResources] = await Promise.all([
    findServiceById(params.serviceId!),
    findAllResources({ page: 1, limit: 10 }),
  ])

  return defer({ service, paginatedResources })
}

const ServiceResourcesAddPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { service, paginatedResources } = useLoaderData() as {
    service: ServiceResponse
    paginatedResources: Paginated<ResourceResponse>
  }

  return (
    <UiContainer size="small">
      <UiHeader
        title={t('page.title.serviceResourcesAddX', { x: service.name })}
        description={t('page.description.serviceResourcesAddX', {
          x: service.name,
        })}
        desktopBackHref={`/services/${service._id}`}
        mobileBackHref={`/services/${service._id}`}
      />
      <div className="py-6 md:py-8">
        <div className="space-y-6">
          <ServiceResourcesAddCard
            service={service}
            paginatedResources={paginatedResources}
          />
        </div>
      </div>
    </UiContainer>
  )
}

export default ServiceResourcesAddPage
