import axios from 'axios'
import CreateUserRequest from '@/business/dto/requests/create-user.request'
import QueryPaginationRequest from '@/business/dto/requests/query-pagination.request'
import UpdateUserRequest from '@/business/dto/requests/update-user.request'
import Paginated from '@/business/dto/responses/paginated.response'
import JwtToken from '../dto/responses/jwt-token'
import ValidationResponse from '../dto/responses/validation.response'
import UserResponse from '../dto/responses/user.response'
import UserPopulatedResponse from '../dto/responses/user-populated.response'

const ressourceUrl = `/api/users`

export const createUser = async (
  data: CreateUserRequest
): Promise<UserResponse> => {
  return axios.post(ressourceUrl, data).then((response) => response.data)
}

export const findAllUsers = async (
  query: QueryPaginationRequest
): Promise<Paginated<UserResponse>> => {
  return axios
    .get(ressourceUrl, {
      params: query,
    })
    .then((response) => response.data)
}

export const findUserById = async (userId: string): Promise<UserResponse> => {
  return axios
    .get(`${ressourceUrl}/${userId}`, {
      params: { populate: false },
    })
    .then((response) => response.data)
}

export const findUserByIdPopulated = async (
  userId: string
): Promise<UserPopulatedResponse> => {
  return axios
    .get(`${ressourceUrl}/${userId}`, {
      params: { populate: true },
    })
    .then((response) => response.data)
}

export const validateUserEmail = async (
  email: string,
  userId?: string
): Promise<ValidationResponse> => {
  return axios
    .get(`${ressourceUrl}/email-validator/${email}`, {
      params: { userId },
    })
    .then((response) => response.data)
}

export const impersonateById = async (userId: string): Promise<JwtToken> => {
  return axios
    .get(`${ressourceUrl}/${userId}/impersonate`)
    .then((response) => response.data)
}

export const updateUserById = async (
  userId: string,
  data: UpdateUserRequest
): Promise<UserResponse> => {
  return axios
    .patch(`${ressourceUrl}/${userId}`, data)
    .then((response) => response.data)
}

export const deleteUserById = async (userId: string): Promise<void> => {
  return axios
    .delete(`${ressourceUrl}/${userId}`)
    .then((response) => response.data)
}
