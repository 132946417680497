import {
  deleteReservationById,
  findAllReservations,
  updateReservationById,
} from '@/business/api/reservation.service'
import { getCurrentFnsLocale } from '@/business/date-utils'
import ReservationPopulatedResponse from '@/business/dto/responses/reservation-populated.response'
import ReservationsPaginated from '@/business/dto/responses/reservations-paginated'
import ReservationStatus from '@/business/dto/types/reservation-status'
import ReservationTabs from '@/business/dto/types/reservation-tabs'
import { handleValidationError } from '@/business/error-handler'
import DangerModal from '@/components/modals/DangerModal'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiSearch from '@/components/ui-kit/input/UiSearch'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import UiHorizonTabs from '@/components/ui-kit/layout/UiHorizonTabs'
import UiEmptyState from '@/components/ui-kit/presentation/UiEmptyState'
import UiNoSearchResults from '@/components/ui-kit/presentation/UiNoSearchResults'
import UiPagination from '@/components/ui-kit/presentation/UiPagination'
import UiToast from '@/components/ui-kit/presentation/UiToast'
import UiDate from '@/components/ui-kit/typography/UiDate'
import useAuth from '@/contexts/use-auth'
import {
  AdjustmentsHorizontalIcon,
  CalendarDaysIcon,
  CheckCircleIcon,
  MinusCircleIcon,
  PencilSquareIcon,
  PlusSmallIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import { AxiosError } from 'axios'
import classNames from 'classnames'
import { formatDistanceToNow, isAfter } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import { Fragment, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useOutletContext,
} from 'react-router-dom'

type ContextType = {
  paginated: ReservationsPaginated
  setPaginated: React.Dispatch<React.SetStateAction<ReservationsPaginated>>
  reload: () => void
}

const loadPage = (
  tab: ReservationTabs,
  page: number,
  search?: string,
  serviceIds?: string[],
  resourceIds?: string[]
) =>
  findAllReservations({
    tab,
    page,
    limit: 10,
    search: search || undefined,
    serviceIds,
    resourceIds,
  })

export const reservationsLoader = async (args: LoaderFunctionArgs) => {
  return loadPage(ReservationTabs.upcoming, 1)
}

const ReservationsPage = () => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const [paginated, setPaginated] = useState<ReservationsPaginated>(
    useLoaderData() as ReservationsPaginated
  )

  const [tab, setTab] = useState<ReservationTabs>(ReservationTabs.upcoming)
  const [search, setSearch] = useState('')
  const [serviceIds, setServiceIds] = useState<string[]>()
  const [resourceIds, setResourceIds] = useState<string[]>()
  const [loadingTimeout, setLoadingTimeout] = useState<NodeJS.Timeout | null>(
    null
  )
  const [open, setOpen] = useState(false)
  const [deleteModalReservation, setDeleteModalReservation] =
    useState<ReservationPopulatedResponse>()

  const reload = (tab: ReservationTabs, page: number, search: string) => {
    loadPage(tab, page, search, serviceIds, resourceIds)
      .then((paginated) => {
        setPaginated(paginated)
        setTab(tab)
        setLoadingTimeout(null)
        window.scrollTo(0, 0)
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  const handleSearchChanged = (search: string) => {
    setSearch(search)

    if (loadingTimeout) {
      clearTimeout(loadingTimeout)
    }

    setLoadingTimeout(setTimeout(() => reload(tab, 1, search), 200))
  }

  const handlePageChanged = (page: number) => {
    reload(tab, page, search)
  }

  const onUnknown = (reservation: ReservationPopulatedResponse) => {
    updateReservationById(reservation._id, {
      status: ReservationStatus.unknown,
    }).then((reservation) => {
      reload(tab, 1, search)
      toast.custom((toast) => (
        <UiToast
          toast={toast}
          type='success'
          title={t('toast.title.reservationUnknown')}
          description={t('toast.description.reservationUnkonwn')}
        />
      ))
    })
  }

  const onCheckIn = (reservation: ReservationPopulatedResponse) => {
    updateReservationById(reservation._id, {
      status: ReservationStatus.checkIn,
    }).then((reservation) => {
      reload(tab, 1, search)
      toast.custom((toast) => (
        <UiToast
          toast={toast}
          type='success'
          title={t('toast.title.reservationCheckedIn')}
          description={t('toast.description.reservationCheckedIn')}
        />
      ))
    })
  }

  const onNoShow = (reservation: ReservationPopulatedResponse) => {
    updateReservationById(reservation._id, {
      status: ReservationStatus.noShow,
    }).then((reservation) => {
      reload(tab, 1, search)
      toast.custom((toast) => (
        <UiToast
          toast={toast}
          type='success'
          title={t('toast.title.reservationNoShowed')}
          description={t('toast.description.reservationNoShowed')}
        />
      ))
    })
  }

  const onCancel = (reservation: ReservationPopulatedResponse) => {
    updateReservationById(reservation._id, {
      status: ReservationStatus.canceledByProvider,
    }).then((reservation) => {
      reload(tab, 1, search)
      toast.custom((toast) => (
        <UiToast
          toast={toast}
          type='success'
          title={t('toast.title.reservationCanceled')}
          description={t('toast.description.reservationCanceled')}
        />
      ))
    })
  }

  const onDelete = (reservation: ReservationPopulatedResponse) => {
    deleteReservationById(reservation._id).then((_) => {
      setDeleteModalReservation(undefined)
      reload(tab, 1, search)
      toast.custom((toast) => (
        <UiToast
          toast={toast}
          type='success'
          title={t('toast.title.deleted')}
          description={t('toast.description.reservationDeleted')}
        />
      ))
    })
  }

  return (
    <UiContainer>
      <UiHeader
        title={`${t('common.reservations')}`}
        description={t('page.description.reservations')}
        mobileAction={{
          icon: AdjustmentsHorizontalIcon,
          onClick: () => setOpen(true),
        }}
        desktopActions={
          <>
            <UiButton
              variant='gray'
              label={t('action.newWalkIn')}
              icon={PlusSmallIcon}
              type='button'
              onClick={() => setOpen(true)}
            />
            <UiButton
              as={Link}
              variant='primary'
              label={t('action.newReservation')}
              icon={PlusSmallIcon}
              type='button'
              to='/reservations/new'
            />
          </>
        }
        search={
          <>
            <UiSearch
              value={search}
              onChanged={(e) => handleSearchChanged(e.target.value)}
              placeholder={t('form.placeholder.searchReservation')}
            />
          </>
        }
      />
      <div className='py-6 md:py-8'>
        <div className='mb-4'>
          <UiHorizonTabs>
            <UiHorizonTabs.Item
              title={'Bevorstehende'}
              active={tab === ReservationTabs.upcoming}
              onClick={() => reload(ReservationTabs.upcoming, 1, search)}
            />
            <UiHorizonTabs.Item
              title={'Aktuelle'}
              active={tab === ReservationTabs.current}
              onClick={() => reload(ReservationTabs.current, 1, search)}
            />
            <UiHorizonTabs.Item
              title='Vergangene'
              active={tab === ReservationTabs.past}
              onClick={() => reload(ReservationTabs.past, 1, search)}
            />
            <UiHorizonTabs.Item
              title='Stornierte'
              active={tab === ReservationTabs.canceled}
              onClick={() => reload(ReservationTabs.canceled, 1, search)}
            />
          </UiHorizonTabs>
        </div>
        <div className='w-full text-left'>
          <div className='space-y-2'>
            {paginated.dates.map((day) => (
              <Fragment key={day.date}>
                <div className='px-3 py-2 text-sm font-semibold leading-6 text-gray-900'>
                  <UiDate date={day.date} format='PPPP' /> ({day.count})
                </div>
                <div className='bg-white rounded-lg shadow divide-y divide-gray-100'>
                  {day.reservations.map((reservation) => (
                    <div
                      key={reservation._id}
                      className='relative hover:bg-gray-50 flex flex-col md:flex-row p-4 gap-3 md:gap-4 md:gap-x-16 lg:gap-x-24'
                    >
                      <div className='shrink-0 flex items-start md:w-48 gap-3 md:gap-6'>
                        {tab === ReservationTabs.upcoming && (
                          <div
                            className={classNames(
                              'flex-none rounded-full p-1.5',
                              isAfter(
                                new Date(reservation.startDateTimeInUtc),
                                new Date()
                              )
                                ? 'bg-blue-400/10 text-blue-400'
                                : 'bg-yellow-400/10 text-yellow-400'
                            )}
                          >
                            <div className='h-3 w-3 rounded-full bg-current' />
                          </div>
                        )}
                        {tab === ReservationTabs.current && (
                          <div className='flex-none rounded-full p-1.5 text-green-400 bg-green-400/10'>
                            <div className='h-3 w-3 rounded-full bg-current' />
                          </div>
                        )}
                        {tab === ReservationTabs.past && (
                          <div
                            className={classNames(
                              'flex-none rounded-full p-1.5',
                              reservation.status ===
                                ReservationStatus.unknown &&
                                'bg-yellow-400/10 text-yellow-400',
                              reservation.status ===
                                ReservationStatus.checkIn &&
                                'bg-green-400/10 text-green-400',
                              reservation.status === ReservationStatus.noShow &&
                                'bg-red-400/10 text-red-400'
                            )}
                          >
                            <div className='h-3 w-3 rounded-full bg-current' />
                          </div>
                        )}
                        {tab === ReservationTabs.canceled && (
                          <div className='flex-none rounded-full p-1.5 text-gray-400 bg-gray-400/10'>
                            <div className='h-3 w-3 rounded-full bg-current' />
                          </div>
                        )}
                        <div className='flex-auto flex items-center justify-between flex-row md:flex-col md:items-start md:justify-start gap-y-1'>
                          <div className='flex items-start gap-x-3'>
                            <div className='text-sm font-medium leading-6 text-gray-900'>
                              {format(
                                utcToZonedTime(
                                  new Date(reservation.startDateTimeInUtc),
                                  auth.company.localization.timeZone
                                ),
                                'p',
                                {
                                  locale: getCurrentFnsLocale(),
                                  weekStartsOn:
                                    auth.company.localization.weekStartsOn,
                                }
                              )}{' '}
                              -{' '}
                              {format(
                                utcToZonedTime(
                                  new Date(reservation.endDateTimeInUtc),
                                  auth.company.localization.timeZone
                                ),
                                'p',
                                {
                                  locale: getCurrentFnsLocale(),
                                  weekStartsOn:
                                    auth.company.localization.weekStartsOn,
                                }
                              )}
                            </div>
                            {false && (
                              <div
                                className={classNames(
                                  'bg-gray-100 text-gray-800',
                                  'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                )}
                              >
                                {reservation.status}
                              </div>
                            )}
                          </div>
                          {true ? (
                            <div className='text-xs leading-5 text-gray-500'>
                              {formatDistanceToNow(
                                new Date(reservation.startDateTimeInUtc),
                                {
                                  locale: getCurrentFnsLocale(),
                                  addSuffix: true,
                                }
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className='grow'>
                        <div className='text-sm font-medium leading-6 text-gray-900'>
                          {reservation.contact.name} (
                          {reservation.contact.email})
                        </div>
                        <div className='mt-1 text-xs leading-5 text-gray-500 space-x-2'>
                          <span>{reservation.persons} Personen</span>
                          <span className='text-gray-500' aria-hidden='true'>
                            ·
                          </span>
                          <span>{reservation.name}</span>
                          <span className='text-gray-500' aria-hidden='true'>
                            ·
                          </span>
                          <span>{reservation.resource.name}</span>
                        </div>
                      </div>
                      {false && (
                        <div className='shrink-0 text-right'>
                          <div className='flex justify-end'>
                            <a
                              href={'/'}
                              className='text-sm font-medium leading-6 text-sky-600 hover:text-sky-500'
                            >
                              <span className='hidden sm:inline'>Details</span>{' '}
                              anzeigen
                              <span className='sr-only'>
                                , invoice #323, Client
                              </span>
                            </a>
                          </div>
                          <div className='mt-1 text-xs leading-5 text-gray-500'>
                            Termin{' '}
                            <span className='text-gray-900'>
                              #{reservation._id}
                            </span>
                          </div>
                        </div>
                      )}
                      <Link
                        to={`/reservations/${reservation._id}`}
                        className='absolute inset-0'
                      />
                      <div className='shrink-0 text-right flex items-center gap-3'>
                        {tab === ReservationTabs.upcoming && (
                          <>
                            <UiButton
                              variant='success'
                              label='Check-In'
                              icon={CheckCircleIcon}
                              type='button'
                              onClick={() => onCheckIn(reservation)}
                            />
                            <UiButton
                              variant='danger'
                              label='No-Show'
                              icon={XCircleIcon}
                              type='button'
                              onClick={() => onNoShow(reservation)}
                            />
                            <UiButton
                              variant='gray'
                              label='Cancel'
                              icon={MinusCircleIcon}
                              type='button'
                              onClick={() => onCancel(reservation)}
                            />
                          </>
                        )}
                        {tab === ReservationTabs.current && (
                          <>
                            <UiButton
                              variant='gray'
                              label='Unknown'
                              icon={QuestionMarkCircleIcon}
                              type='button'
                              onClick={() => onUnknown(reservation)}
                            />
                            <UiButton
                              variant='gray'
                              label='Cancel'
                              icon={MinusCircleIcon}
                              type='button'
                              onClick={() => onCancel(reservation)}
                            />
                          </>
                        )}
                        {tab === ReservationTabs.past && (
                          <>
                            <UiButton
                              variant='gray'
                              label='Unknown'
                              icon={QuestionMarkCircleIcon}
                              type='button'
                              onClick={() => onUnknown(reservation)}
                            />
                            <UiButton
                              variant='gray'
                              label='Cancel'
                              icon={MinusCircleIcon}
                              type='button'
                              onClick={() => onCancel(reservation)}
                            />
                          </>
                        )}
                        {tab === ReservationTabs.canceled && (
                          <>
                            <UiButton
                              variant='gray'
                              label='Unknown'
                              icon={QuestionMarkCircleIcon}
                              type='button'
                              onClick={() => onUnknown(reservation)}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </Fragment>
            ))}
          </div>
        </div>
        {paginated.totalPages > 1 && (
          <div className='flex justify-center'>
            <UiPagination
              page={paginated.page}
              totalPages={paginated.totalPages}
              onPageChanged={handlePageChanged}
            />
          </div>
        )}
        {paginated.totalReservations === 0 && !paginated.search && (
          <UiEmptyState
            icon={CalendarDaysIcon}
            title={t('hint.title.reservationEmptyState')}
            description={t('hint.description.reservationEmptyState')}
            button={{
              label: t('action.newReservation'),
              to: '/reservations/new',
            }}
          />
        )}
        {paginated.totalReservations === 0 && paginated.search && (
          <UiNoSearchResults />
        )}
      </div>
      {/* <DangerModal
        open={deleteModalReservation !== undefined}
        setOpen={(value: boolean) => {
          if (!value) {
            setDeleteModalReservation(undefined)
          }
        }}
        title={t('card.title.deleteReservation')}
        description={t('card.description.deleteReservation')}
        button={t('action.deleteReservation')}
        action={onDelete}
      /> */}
    </UiContainer>
  )
}

export default ReservationsPage

export function useReservations() {
  return useOutletContext<ContextType>()
}
