import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import {
  createServiceException,
  deleteServiceException,
  updateServiceException,
} from '@/business/api/service.service'
import ExceptionCard from './ExceptionsCard'
import CreateOrUpdateExceptionRequest from '@/business/dto/requests/create-or-update-exception.request'
import ServicePopulatedResponse from '@/business/dto/responses/service-populated.response'

export type ServiceExceptionsCardProps = {
  service: ServicePopulatedResponse
  setServiceDetails: React.Dispatch<React.SetStateAction<ServicePopulatedResponse>>
}

const ServiceExceptionsCard = (props: ServiceExceptionsCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  return (
    <ExceptionCard
      entityUri={`/services/${props.service._id}`}
      exceptions={props.service.exceptions}
      createFn={(data: CreateOrUpdateExceptionRequest) =>
        createServiceException(props.service._id, data)
      }
      updateFn={(exceptionId: string, data: CreateOrUpdateExceptionRequest) =>
        updateServiceException(props.service._id, exceptionId, data)
      }
      deleteFn={(exceptionId: string) =>
        deleteServiceException(props.service._id, exceptionId)
      }
    />
  )
}

export default ServiceExceptionsCard
