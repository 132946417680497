import { useTranslation } from 'react-i18next'
import { FaceSmileIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import ReservationPopulatedResponse from '@/business/dto/responses/reservation-populated.response'
import UiDateTime from '../ui-kit/typography/UiDateTime'
import { format, utcToZonedTime } from 'date-fns-tz'
import { getCurrentFnsLocale } from '@/business/date-utils'

export type ReservationGeneralCardProps = {
  reservation: ReservationPopulatedResponse
}

const ReservationGeneralCard = (props: ReservationGeneralCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  return (
    <UiCard>
      <UiCard.Header
        title={t('common.general')}
        description={t('card.description.serviceGeneral')}
        icon={FaceSmileIcon}
      />
      <UiCard.Body>
        <dl className="space-y-5">
          <div className="w-full overflow-hidden space-y-1">
            <dt className="text-gray-700 text-sm truncate font-medium">Was</dt>
            <dd className="text-gray-500 text-sm truncate">
              {props.reservation.name}
            </dd>
          </div>
        </dl>
        <dl className="space-y-5">
          <div className="w-full overflow-hidden space-y-1">
            <dt className="text-gray-700 text-sm truncate font-medium">Wann</dt>
            <dd className="text-gray-500 text-sm truncate">
              <p>
                {props.reservation.startDateTimeInUtc ? (
                  <UiDateTime
                    dateTimeInUtc={props.reservation.startDateTimeInUtc}
                    format="PPPP"
                  />
                ) : (
                  '-'
                )}
              </p>
              <p>
                {format(
                  utcToZonedTime(
                    new Date(props.reservation.startDateTimeInUtc),
                    auth.company.localization.timeZone
                  ),
                  'p',
                  {
                    locale: getCurrentFnsLocale(),
                    weekStartsOn: auth.company.localization.weekStartsOn,
                  }
                )}{' '}
                -{' '}
                {format(
                  utcToZonedTime(
                    new Date(props.reservation.endDateTimeInUtc),
                    auth.company.localization.timeZone
                  ),
                  'p',
                  {
                    locale: getCurrentFnsLocale(),
                    weekStartsOn: auth.company.localization.weekStartsOn,
                  }
                )}
              </p>
            </dd>
          </div>
        </dl>
        <dl className="space-y-5">
          <div className="w-full overflow-hidden space-y-1">
            <dt className="text-gray-700 text-sm truncate font-medium">Wer</dt>
            <dd className="text-gray-500 text-sm truncate">
              <p>{props.reservation.contact.name}</p>
              <p>{props.reservation.resource.name}</p>
            </dd>
          </div>
        </dl>
      </UiCard.Body>
      <UiCard.Footer />
    </UiCard>
  )
}

export default ReservationGeneralCard
