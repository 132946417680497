import {
  Control,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form'
import Language from '@/business/dto/types/language'
import UiCombobox from './basic/UiCombobox'

type UiLanguageComboboxProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  label?: string | null
  disabled?: boolean
  className?: string

  control: Control<TFieldValues>
  name: TName
  rules?: RegisterOptions<TFieldValues, TName>
}

const UiLanguageCombobox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UiLanguageComboboxProps<TFieldValues, TName>
) => {
  const options = [
    // { label: t('common.country.'), value: Language.AC },
    // { label: t('common.language.bg'), value: Language.bg },
    // { label: t('common.language.cs'), value: Language.cs },
    // { label: t('common.language.da'), value: Language.da },
    { label: 'Deutsch', value: Language.de },
    // { label: t('common.language.el'), value: Language.el },
    { label: 'English (United States)', value: Language.en },
    // { label: t('common.language.en-GB'), value: Language.enGB },
    // { label: t('common.language.es'), value: Language.es },
    // { label: t('common.language.es-419'), value: Language.es419 },
    // { label: t('common.language.et'), value: Language.et },
    // { label: t('common.language.fi'), value: Language.fi },
    // { label: t('common.language.fil'), value: Language.fil },
    // { label: t('common.language.fr'), value: Language.fr },
    // { label: t('common.language.fr-CA'), value: Language.frCA },
    // { label: t('common.language.hr'), value: Language.hr },
    // { label: t('common.language.hu'), value: Language.hu },
    // { label: t('common.language.id'), value: Language.id },
    // { label: t('common.language.it'), value: Language.it },
    // { label: t('common.language.ja'), value: Language.ja },
    // { label: t('common.language.ko'), value: Language.ko },
    // { label: t('common.language.lt'), value: Language.lt },
    // { label: t('common.language.lv'), value: Language.lv },
    // { label: t('common.language.ms'), value: Language.ms },
    // { label: t('common.language.mt'), value: Language.mt },
    // { label: t('common.language.nb'), value: Language.nb },
    // { label: t('common.language.nl'), value: Language.nl },
    // { label: t('common.language.pl'), value: Language.pl },
    // { label: t('common.language.pt'), value: Language.pt },
    // { label: t('common.language.pt-BR'), value: Language.ptBR },
    // { label: t('common.language.ro'), value: Language.ro },
    // { label: t('common.language.ru'), value: Language.ru },
    // { label: t('common.language.sk'), value: Language.sk },
    // { label: t('common.language.sl'), value: Language.sl },
    // { label: t('common.language.sv'), value: Language.sv },
    // { label: t('common.language.th'), value: Language.th },
    // { label: t('common.language.tr'), value: Language.tr },
    // { label: t('common.language.vi'), value: Language.vi },
    // { label: t('common.language.zh'), value: Language.zh },
    // { label: t('common.language.zh-HK'), value: Language.zhHK },
    // { label: t('common.language.zh-TW'), value: Language.zhTW },
  ]

  return <UiCombobox {...props} options={options} />
}

export default UiLanguageCombobox
