import React from 'react'
import { Link } from 'react-router-dom'

type UiHorizonTabsProps = {
  children: React.ReactNode
}

const UiHorizonTabs = (props: UiHorizonTabsProps) => {
  return (
    <nav className="flex space-x-4" aria-label="Navbar">
      {props.children}
    </nav>
  )
}

type UiHorizonTabsItemProps = {
  title: string
  subtitle?: string
  icon?: React.ElementType
  active: boolean
  onClick?: () => void
}

const UiHorizonTabsItem = (props: UiHorizonTabsItemProps) => {
  return (
    <button
      className={`group rounded-md px-3 py-2 flex items-center ${
        props.active
          ? 'bg-gray-200 text-gray-900'
          : 'text-gray-700 hover:bg-gray-200 hover:text-gray-900'
      }`}
      onClick={props.onClick}
      aria-current={props.active ? 'page' : undefined}
    >
      {props.icon && (
        <props.icon
          className={`flex-shrink-0 -ml-1 mr-3 h-6 w-6 ${
            props.active
              ? 'text-gray-700'
              : 'text-gray-400 group-hover:text-gray-700'
          }`}
          aria-hidden="true"
        />
      )}
      <div className="overflow-hidden">
        <p className="text-sm font-medium truncate">{props.title}</p>
        {props.subtitle && (
          <p className="mt-1 text-xs truncate">{props.subtitle}</p>
        )}
      </div>
    </button>
  )
}

export default Object.assign(UiHorizonTabs, {
  Item: UiHorizonTabsItem,
})
