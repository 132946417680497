import { AxiosError } from 'axios'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import UiContainer from './ui-kit/layout/UiContainer'
import UiAlert from './ui-kit/presentation/UiAlert'

const ErrorBoundary = () => {
  const error = useRouteError()
  console.log(error)

  return (
    <UiContainer>
      <div className="py-8">
        {isRouteErrorResponse(error) ? (
          <UiAlert
            title="Sorry"
            description={`Something went wrong in our loaders: ${error.statusText}`}
          />
        ) : error instanceof AxiosError ? (
          <UiAlert title={error.message} description={error.response?.data?.message} />
        ) : (
          <UiAlert title="Sorry" description="Something went wrong" />
        )}
      </div>
    </UiContainer>
  )
}

export default ErrorBoundary
