import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useState } from 'react'
import UpdateCompanyRequest from '@/business/dto/requests/update-company.request'
import { updateCompany } from '@/business/api/company.service'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import { TagIcon } from '@heroicons/react/24/outline'
import CompanyPopulatedResponse from '@/business/dto/responses/company-populated.response'
import UiInput from '../ui-kit/input/basic/UiInput'

const mapToFormValue = (
  company: CompanyPopulatedResponse
): UpdateCompanyRequest => {
  return {
    nomenclature: {
      reservation: company.nomenclature.reservation || '',
      reservations: company.nomenclature.reservations || '',
      contact: company.nomenclature.contact || '',
      contacts: company.nomenclature.contacts || '',
      category: company.nomenclature.category || '',
      categories: company.nomenclature.categories || '',
      service: company.nomenclature.service || '',
      services: company.nomenclature.services || '',
      resource: company.nomenclature.resource || '',
      resources: company.nomenclature.resources || '',
    },
  }
}

export type CompanyNomenclatureCardProps = {
  company: CompanyPopulatedResponse
  setCompany: React.Dispatch<React.SetStateAction<CompanyPopulatedResponse>>
}

const CompanyNomenclatureCard = (
  props: CompanyNomenclatureCardProps
) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<UpdateCompanyRequest>({
    defaultValues: mapToFormValue(props.company),
  })

  const onSubmit: SubmitHandler<UpdateCompanyRequest> = (data) => {
    setLoading(true)
    updateCompany(data)
      .then((response) => {
        props.setCompany(response)
        form.reset(mapToFormValue(response))
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.updated')}
            description={t('toast.description.companyUpdated')}
          />
        ))
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <UiCard>
          <UiCard.Header
            title={t('page.company.nomenclature.heading')}
            description={t('page.company.nomenclature.description')}
            icon={TagIcon}
          />
          <UiCard.Body>
            <div className='grid grid-cols-12 gap-6'>
              <div className='col-span-12 sm:col-span-6 space-y-6'>
                <div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                  <div className='sm:col-span-3'>
                    <UiInput
                      label={t('common.reservation')}
                      type='text'
                      control={form.control}
                      name='nomenclature.reservation'
                      placeholder='z. B. Reservierung, Termin, Buchung'
                      rules={{
                        maxLength: {
                          value: 24,
                          message: t('form.validation.maxLength', {
                            x: 24,
                          }),
                        },
                      }}
                    />
                  </div>
                  <div className='sm:col-span-3'>
                    <UiInput
                      label={t('common.reservations')}
                      type='text'
                      control={form.control}
                      name='nomenclature.reservations'
                      rules={{
                        maxLength: {
                          value: 24,
                          message: t('form.validation.maxLength', {
                            x: 24,
                          }),
                        },
                      }}
                    />
                  </div>
                  <div className='sm:col-span-3'>
                    <UiInput
                      label={t('common.contact')}
                      type='text'
                      control={form.control}
                      name='nomenclature.contact'
                      placeholder='z. B. Kunde, Gast, Patient'
                      rules={{
                        maxLength: {
                          value: 24,
                          message: t('form.validation.maxLength', { x: 24 }),
                        },
                      }}
                    />
                  </div>
                  <div className='sm:col-span-3'>
                    <UiInput
                      label={t('common.contacts')}
                      type='text'
                      control={form.control}
                      name='nomenclature.contacts'
                      rules={{
                        maxLength: {
                          value: 24,
                          message: t('form.validation.maxLength', { x: 24 }),
                        },
                      }}
                    />
                  </div>
                  <div className='sm:col-span-3'>
                    <UiInput
                      label={t('common.category')}
                      type='text'
                      control={form.control}
                      name='nomenclature.category'
                      placeholder='z. B.'
                      rules={{
                        maxLength: {
                          value: 24,
                          message: t('form.validation.maxLength', { x: 24 }),
                        },
                      }}
                    />
                  </div>
                  <div className='sm:col-span-3'>
                    <UiInput
                      label={t('common.categories')}
                      type='text'
                      control={form.control}
                      name='nomenclature.categories'
                      rules={{
                        maxLength: {
                          value: 24,
                          message: t('form.validation.maxLength', { x: 24 }),
                        },
                      }}
                    />
                  </div>
                  <div className='sm:col-span-3'>
                    <UiInput
                      label={t('common.service')}
                      type='text'
                      control={form.control}
                      name='nomenclature.service'
                      placeholder='z. B. Behandlung, Ticket, Bereich'
                      rules={{
                        maxLength: {
                          value: 24,
                          message: t('form.validation.maxLength', { x: 24 }),
                        },
                      }}
                    />
                  </div>
                  <div className='sm:col-span-3'>
                    <UiInput
                      label={t('common.services')}
                      type='text'
                      control={form.control}
                      name='nomenclature.services'
                      rules={{
                        maxLength: {
                          value: 24,
                          message: t('form.validation.maxLength', { x: 24 }),
                        },
                      }}
                    />
                  </div>
                  <div className='sm:col-span-3'>
                    <UiInput
                      label={t('common.resource')}
                      type='text'
                      control={form.control}
                      name='nomenclature.resource'
                      placeholder='z. B. Tisch, Bowlingbahn, Kartbahn'
                      rules={{
                        maxLength: {
                          value: 24,
                          message: t('form.validation.maxLength', { x: 24 }),
                        },
                      }}
                    />
                  </div>
                  <div className='sm:col-span-3'>
                    <UiInput
                      label={t('common.resources')}
                      type='text'
                      control={form.control}
                      name='nomenclature.resources'
                      rules={{
                        maxLength: {
                          value: 24,
                          message: t('form.validation.maxLength', { x: 24 }),
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='hidden sm:block sm:col-span-6'>
                <UiCard.Image src='/img/rose/undraw_text_field_htlv.svg' />
              </div>
            </div>
          </UiCard.Body>
          <UiCard.Footer>
            <UiButton
              label={t('action.cancel')}
              variant='flat'
              type='button'
              disabled={!form.formState.isDirty}
              onClick={() => form.reset(mapToFormValue(props.company))}
            />
            <UiButton
              label={t('action.save')}
              variant='dark'
              type='submit'
              loading={loading}
              disabled={!form.formState.isDirty}
            />
          </UiCard.Footer>
        </UiCard>
      </form>
    </>
  )
}

export default CompanyNomenclatureCard
