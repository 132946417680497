import axios from 'axios'
import CreateServiceRequest from '@/business/dto/requests/create-service.request'
import QueryPaginationRequest from '@/business/dto/requests/query-pagination.request'
import UpdateServiceRequest from '@/business/dto/requests/update-service.request'
import Paginated from '@/business/dto/responses/paginated.response'
import CreateOrUpdateAvailabilityRequest from '@/business/dto/requests/create-or-update-availability.request'
import CreateOrUpdateExceptionRequest from '@/business/dto/requests/create-or-update-exception.request'
import CreateOrUpdateQuestionRequest from '@/business/dto/requests/create-or-update-question.request'
import QueryServiceDayAvailabilitiesRequest from '../dto/requests/query-service-day-availabilities.request'
import QueryServiceSlotAvailabilitiesRequest from '../dto/requests/query-service-slot-availabilities.request'
import ServiceSlotAvailabilityResponse from '../dto/responses/service-slot-availability.response'
import ServiceDayAvailabilityResponse from '../dto/responses/service-day-availability.response'
import ServiceResponse from '../dto/responses/service.response'
import ServicePopulatedResponse from '../dto/responses/service-populated.response'
import CategoryResponse from '../dto/responses/category.response'
import ResourceResponse from '../dto/responses/resource.response'

const ressourceUrl = `/api/services`

export const createService = async (
  data: CreateServiceRequest
): Promise<ServiceResponse> => {
  return axios.post(ressourceUrl, data).then((response) => response.data)
}

export const findAllServices = async (
  query: QueryPaginationRequest
): Promise<Paginated<ServiceResponse>> => {
  return axios
    .get(ressourceUrl, {
      params: query,
    })
    .then((response) => response.data)
}

export const findServiceById = async (
  serviceId: string
): Promise<ServiceResponse> => {
  return axios
    .get(`${ressourceUrl}/${serviceId}`, {
      params: { populate: false },
    })
    .then((response) => response.data)
}

export const findServiceByIdPopulated = async (
  serviceId: string
): Promise<ServicePopulatedResponse> => {
  return axios
    .get(`${ressourceUrl}/${serviceId}`, {
      params: { populate: true },
    })
    .then((response) => response.data)
}

export const updateServiceById = async (
  serviceId: string,
  data: UpdateServiceRequest
): Promise<ServiceResponse> => {
  return axios
    .patch(`${ressourceUrl}/${serviceId}`, data)
    .then((response) => response.data)
}

export const deleteServiceById = async (serviceId: string): Promise<void> => {
  return axios
    .delete(`${ressourceUrl}/${serviceId}`)
    .then((response) => response.data)
}

export const createServiceAvailability = async (
  serviceId: string,
  data: CreateOrUpdateAvailabilityRequest
): Promise<ServiceResponse> => {
  return axios
    .post(`${ressourceUrl}/${serviceId}/availabilities`, data)
    .then((response) => response.data)
}

export const updateServiceAvailability = async (
  serviceId: string,
  availabilityId: string,
  data: CreateOrUpdateAvailabilityRequest
): Promise<ServiceResponse> => {
  return axios
    .put(`${ressourceUrl}/${serviceId}/availabilities/${availabilityId}`, data)
    .then((response) => response.data)
}

export const deleteServiceAvailability = async (
  serviceId: string,
  availabilityId: string
): Promise<ServiceResponse> => {
  return axios
    .delete(`${ressourceUrl}/${serviceId}/availabilities/${availabilityId}`)
    .then((response) => response.data)
}

export const createServiceException = async (
  serviceId: string,
  data: CreateOrUpdateExceptionRequest
): Promise<ServiceResponse> => {
  return axios
    .post(`${ressourceUrl}/${serviceId}/exceptions`, data)
    .then((response) => response.data)
}

export const updateServiceException = async (
  serviceId: string,
  exceptionId: string,
  data: CreateOrUpdateExceptionRequest
): Promise<ServiceResponse> => {
  return axios
    .put(`${ressourceUrl}/${serviceId}/exceptions/${exceptionId}`, data)
    .then((response) => response.data)
}

export const deleteServiceException = async (
  serviceId: string,
  exceptionId: string
): Promise<ServiceResponse> => {
  return axios
    .delete(`${ressourceUrl}/${serviceId}/exceptions/${exceptionId}`)
    .then((response) => response.data)
}

export const createServiceQuestion = async (
  serviceId: string,
  data: CreateOrUpdateQuestionRequest
): Promise<ServiceResponse> => {
  return axios
    .post(`${ressourceUrl}/${serviceId}/questions`, data)
    .then((response) => response.data)
}

export const updateServiceQuestion = async (
  serviceId: string,
  questionId: string,
  data: CreateOrUpdateQuestionRequest
): Promise<ServiceResponse> => {
  return axios
    .put(`${ressourceUrl}/${serviceId}/questions/${questionId}`, data)
    .then((response) => response.data)
}

export const deleteServiceQuestion = async (
  serviceId: string,
  questionId: string
): Promise<ServiceResponse> => {
  return axios
    .delete(`${ressourceUrl}/${serviceId}/questions/${questionId}`)
    .then((response) => response.data)
}

export const findServiceCategories = async (
  serviceId: string,
  query: QueryPaginationRequest
): Promise<Paginated<CategoryResponse>> => {
  return axios
    .get(`${ressourceUrl}/${serviceId}/categories`, {
      params: query,
    })
    .then((response) => response.data)
}

export const findServiceResources = async (
  serviceId: string,
  query: QueryPaginationRequest
): Promise<Paginated<ResourceResponse>> => {
  return axios
    .get(`${ressourceUrl}/${serviceId}/resources`, {
      params: query,
    })
    .then((response) => response.data)
}

export const findDaysAvailabilities = async (
  serviceId: string,
  query: QueryServiceDayAvailabilitiesRequest
): Promise<ServiceDayAvailabilityResponse[]> => {
  return axios
    .get(`${ressourceUrl}/${serviceId}/day-availabilities`, {
      params: query,
    })
    .then((response) => response.data)
}

export const findSlotAvailabilities = async (
  serviceId: string,
  query: QueryServiceSlotAvailabilitiesRequest
): Promise<ServiceSlotAvailabilityResponse[]> => {
  return axios
    .get(`${ressourceUrl}/${serviceId}/slot-availabilities`, {
      params: query,
    })
    .then((response) => response.data)
}
