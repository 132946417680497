import {
  Control,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import UiCombobox from './basic/UiCombobox'
import Industry from '@/business/dto/types/industry'

type UiIndustryComboboxProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  label?: string | null
  disabled?: boolean
  className?: string

  control: Control<TFieldValues>
  name: TName
  rules?: RegisterOptions<TFieldValues, TName>
}

const UiIndustryCombobox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UiIndustryComboboxProps<TFieldValues, TName>
) => {
  const { t, i18n } = useTranslation()
  const industries = Object.values(Industry)

  return (
    <UiCombobox
      {...props}
      options={industries.map((industry) => {
        return {
          label: industry,
          value: industry,
        }
      })}
    />
  )
}

export default UiIndustryCombobox
