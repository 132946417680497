import useAuth from '@/contexts/use-auth'
import { ElementType, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import UiButton from '../actions/UiButton'
import { PlusSmallIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

type UiEmptyStateProps = {
  icon: ElementType
  title: string
  description: string
  button?: {
    label: string
    to: string
  }
}

const UiEmptyState = (props: UiEmptyStateProps) => {
  const { t } = useTranslation()
  const auth = useAuth()
  const color = auth?.company?.appearance?.color || 'rose'

  return (
    <div className="text-center max-w-2xl mx-auto">
      <props.icon className="mx-auto h-12 w-12 text-gray-400" />
      <div className="mt-2 text-sm font-semibold text-gray-900">
        {props.title}
      </div>
      <p className="mt-1 text-sm text-gray-500">{props.description}</p>
      {props.button && (
        <div className="mt-6">
          <UiButton
            as={Link}
            variant="primary"
            label={props.button.label}
            icon={PlusSmallIcon}
            type="button"
            to={props.button.to}
          />
        </div>
      )}
    </div>
  )
}

export default UiEmptyState
