import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { handleValidationError } from '@/business/error-handler'
import { deleteResourceById } from '@/business/api/resource.service'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import DangerModal from '../modals/DangerModal'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import ResourcePopulatedResponse from '@/business/dto/responses/resource-populated.response'

export type ResourceDangerzoneCardProps = {
  resource: ResourcePopulatedResponse
  setResourceDetails: React.Dispatch<React.SetStateAction<ResourcePopulatedResponse>>
}

const ResourceDangerzoneCard = (props: ResourceDangerzoneCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [open, setOpen] = useState<boolean>(false)

  const onDelete = () =>
    deleteResourceById(props.resource._id)
      .then((_) => {
        setOpen(false)
        navigate('/resources')
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type="success"
            title={t('toast.title.deleted')}
            description={t('toast.description.resourceDeleted')}
          />
        ))
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))

  return (
    <>
      <UiCard>
        <UiCard.Header
          title={t('common.dangerZone')}
          description={
            'Destructive actions below can result in irrecoverable data loss. Be careful.'
          }
          icon={ExclamationTriangleIcon}
        />
        <UiCard.Body>
          <UiButton
            label={t('action.deleteResource')}
            variant="danger"
            type="button"
            onClick={() => setOpen(true)}
          />
        </UiCard.Body>
        <UiCard.Footer />
      </UiCard>
      <DangerModal
        open={open}
        setOpen={setOpen}
        title={t('card.title.deleteResource')}
        description={t('card.description.deleteResource')}
        button={t('action.deleteResource')}
        action={onDelete}
      />
    </>
  )
}

export default ResourceDangerzoneCard
