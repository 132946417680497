import { useTranslation } from 'react-i18next'
import {
  PencilIcon,
  PlusSmallIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '../ui-kit/actions/UiButton'
import DangerModal from '../modals/DangerModal'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import ServicePopulatedResponse from '@/business/dto/responses/service-populated.response'

export type ServiceQuestionCardProps = {
  service: ServicePopulatedResponse
}

const ServiceQuestionCard = (props: ServiceQuestionCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  const onDelete = () => {
    // deleteServiceException(service._id, currentException._id).then(
    //   (response) => {
    //     setService(response)
    //     setDeleteModalOpen(false)
    //     toast.custom((t) => (
    //       <UiToast
    //         toast={t}
    //         type="success"
    //         title="Ausnahme gelöscht"
    //         description={`Sie haben die Ausnahme erfolgreich gelöscht.`}
    //       />
    //     ))
    //   }
    // )

    return Promise.resolve()
  }

  return (
    <>
      {/* <UiCard>
        <UiCard.Header
          title={'Questions'}
          description={t('page.services.questions.description')}
          icon={QuestionMarkCircleIcon}
        >
          <UiButton
            as={Link}
            label={`${t('common.resource')} zuweisen`}
            variant="default"
            icon={PlusSmallIcon}
            to={`/services/${props.service._id}/questions`}
          />
        </UiCard.Header>
        <UiCard.Body>
          <ul role="list" className="divide-y divide-gray-100">
            {props.service.customQuestions?.map((question) => (
              <li
                key={question.name}
                className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap"
              >
                <div>
                  <div className="text-sm font-semibold leading-6 text-gray-700">
                    {question.name}
                  </div>
                  <div className="mt-1 text-xs text-gray-500"></div>
                </div>
                <div className="flex w-full flex-none justify-between gap-x-3 sm:w-auto">
                  <UiButton
                    as={Link}
                    label="Edit"
                    variant="light-gray"
                    icon={PencilIcon}
                    iconOnly={true}
                    to={`/services/${props.service._id}/questions/${question._id}`}
                  />
                  <UiButton
                    label="Delete"
                    variant="danger"
                    icon={TrashIcon}
                    iconOnly={true}
                    onClick={() => setOpen(true)}
                  />
                </div>
              </li>
            ))}
          </ul>
          {false && (
            <div>
              Question, Required, On, Answer Type [One Line, Multiple Lines,
              Radio Buttons, Checkboxes]
            </div>
          )}
        </UiCard.Body>
        <UiCard.Footer />
      </UiCard> */}
      <DangerModal
        open={open}
        setOpen={setOpen}
        title={t('card.title.deleteQuestion')}
        description={t('card.description.deleteQuestion')}
        button={t('action.deleteQuestion')}
        action={onDelete}
      />
    </>
  )
}

export default ServiceQuestionCard
