import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${process.env.REACT_APP_VERSION}`,
    },
  })
  .then(() => {
    console.log(`language initiated: ${i18n.language}`)
    document.documentElement.lang = i18n.language

    i18n.on('languageChanged', (lng) => {
      console.log(`Language changed to ${lng}`)
      document.documentElement.setAttribute('lang', lng)
    })
  })

export default i18n
