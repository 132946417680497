import classNames from 'classnames'
import React from 'react'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'

type UiVerticalNavigationProps = {
  children: React.ReactNode
}

const UiVerticalNavigation = (props: UiVerticalNavigationProps) => {
  return (
    <nav className="space-y-1" aria-label="Sidebar">
      {props.children}
    </nav>
  )
}

type UiVerticalNavigationItemProps = {
  title: string
  subtitle?: string
  icon?: React.ElementType
  href: string
  end: boolean
  count?: number
}

const UiVerticalNavigationItem = (props: UiVerticalNavigationItemProps) => {
  let resolved = useResolvedPath(props.href)
  let match = useMatch({ path: resolved.pathname, end: props.end })

  return (
    <Link
      to={props.href}
      className={`group rounded-md px-3 py-2 flex items-center ${
        match
          ? 'bg-gray-200 text-gray-900'
          : 'text-gray-700 hover:bg-gray-200 hover:text-gray-900'
      }`}
      aria-current={match ? 'page' : undefined}
    >
      {props.icon && (
        <props.icon
          className={`flex-shrink-0 -ml-1 mr-3 h-6 w-6 ${
            match ? 'text-gray-700' : 'text-gray-400 group-hover:text-gray-700'
          }`}
          aria-hidden="true"
        />
      )}
      <div className="overflow-hidden">
        <p className="text-sm font-medium truncate">{props.title}</p>
        {props.subtitle && (
          <p className="mt-1 text-xs truncate">{props.subtitle}</p>
        )}
      </div>
      {props.count ? (
        <span
          className={classNames(
            match ? 'bg-gray-50' : 'bg-gray-200 text-gray-600',
            'ml-auto inline-block py-0.5 px-3 text-xs rounded-full'
          )}
        >
          {props.count}
        </span>
      ) : null}
    </Link>
  )
}

export default Object.assign(UiVerticalNavigation, {
  Item: UiVerticalNavigationItem,
})
