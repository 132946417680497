import { useTranslation } from 'react-i18next'
import { CubeTransparentIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import { Line } from 'react-chartjs-2'
import { faker } from '@faker-js/faker'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ScriptableContext,
} from 'chart.js'
import { add, addDays, format } from 'date-fns'
import { useEffect, useState } from 'react'

export type DashboardStatsCardProps = {}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  // Title
  Tooltip
  // Legend
)

const labels = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
]

const DashboardStatsCard = (props: DashboardStatsCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const [data, setData] = useState<number[]>([])

  useEffect(() => {
    setData(labels.map(() => faker.datatype.number({ min: 0, max: 10800 })))
  }, [])

  return (
    <UiCard>
      <UiCard.Header
        title={t('common.general')}
        description={t('card.description.categoryGeneral')}
        icon={CubeTransparentIcon}
      />
      <UiCard.Body>
        {data && data.length > 0 && (
          <div className="relative w-full h-96">
            <Line
              options={{
                responsive: true,
                maintainAspectRatio: false,
                locale: auth.company.localization.language,
                plugins: {
                  // legend: {
                  //   position: 'top' as const,
                  // },
                  // title: {
                  //   display: true,
                  //   text: 'Chart.js Line Chart',
                  // },
                },
                scales: {
                  x: {
                    border: {
                      display: true,
                      color: '#e5e7eb',
                    },
                    grid: {
                      display: true,
                      color: '#f3f4f6',
                    },
                    ticks: {
                      color: '#6b7280',
                      maxTicksLimit: 8,
                      font: {
                        size: 14,
                        family:
                          'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                      },
                    },
                  },
                  y: {
                    border: {
                      display: true,
                      color: '#e5e7eb',
                    },
                    grid: {
                      display: true,
                      color: '#f3f4f6',
                    },
                    ticks: {
                      color: '#6b7280',
                      maxTicksLimit: 8,
                      font: {
                        size: 14,
                        family:
                          'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                      },
                    },
                  },
                },
              }}
              data={{
                labels: labels.map((x) =>
                  format(addDays(new Date(), +x), 'PP')
                ),
                datasets: [
                  {
                    label: 'Reservations',
                    data,
                    tension: 0.3,
                    cubicInterpolationMode: 'monotone',
                    // Line Styling
                    backgroundColor: 'rgba(244,63,94,0.05)',
                    borderColor: '#f43f5e',
                    borderWidth: 3,
                    fill: true,
                    // Point Styling
                    pointStyle: 'circle',
                    pointBackgroundColor: '#f43f5e',
                    pointBorderColor: '#f43f5e',
                    pointRadius: 3,
                    pointHitRadius: 32,
                    pointHoverRadius: 6,
                  },
                ],
              }}
              updateMode="resize"
            />
          </div>
        )}
      </UiCard.Body>
      <UiCard.Footer />
    </UiCard>
  )
}

export default DashboardStatsCard
