import ReservationPopulatedResponse from '@/business/dto/responses/reservation-populated.response'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { PencilIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Link,
  LoaderFunctionArgs,
  defer,
  useLoaderData,
} from 'react-router-dom'
import ReservationGeneralCard from '@/components/cards/ReservationGeneralCard'
import { findReservationById } from '@/business/api/reservation.service'

export const reservationPageLoader = async ({ params }: LoaderFunctionArgs) => {
  const [reservation] = await Promise.all([
    findReservationById(params.reservationId!),
  ])
  return defer({ reservation })
}

const ReservationPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const loaderData = useLoaderData() as any
  const [reservation, setReservation] = useState<ReservationPopulatedResponse>(
    loaderData.reservation
  )

  return (
    <UiContainer size="small">
      <UiHeader
        avatar={{
          name: reservation.name,
          color: reservation.contact.color,
          image: reservation.contact.image,
          shape: 'rounded',
        }}
        title={reservation.name}
        description={t('page.description.reservation')}
        desktopBackHref="/reservations"
        mobileBackHref="/reservations"
        mobileAction={{
          icon: PencilSquareIcon,
          to: `/reservations/${reservation._id}/edit`,
        }}
        desktopActions={
          <UiButton
            as={Link}
            variant="primary"
            label={t('action.editReservation')}
            icon={PencilIcon}
            to={`/reservations/${reservation._id}/edit`}
          />
        }
      />
      <div className="py-6 md:py-8">
        <div className="space-y-6">
          <ReservationGeneralCard reservation={reservation} />
        </div>
      </div>
    </UiContainer>
  )
}

export default ReservationPage
