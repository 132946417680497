import axios from 'axios'
import VerificationRequest from '@/business/dto/requests/verification.request'
import ObtainTokenRequest from '@/business/dto/requests/obtain-token.request'
import SignInRequest from '@/business/dto/requests/sign-in.request'
import SignUpRequest from '@/business/dto/requests/sign-up.request'
import VerifyCodeRequest from '@/business/dto/requests/verify-code.request'
import JwtToken from '@/business/dto/responses/jwt-token'
import ForgotPasswordRequest from '../dto/requests/forgot-password.request'
import ResetPasswordRequest from '../dto/requests/reset-password.request'
import UserPopulatedResponse from '../dto/responses/user-populated.response'
import UserResponse from '../dto/responses/user.response'

const ressourceUrl = `/auth`

export const signUp = async (data: SignUpRequest): Promise<void> => {
  return axios
    .post(`${ressourceUrl}/sign-up`, data)
    .then((response) => response.data)
}

export const signIn = async (data: SignInRequest): Promise<JwtToken> => {
  return axios
    .post(`${ressourceUrl}/sign-in`, data)
    .then((response) => response.data)
}

export const obtainToken = async (
  data: ObtainTokenRequest
): Promise<JwtToken> => {
  return axios
    .post(`${ressourceUrl}/token`, data)
    .then((response) => response.data)
}

export const createVerification = async (
  data: VerificationRequest
): Promise<void> => {
  return axios
    .post(`${ressourceUrl}/verification`, data)
    .then((response) => response.data)
}

export const verifyCodeAndSignIn = async (
  data: VerifyCodeRequest
): Promise<JwtToken> => {
  return axios
    .post(`${ressourceUrl}/verify`, data)
    .then((response) => response.data)
}

export const forgotPassword = async (
  data: ForgotPasswordRequest
): Promise<void> => {
  return axios
    .post(`${ressourceUrl}/forgot-password`, data)
    .then((response) => response.data)
}

export const resetPassword = async (
  data: ResetPasswordRequest
): Promise<JwtToken> => {
  return axios
    .post(`${ressourceUrl}/reset-password`, data)
    .then((response) => response.data)
}

export const loadAuth = async (): Promise<UserResponse> => {
  return axios
    .get(ressourceUrl, {
      params: { populate: false },
    })
    .then((response) => response.data)
}

export const loadAuthPopulated = async (): Promise<UserPopulatedResponse> => {
  return axios
    .get(ressourceUrl, {
      params: { populate: true },
    })
    .then((response) => response.data)
}
