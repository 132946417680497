import {
  findServiceByIdPopulated,
  findServiceCategories,
  findServiceResources,
} from '@/business/api/service.service'
import CategoryResponse from '@/business/dto/responses/category.response'
import Paginated from '@/business/dto/responses/paginated.response'
import ResourceResponse from '@/business/dto/responses/resource.response'
import ServicePopulatedResponse from '@/business/dto/responses/service-populated.response'
import ServiceAvailabilitiesCard from '@/components/cards/ServiceAvailabilitiesCard'
import ServiceCategoriesCard from '@/components/cards/ServiceCategoriesCard'
import ServiceDangerzoneCard from '@/components/cards/ServiceDangerzoneCard'
import ServiceExceptionsCard from '@/components/cards/ServiceExceptionsCard'
import ServiceGeneralCard from '@/components/cards/ServiceGeneralCard'
import ServiceMetadataCard from '@/components/cards/ServiceMetadataCard'
import ServiceQuestionCard from '@/components/cards/ServiceQuestionCard'
import ServiceResourcesCard from '@/components/cards/ServiceResourcesCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { PencilIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Link,
  LoaderFunctionArgs,
  defer,
  useLoaderData,
} from 'react-router-dom'

export const servicePageLoader = async ({ params }: LoaderFunctionArgs) => {
  const [service, paginatedResources, paginatedServices] = await Promise.all([
    findServiceByIdPopulated(params.serviceId!),
    findServiceResources(params.serviceId!, { page: 1, limit: 10 }),
    findServiceCategories(params.serviceId!, { page: 1, limit: 10 }),
  ])

  return defer({ service, paginatedResources, paginatedServices })
}

const ServicePage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const loaderData = useLoaderData() as any
  const [service, setServiceDetails] = useState<ServicePopulatedResponse>(
    loaderData.service
  )
  const [paginatedResources, setPaginatedResources] = useState<
    Paginated<ResourceResponse>
  >(loaderData.paginatedResources)
  const [paginatedCategories, setPaginatedCategories] = useState<
    Paginated<CategoryResponse>
  >(loaderData.paginatedServices)

  return (
    <UiContainer>
      <UiHeader
        avatar={{
          name: service.name,
          color: service.color,
          image: service.image,
          shape: 'rounded',
        }}
        title={service.name}
        description={t('page.description.service')}
        desktopBackHref="/services"
        mobileBackHref="/services"
        mobileAction={{
          icon: PencilSquareIcon,
          to: `/services/${service._id}/edit`,
        }}
        desktopActions={
          <UiButton
            as={Link}
            variant="primary"
            label={t('action.editService')}
            icon={PencilIcon}
            to={`/services/${service._id}/edit`}
          />
        }
      />
      <div className="py-6 md:py-8">
        <div className="space-y-6">
          <div className="grid grid-cols-1 grid-rows-1 items-start md:gap-6 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
            <div className="lg:col-start-1">
              <ServiceGeneralCard service={service} />
            </div>
            <div className="lg:col-start-2 lg:col-span-2 lg:row-span-2 lg:row-end-2 2xl:col-start-2 2xl:col-span-3">
              <div className="space-y-6">
                {
                  //     timeIncrements: service.timeIncrements || 15,
                  //     minimumLeadTime: service.minimumLeadTime || 720,
                  //     maximumLeadTime: service.maximumLeadTime || 43200,
                }
                <ServiceAvailabilitiesCard
                  service={service}
                  setService={setServiceDetails}
                />
                <ServiceExceptionsCard
                  service={service}
                  setServiceDetails={setServiceDetails}
                />
                <ServiceCategoriesCard
                  service={service}
                  setServiceDetails={setServiceDetails}
                  paginatedCategories={paginatedCategories}
                  setPaginatedCategories={setPaginatedCategories}
                />
                {
                  // resourceSelectType: service.resourceSelectType || 'BEFORE_DATE', 'AFTER_DATE',
                  // resourceSelectable: service.resourceSelectable || false,
                  // resourcen egal wählen gibts immer wenn mehr als 1
                  // Vergabealgorithmus der Resourcen : Round Robin, by Index, (random)
                }
                <ServiceResourcesCard
                  service={service}
                  setServiceDetails={setServiceDetails}
                  paginatedResources={paginatedResources}
                  setPaginatedResources={setPaginatedResources}
                />
                <ServiceQuestionCard service={service} />
                <ServiceDangerzoneCard
                  service={service}
                  setServiceDetails={setServiceDetails}
                />
              </div>
            </div>
            <div className="lg:col-start-1">
              <ServiceMetadataCard service={service} />
            </div>
          </div>
        </div>
      </div>
    </UiContainer>
  )
}

export default ServicePage
