import UiHeader from '@/components/ui-kit/layout/UiHeader'
import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import { LoaderFunctionArgs, defer, useLoaderData } from 'react-router-dom'
import { findServiceById } from '@/business/api/service.service'
import ServiceQuestionCreateUpdateCard from '@/components/modals/ServiceQuestionCreateUpdateCard'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import ServiceResponse from '@/business/dto/responses/service.response'

export const serviceQuestionsNewPageLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const [service] = await Promise.all([findServiceById(params.serviceId!)])

  return defer({
    service,
  })
}

const ServiceQuestionsNewPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { service } = useLoaderData() as {
    service: ServiceResponse
  }

  return (
    <UiContainer size="small">
      <UiHeader
        title={t('page.title.serviceQuestionNewX', { x: service.name })}
        description={t('page.description.serviceQuestionNewX', {
          x: service.name,
        })}
        desktopBackHref={`/services/${service._id}`}
        mobileBackHref={`/services/${service._id}`}
      />
      <div className="py-6 md:py-8">
        <div className="space-y-6">
          <ServiceQuestionCreateUpdateCard service={service} />
        </div>
      </div>
    </UiContainer>
  )
}

export default ServiceQuestionsNewPage
