import UiHeader from '@/components/ui-kit/layout/UiHeader'
import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import { LoaderFunctionArgs, defer, useLoaderData } from 'react-router-dom'
import { findServiceById } from '@/business/api/service.service'
import CustomQuestion from '@/business/dto/embeddeds/custom-question'
import ServiceQuestionCreateUpdateCard from '@/components/modals/ServiceQuestionCreateUpdateCard'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import ServiceResponse from '@/business/dto/responses/service.response'

export const serviceQuestionsEditPageLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const [service] = await Promise.all([findServiceById(params.serviceId!)])
  const question = service.customQuestions?.find(
    (x) => x._id === params.questionId
  )

  if (!question) {
    throw new Response('Not Found', { status: 404 })
  }

  return defer({
    service,
    question,
  })
}

const ServiceQuestionsEditPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { service, question } = useLoaderData() as {
    service: ServiceResponse
    question: CustomQuestion
  }

  return (
    <UiContainer size="small">
      <UiHeader
        title={t('page.title.serviceQuestionEditX', { x: service.name })}
        description={t('page.description.serviceQuestionEditX', {
          x: service.name,
        })}
        desktopBackHref={`/services/${service._id}`}
        mobileBackHref={`/services/${service._id}`}
      />
      <div className="py-6 md:py-8">
        <div className="space-y-6">
          <ServiceQuestionCreateUpdateCard
            service={service}
            question={question}
          />
        </div>
      </div>
    </UiContainer>
  )
}

export default ServiceQuestionsEditPage
