import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Color from '@/business/dto/types/color';
import useAuth from '@/contexts/use-auth';
import UiAvatar from '@/components/ui-kit/layout/UiAvatar';

type UiMenuProps = {
  children: React.ReactNode;
  sr: string;
  icon?: React.ElementType;
  avatar?: {
    name: string;
    color: Color;
    image: string | null;
    shape: 'circle' | 'rounded';
  };
};

const UiMenu = (props: UiMenuProps) => {
  const auth = useAuth();

  return (
    <Menu as='div' className='relative flex-shrink-0'>
      <div>
        <MenuButton
          className={classNames(
            'group flex rounded-md focus:outline-none',
            props.icon && 'px-3 py-2'
          )}
        >
          <span className='sr-only'>{props.sr}</span>
          {props.icon && (
            <props.icon
              className='transition ease-in-out text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6'
              aria-hidden='true'
            />
          )}
          {props.avatar && (
            <UiAvatar
              name={props.avatar.name}
              color={props.avatar.color}
              image={props.avatar.image}
              shape={props.avatar.shape}
              size={10}
            />
          )}
        </MenuButton>
      </div>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <MenuItems
          className='absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[280px]'
          modal={false}
        >
          {props.children}
        </MenuItems>
      </Transition>
    </Menu>
  );
};

export type UiHeaderItemsProps = {
  title: string;
  description?: string | null;
  children?: React.ReactNode;
};

const UiHeaderItems = (props: UiHeaderItemsProps) => {
  return (
    <div className='px-4 py-3'>
      <div className='flex items-start justify-between space-x-3'>
        <div className='overflow-hidden'>
          <h3 className='text-sm font-medium leading-6 text-gray-900 truncate'>
            {props.title}
          </h3>
          {props.description && (
            <p className='max-w-2xl text-xs text-gray-500 truncate'>
              {props.description}
            </p>
          )}
        </div>
        <div className='text-sm shrink-0'>{props.children}</div>
      </div>
    </div>
  );
};

export type UiMenuSeperatorProps = {
  children: React.ReactNode;
};

const UiMenuItems = (props: UiMenuSeperatorProps) => {
  return <div className='p-2 space-y-0.5'>{props.children}</div>;
};

export type UiMenuItemProps = {
  name: string;
  icon?: any;
  href?: string;
  danger?: boolean;
  onClick?: () => void;
};

const UiMenuItem = (props: UiMenuItemProps) => {
  // let resolved = useResolvedPath(props.to)
  // let match = useMatch({ path: resolved.pathname, end: props.end })

  return (
    <>
      {props.href && !props.href.startsWith('http') && (
        <MenuItem>
          {({ active }) => (
            <Link
              to={props.href!}
              className={classNames(
                active
                  ? `bg-gray-100 text-${props.danger ? 'rose' : 'gray'}-900`
                  : `text-${props.danger ? 'rose' : 'gray'}-700`,
                'flex items-center px-3 py-2 text-sm rounded-md'
              )}
            >
              {props.icon && (
                <props.icon
                  className={classNames(
                    active
                      ? `text-${props.danger ? 'rose' : 'gray'}-500`
                      : `text-${props.danger ? 'rose' : 'gray'}-400`,
                    'mr-3 h-5 w-5'
                  )}
                  aria-hidden='true'
                />
              )}
              <span className='truncate'>{props.name}</span>
            </Link>
          )}
        </MenuItem>
      )}
      {props.href && props.href.startsWith('http') && (
        <MenuItem>
          {({ active }) => (
            <a
              href={props.href}
              target='_blank'
              rel='noopener'
              className={classNames(
                active
                  ? `bg-gray-100 text-${props.danger ? 'rose' : 'gray'}-900`
                  : `text-${props.danger ? 'rose' : 'gray'}-700`,
                'flex items-center px-3 py-2 text-sm rounded-md'
              )}
            >
              {props.icon && (
                <props.icon
                  className={classNames(
                    active
                      ? `text-${props.danger ? 'rose' : 'gray'}-500`
                      : `text-${props.danger ? 'rose' : 'gray'}-400`,
                    'mr-3 h-5 w-5'
                  )}
                  aria-hidden='true'
                />
              )}
              <span className='truncate'>{props.name}</span>
              <div className='ml-auto'>
                <ArrowTopRightOnSquareIcon
                  className={classNames(
                    active ? 'text-gray-500' : 'text-gray-400',
                    'h-5 pb-[2px] w-5 pl-[2px]'
                  )}
                  aria-hidden='true'
                />
              </div>
            </a>
          )}
        </MenuItem>
      )}
      {props.onClick && (
        <MenuItem>
          {({ active }) => (
            <button
              onClick={props.onClick}
              className={classNames(
                active
                  ? `bg-gray-100 text-${props.danger ? 'rose' : 'gray'}-900`
                  : `text-${props.danger ? 'rose' : 'gray'}-700`,
                'group flex items-center px-3 py-2 text-sm w-full rounded-md'
              )}
            >
              {props.icon && (
                <props.icon
                  className={classNames(
                    active
                      ? `text-${props.danger ? 'rose' : 'gray'}-500`
                      : `text-${props.danger ? 'rose' : 'gray'}-400`,
                    'mr-3 h-5 w-5'
                  )}
                  aria-hidden='true'
                />
              )}
              <span className='truncate'>{props.name}</span>
            </button>
          )}
        </MenuItem>
      )}
    </>
  );
};

export default Object.assign(UiMenu, {
  Header: UiHeaderItems,
  Items: UiMenuItems,
  Item: UiMenuItem,
});
