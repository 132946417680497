import UiHeader from '@/components/ui-kit/layout/UiHeader'
import { useCompany } from './Company'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import {
  createCompanyException,
  deleteCompanyException,
  updateCompanyException,
} from '@/business/api/company.service'
import ExceptionCard from '@/components/cards/ExceptionsCard'
import CreateOrUpdateExceptionRequest from '@/business/dto/requests/create-or-update-exception.request'

const CompanyAvailabilityPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { company, setCompany } = useCompany()

  return (
    <>
      <UiHeader
        className="md:hidden"
        title={t('common.availability')}
        description=""
        mobileBackHref="/company"
      />
      <div className="py-6 md:py-0">
        <div className="space-y-6">
          <ExceptionCard
            entityUri={`/company/availability`}
            exceptions={company.exceptions}
            createFn={(data: CreateOrUpdateExceptionRequest) =>
              createCompanyException(data).then((company) =>
                setCompany(company)
              )
            }
            updateFn={(
              exceptionId: string,
              data: CreateOrUpdateExceptionRequest
            ) =>
              updateCompanyException(exceptionId, data).then((company) =>
                setCompany(company)
              )
            }
            deleteFn={(exceptionId: string) =>
              deleteCompanyException(exceptionId).then((company) =>
                setCompany(company)
              )
            }
          />
        </div>
      </div>
    </>
  )
}

export default CompanyAvailabilityPage
