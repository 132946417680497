import {
  findResourceByIdPopulated,
  findResourceServices,
} from '@/business/api/resource.service'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { PencilIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Link,
  LoaderFunctionArgs,
  defer,
  useLoaderData,
} from 'react-router-dom'
import ResourceServicesCard from '../../components/cards/ResourceServicesCard'
import ResourceGeneralCard from '@/components/cards/ResourceGeneralCard'
import ResourceMetadataCard from '@/components/cards/ResourceMetadataCard'
import ResourceAvailabilitiesCard from '@/components/cards/ResourceAvailabilitiesCard'
import ResourceExceptionsCard from '@/components/cards/ResourceExceptionsCard'
import Paginated from '@/business/dto/responses/paginated.response'
import ResourceDangerzoneCard from '@/components/cards/ResourceDangerzoneCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import ResourcePopulatedResponse from '@/business/dto/responses/resource-populated.response'
import ServiceResponse from '@/business/dto/responses/service.response'

export const resourcePageLoader = async ({ params }: LoaderFunctionArgs) => {
  const [resource, paginatedServices] = await Promise.all([
    findResourceByIdPopulated(params.resourceId!),
    findResourceServices(params.resourceId!, { page: 1, limit: 10 }),
  ])

  return defer({ resource, paginatedServices })
}

const ResourcePage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const loaderData = useLoaderData() as any
  const [resource, setResourceDetails] = useState<ResourcePopulatedResponse>(
    loaderData.resource
  )
  const [paginatedServices, setPaginatedServices] = useState<
    Paginated<ServiceResponse>
  >(loaderData.paginatedServices)

  return (
    <UiContainer>
      <UiHeader
        avatar={{
          name: resource.name,
          color: resource.color,
          image: resource.image,
          shape: 'rounded',
        }}
        title={resource.name}
        description={t('page.description.resource')}
        desktopBackHref="/resources"
        mobileBackHref="/resources"
        mobileAction={{
          icon: PencilSquareIcon,
          to: `/resources/${resource._id}/edit`,
        }}
        desktopActions={
          <UiButton
            as={Link}
            variant="primary"
            label={t('action.editResource')}
            icon={PencilIcon}
            to={`/resources/${resource._id}/edit`}
          />
        }
      />
      <div className="py-6 md:py-8">
        <div className="space-y-6">
          <div className="grid grid-cols-1 grid-rows-1 items-start md:gap-6 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
            <div className="lg:col-start-1">
              <ResourceGeneralCard resource={resource} />
            </div>
            <div className="lg:col-start-2 lg:col-span-2 lg:row-span-2 lg:row-end-2 2xl:col-start-2 2xl:col-span-3">
              <div className="space-y-6">
                <ResourceAvailabilitiesCard
                  resource={resource}
                  setResourceDetails={setResourceDetails}
                />
                <ResourceExceptionsCard
                  resource={resource}
                  setResourceDetails={setResourceDetails}
                />
                <ResourceServicesCard
                  resource={resource}
                  setResource={setResourceDetails}
                  paginatedServices={paginatedServices}
                  setPaginatedServices={setPaginatedServices}
                />
                <ResourceDangerzoneCard
                  resource={resource}
                  setResourceDetails={setResourceDetails}
                />
              </div>
            </div>
            <div className="lg:col-start-1">
              <ResourceMetadataCard resource={resource} />
            </div>
          </div>
        </div>
      </div>
    </UiContainer>
  )
}

export default ResourcePage
