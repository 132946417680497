import Role from '@/business/dto/types/role'
import useAuth from '@/contexts/use-auth'
import { Navigate } from 'react-router-dom'

type Props = {
  children: React.ReactNode
}

const RequireAtLeastAdmin = (props: Props) => {
  const auth = useAuth()

  return (
    <>
      {auth.user.role === Role.owner || auth.user.role === Role.admin ? (
        <>{props.children}</>
      ) : (
        <Navigate to='/' replace={true} />
      )}
    </>
  )
}

export default RequireAtLeastAdmin
