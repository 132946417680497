import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import UiList from '@/components/ui-kit/layout/UiList'
import useAuth from '@/contexts/use-auth'
import i18n from '@/i18n'
import {
  ArrowRightOnRectangleIcon,
  BuildingOffice2Icon,
  CubeTransparentIcon,
  FaceSmileIcon,
  FolderIcon,
  QuestionMarkCircleIcon,
  SquaresPlusIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

const SettingsMobilePage = () => {
  const auth = useAuth()
  const { t } = useTranslation()

  return (
    <UiContainer>
      <UiHeader
        title={t('common.settings')}
        description={t('page.description.settings')}
      />
      <div className="py-6 md:py-8 space-y-6">
        <div className="flex flex-col gap-4 md:gap-6">
          <UiList>
            <UiList.Item
              title={auth.user.name}
              subtitle={auth.user.email}
              avatar={{
                name: auth.user.name,
                color: auth.user.color,
                shape: 'circle',
                image: auth.user.image,
              }}
              href={'/account'}
            />
          </UiList>
          <UiList>
            <UiList.Item
              title={auth.company.name}
              icon={BuildingOffice2Icon}
              href="/company"
            />
            <UiList.Item
              title="Anmeldekonten"
              // subtitle={auth.company.name}
              icon={UserGroupIcon}
              href="/users"
            />
          </UiList>
          <UiList>
            <UiList.Item
              title="Kategorien"
              icon={FolderIcon}
              href="/categories"
            />
            <UiList.Item
              title="Services"
              icon={FaceSmileIcon}
              href="/services"
            />
            <UiList.Item
              title="Ressourcen"
              icon={CubeTransparentIcon}
              href="/resources"
            />
          </UiList>
          <UiList>
            <UiList.Item
              title="Integrationen"
              icon={SquaresPlusIcon}
              href="/integrations"
            />
            {/* <UiList.Item
              title="Weiterempfehlen"
              icon={HeartIcon}
              href="/recommend"
            /> */}
          </UiList>
          <UiList>
            <UiList.Item
              title="Hilfe"
              icon={QuestionMarkCircleIcon}
              href="https://resflow.com/help"
            />
            <UiList.Item
              title="Abmelden"
              icon={ArrowRightOnRectangleIcon}
              onClick={() => auth.signOut()}
            />
            {/* <UiList.Item
              title="Weiterempfehlen"
              icon={HeartIcon}
              href="/recommend"
            /> */}
          </UiList>
        </div>
        <div className="flex flex-col justify-between md:hidden">
          <ul role="list" className="flex flex-col space-y-2 mb-2">
            <li>
              <a
                href={`https://resflow.com/${i18n.language}/${t(
                  'href.legal'
                )}/`}
                target="_blank"
                rel="noopener"
                className="text-sm leading-6 text-gray-500 hover:text-gray-900"
              >
                {t('common.legal')}
              </a>
            </li>
            <li>
              <a
                href={`https://resflow.com/${i18n.language}/${t(
                  'href.terms'
                )}/`}
                target="_blank"
                rel="noopener"
                className="text-sm leading-6 text-gray-500 hover:text-gray-900"
              >
                {t('common.terms')}
              </a>
            </li>
            <li>
              <a
                href={`https://resflow.com/${i18n.language}/${t(
                  'href.privacy'
                )}/`}
                target="_blank"
                rel="noopener"
                className="text-sm leading-6 text-gray-500 hover:text-gray-900"
              >
                {t('common.privacy')}
              </a>
            </li>
            <li>
              <a
                href={`https://resflow.com/${i18n.language}/${t(
                  'href.changelog'
                )}/`}
                target="_blank"
                rel="noopener"
                className="text-sm leading-6 text-gray-500 hover:text-gray-900"
              >
                v{process.env.REACT_APP_VERSION}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </UiContainer>
  )
}

export default SettingsMobilePage
