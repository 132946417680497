import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import {
  createResourceException,
  deleteResourceException,
  updateResourceException,
} from '@/business/api/resource.service'
import ExceptionCard from './ExceptionsCard'
import CreateOrUpdateExceptionRequest from '@/business/dto/requests/create-or-update-exception.request'
import ResourcePopulatedResponse from '@/business/dto/responses/resource-populated.response'

export type ResourceExceptionsCardProps = {
  resource: ResourcePopulatedResponse
  setResourceDetails: React.Dispatch<React.SetStateAction<ResourcePopulatedResponse>>
}

const ResourceExceptionsCard = (props: ResourceExceptionsCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  return (
    <ExceptionCard
      entityUri={`/resources/${props.resource._id}`}
      exceptions={props.resource.exceptions}
      createFn={(data: CreateOrUpdateExceptionRequest) =>
        createResourceException(props.resource._id, data)
      }
      updateFn={(exceptionId: string, data: CreateOrUpdateExceptionRequest) =>
        updateResourceException(props.resource._id, exceptionId, data)
      }
      deleteFn={(exceptionId: string) =>
        deleteResourceException(props.resource._id, exceptionId)
      }
    />
  )
}

export default ResourceExceptionsCard
