import Availability from '@/business/dto/embeddeds/availability'
import CreateOrUpdateAvailabilityRequest from '@/business/dto/requests/create-or-update-availability.request'
import DangerModal from '@/components/modals/DangerModal'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiToast from '@/components/ui-kit/presentation/UiToast'
import useAuth from '@/contexts/use-auth'
import {
  CalendarDaysIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  PlusSmallIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import UiDate from '../ui-kit/typography/UiDate'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import UiDateTime from '../ui-kit/typography/UiDateTime'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { prettyPrintAvailability } from '@/business/date-utils'

const enum Weekday {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

const weekdays: Weekday[] = [
  Weekday.Monday,
  Weekday.Tuesday,
  Weekday.Wednesday,
  Weekday.Thursday,
  Weekday.Friday,
  Weekday.Saturday,
  Weekday.Sunday,
]

type AvailabilitiesCardProps = {
  entityUri: string
  availabilities: Availability[] | undefined
  createFn: (data: CreateOrUpdateAvailabilityRequest) => Promise<any>
  updateFn: (
    availabilitiesId: string,
    data: CreateOrUpdateAvailabilityRequest
  ) => Promise<any>
  deleteFn: (availabilitiesId: string) => Promise<any>
}

const AvailabiltiesCard = (props: AvailabilitiesCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [currentAvailability, setCurrentAvailability] = useState<Availability>()

  const onDelete = () =>
    currentAvailability
      ? props.deleteFn(currentAvailability._id).then((_) => {
          setDeleteModalOpen(false)
          props.availabilities?.splice(
            props.availabilities.findIndex(
              (availability) => availability._id === currentAvailability._id
            ),
            1
          )
          toast.custom((toast) => (
            <UiToast
              toast={toast}
              type='success'
              title={t('toast.title.deleted')}
              description={t('toast.description.availabilityDeleted')}
            />
          ))
        })
      : Promise.resolve()

  return (
    <>
      <UiCard>
        <UiCard.Header
          title={'Generelle Verfügbarkeit'}
          description='Fügen Sie die allgemeine Verfügbarkeit hinzu.'
          icon={CalendarDaysIcon}
        >
          <UiButton
            label={t('action.newAvailability')}
            variant='default'
            icon={PlusSmallIcon}
            onClick={() => {
              setCurrentAvailability(undefined)
              navigate(`${props.entityUri}/availabilities`)
            }}
          />
        </UiCard.Header>
        <UiCard.Body>
          <dl className='space-y-5'>
            {props.availabilities?.map((availability) => (
              <div className='flex items-center justify-between'>
                <div className='w-full overflow-hidden space-y-1'>
                  <dt className='text-gray-700 text-sm truncate font-medium'>
                    Valid from{' '}
                    <UiDateTime
                      dateTimeInUtc={availability.startDate}
                      format='PPPP'
                    />
                  </dt>
                  <dd className='text-gray-500 text-sm'>
                    {availability.monday.length === 0 &&
                    availability.tuesday.length === 0 &&
                    availability.wednesday.length === 0 &&
                    availability.thursday.length === 0 &&
                    availability.friday.length === 0 &&
                    availability.saturday.length === 0 &&
                    availability.sunday.length === 0 ? (
                      <>Nie verfügbar</>
                    ) : availability.monday.length === 1 &&
                      availability.monday[0].startTime === '00:00' &&
                      availability.monday[0].endTime === '24:00' &&
                      availability.tuesday.length === 1 &&
                      availability.tuesday[0].startTime === '00:00' &&
                      availability.tuesday[0].endTime === '24:00' &&
                      availability.wednesday.length === 1 &&
                      availability.wednesday[0].startTime === '00:00' &&
                      availability.wednesday[0].endTime === '24:00' &&
                      availability.thursday.length === 1 &&
                      availability.thursday[0].startTime === '00:00' &&
                      availability.thursday[0].endTime === '24:00' &&
                      availability.friday.length === 1 &&
                      availability.friday[0].startTime === '00:00' &&
                      availability.friday[0].endTime === '24:00' &&
                      availability.saturday.length === 1 &&
                      availability.saturday[0].startTime === '00:00' &&
                      availability.saturday[0].endTime === '24:00' &&
                      availability.sunday.length === 1 &&
                      availability.sunday[0].startTime === '00:00' &&
                      availability.sunday[0].endTime === '24:00' ? (
                      <>Rund um die Uhr, sofern nicht anders eingeschränkt</>
                    ) : (
                      <>
                        {prettyPrintAvailability(
                          availability,
                          auth.company.localization.weekStartsOn,
                          t
                        ).join(', ')}
                      </>
                      // <div className='grid grid-cols-12 gap-x-1'>
                      //   {weekdays.map((day) => (
                      //     <>
                      //       <div className='col-span-5 truncate'>
                      //         {t(`common.${day}`)}
                      //       </div>
                      //       {availability[day].length > 0 ? (
                      //         availability[day].map((interval) => (
                      //           <>
                      //             <div className='col-span-3 truncate'>
                      //               {interval.startTime}
                      //             </div>
                      //             <div className='col-span-1 truncate'>-</div>
                      //             <div className='col-span-3 truncate'>
                      //               {interval.endTime}
                      //             </div>
                      //           </>
                      //         ))
                      //       ) : (
                      //         <div className='col-span-7 truncate'>
                      //           nicht verfügbar
                      //         </div>
                      //       )}
                      //     </>
                      //   ))}
                      // </div>
                    )}
                  </dd>
                </div>
                <div className='flex gap-3'>
                  <Menu as='div' className='relative flex-none'>
                    <MenuButton className='group flex rounded-md focus:outline-none p-2 -mb-2.5'>
                      <span className='sr-only'>Open options</span>
                      <EllipsisVerticalIcon
                        className='transition ease-in-out text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-6 w-6'
                        aria-hidden='true'
                      />
                    </MenuButton>
                    <MenuItems
                      transition
                      className='absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in'
                    >
                      <MenuItem>
                        <Link
                          to={`${props.entityUri}/availabilities/${availability._id}`}
                          className='flex w-full px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50'
                        >
                          {t('action.edit')}
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <button
                          onClick={() => {
                            setCurrentAvailability(availability)
                            setDeleteModalOpen(true)
                          }}
                          className='flex w-full px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50'
                        >
                          {t('action.delete')}
                        </button>
                      </MenuItem>
                    </MenuItems>
                  </Menu>
                </div>
              </div>
            ))}
          </dl>
        </UiCard.Body>
        <UiCard.Footer />
      </UiCard>
      {/* <AvailablityCreateAndEditModal
        createFn={props.createFn}
        updateFn={props.updateFn}
        open={createEditModalOpen}
        setOpen={setCreateEditModalOpen}
        availability={currentAvailability}
      /> */}
      <DangerModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        title={t('card.title.deleteAvailability')}
        description={t('card.description.deleteAvailability')}
        button={t('action.deleteAvailability')}
        action={onDelete}
      />
    </>
  )
}

export default AvailabiltiesCard
