import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { deleteUserById } from '@/business/api/user.service'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import DangerModal from '../modals/DangerModal'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import Role from '@/business/dto/types/role'
import UserPopulatedResponse from '@/business/dto/responses/user-populated.response'

export type UserDangerzoneCardProps = {
  user: UserPopulatedResponse
  setUserDetails: React.Dispatch<React.SetStateAction<UserPopulatedResponse>>
}

const UserDangerzoneCard = (props: UserDangerzoneCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [open, setOpen] = useState<boolean>(false)

  const onDelete = () =>
    deleteUserById(props.user._id).then((_) => {
      if (props.user._id === auth.user._id) {
        onSignOut()
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type="success"
            title={t('toast.title.deleted')}
            description={t('toast.description.accountDeleted')}
          />
        ))
      } else {
        navigate('/users')
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type="success"
            title={t('toast.title.deleted')}
            description={t('toast.description.accountDeleted')}
          />
        ))
      }
    })

  const onSignOut = () => {
    localStorage.removeItem('token_type')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    navigate('/sign-in', { replace: true })
  }

  return (
    <>
      <UiCard>
        <UiCard.Header
          title={t('common.dangerZone')}
          description={
            props.user._id === auth.user._id
              ? t('card.description.accountDangerZone')
              : t('card.description.userDangerZone')
          }
          icon={ExclamationTriangleIcon}
        />
        <UiCard.Body>
          <div className="space-y-3">
            <UiButton
              label={
                props.user._id === auth.user._id
                  ? t('action.deleteAccount')
                  : t('action.deleteUser')
              }
              variant="danger"
              type="button"
              disabled={props.user.role === Role.owner}
              onClick={() => setOpen(true)}
            />
            {props.user.role === Role.owner && (
              <p className="mt-1 text-xs text-gray-500 max-w-xl">
                {t('form.description.deleteOwner')}
              </p>
            )}
          </div>
        </UiCard.Body>
        <UiCard.Footer />
      </UiCard>
      <DangerModal
        open={open}
        setOpen={setOpen}
        title={t('card.title.deleteUser')}
        description={t('card.description.deleteUser')}
        button={t('action.deleteUser')}
        action={onDelete}
      />
    </>
  )
}

export default UserDangerzoneCard
