import React, { ChangeEvent } from 'react'
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form'
import Color from '@/business/dto/types/color'
import useAuth from '@/contexts/use-auth'
import UiAvatar from '@/components/ui-kit/layout/UiAvatar'
import UiButton from '../../actions/UiButton'
import { useTranslation } from 'react-i18next'

export interface UiImageUploadProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string | null
  avatar: {
    name: string
    color: Color
    shape: 'circle' | 'rounded'
  }

  control: Control<TFieldValues>
  name: TName
  rules?: RegisterOptions<TFieldValues, TName>
}

const UiImageUpload = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UiImageUploadProps<TFieldValues, TName>
) => {
  const { label, control, name, rules, ...other } = props
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const color = auth?.company?.appearance?.color || 'rose'

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => (
        <div>
          <input
            id={name}
            name={name}
            ref={ref}
            type="file"
            className="hidden"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              event.preventDefault()
              const files = event.target.files
              if (files?.length) {
                toBase64(files[0])
                  .then((result: any) => onChange(result))
                  .catch((error) => {})
              } else {
                onChange(event)
              }
            }}
          />
          {props.label && (
            <div className="mb-1 block text-sm font-medium text-gray-700">
              {props.label}
            </div>
          )}
          <div className="flex items-center">
            <UiAvatar {...props.avatar} image={value} size={14} />
            <div className="flex items-center gap-3 ml-5">
              <label
                htmlFor={name}
                className="relative inline-flex justify-center items-center rounded-full border undefined border-gray-300 bg-white text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 px-4 py-2 text-sm font-medium leading-6 cursor-pointer"
              >
                {t('action.uploadImage')}
              </label>
              {value && (
                <UiButton
                  label={t('action.remove')}
                  type="submit"
                  variant="default"
                  onClick={() => onChange(null)}
                />
              )}
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default UiImageUpload
