import { useTranslation } from 'react-i18next'
import { MegaphoneIcon, SignalIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import ContactPopulatedResponse from '@/business/dto/responses/contact-populated.response'
import classNames from 'classnames'
import { format, formatDistanceToNow, isAfter } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { getCurrentFnsLocale } from '@/business/date-utils'
import { Link } from 'react-router-dom'
import UiDateTime from '../ui-kit/typography/UiDateTime'

export type ContactReservationCardProps = {
  contact: ContactPopulatedResponse
  setContact: React.Dispatch<React.SetStateAction<ContactPopulatedResponse>>
}

const ContactReservationCard = (props: ContactReservationCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  return (
    <UiCard>
      <UiCard.Header
        title={t('card.title.upcommingReservations')}
        description={t('card.description.upcommingReservations')}
        icon={MegaphoneIcon}
      />
      <UiCard.Body>
        {props.contact.reservations.map((reservation) => (
          <div
            key={reservation._id}
            className="relative hover:bg-gray-50 flex flex-col md:flex-row p-4 gap-3 md:gap-4 md:gap-x-16 lg:gap-x-24"
          >
            <div className="grow">
              <div className="text-sm font-medium leading-6 text-gray-900">
                <UiDateTime
                  dateTimeInUtc={reservation.startDateTimeInUtc}
                  format="PPPP"
                />
                ,{' '}
                {format(
                  utcToZonedTime(
                    new Date(reservation.startDateTimeInUtc),
                    auth.company.localization.timeZone
                  ),
                  'p',
                  {
                    locale: getCurrentFnsLocale(),
                    weekStartsOn: auth.company.localization.weekStartsOn,
                  }
                )}{' '}
                -{' '}
                {format(
                  utcToZonedTime(
                    new Date(reservation.endDateTimeInUtc),
                    auth.company.localization.timeZone
                  ),
                  'p',
                  {
                    locale: getCurrentFnsLocale(),
                    weekStartsOn: auth.company.localization.weekStartsOn,
                  }
                )}{' '}
                (
                {formatDistanceToNow(new Date(reservation.startDateTimeInUtc), {
                  locale: getCurrentFnsLocale(),
                  addSuffix: true,
                })}
                )
              </div>
              <div className="mt-1 text-xs leading-5 text-gray-500 space-x-2">
                <span>{reservation.persons} Personen</span>
                <span className="text-gray-500" aria-hidden="true">
                  ·
                </span>
                <span>{reservation.name}</span>
                <span className="text-gray-500" aria-hidden="true">
                  ·
                </span>
                <span>{reservation.resource.name}</span>
              </div>
            </div>
            {false && (
              <div className="shrink-0 text-right">
                <div className="flex justify-end">
                  <a
                    href={'/'}
                    className="text-sm font-medium leading-6 text-sky-600 hover:text-sky-500"
                  >
                    <span className="hidden sm:inline">Details</span> anzeigen
                    <span className="sr-only">, invoice #323, Client</span>
                  </a>
                </div>
                <div className="mt-1 text-xs leading-5 text-gray-500">
                  Termin{' '}
                  <span className="text-gray-900">#{reservation._id}</span>
                </div>
              </div>
            )}
            <Link
              to={`/reservations/${reservation._id}`}
              className="absolute inset-0"
            />
          </div>
        ))}
      </UiCard.Body>
      <UiCard.Footer />
    </UiCard>
  )
}

export default ContactReservationCard
