import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import React from 'react'
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form'
import useAuth from '@/contexts/use-auth'

export interface UiInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string | null
  leadingText?: string
  trailingText?: string
  hint?: string
  icon?: React.ElementType
  description?: string

  control: Control<TFieldValues>
  name: TName
  rules?: RegisterOptions<TFieldValues, TName>
}

const UiInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UiInputProps<TFieldValues, TName>
) => {
  const {
    label,
    leadingText,
    trailingText,
    icon,
    description,
    control,
    name,
    rules,
    ...other
  } = props
  const auth = useAuth()
  const color = auth?.company?.appearance?.color || 'rose'

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => (
        <div>
          <div className="flex justify-between">
            {props.label && (
              <label
                htmlFor={name}
                className="block grow-1 mb-1 text-sm font-medium text-gray-700"
              >
                {props.label}
                {(!props.rules || !props.rules.required) && (
                  <span className="font-normal text-gray-500"> (optional)</span>
                )}
                {/*props.rules && props.rules.required && (
                <span
                  className={`font-normal text-${color}-600`}
                >
                  {' '}
                  *
                </span>
              )*/}
              </label>
            )}
            {props.hint && (
              <div className="text-gray-500 text-xs mt-1">{props.hint}</div>
            )}
          </div>
          <div className="relative flex rounded-md shadow-sm">
            {props.leadingText && (
              <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 text-sm">
                {props.leadingText}
              </span>
            )}
            <div className="relative w-full">
              {props.icon && (
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <props.icon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              )}
              <input
                {...other}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                id={name}
                ref={ref}
                autoComplete="off"
                className={classNames(
                  `block w-full focus:border-${color}-500 focus:ring-${color}-500 text-sm leading-6 focus-within:z-10 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200`,
                  props.leadingText && !props.trailingText && 'rounded-r-md',
                  props.trailingText && !props.leadingText && 'rounded-l-md',
                  !props.trailingText && !props.leadingText && 'rounded-md',
                  props.icon ? 'pl-10' : '',
                  error ? 'pr-10 border-red-300' : 'border-gray-300',
                  props.className
                )}
                aria-invalid={error ? 'true' : 'false'}
              />
              {error && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            {props.trailingText && (
              <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 px-3 text-gray-500 text-sm">
                {props.trailingText}
              </span>
            )}
          </div>
          {props.description && (
            <p className="mt-1 text-xs text-gray-500">{props.description}</p>
          )}
          {error && (
            <span
              id={`${name}-error`}
              role="alert"
              className="mt-1 text-xs text-red-600"
            >
              {error.message}
            </span>
          )}
        </div>
      )}
    />
  )
}

export default UiInput
