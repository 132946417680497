import {
  ArrowPathIcon,
  BookOpenIcon,
  CalendarDaysIcon,
  ChartPieIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CubeTransparentIcon,
  CursorArrowRaysIcon,
  EnvelopeIcon,
  FaceSmileIcon,
  FingerPrintIcon,
  LinkIcon,
  PhoneIcon,
  PlayCircleIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import UiButton from './ui-kit/actions/UiButton';
import UiContainer from './ui-kit/layout/UiContainer';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import useAuth from '@/contexts/use-auth';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const solutions = [
  {
    name: 'Erstellen Sie einen Service',
    description:
      'Bei einem Service handelt es sich um eine bestimmte Leistung oder Aktivität, die von Ihrem Unternehmen angeboten und von Kunden gebucht oder reserviert werden kann.',
    href: '/services',
    icon: FaceSmileIcon,
  },
  {
    name: 'Erstellen Sie eine Resource',
    description:
      'Bei einer Resource handelt es sich um einen Mitarbeiter oder einen Gegenstand, den Sie zur Erfüllung eines oder mehrerer Services benötigen.',
    href: '/resources',
    icon: CubeTransparentIcon,
  },
  {
    name: 'Weisen Sie dem Service die Resource zu',
    description:
      'Durch die explizite Zuweisung einer Resource zu einem Service muss mindestens eine Resource verfügbar sein, damit der jeweilige Service reserviert werden kann.',
    href: '/services/1/resources',
    icon: LinkIcon,
  },
  {
    name: 'Definieren Sie Verfügbarkeiten',
    description:
      'Verfügbarkeit kann auf verschiedene Weise definiert werden. Sie können die Verfügbarkeit einer Resource oder eines Services für einen bestimmten Zeitraum festlegen oder die Verfügbarkeit für einen bestimmten Zeitraum blockieren.',
    href: '#',
    icon: CalendarDaysIcon,
  },
  {
    name: 'Testen Sie Ihre neue resflow Seite',
    description:
      'Fertig! Sie können jetzt Reservierungen annehmen. Machen Sie selbst eine Reservierung, um zu sehen, wie es funktioniert.',
    href: '#',
    icon: SparklesIcon,
  },
];
const callsToAction = [
  { name: 'Dokumentation', href: '#', icon: QuestionMarkCircleIcon },
  { name: 'resflow Support', href: '#', icon: EnvelopeIcon },
];

const Checklist = () => {
  const auth = useAuth();
  const { t, i18n } = useTranslation();
  const color = auth?.company?.appearance?.color || 'rose';

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  let { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'top-end',
    }
  );

  return (
    <div className='fixed bottom-16 right-4 md:bottom-6 md:right-6 z-40'>
      <Popover className='relative'>
        {({ open }) => (
          <>
            {true && (
              <PopoverButton
                ref={setReferenceElement}
                className='relative flex items-center rounded-full border justify-center px-5 py-3 text-sm font-medium sm:leading-6 border-transparent bg-gray-800 text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 disabled:bg-gray-900/50'
              >
                {t('common.firstSteps')}
              </PopoverButton>
            )}
            {false && (
              <PopoverButton
                ref={setReferenceElement}
                className='relative inline-flex items-center rounded-full border p-3 sm:leading-6 border-transparent bg-gray-800 text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 disabled:bg-gray-900/50'
              >
                <span className='sr-only'>Schließen</span>
                <XMarkIcon className='h-5 w-5' aria-hidden='true' />
              </PopoverButton>
            )}
            <Transition
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <PopoverPanel
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                className='absolute left-1/2 z-10 mb-5 flex w-screen max-w-max -translate-x-1/2 px-4'
              >
                <div className='w-screen max-w-sm flex-auto overflow-hidden rounded-lg bg-white text-sm leading-6 shadow-lg border border-gray-200'>
                  <div className='p-3'>
                    {solutions.map((item, i) => (
                      <div
                        key={item.name}
                        className='group relative flex items-center gap-x-4 rounded-lg px-3 py-2 hover:bg-gray-50'
                      >
                        {i > 2 && (
                          <div className='mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100'>
                            <item.icon
                              className={`h-6 w-6 text-gray-600 group-hover:text-${color}-600`}
                              aria-hidden='true'
                            />
                          </div>
                        )}
                        {i <= 2 && (
                          <div className='mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-green-600'>
                            <CheckCircleIcon
                              className={`h-6 w-6 text-white`}
                              aria-hidden='true'
                            />
                          </div>
                        )}
                        <div className='grow'>
                          <a
                            href={item.href}
                            className={classNames(
                              'font-semibold text-gray-900',
                              i <= 2 && 'line-through'
                            )}
                          >
                            {item.name}
                            <span className='absolute inset-0' />
                          </a>
                        </div>
                        <ChevronRightIcon
                          className='h-5 w-5 flex-none text-gray-400'
                          aria-hidden='true'
                        />
                      </div>
                    ))}
                  </div>
                  <div className='grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50'>
                    <a
                      href={`https://resflow.com/${i18n.language}/${t(
                        'href.documentation'
                      )}/`}
                      target='_blank'
                      rel='noopener'
                      className='flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100'
                    >
                      <BookOpenIcon
                        className='h-5 w-5 flex-none text-gray-400'
                        aria-hidden='true'
                      />
                      {t('common.documentation')}
                    </a>
                    <a
                      href={`https://resflow.com/${i18n.language}/${t(
                        'href.contact'
                      )}/`}
                      target='_blank'
                      rel='noopener'
                      className='flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100'
                    >
                      <EnvelopeIcon
                        className='h-5 w-5 flex-none text-gray-400'
                        aria-hidden='true'
                      />
                      {t('common.support')}
                    </a>
                  </div>
                </div>
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default Checklist;
