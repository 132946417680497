import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import { LoaderFunctionArgs, defer, useLoaderData } from 'react-router-dom'
import { findResourceById } from '@/business/api/resource.service'
import { ResourceUpdateCard } from '@/components/modals/ResourceCreateUpdateCard'
import ResourceResponse from '@/business/dto/responses/resource.response'

export const resourceEditPageLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const [resource] = await Promise.all([findResourceById(params.resourceId!)])
  return defer({ resource })
}

const ResourceEditPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { resource } = useLoaderData() as { resource: ResourceResponse }

  return (
    <UiContainer size='small'>
      <UiHeader
        title={t('page.title.resourceEdit')}
        description={t('page.description.resourceEditX', {
          x: resource.name,
        })}
        desktopBackHref={`/resources/${resource._id}`}
        mobileBackHref={`/resources/${resource._id}`}
      />
      <div className='py-6 md:py-8'>
        <div className='space-y-6'>
          <ResourceUpdateCard resource={resource} />
        </div>
      </div>
    </UiContainer>
  )
}

export default ResourceEditPage
