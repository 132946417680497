import useAuth from '@/contexts/use-auth'
import { SparklesIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import React from 'react'

type UiCardProps = {
  children?: React.ReactNode
  className?: string
}

const UiCard = (props: UiCardProps) => {
  return (
    <div className={classNames('bg-white shadow rounded-lg', props.className)}>
      {props.children}
    </div>
  )
}

type UiCardHeaderProps = {
  title: string
  description: string
  icon?: React.ElementType
  children?: React.ReactNode
}

const UiCardHeader = (props: UiCardHeaderProps) => {
  const auth = useAuth()

  return (
    <>
      <div className="border-b border-gray-200 px-4 py-5 sm:px-6 flex space-x-4">
        {props.icon && (
          <div>
            <div
              className={classNames(
                'flex-shrink-0 flex items-center justify-center p-2 my-1 rounded-full ',
                `bg-gray-700`
              )}
            >
              <props.icon className="h-6 w-6 text-white" />
            </div>
          </div>
        )}
        <div className="space-y-4 sm:space-y-0 sm:space-x-4 flex flex-wrap items-start justify-between sm:flex-nowrap flex-grow">
          <div className="">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              {props.title}
            </h3>
            <p className="mt-1 text-sm text-gray-500">{props.description}</p>
          </div>
          {props.children && (
            <div className="flex-shrink-0 h-12 flex items-center">
              {props.children}
            </div>
          )}
        </div>
      </div>

      {/* {false && (
        <div className="flex flex-wrap items-center justify-between sm:flex-nowrap border-b border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex space-x-4">
            {props.icon && (
              <div>
                <div
                  className={classNames(
                    'flex-shrink-0 flex items-center justify-center p-3 rounded-full ',
                    `bg-${auth.company.appearance.color}-600`
                  )}
                >
                  <props.icon className="h-6 w-6 text-white" />
                </div>
              </div>
            )}
            <div>
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                {props.title}
              </h3>
              <p className="mt-1 text-sm text-gray-500 max-w-2xl">
                {props.description}
              </p>
            </div>
          </div>
          <div className="mt-4 sm:mt-0 flex-shrink-0 flex-wrap items-center gap-3">
            {props.children}
          </div>
        </div>
      )} */}
    </>
  )
}

type UiCardBodyProps = {
  children?: React.ReactNode
  className?: string
}

const UiCardBody = (props: UiCardBodyProps) => {
  return (
    <div className={classNames('px-4 py-5 space-y-6 sm:px-6', props.className)}>
      {props.children}
    </div>
  )
}

type UiCardFooterProps = {
  children?: React.ReactNode
  className?: string
}

const UiCardFooter = (props: UiCardFooterProps) => {
  return (
    <div
      className={classNames(
        'flex space-x-3 bg-gray-50 px-4 py-3 items-center justify-end sm:px-6 rounded-b-lg',
        props.className
      )}
    >
      {props.children}
    </div>
  )
}

type UiCardImageProps = {
  src: string
}

const UiCardImage = (props: UiCardImageProps) => {
  return (
    <div className="relative w-full h-full flex justify-center items-center">
      <img className="w-72 max-h-full absolute" src={props.src} />
    </div>
  )
}

export default Object.assign(UiCard, {
  Header: UiCardHeader,
  Body: UiCardBody,
  Footer: UiCardFooter,
  Image: UiCardImage,
})
