import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import {
  ArrowDownOnSquareIcon,
  BookmarkSquareIcon,
} from '@heroicons/react/24/outline'
import CompanyPopulatedResponse from '@/business/dto/responses/company-populated.response'

export type CompanyContractDpaProps = {
  company: CompanyPopulatedResponse
  setCompany: React.Dispatch<React.SetStateAction<CompanyPopulatedResponse>>
}

const CompanyContractDpaCard = (props: CompanyContractDpaProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  return (
    <UiCard>
      <UiCard.Header
        title={t('card.title.companyContractDataProcessing')}
        description={t('card.description.companyContractDataProcessing')}
        // description={
        //   'Erstellung eines Auftragsverarbeitungsvertrags gemäß Art. 28 DSGVO.'
        // }
        icon={BookmarkSquareIcon}
      />
      <UiCard.Body>
        <UiButton
          variant="default"
          type="button"
          label={t('action.downloadDPA')}
          icon={ArrowDownOnSquareIcon}
          onClick={() => {}}
          disabled={true}
        />
      </UiCard.Body>
      <UiCard.Footer />
    </UiCard>
  )
}

export default CompanyContractDpaCard
