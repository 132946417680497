import {
  createResourceAvailability,
  findResourceById,
} from '@/business/api/resource.service'
import CreateOrUpdateAvailabilityRequest from '@/business/dto/requests/create-or-update-availability.request'
import ResourceResponse from '@/business/dto/responses/resource.response'
import { AvailabilityCreateCard } from '@/components/modals/AvailabilityCreateUpdateCard'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import { LoaderFunctionArgs, defer, useLoaderData } from 'react-router-dom'

export const resourceAvailabilityNewPageLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const [resource] = await Promise.all([findResourceById(params.resourceId!)])
  return defer({ resource })
}

const ResourceAvailabilityNewPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { resource } = useLoaderData() as { resource: ResourceResponse }

  return (
    <UiContainer size='medium'>
      <UiHeader
        title={t('page.title.resourceAvailabilityNew')}
        description={t('page.description.resourceAvailabilityNewX', {
          x: resource.name,
        })}
        desktopBackHref={`/resources/${resource._id}`}
        mobileBackHref={`/resources/${resource._id}`}
      />
      <div className='py-6 md:py-8'>
        <div className='space-y-6'>
          <AvailabilityCreateCard
            createFn={(data: CreateOrUpdateAvailabilityRequest) =>
              createResourceAvailability(resource._id, data)
            }
            backHref={`/resources/${resource._id}`}
          />
        </div>
      </div>
    </UiContainer>
  )
}

export default ResourceAvailabilityNewPage
