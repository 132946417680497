import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import UiContractBanner from './UiContractBanner'
import Subscription from '@/business/dto/embeddeds/subscription'
import { getSubscriptionEndsString } from '@/business/date-utils'
import useAuth from '@/contexts/use-auth'
import Role from '@/business/dto/types/role'

type UiSubscriptionTrialingBannerProps = {
  subscription: Subscription
}

const UiSubscriptionTrialingBanner = (
  props: UiSubscriptionTrialingBannerProps
) => {
  const auth = useAuth()
  const { t } = useTranslation()
  const [subscriptionEndsString, setSubscriptionEndsString] = useState<string>(
    getSubscriptionEndsString(props.subscription)
  )

  useEffect(() => {
    const timer = setInterval(
      () =>
        setSubscriptionEndsString(
          getSubscriptionEndsString(props.subscription)
        ),
      1000
    )

    return () => clearInterval(timer)
  }, [])

  return (
    <UiContractBanner
      message={t(
        auth.user.role === Role.owner
          ? 'banner.trialingOwner'
          : 'banner.trialingNotOwner',
        {
          x: subscriptionEndsString,
        }
      )}
      action={
        auth.user.role === Role.owner ? t('action.addPaymentMethod') : undefined
      }
    />
  )
}

export default UiSubscriptionTrialingBanner
