import useAuth from '@/contexts/use-auth'
import { Fragment, useRef, useState } from 'react'
import UiButton from '../ui-kit/actions/UiButton'
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { ExclamationTriangleIcon, TrashIcon } from '@heroicons/react/24/outline'

type DangerModalProps = {
  open: boolean
  setOpen: (open: boolean) => void
  title: string
  description: string
  button: string
  action: () => Promise<void>
}

const DangerModal = (props: DangerModalProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  const cancelButtonRef = useRef(null)
  const [loading, setLoading] = useState<boolean>(false)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setLoading(true)
    props
      .action()
      .then(() => {
        props.setOpen(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Transition show={props.open} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-50'
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <TransitionChild
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </TransitionChild>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <TransitionChild
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <DialogPanel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
                <div className='bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
                  <div className='sm:flex sm:items-start'>
                    <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                      <ExclamationTriangleIcon
                        className='h-6 w-6 text-red-600'
                        aria-hidden='true'
                      />
                    </div>
                    <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
                      <Dialog.Title
                        as='h3'
                        className='text-base font-semibold leading-6 text-gray-900'
                      >
                        {props.title}
                      </Dialog.Title>
                      <div className='mt-2'>
                        <p className='text-sm text-gray-500'>
                          {props.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-3'>
                  <UiButton
                    label={props.button}
                    type='button'
                    variant='danger'
                    loading={loading}
                    icon={TrashIcon}
                    onClick={props.action}
                  />
                  <UiButton
                    label={t('action.cancel')}
                    type='button'
                    variant='flat'
                    ref={cancelButtonRef}
                    onClick={() => props.setOpen(false)}
                  />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default DangerModal
