import { AxiosError } from 'axios'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import {
  createCompanyTrial,
  updateCompany,
} from '@/business/api/company.service'
import UpdateCompanyRequest from '@/business/dto/requests/update-company.request'
import Color from '@/business/dto/types/color'
import Theme from '@/business/dto/types/theme'
import { handleValidationError } from '@/business/error-handler'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiColorPicker from '@/components/ui-kit/input/UiColorPicker'
import UiToast from '@/components/ui-kit/presentation/UiToast'
import useAuth from '@/contexts/use-auth'
import { updatePageTitle } from '@/business/utils'
import CompanyResponse from '@/business/dto/responses/company.response'

const mapToFormValue = (company: CompanyResponse): UpdateCompanyRequest => {
  return {
    appearance: {
      theme: company.appearance?.theme || Theme.light,
      color: company.appearance?.color || Color.rose,
      branding: company.appearance?.branding || true,
    },
  }
}

export type Step4Props = {
  currentStep: number
  setCurrentStep: Dispatch<SetStateAction<number>>
}

const Step4 = (props: Step4Props) => {
  const auth = useAuth()
  const color = auth.company.appearance?.color || Color.rose
  const { t, i18n } = useTranslation()
  const form = useForm<UpdateCompanyRequest>({
    defaultValues: mapToFormValue(auth.company),
  })
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    updatePageTitle(t('page.title.introStep4'), auth.company)
  }, [])

  useEffect(() => {
    auth.setCompany((company) => ({
      ...company,
      appearance: {
        ...company.appearance,
        color: form.watch('appearance.color'),
      },
    }))
  }, [form.watch('appearance.color')])

  const onSubmit: SubmitHandler<UpdateCompanyRequest> = (data) => {
    setLoading(true)
    updateCompany(data, true)
      .then((response) => {
        setLoading(true)
        createCompanyTrial({})
          .then((response) => {
            auth.setCompany(response)
            toast.custom((toast) => (
              <UiToast
                toast={toast}
                type='success'
                title={t('toast.title.updated')}
                description={t('toast.description.companyUpdated')}
              />
            ))
          })
          .catch((err: AxiosError) => handleValidationError(err, i18n))
          .finally(() => setLoading(false))
      })
      .catch((err: AxiosError) => {
        handleValidationError(err, i18n)
        setLoading(false)
      })
  }

  return (
    <>
      <div className='relative sm:mx-auto sm:w-full sm:max-w-md'>
        <img
          className='mx-auto h-6 w-auto'
          src='/img/resflow-logo.svg'
          alt={t('common.resflowLogo')!}
        />
        <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
          {t('page.title.introStep4')}
        </h2>
        <p className='mt-2 text-center text-base text-gray-600'>
          {t('page.description.introStep4')}
        </p>
      </div>

      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white px-6 shadow rounded-lg py-8 sm:px-10'>
          <form className='space-y-6' onSubmit={form.handleSubmit(onSubmit)}>
            <UiColorPicker
              label={t('common.color')}
              control={form.control}
              name='appearance.color'
              rules={{ required: true }}
            />
            <UiButton
              label='Test beginnen'
              variant='primary'
              block={true}
              loading={loading}
            />
            <nav
              className='flex items-center justify-center'
              aria-label='Progress'
            >
              <p className='text-sm font-medium'>Schritt 4 von 4</p>
              <ol role='list' className='ml-8 flex items-center space-x-5'>
                <li>
                  <button
                    type='button'
                    onClick={() => props.setCurrentStep(0)}
                    className={`block h-2.5 w-2.5 rounded-full bg-${color}-600 hover:bg-${color}-900`}
                  >
                    <span className='sr-only'>Schritt 1</span>
                  </button>
                </li>
                <li>
                  <button
                    type='button'
                    onClick={() => props.setCurrentStep(1)}
                    className={`block h-2.5 w-2.5 rounded-full bg-${color}-600 hover:bg-${color}-900`}
                  >
                    <span className='sr-only'>Schritt 2</span>
                  </button>
                </li>
                <li>
                  <button
                    type='button'
                    onClick={() => props.setCurrentStep(2)}
                    className={`block h-2.5 w-2.5 rounded-full bg-${color}-600 hover:bg-${color}-900`}
                  >
                    <span className='sr-only'>Schritt 3</span>
                  </button>
                </li>
                <li>
                  <div
                    className='relative flex items-center justify-center'
                    aria-current='step'
                  >
                    <span
                      className='absolute flex h-5 w-5 p-px'
                      aria-hidden='true'
                    >
                      <span
                        className={`h-full w-full rounded-full bg-${color}-200`}
                      />
                    </span>
                    <span
                      className={`relative block h-2.5 w-2.5 rounded-full bg-${color}-600`}
                      aria-hidden='true'
                    />
                    <span className='sr-only'>Schritt 4</span>
                  </div>
                </li>
              </ol>
            </nav>
          </form>
        </div>
      </div>
    </>
  )
}

export default Step4
