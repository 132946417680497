import { loadAuth } from '@/business/api/auth.service'
import { findAllCompanies, loadCompany } from '@/business/api/company.service'
import { impersonateById } from '@/business/api/user.service'
import CompanyResponse from '@/business/dto/responses/company.response'
import Paginated from '@/business/dto/responses/paginated.response'
import UserResponse from '@/business/dto/responses/user.response'
import { handleValidationError } from '@/business/error-handler'
import UiSearch from '@/components/ui-kit/input/UiSearch'
import UiAvatar from '@/components/ui-kit/layout/UiAvatar'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import UiBadge from '@/components/ui-kit/presentation/UiBadge'
import UiNoSearchResults from '@/components/ui-kit/presentation/UiNoSearchResults'
import UiPagination from '@/components/ui-kit/presentation/UiPagination'
import UiToast from '@/components/ui-kit/presentation/UiToast'
import useAuth from '@/contexts/use-auth'
import { BriefcaseIcon, CubeIcon } from '@heroicons/react/24/outline'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from 'react-router-dom'

const loadPage = (page: number, search?: string) =>
  findAllCompanies({ page, limit: 10, search })

export const companiesLoader = async (args: LoaderFunctionArgs) => loadPage(1)

const ResflowCompaniesPage = () => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const revalidator = useRevalidator()
  const [paginated, setPaginated] = useState<
    Paginated<{ company: CompanyResponse; user: UserResponse }>
  >(
    useLoaderData() as Paginated<{
      company: CompanyResponse
      user: UserResponse
    }>
  )
  const [search, setSearch] = useState('')
  const [loadingTimeout, setLoadingTimeout] = useState<NodeJS.Timeout | null>(
    null
  )

  const reload = (page: number, search: string) => {
    loadPage(page, search)
      .then((response) => {
        setPaginated(response)
        setLoadingTimeout(null)
        window.scrollTo(0, 0)
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  const handleSearchChanged = (search: string) => {
    setSearch(search)

    if (loadingTimeout) {
      clearTimeout(loadingTimeout)
    }

    setLoadingTimeout(setTimeout(() => reload(1, search), 200))
  }

  const handlePageChanged = (page: number) => {
    reload(page, search)
  }

  const impersonateOwner = (user: UserResponse) => {
    impersonateById(user._id)
      .then(async (response) => {
        localStorage.setItem(
          'resflow_staff_token_type',
          localStorage.getItem('token_type') || ''
        )
        localStorage.setItem(
          'resflow_staff_access_token',
          localStorage.getItem('access_token') || ''
        )
        localStorage.setItem(
          'resflow_staff_refresh_token',
          localStorage.getItem('refresh_token') || ''
        )

        localStorage.setItem('token_type', response.tokenType)
        localStorage.setItem('access_token', response.accessToken)
        localStorage.setItem('refresh_token', response.refreshToken)

        Promise.all([loadAuth(), loadCompany()])
          .then(([authResponse, companyResponse]) => {
            auth.setUser(authResponse)
            auth.setCompany(companyResponse)

            toast.custom((toast) => (
              <UiToast
                toast={toast}
                type="success"
                title={t('toast.title.signedIn')}
                description={t('toast.description.signedIn')}
              />
            ))
          })
          .catch((err: AxiosError) => handleValidationError(err, i18n))
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  return (
    <UiContainer>
      <UiHeader
        title={`${t('common.companies')} (${paginated.totalResults})`}
        description={t('page.description.companies')}
        search={
          <>
            {(paginated.totalResults > 0 || paginated.search) && (
              <UiSearch
                value={search}
                onChanged={(e) => handleSearchChanged(e.target.value)}
                placeholder={t('form.placeholder.searchCompany')}
                loadingTimeout={loadingTimeout}
              />
            )}
          </>
        }
      />
      <div className="py-6 space-y-6 md:py-8 md:space-y-8">
        <ul
          role="list"
          className="grid grid-cols-1 gap-2 md:gap-6 sm:grid-cols-2 lg:grid-cols-3"
        >
          {paginated.results.map(({ company, user }) => (
            <li
              key={company._id}
              className="relative col-span-1 rounded-lg bg-white shadow hover:bg-gray-50"
            >
              <button
                onClick={() => impersonateOwner(user)}
                className="text-left block w-full"
              >
                <div className="flex items-center space-x-4 p-4 sm:px-6">
                  <div className="flex-shrink-0">
                    <UiAvatar
                      name={company.name}
                      color={company.appearance.color}
                      image={company.image}
                      shape="circle"
                      size={14}
                    />
                  </div>
                  <div className="min-w-0 flex-1 overflow-hidden">
                    <p className="truncate text-sm font-semibold text-gray-700">
                      {company.name} ({company.stripeId})
                    </p>
                    <p className="truncate text-sm text-gray-500">
                      resflow.com/{company.publicId}
                    </p>
                    <ul className="mt-2 flex flex-wrap gap-1">
                      <li>
                        <UiBadge
                          icon={CubeIcon}
                          label={
                            company.subscription
                              ? t(`product.${company.subscription.productId}`)
                              : '-'
                          }
                        />
                      </li>
                      <li>
                        <UiBadge icon={BriefcaseIcon} label={user.name} />
                      </li>
                    </ul>
                  </div>
                </div>
              </button>
            </li>
          ))}
        </ul>
        {paginated.totalPages > 1 && (
          <div className="flex justify-center">
            <UiPagination
              page={paginated.page}
              totalPages={paginated.totalPages}
              onPageChanged={handlePageChanged}
            />
          </div>
        )}
        {paginated.totalResults === 0 && <UiNoSearchResults />}
      </div>
    </UiContainer>
  )
}

export default ResflowCompaniesPage
