import axios from 'axios'
import CreateContactRequest from '@/business/dto/requests/create-contact.request'
import UpdateContactRequest from '@/business/dto/requests/update-contact.request'
import Paginated from '@/business/dto/responses/paginated.response'
import QueryPaginationRequest from '@/business/dto/requests/query-pagination.request'
import ContactResponse from '../dto/responses/contact.response'
import ContactPopulatedResponse from '../dto/responses/contact-populated.response'
import CreateOrUpdateNoteRequest from '../dto/responses/create-or-update-note.request'
import NotePopulated from '../dto/embeddeds/note-populated.response'

const ressourceUrl = `/api/contacts`

export const createContact = async (
  data: CreateContactRequest
): Promise<ContactResponse> => {
  return axios.post(ressourceUrl, data).then((response) => response.data)
}

export const findAllContacts = async (
  query: QueryPaginationRequest
): Promise<Paginated<ContactResponse>> => {
  return axios
    .get(ressourceUrl, {
      params: query,
    })
    .then((response) => response.data)
}

export const findContactById = async (
  contactId: string
): Promise<ContactResponse> => {
  return axios
    .get(`${ressourceUrl}/${contactId}`, {
      params: { populate: false },
    })
    .then((response) => response.data)
}

export const findContactByIdPopulated = async (
  contactId: string
): Promise<ContactPopulatedResponse> => {
  return axios
    .get(`${ressourceUrl}/${contactId}`, {
      params: { populate: true },
    })
    .then((response) => response.data)
}

export const updateContactById = async (
  contactId: string,
  data: UpdateContactRequest
): Promise<ContactResponse> => {
  return axios
    .patch(`${ressourceUrl}/${contactId}`, data)
    .then((response) => response.data)
}

export const createContactNote = async (
  contactId: string,
  data: CreateOrUpdateNoteRequest
): Promise<NotePopulated> => {
  return axios
    .post(`${ressourceUrl}/${contactId}/notes`, data)
    .then((response) => response.data)
}

export const updateContactNote = async (
  contactId: string,
  noteId: string,
  data: CreateOrUpdateNoteRequest
): Promise<NotePopulated> => {
  return axios
    .put(`${ressourceUrl}/${contactId}/notes/${noteId}`, data)
    .then((response) => response.data)
}

export const deleteContactNote = async (
  contactId: string,
  noteId: string
): Promise<boolean> => {
  return axios
    .delete(`${ressourceUrl}/${contactId}/notes/${noteId}`)
    .then((response) => response.data)
}

export const deleteContactById = async (contactId: string): Promise<void> => {
  return axios
    .delete(`${ressourceUrl}/${contactId}`)
    .then((response) => response.data)
}
