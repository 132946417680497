import { Navigate, Outlet } from 'react-router-dom'

const PublicLayout = () => {
  return (
    <>
      {localStorage.getItem('token_type') ? (
        <Navigate to="/" replace={true} />
      ) : (
        <div className="flex min-h-full flex-col justify-center py-12 px-4 sm:px-6">
          <div className="absolute -z-10 inset-0 top-96 skew-y-[-16deg]">
            <div className="absolute top-0 left-0 right-0 h-[48px] bg-gray-200"></div>
            <div className="absolute top-8 left-0 bg-rose-500 right-[calc(60%+256px)] h-[32px] shadow sm:rounded-r-sm"></div>
            <div className="absolute -top-4 right-0 bg-rose-500 left-[calc(60%+256px)] h-[32px] shadow sm:rounded-l-sm"></div>
          </div>

          <Outlet />
        </div>
      )}
    </>
  )
}

export default PublicLayout
