import { getCurrentFnsLocale } from '@/business/date-utils'
import useAuth from '@/contexts/use-auth'
import { formatISO } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'

type UiDateTimeProps = {
  dateTimeInUtc: string
  format: string
}

const UiDateTime = (props: UiDateTimeProps) => {
  const auth = useAuth()
  const isoDateTime = new Date(props.dateTimeInUtc)
  const zonedDateTime = utcToZonedTime(
    props.dateTimeInUtc,
    auth.company.localization.timeZone
  )

  return (
    <time dateTime={formatISO(isoDateTime)}>
      {format(zonedDateTime, props.format, {
        locale: getCurrentFnsLocale(),
        weekStartsOn: auth.company.localization.weekStartsOn,
        timeZone: auth.company.localization.timeZone,
      })}
    </time>
  )
}

export default UiDateTime

// 'PPPPpp'
