import axios from 'axios'
import UpdateCompanyRequest from '@/business/dto/requests/update-company.request'
import CreateCompanyTrialRequest from '@/business/dto/requests/create-company-trial.request'
import CreateCompanyPortalRequest from '@/business/dto/requests/create-company-portal.request'
import CreateOrUpdateExceptionRequest from '@/business/dto/requests/create-or-update-exception.request'
import Quota from '@/business/dto/responses/quota'
import CreateOrUpdateAvailabilityRequest from '../dto/requests/create-or-update-availability.request'
import QueryPaginationRequest from '../dto/requests/query-pagination.request'
import Paginated from '../dto/responses/paginated.response'
import CompanyResponse from '../dto/responses/company.response'
import UserResponse from '../dto/responses/user.response'
import CompanyPopulatedResponse from '../dto/responses/company-populated.response'

export const findAllCompanies = async (
  query: QueryPaginationRequest
): Promise<Paginated<{ company: CompanyResponse; user: UserResponse }>> => {
  return axios
    .get(`/api/companies`, {
      params: query,
    })
    .then((response) => response.data)
}

const ressourceUrl = `/api/company`

export const loadCompany = async (): Promise<CompanyResponse> => {
  return axios
    .get(ressourceUrl, {
      params: { populate: false },
    })
    .then((response) => response.data)
}

export const loadCompanyPopulate =
  async (): Promise<CompanyPopulatedResponse> => {
    return axios
      .get(ressourceUrl, {
        params: { populate: true },
      })
      .then((response) => response.data)
  }

export const updateCompany = async (
  data: UpdateCompanyRequest,
  skipPropagation?: boolean
): Promise<CompanyPopulatedResponse> => {
  return axios
    .patch(ressourceUrl, data, {
      params: { skipPropagation },
    })
    .then((response) => response.data)
}

export const createCompanyAvailability = async (
  data: CreateOrUpdateAvailabilityRequest
): Promise<CompanyPopulatedResponse> => {
  return axios
    .post(`${ressourceUrl}/availabilities`, data)
    .then((response) => response.data)
}

export const updateCompanyAvailability = async (
  availabilityId: string,
  data: CreateOrUpdateAvailabilityRequest
): Promise<CompanyPopulatedResponse> => {
  return axios
    .put(`${ressourceUrl}/availabilities/${availabilityId}`, data)
    .then((response) => response.data)
}

export const deleteCompanyAvailability = async (
  availabilityId: string
): Promise<CompanyPopulatedResponse> => {
  return axios
    .delete(`${ressourceUrl}/availabilities/${availabilityId}`)
    .then((response) => response.data)
}

export const createCompanyException = async (
  data: CreateOrUpdateExceptionRequest
): Promise<CompanyPopulatedResponse> => {
  return axios
    .post(`${ressourceUrl}/exceptions`, data)
    .then((response) => response.data)
}

export const updateCompanyException = async (
  exceptionId: string,
  data: CreateOrUpdateExceptionRequest
): Promise<CompanyPopulatedResponse> => {
  return axios
    .put(`${ressourceUrl}/exceptions/${exceptionId}`, data)
    .then((response) => response.data)
}

export const deleteCompanyException = async (
  exceptionId: string
): Promise<CompanyPopulatedResponse> => {
  return axios
    .delete(`${ressourceUrl}/exceptions/${exceptionId}`)
    .then((response) => response.data)
}

export const findQuota = async (): Promise<Quota> => {
  return axios.get(`${ressourceUrl}/quota`).then((response) => response.data)
}

export const deleteCompany = async (contactId: string): Promise<void> => {
  return axios
    .delete(`${ressourceUrl}/${contactId}`)
    .then((response) => response.data)
}

export const createCompanyTrial = async (
  data: CreateCompanyTrialRequest
): Promise<CompanyPopulatedResponse> => {
  return axios
    .post(`${ressourceUrl}/trial`, data)
    .then((response) => response.data)
}

export const createCompanyPortal = async (
  data: CreateCompanyPortalRequest
): Promise<{ url: string }> => {
  return axios
    .post(`${ressourceUrl}/customer-portal`, data)
    .then((response) => response.data)
}
