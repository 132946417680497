import {
  CodeBracketSquareIcon,
  LinkIcon,
  QrCodeIcon,
  SquaresPlusIcon,
} from '@heroicons/react/24/outline'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import { useEffect, useState } from 'react'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import UiSearch from '@/components/ui-kit/input/UiSearch'

const integrations = [
  {
    name: 'Link',
    description: 'Erstelle einen Link, den du versenden kannst.',
    role: 'Aktiv',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    icon: LinkIcon,
  },
  {
    name: 'Webseite',
    description: 'Binde deine Reservierungsseite in deine Website ein.',
    role: 'Aktiv',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    icon: CodeBracketSquareIcon,
  },
  {
    name: 'QR-Code',
    description: 'Binde deine Reservierungsseite in deine Website ein.',
    role: 'Aktiv',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    icon: QrCodeIcon,
  },
  {
    name: 'Facebook',
    description:
      'Facebook ist ein Online-Dienst für soziale Medien und soziale Netzwerket.',
    role: 'Aktiv',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    image: '/integrations/f_logo_RGB-Blue_144.png',
  },
  {
    name: 'Instagram',
    description:
      'Instagram ist ein sozialer Netzwerkdienst zum Teilen von Fotos und Videos.',
    role: 'Aktiv',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    image: '/integrations/Instagram_Glyph_Gradient.png',
  },
  {
    name: 'Google My Business',
    description:
      'Mit Google My Business deine Online-Präsenz in Google verwalten.',
    role: 'Aktiv',
    email: 'janecooper@example.com',
    telephone: '+1-202-555-0170',
    image: '/integrations/google-my-business-logo.svg',
  },
  // More people...
]

const IntegrationsPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const [search, setSearch] = useState<string>('')
  const [filteredIntegrations, setFilteredIntegrations] =
    useState<any[]>(integrations)

  useEffect(() => {
    setFilteredIntegrations(
      integrations.filter((integration) =>
        integration.name.toLowerCase().includes(search.toLowerCase())
      )
    )
  }, [integrations, search])

  return (
    <>
      <UiContainer>
        <UiHeader
          title={t('common.integrations')}
          description={t('page.description.integrations')}
          search={
            <UiSearch
              value={search}
              onChanged={(e) => setSearch(e.target.value)}
              placeholder={t('form.placeholder.searchIntegration')}
            />
          }
        />
        <div className="py-6 md:py-8">
          <ul
            role="list"
            className="grid grid-cols-1 gap-2 md:gap-6 sm:grid-cols-2 lg:grid-cols-3"
          >
            {filteredIntegrations.map((integration) => (
              <li
                key={integration.email}
                className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow hover:bg-gray-50 cursor-pointer"
              >
                <div className="flex w-full items-center justify-between space-x-6 p-6">
                  <div className="flex-1">
                    <div className="flex items-center space-x-3">
                      <h3 className="truncate text font-medium text-gray-900">
                        {integration.name}
                      </h3>
                      <span className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                        {integration.role}
                      </span>
                    </div>
                    <p className="mt-1 text-sm text-gray-500">
                      {integration.description}
                    </p>
                  </div>
                  {integration.image && (
                    <img
                      className="h-10 w-10 flex-shrink-0"
                      src={integration.image}
                      alt=""
                    />
                  )}
                  {integration.icon && (
                    <integration.icon className="h-10 w-10 flex-shrink-0 text-rose-600" />
                  )}
                </div>
              </li>
            ))}
            <li className="h-24">
              <button
                type="button"
                // onClick={() => setOpen(true)}
                className={`relative flex justify-center items-center border-2 border-gray-300 border-dashed rounded-lg px-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500 w-full h-full`}
              >
                <div>
                  <SquaresPlusIcon className="mx-auto h-8 w-8 text-gray-400" />
                  <span className="mt-2 block text-sm font-medium text-gray-900">
                    Integration anfragen
                  </span>
                </div>
              </button>
            </li>
          </ul>
        </div>
        {false && (
          <div className="pt-4 flex border-t border-gray-200">
            <a
              href="#"
              className="text-xs w-full text-gray-500 hover:text-gray-700"
            >
              Ihre Integration fehlt? Kontaktieren Sie uns!
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
        )}
      </UiContainer>

      {/* {false && (
        <UiHeader title="Integrationen">
          <EasySearch
            value={search}
            onChanged={(e) => setSearch(e.target.value)}
            placeholder="Integration suchen..."
          />
        </UiHeader>
      )} */}
    </>
  )
}

export default IntegrationsPage
