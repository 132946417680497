import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
} from '@headlessui/react'
import {
  CheckCircleIcon,
  CheckIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import {
  Control,
  FieldPath,
  FieldValues,
  PathValue,
  RegisterOptions,
  useController,
} from 'react-hook-form'
import useAuth from '@/contexts/use-auth'

type UiComboboxProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  label?: string | null
  description?: string | null
  options: {
    value: string | number
    label: string
    disabled?: boolean
    hidden?: boolean
  }[]
  disabled?: boolean
  className?: string

  control: Control<TFieldValues>
  name: TName
  rules?: RegisterOptions<TFieldValues, TName>
  onChange?: (value: PathValue<TFieldValues, TName> | null) => void
}

const UiCombobox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UiComboboxProps<TFieldValues, TName>
) => {
  const auth = useAuth()
  const color = auth?.company?.appearance?.color || 'rose'

  const {
    field: { onChange, onBlur, value, name, ref },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name: props.name,
    control: props.control,
    rules: props.rules,
  })

  const [search, setSearch] = useState('')

  const filteredOptions =
    search === ''
      ? props.options
      : props.options.filter((option) => {
          return option.label.toLowerCase().includes(search.toLowerCase())
        })

  const getLabelByValue = (value: string) =>
    props.options.find((option) => option.value == value)?.label || ''

  useEffect(() => {
    setSearch('')
  }, [value])

  return (
    <>
      <Combobox
        immediate
        as='div'
        value={value}
        onChange={(e: PathValue<TFieldValues, TName> | null) => {
          props.onChange?.(e)
          onChange(e as any)
        }}
        disabled={props.disabled}
      >
        {props.label && (
          <Label className='mb-1 block text-sm font-medium text-gray-700'>
            {props.label}
          </Label>
        )}
        <div className='relative'>
          <ComboboxInput
            className={`w-full rounded-md border-0 bg-white py-2 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-${auth.company.appearance.color}-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200`}
            onChange={(event) => setSearch(event.target.value)}
            onBlur={() => setSearch('')}
            displayValue={(value) => getLabelByValue(value as string)}
          />
          <ComboboxButton className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
            <ChevronUpDownIcon
              className='h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          </ComboboxButton>
          {filteredOptions.length > 0 && (
            <ComboboxOptions
              anchor='bottom'
              className='w-[var(--input-width)] absolute z-50 overflow-auto rounded-md bg-white p-2 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
            >
              {filteredOptions.length === 0 && search !== '' ? (
                <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                  Keine Treffer
                </div>
              ) : (
                filteredOptions
                  .filter((option) => !option.hidden)
                  .map((option) => (
                    <ComboboxOption
                      key={option.value}
                      value={option.value}
                      disabled={option.disabled}
                      className={({ active, selected }) =>
                        classNames(
                          'relative cursor-pointer select-none rounded-md py-2 px-3',
                          selected
                            ? `text-white bg-${color}-500 pr-10`
                            : 'text-gray-900',
                          active && !selected && 'bg-gray-100'
                        )
                      }
                    >
                      {({ selected, disabled }) => (
                        <>
                          <span
                            className={classNames(
                              'block truncate',
                              selected && !disabled && 'font-semibold',
                              disabled && 'text-gray-400'
                            )}
                          >
                            {option.label}
                          </span>
                          {selected && (
                            <span className='absolute inset-y-0 right-0 flex items-center pr-3'>
                              <CheckCircleIcon
                                className='h-5 w-5'
                                aria-hidden='true'
                              />
                            </span>
                          )}
                        </>
                      )}
                    </ComboboxOption>
                  ))
              )}
            </ComboboxOptions>
          )}
        </div>
        {props.description && (
          <p className='mt-1 text-xs text-gray-500'>{props.description}</p>
        )}
        {error && (
          <span
            id={`${name}-error`}
            role='alert'
            className='mt-1 text-xs text-red-600'
          >
            {error?.message}
          </span>
        )}
      </Combobox>
      {false && (
        <Combobox
          as='div'
          className={props.className}
          value={value}
          onChange={(e: PathValue<TFieldValues, TName> | null) => {
            props.onChange?.(e)
            onChange(e as any)
          }}
          disabled={props.disabled}
          nullable={false}
          multiple={false}
        >
          {props.label && (
            <Label className='mb-1 block text-sm font-medium text-gray-700'>
              {props.label}
            </Label>
          )}
          <div className='relative'>
            <ComboboxButton
              className={classNames(
                'relative w-full rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm border border-gray-300 focus:outline-none focus:ring-2 text-sm leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200',
                `focus:ring-${auth.company.appearance.color}-600`
              )}
              onClick={() => setSearch('')}
            >
              <span className='block truncate'>
                {getLabelByValue(value) || <>&nbsp;</>}
              </span>
              <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                <ChevronUpDownIcon
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </ComboboxButton>

            <ComboboxOptions
              className='absolute z-10 mt-1 overflow-hidden w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
              modal={false}
            >
              <div className='sticky top-0 bg-white w-full border-b border-gray'>
                <label htmlFor='search' className='sr-only'>
                  Search
                </label>
                <div className='relative flex rounded-md shadow-none'>
                  <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                    <MagnifyingGlassIcon
                      className='h-5 w-5 text-gray-400'
                      aria-hidden='true'
                    />
                  </div>
                  <ComboboxInput
                    className='bg-transparent block w-full rounded-md border border-transparent py-3 px-10 focus:border-transparent focus:ring-0 sm:text-sm'
                    value={search}
                    displayValue={(_: string) => search}
                    onChange={(event) => setSearch(event.target.value)}
                  />
                </div>
              </div>
              <div className='max-h-60 w-full overflow-auto p-2 text-base sm:text-sm space-y-0.5'>
                {filteredOptions.length === 0 && search !== '' ? (
                  <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                    Keine Treffer
                  </div>
                ) : (
                  filteredOptions
                    .filter((option) => !option.hidden)
                    .map((option) => (
                      <ComboboxOption
                        key={option.value}
                        value={option.value}
                        disabled={option.disabled}
                        className={({ active, selected }) =>
                          classNames(
                            'relative cursor-pointer select-none rounded-md py-2 px-3',
                            selected
                              ? `text-white bg-${color}-500 pr-10`
                              : 'text-gray-900',
                            active && !selected && 'bg-gray-100'
                          )
                        }
                      >
                        {({ selected, disabled }) => (
                          <>
                            <span
                              className={classNames(
                                'block truncate',
                                selected && !disabled && 'font-semibold',
                                disabled && 'text-gray-400'
                              )}
                            >
                              {option.label}
                            </span>
                            {selected && (
                              <span className='absolute inset-y-0 right-0 flex items-center pr-3'>
                                <CheckCircleIcon
                                  className='h-5 w-5'
                                  aria-hidden='true'
                                />
                              </span>
                            )}
                          </>
                        )}
                      </ComboboxOption>
                    ))
                )}
              </div>
            </ComboboxOptions>
          </div>
          {props.description && (
            <p className='mt-1 text-xs text-gray-500'>{props.description}</p>
          )}
          {error && (
            <span
              id={`${name}-error`}
              role='alert'
              className='mt-1 text-xs text-red-600'
            >
              {error?.message}
            </span>
          )}
        </Combobox>
      )}
    </>
  )
}

export default UiCombobox
