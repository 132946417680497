import UiHeader from '@/components/ui-kit/layout/UiHeader'
import UiList from '@/components/ui-kit/layout/UiList'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import { useCompany } from './Company'

const CompanyMobilePage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { company, setCompany, tabs } = useCompany()
  // const isDesktop = window.matchMedia('(min-width: 768px)').matches

  // if (isBase && isDesktop) {
  //   return (
  //     <Navigate to={`/services/${service._id}/properties`} replace={true} />
  //   )
  // }

  return (
    <>
      <UiHeader
        className="md:hidden"
        title={t('common.general')}
        description={t('page.services.properties.description')}
        mobileBackHref="/services"
      />
      <div className="md:hidden py-6 md:py-0">
        <div className="space-y-6">
          <UiList>
            {tabs.map((tab) => (
              <UiList.Item
                key={tab.name}
                title={tab.name}
                subtitle={tab.description}
                icon={tab.icon}
                href={`/company${tab.href}`}
              />
            ))}
          </UiList>
        </div>
      </div>
    </>
  )
}

export default CompanyMobilePage
