import { useState } from 'react'
import {
  BriefcaseIcon,
  PlusSmallIcon,
  UserGroupIcon,
  UserIcon,
  WrenchIcon,
} from '@heroicons/react/24/outline'
import {
  Link,
  LoaderFunctionArgs,
  defer,
  useLoaderData,
} from 'react-router-dom'
import UiAvatar from '@/components/ui-kit/layout/UiAvatar'
import { findAllUsers } from '@/business/api/user.service'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import UiSearch from '@/components/ui-kit/input/UiSearch'
import UiBadge from '@/components/ui-kit/presentation/UiBadge'
import Paginated from '@/business/dto/responses/paginated.response'
import Role from '@/business/dto/types/role'
import UiButton from '@/components/ui-kit/actions/UiButton'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiPagination from '@/components/ui-kit/presentation/UiPagination'
import UiEmptyState from '@/components/ui-kit/presentation/UiEmptyState'
import UiNoSearchResults from '@/components/ui-kit/presentation/UiNoSearchResults'
import UserResponse from '@/business/dto/responses/user.response'

const loadPage = (page: number, search?: string) =>
  findAllUsers({ page, limit: 10, search })

export const usersPageLoader = async ({ params }: LoaderFunctionArgs) => {
  const [users] = await Promise.all([loadPage(1)])

  return defer({
    users,
  })
}

const UsersPage = () => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const { users } = useLoaderData() as any
  const [paginated, setPaginated] = useState<Paginated<UserResponse>>(users)
  const [search, setSearch] = useState('')
  const [loadingTimeout, setLoadingTimeout] = useState<NodeJS.Timeout | null>(
    null
  )

  const reload = (page: number, search: string) => {
    loadPage(page, search)
      .then((response) => {
        setPaginated(response)
        setLoadingTimeout(null)
        window.scrollTo(0, 0)
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  const handleSearchChanged = (search: string) => {
    setSearch(search)

    if (loadingTimeout) {
      clearTimeout(loadingTimeout)
    }

    setLoadingTimeout(setTimeout(() => reload(1, search), 200))
  }

  const handlePageChanged = (page: number) => {
    reload(page, search)
  }

  return (
    <UiContainer>
      <UiHeader
        title={`${t('common.users')} (${paginated.totalResults})`}
        description={t('page.description.users')}
        mobileBackHref="/settings"
        mobileAction={{ icon: PlusSmallIcon, to: '/users/new' }}
        desktopActions={
          <>
            {(paginated.totalResults > 0 || paginated.search) && (
              <UiButton
                as={Link}
                variant="primary"
                label={t('action.newUser')}
                icon={PlusSmallIcon}
                type="button"
                to="/users/new"
              />
            )}
          </>
        }
        search={
          <>
            {(paginated.totalResults > 0 || paginated.search) && (
              <UiSearch
                value={search}
                onChanged={(e) => handleSearchChanged(e.target.value)}
                placeholder={t('form.placeholder.searchUser')}
                loadingTimeout={loadingTimeout}
              />
            )}
          </>
        }
      />
      <div className="py-6 space-y-6 md:py-8 md:space-y-8">
        <ul
          role="list"
          className="grid grid-cols-1 gap-2 md:gap-6 sm:grid-cols-2 lg:grid-cols-3"
        >
          {paginated.results.map((user) => (
            <li
              key={user._id}
              className="relative col-span-1 rounded-lg bg-white shadow hover:bg-gray-50"
            >
              <div className="flex items-center space-x-4 p-4 sm:px-6">
                <div className="flex-shrink-0">
                  <UiAvatar
                    name={user.name}
                    color={user.color}
                    image={user.image}
                    shape="circle"
                    size={14}
                  />
                </div>
                <div className="min-w-0 flex-1 overflow-hidden">
                  <p className="truncate text-sm font-semibold text-gray-700">
                    {user.name}
                  </p>
                  <p className="truncate text-sm text-gray-500">{user.email}</p>
                  <ul className="mt-2 flex flex-wrap gap-1">
                    {user.role === Role.owner && (
                      <li>
                        <UiBadge
                          icon={BriefcaseIcon}
                          bgClass="bg-rose-100"
                          textClass="text-rose-800"
                          label={t('common.owner')}
                        />
                      </li>
                    )}
                    {user.role === Role.admin && (
                      <li>
                        <UiBadge
                          icon={WrenchIcon}
                          bgClass="bg-sky-100"
                          textClass="text-sky-800"
                          label={t('common.admin')}
                        />
                      </li>
                    )}
                    {user.role === Role.member && (
                      <li>
                        <UiBadge icon={UserIcon} label={t('common.member')} />
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <Link
                to={`/users/${user._id}`}
                className="absolute top-0 right-0 bottom-0 left-0"
              />
            </li>
          ))}
        </ul>
        {paginated.totalPages > 1 && (
          <div className="flex justify-center">
            <UiPagination
              page={paginated.page}
              totalPages={paginated.totalPages}
              onPageChanged={handlePageChanged}
            />
          </div>
        )}
        {paginated.totalResults === 0 && !paginated.search && (
          <UiEmptyState
            icon={UserGroupIcon}
            title={t('hint.title.userEmptyState')}
            description={t('hint.description.userEmptyState')}
            button={{
              label: t('action.newUser'),
              to: '/users/new',
            }}
          />
        )}
        {paginated.totalResults === 0 && paginated.search && (
          <UiNoSearchResults />
        )}
      </div>
    </UiContainer>
  )
}

export default UsersPage
