import { useTranslation } from 'react-i18next'
import { TrashIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import { useEffect, useState } from 'react'
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form'
import UpdateContactRequest from '@/business/dto/requests/update-contact.request'
import {
  createContact,
  updateContactById,
} from '@/business/api/contact.service'
import { calcColor } from '@/business/api/image.service'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import UiImageUpload from '../ui-kit/input/basic/UiImageUpload'
import UiInput from '../ui-kit/input/basic/UiInput'
import { useNavigate } from 'react-router-dom'
import CreateContactRequest from '@/business/dto/requests/create-contact.request'
import Color from '@/business/dto/types/color'
import UiColorPicker from '../ui-kit/input/UiColorPicker'
import UiCountryCombobox from '../ui-kit/input/UiCountryCombobox'
import ContactResponse from '@/business/dto/responses/contact.response'

const mapToFormValue = (
  contact?: ContactResponse
): CreateContactRequest | UpdateContactRequest => {
  return {
    name: contact?.name || '',
    description: contact?.description || '',
    image: contact?.image || null,
    color: contact?.color || Color.gray,
    identificationNumber: contact?.identificationNumber || '',
    email: contact?.email || '',
    phone: contact?.phone || '',
    address: contact?.address || null,
    customFields: contact?.customFields || [],
  }
}

export const ContactCreateCard = () => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<CreateContactRequest>({
    defaultValues: mapToFormValue(undefined),
  })
  const name = form.watch('name')

  useEffect(() => {
    form.setValue('color', calcColor(name))
  }, [name])

  const onSubmit: SubmitHandler<CreateContactRequest> = (data) => {
    setLoading(true)
    createContact(data)
      .then((response) => {
        navigate(`/contacts/${response._id}`)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.created')}
            description={t('toast.description.contactCreated')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        handleValidationError(err, i18n)
      })
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ContactCreateUpdateCard loading={loading} contact={undefined} />
      </form>
    </FormProvider>
  )
}

export type ContactUpdateCardProps = {
  contact: ContactResponse
}

export const ContactUpdateCard = (props: ContactUpdateCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<UpdateContactRequest>({
    defaultValues: mapToFormValue(props.contact),
  })

  const onSubmit: SubmitHandler<UpdateContactRequest> = (data) => {
    setLoading(true)
    updateContactById(props.contact._id, data)
      .then((response) => {
        navigate(`/contacts/${response._id}`)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.updated')}
            description={t('toast.description.contactUpdated')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        handleValidationError(err, i18n)
        setLoading(false)
      })
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ContactCreateUpdateCard loading={loading} contact={props.contact} />
      </form>
    </FormProvider>
  )
}

type ContactCreateUpdateCardProps = {
  loading: boolean
  contact?: ContactResponse
}

const ContactCreateUpdateCard = (props: ContactCreateUpdateCardProps) => {
  const auth = useAuth()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const form = useFormContext<CreateContactRequest | UpdateContactRequest>()
  const customFieldsFieldArray = useFieldArray({
    name: 'customFields',
  })

  return (
    <UiCard>
      {/* <UiCard.Header
        title={'General'}
        description={t('page.contacts.properties.description')}
        icon={CubeTransparentIcon}
      /> */}
      <UiCard.Body>
        <div className='space-y-6'>
          <UiImageUpload
            label={t('common.image')}
            avatar={{
              name: form.watch('name')!,
              color: form.watch('color')!,
              shape: 'circle',
            }}
            name='image'
            control={form.control}
          />
          <UiInput
            label={t('common.name')}
            type='text'
            name='name'
            control={form.control}
            autoFocus={true}
            rules={{
              required: {
                value: true,
                message: t('form.validation.required'),
              },
              minLength: {
                value: 3,
                message: t('form.validation.minLength', { x: 3 }),
              },
              maxLength: {
                value: 32,
                message: t('form.validation.maxLength', { x: 32 }),
              },
            }}
          />
          <UiInput
            label={t('common.description')}
            type='text'
            name='description'
            control={form.control}
            rules={{
              maxLength: {
                value: 64,
                message: t('form.validation.maxLength', { x: 64 }),
              },
            }}
          />
          <UiInput
            label={t('common.identificationNumber')}
            type='text'
            name='identificationNumber'
            control={form.control}
            rules={{
              maxLength: {
                value: 64,
                message: t('form.validation.maxLength', { x: 64 }),
              },
            }}
          />
          <UiInput
            label={t('common.emailAddress')}
            type='email'
            name='email'
            control={form.control}
            rules={{
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: t('form.validation.email'),
              },
              maxLength: {
                value: 64,
                message: t('form.validation.maxLength', { x: 64 }),
              },
            }}
          />
          <UiInput
            label={t('common.phoneNumber')}
            type='tel'
            name='phone'
            control={form.control}
            rules={{
              maxLength: {
                value: 64,
                message: t('form.validation.maxLength', { x: 64 }),
              },
            }}
          />
          <UiColorPicker
            label={t('common.color')}
            control={form.control}
            name='color'
            rules={{ required: true }}
          />
          <hr className='bg-gray-100' />
          {!form.watch('address') && (
            <button
              type='button'
              className='text-sm font-medium text-gray-500 hover:text-gray-600'
              onClick={() =>
                form.setValue(
                  'address',
                  {
                    line1: '',
                    line2: '',
                    postalCode: '',
                    city: '',
                    country: auth.company.location.country,
                  },
                  {
                    shouldDirty: true,
                  }
                )
              }
            >
              <span>+ </span>
              <span>{t('action.addAddress')}</span>
            </button>
          )}
          {form.watch('address') && (
            <>
              <div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12'>
                <div className='sm:col-span-12'>
                  <UiInput
                    label={t('common.streetAndHouseNumber')}
                    type='text'
                    name='address.line1'
                    control={form.control}
                    autoFocus={true}
                    rules={{
                      required: {
                        value: true,
                        message: t('form.validation.required'),
                      },
                      maxLength: {
                        value: 64,
                        message: t('form.validation.maxLength', { x: 64 }),
                      },
                    }}
                  />
                </div>
                <div className='sm:col-span-12'>
                  <UiInput
                    label={t('common.addressLine2')}
                    type='text'
                    name='address.line2'
                    control={form.control}
                    rules={{
                      maxLength: {
                        value: 64,
                        message: t('form.validation.maxLength', { x: 64 }),
                      },
                    }}
                  />
                </div>
                <div className='sm:col-span-6'>
                  <UiInput
                    label={t('common.zipCode')}
                    type='text'
                    name='address.postalCode'
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: t('form.validation.required'),
                      },
                      maxLength: {
                        value: 64,
                        message: t('form.validation.maxLength', { x: 64 }),
                      },
                    }}
                  />
                </div>
                <div className='sm:col-span-6'>
                  <UiInput
                    label={t('common.city')}
                    type='text'
                    name='address.city'
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: t('form.validation.required'),
                      },
                      maxLength: {
                        value: 64,
                        message: t('form.validation.maxLength', { x: 64 }),
                      },
                    }}
                  />
                </div>
                <div className='sm:col-span-12'>
                  <UiCountryCombobox
                    label={t('common.country')}
                    control={form.control}
                    name='address.country'
                  />
                </div>
              </div>
              <button
                type='button'
                className='text-sm font-medium text-gray-500 hover:text-gray-600'
                onClick={() => {
                  form.setValue('address', null!)
                  // hack to trigger validation
                  form.setValue('name', form.watch('name'), {
                    shouldDirty: true,
                  })
                }}
              >
                <span>- </span>
                <span>{t('action.removeAddress')}</span>
              </button>
            </>
          )}
          <hr className='bg-gray-100' />
          {customFieldsFieldArray.fields.map((field: any, index: number) => (
            <div key={field.id} className='flex space-x-3'>
              <div className='flex-1'>
                <UiInput
                  label={t('common.key')}
                  type='text'
                  name={`customFields.${index}.key`}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                    maxLength: {
                      value: 64,
                      message: t('form.validation.maxLength', { x: 64 }),
                    },
                  }}
                />
              </div>
              <div className='flex-1'>
                <UiInput
                  label={t('common.value')}
                  type='text'
                  name={`customFields.${index}.value`}
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                    maxLength: {
                      value: 64,
                      message: t('form.validation.maxLength', { x: 64 }),
                    },
                  }}
                />
              </div>
              <div className='flex-shrink pt-6'>
                <UiButton
                  type='button'
                  label={t('action.removeCustomField')}
                  variant='light-gray'
                  icon={TrashIcon}
                  iconOnly={true}
                  onClick={() => {
                    customFieldsFieldArray.remove(index)
                  }}
                />
              </div>
            </div>
          ))}
          <button
            type='button'
            className='text-sm font-medium text-gray-500 hover:text-gray-600'
            onClick={() =>
              customFieldsFieldArray.append({
                key: '',
                value: '',
              })
            }
          >
            <span>+ </span>
            <span>{t('action.addCustomField')}</span>
          </button>
        </div>
      </UiCard.Body>
      <UiCard.Footer>
        <UiButton
          label={t('action.cancel')}
          type='button'
          variant='flat'
          onClick={
            props.contact
              ? () => navigate(`/contacts/${props.contact?._id}`)
              : () => navigate('/contacts')
          }
        />
        <UiButton
          label={props.contact ? t('action.save') : t('action.create')}
          type='submit'
          variant={props.contact ? 'dark' : 'primary'}
          loading={props.loading}
          disabled={!form.formState.isDirty}
        />
      </UiCard.Footer>
    </UiCard>
  )
}
