import { findContactByIdPopulated } from '@/business/api/contact.service'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { PencilIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Link,
  LoaderFunctionArgs,
  defer,
  useLoaderData,
} from 'react-router-dom'
import ContactGeneralCard from '@/components/cards/ContactGeneralCard'
import ContactMetadataCard from '@/components/cards/ContactMetadataCard'
import ContactActivityCard from '@/components/cards/ContactActivityCard'
import ContactDangerzoneCard from '@/components/cards/ContactDangerzoneCard'
import ContactReservationCard from '@/components/cards/ContactReservations'
import ContactPopulatedResponse from '@/business/dto/responses/contact-populated.response'

export const contactPageLoader = async ({ params }: LoaderFunctionArgs) => {
  const [contact] = await Promise.all([
    findContactByIdPopulated(params.contactId!),
  ])
  return defer({ contact })
}

const ContactPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const loaderData = useLoaderData() as any
  const [contact, setContact] = useState<ContactPopulatedResponse>(
    loaderData.contact
  )

  return (
    <UiContainer>
      <UiHeader
        avatar={{
          name: contact.name,
          color: contact.color,
          image: contact.image,
          shape: 'circle',
        }}
        title={contact.name}
        description={t('page.description.contact')}
        desktopBackHref='/contacts'
        mobileBackHref='/contacts'
        mobileAction={{
          icon: PencilSquareIcon,
          to: `/contacts/${contact._id}/edit`,
        }}
        desktopActions={
          <UiButton
            as={Link}
            variant='primary'
            label={t('action.editContact')}
            icon={PencilIcon}
            to={`/contacts/${contact._id}/edit`}
          />
        }
      />
      <div className='py-6 md:py-8'>
        <div className='space-y-6'>
          <div className='grid grid-cols-1 grid-rows-1 items-start md:gap-6 lg:grid-cols-3 2xl:grid-cols-4 gap-6'>
            <div className='lg:col-start-1'>
              <ContactGeneralCard contact={contact} />
            </div>
            <div className='lg:col-start-2 lg:col-span-2 lg:row-span-2 lg:row-end-2 2xl:col-start-2 2xl:col-span-3'>
              <div className='space-y-6'>
                <ContactReservationCard
                  contact={contact}
                  setContact={setContact}
                />
                <ContactActivityCard
                  contact={contact}
                  setContact={setContact}
                />
                <ContactDangerzoneCard
                  contact={contact}
                  setContact={setContact}
                />
              </div>
            </div>
            <div className='lg:col-start-1'>
              <ContactMetadataCard contact={contact} />
            </div>
          </div>
        </div>
      </div>
    </UiContainer>
  )
}

export default ContactPage
