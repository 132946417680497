import { useEffect } from 'react'
import useAuth from '@/contexts/use-auth'

const SignOut = () => {
  const auth = useAuth()

  useEffect(() => {
    auth.signOut()
  }, [])

  return null
}

export default SignOut
