import { EnvelopeIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { forgotPassword } from '@/business/api/auth.service'
import UiInput from '@/components/ui-kit/input/basic/UiInput'
import { toast } from 'react-hot-toast'
import UiToast from '@/components/ui-kit/presentation/UiToast'
import { useTranslation } from 'react-i18next'
import { updatePageTitle } from '@/business/utils'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import UiButton from '@/components/ui-kit/actions/UiButton'
import ForgotPasswordRequest from '@/business/dto/requests/forgot-password.request'
import PublicLayout from '@/components/PublicLayout'

const ForgotPasswordPage = () => {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [requested, setRequested] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [resendLoading, setResendLoading] = useState<boolean>(false)
  const form = useForm<ForgotPasswordRequest>({
    defaultValues: {
      email: searchParams.get('email') || (location.state as any)?.email || '',
    },
  })
  const formEmail = form.watch('email')

  useEffect(() => {
    updatePageTitle(t('page.title.forgotPassword'))
  }, [])

  const onSubmit: SubmitHandler<ForgotPasswordRequest> = (data) => {
    setLoading(true)
    forgotPassword(data)
      .then((_) => {
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.emailSent')}
            description={t('toast.description.resetPasswordTokenSent')}
          />
        ))
        setRequested(true)
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
      .finally(() => setLoading(false))
  }

  const onResendForgotPassword = () => {
    setResendLoading(true)
    forgotPassword({
      email: formEmail,
    })
      .then((_) => {
        setResendLoading(false)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.emailResent')}
            description={t('toast.description.resetPasswordTokenSent')}
          />
        ))
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  return (
    <>
      {!requested && (
        <>
          <div className='sm:mx-auto sm:w-full sm:max-w-md'>
            <img
              className='mx-auto h-6 w-auto'
              src='/img/resflow-logo.svg'
              alt={t('common.resflowLogo')!}
            />
            <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
              {t('page.title.forgotPassword')}
            </h2>
            <p className='mt-2 text-center text-base text-gray-600'>
              {t('page.description.forgotPassword')}
            </p>
          </div>
          <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
            <div className='bg-white px-6 shadow rounded-lg py-8 sm:px-10'>
              <form
                className='space-y-6'
                onSubmit={form.handleSubmit(onSubmit)}
              >
                <UiInput
                  label={t('common.emailAddress')}
                  type='text'
                  icon={EnvelopeIcon}
                  placeholder={t('form.placeholder.emailAddress')!}
                  autoComplete='email'
                  name='email'
                  autoFocus
                  control={form.control}
                  rules={{
                    required: {
                      value: true,
                      message: t('form.validation.required'),
                    },
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: t('form.validation.emailAddress'),
                    },
                    maxLength: {
                      value: 64,
                      message: t('form.validation.maxLength', { x: 64 }),
                    },
                  }}
                />
                <UiButton
                  type='submit'
                  label={t('action.sendResetLink')}
                  variant='primary'
                  block={true}
                  loading={loading}
                />
              </form>
              <div className='mt-6 text-center'>
                <p className='text-xs text-gray-600'>
                  <Link
                    to='/sign-in'
                    state={{
                      name: (location.state as any)?.name || '',
                      email: formEmail,
                      password: (location.state as any)?.password || '',
                    }}
                    className='font-medium text-rose-600 hover:text-rose-700'
                  >
                    {t('action.goToSignIn')}
                    <span aria-hidden='true'> &rarr;</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      {requested && (
        <>
          <div className='sm:mx-auto sm:w-full sm:max-w-md'>
            <img
              className='mx-auto h-6 w-auto'
              src='/img/resflow-logo.svg'
              alt={t('common.resflowLogo')!}
            />
            <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
              {t('page.title.forgotPassword')}
            </h2>
            <p
              className='mt-2 text-center text-base text-gray-600'
              dangerouslySetInnerHTML={{
                __html: t('page.description.forgotPassword2', {
                  email: formEmail,
                }),
              }}
            />
          </div>
          <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
            <div className='bg-white px-6 shadow rounded-lg py-8 sm:px-10'>
              <div className='flex justify-center py-6'>
                <img
                  className='w-auto h-48'
                  src='/img/rose/undraw_mailbox_re_dvds.svg'
                />
              </div>
              <div className='mt-6 text-xs text-gray-500'>
                <div>
                  {t('description.noEmailReceived')}
                  <ul className='pl-4 list-disc'>
                    <li>{t('description.waitAFewMinutes')}</li>
                    <li>{t('description.lookInYourSpam')}</li>
                    <li>
                      <button
                        className='font-medium text-rose-600 hover:text-rose-700 disabled:text-gray-400 disabled:cursor-not-allowed'
                        onClick={onResendForgotPassword}
                        disabled={resendLoading}
                      >
                        {t('action.resendEmail')}
                      </button>{' '}
                      (<span className='font-medium'>{formEmail}</span>)
                    </li>
                    <li>
                      <Link
                        className='font-medium text-rose-600 hover:text-rose-700'
                        to='/forgot-password'
                      >
                        {t('action.correctEmailAddress')}
                      </Link>
                    </li>
                    <li>
                      <a
                        className='font-medium text-rose-600 hover:text-rose-700'
                        href='mailto:support@resflow.com'
                      >
                        {t('action.contactSupport')}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ForgotPasswordPage
