import UiContainer from '@/components/ui-kit/layout/UiContainer'

const UiImpersonatedBanner = () => {
  return (
    <div className="relative flex items-center h-4 md:h-8 bg-rose-600">
      <UiContainer>
        <p className="font-semibold text-white text-xs text-center">
          Impersonated
        </p>
      </UiContainer>
    </div>
  )
}

export default UiImpersonatedBanner
