import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Theme from '@/business/dto/types/theme'
import useAuth from '@/contexts/use-auth'

type UiThemePickerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  label: string

  control: Control<TFieldValues>
  name: TName
  rules?: RegisterOptions<TFieldValues, TName>
}

const UiThemePicker = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UiThemePickerProps<TFieldValues, TName>
) => {
  const auth = useAuth()
  const color = auth?.company?.appearance?.color || 'rose'
  const { t } = useTranslation()

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => (
        <RadioGroup value={value} onChange={onChange}>
          {props.label && (
            <RadioGroup.Label className="block text-sm font-medium text-gray-700">
              {props.label}
            </RadioGroup.Label>
          )}
          <div className="mt-1 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
            {[
              {
                value: Theme.light,
                label: t('common.ligth'),
                description: 'NO_TRANSLATION',
              },
              {
                value: Theme.dark,
                label: t('common.dark'),
                description: 'NO_TRANSLATION',
              },
              {
                value: Theme.system,
                label: t('common.system'),
                description: 'NO_TRANSLATION',
              },
            ].map((theme) => (
              <RadioGroup.Option
                key={theme.value}
                value={theme.value}
                className={({ checked, active }) =>
                  classNames(
                    checked ? 'border-transparent' : 'border-gray-300',
                    active
                      ? `border-${color}-500 ring-2 ring-${color}-500`
                      : '',
                    'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                  )
                }
              >
                {({ checked, active }) => (
                  <>
                    <span className="flex flex-1">
                      <span className="flex flex-col">
                        <RadioGroup.Label
                          as="span"
                          className="block text-sm font-medium text-gray-900"
                        >
                          {theme.label}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className="mt-1 flex items-center text-sm text-gray-500"
                        >
                          {theme.description}
                        </RadioGroup.Description>
                      </span>
                    </span>
                    <CheckCircleIcon
                      className={classNames(
                        !checked ? 'invisible' : '',
                        `h-5 w-5 text-${color}-600`
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        active ? 'border' : 'border-2',
                        checked ? `border-${color}-500` : 'border-transparent',
                        'pointer-events-none absolute -inset-px rounded-lg'
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      )}
    />
  )
}

export default UiThemePicker
