import { useTranslation } from 'react-i18next'
import {
  CubeTransparentIcon,
  PlusSmallIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import { useState } from 'react'
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import { useNavigate } from 'react-router-dom'
import CreateOrUpdateAvailabilityRequest from '@/business/dto/requests/create-or-update-availability.request'
import Availability from '@/business/dto/embeddeds/availability'
import { startOfDay } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import UiCalendar from '../ui-kit/input/UiCalendar'
import UiTimeCombobox from '../ui-kit/input/UiTimeCombobox'
import { Switch } from '@headlessui/react'
import classNames from 'classnames'

export type AvailabilityCreateCardProps = {
  createFn: (data: CreateOrUpdateAvailabilityRequest) => Promise<any>
  backHref: string
}

export const AvailabilityCreateCard = (props: AvailabilityCreateCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)

  const nowInUtc = new Date()
  const todayDate = format(
    startOfDay(utcToZonedTime(nowInUtc, auth.company.localization.timeZone)),
    'yyyy-MM-dd',
    { timeZone: auth.company.localization.timeZone }
  )

  const form = useForm<CreateOrUpdateAvailabilityRequest>({
    defaultValues: {
      startDate: todayDate,
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
  })

  const onSubmit: SubmitHandler<CreateOrUpdateAvailabilityRequest> = (data) => {
    setLoading(true)
    props
      .createFn(data)
      .then((_) => {
        navigate(props.backHref)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.created')}
            description={t('toast.description.availabilityCreated')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        handleValidationError(err, i18n)
      })
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <AvailabilityCreateUpdateCard
          loading={loading}
          availability={undefined}
          backHref={props.backHref}
        />
      </form>
    </FormProvider>
  )
}

export type AvailabilityUpdateCardProps = {
  availability: Availability
  updateFn: (
    availabilityId: string,
    data: CreateOrUpdateAvailabilityRequest
  ) => Promise<any>
  backHref: string
}

export const AvailabilityUpdateCard = (props: AvailabilityUpdateCardProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<CreateOrUpdateAvailabilityRequest>({
    defaultValues: {
      startDate: props.availability.startDate,
      monday: props.availability.monday,
      tuesday: props.availability.tuesday,
      wednesday: props.availability.wednesday,
      thursday: props.availability.thursday,
      friday: props.availability.friday,
      saturday: props.availability.saturday,
      sunday: props.availability.sunday,
    },
  })

  const onSubmit: SubmitHandler<CreateOrUpdateAvailabilityRequest> = (data) => {
    props
      .updateFn(props.availability._id, data)
      .then((_) => {
        navigate(props.backHref)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.updated')}
            description={t('toast.description.availabilityUpdated')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        handleValidationError(err, i18n)
      })
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <AvailabilityCreateUpdateCard
          loading={loading}
          availability={props.availability}
          backHref={props.backHref}
        />
      </form>
    </FormProvider>
  )
}

type AvailabilityCreateUpdateCardProps = {
  loading: boolean
  availability?: Availability
  backHref: string
}

const AvailabilityCreateUpdateCard = (
  props: AvailabilityCreateUpdateCardProps
) => {
  const auth = useAuth()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const form = useFormContext<CreateOrUpdateAvailabilityRequest>()

  return (
    <UiCard>
      {/* <UiCard.Header
        title={'General'}
        description={t('page.contacts.properties.description')}
        icon={CubeTransparentIcon}
      /> */}
      <UiCard.Body>
        <div className='md:grid md:grid-cols-5 md:divide-x md:divide-gray-200'>
          <div className='md:pr-6 md:col-span-2'>
            <Controller
              name='startDate'
              control={form.control}
              rules={{ required: true }}
              render={({ field: daysField }) => (
                <UiCalendar
                  value={[daysField.value]}
                  onChanged={(value) =>
                    daysField.onChange(value && value.length ? value[0] : null!)
                  }
                  single={true}
                />
              )}
            />
          </div>
          <div className='mt-12 md:mt-0 md:pl-6 md:col-span-3 space-y-6'>
            <div className='divide-y divide-gray-200'>
              <OpeningsDay weekday='monday' />
              <OpeningsDay weekday='tuesday' />
              <OpeningsDay weekday='wednesday' />
              <OpeningsDay weekday='thursday' />
              <OpeningsDay weekday='friday' />
              <OpeningsDay weekday='saturday' />
              <OpeningsDay weekday='sunday' />
            </div>
          </div>
        </div>
      </UiCard.Body>
      <UiCard.Footer>
        <UiButton
          label={t('action.cancel')}
          type='button'
          variant='flat'
          onClick={() => navigate(props.backHref)}
        />
        <UiButton
          label={props.availability ? t('action.save') : t('action.create')}
          type='submit'
          variant={props.availability ? 'dark' : 'primary'}
          loading={props.loading}
          disabled={!form.formState.isDirty}
        />
      </UiCard.Footer>
    </UiCard>
  )
}

type OpeningsDayProps = {
  weekday:
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'
    | 'sunday'
}

const OpeningsDay = (props: OpeningsDayProps) => {
  const auth = useAuth()
  const color = auth?.company?.appearance?.color || 'rose'
  const { t } = useTranslation()
  const form = useFormContext<CreateOrUpdateAvailabilityRequest>()
  const timeIntervalsFieldArray = useFieldArray({
    name: `${props.weekday}`,
  })

  return (
    <Controller
      name={`${props.weekday}`}
      control={form.control}
      rules={{ required: false }}
      render={({ field }) => (
        <div className='flex'>
          <Switch.Group as='div' className='flex items-center h-14 w-36'>
            <Switch
              checked={field.value.length > 0}
              onChange={(e: boolean) => {
                if (e) {
                  timeIntervalsFieldArray.append({
                    startTime: '08:00',
                    endTime: '17:00',
                  })
                } else {
                  timeIntervalsFieldArray.remove()
                }
              }}
              className={classNames(
                field.value.length > 0 ? `bg-${color}-600` : 'bg-gray-200',
                `relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-${color}-500 focus:ring-offset-2`
              )}
            >
              <span
                aria-hidden='true'
                className={classNames(
                  field.value.length > 0 ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              />
            </Switch>
            <Switch.Label as='span' className='ml-4 flex items-center'>
              <span
                className={`text-sm font-medium ${
                  timeIntervalsFieldArray.fields.length === 0
                    ? 'text-gray-500 opacity-50'
                    : 'text-gray-900'
                }`}
              >
                {t(`common.${props.weekday}Short`)}
              </span>
            </Switch.Label>
          </Switch.Group>
          <div className='ml-4 flex flex-col items-start'>
            {timeIntervalsFieldArray.fields.map((field: any, index: number) => (
              <div
                key={field.id}
                className={`flex items-center space-x-3 ${
                  index === 0 ? 'py-2' : 'pb-2'
                }`}
              >
                <UiTimeCombobox
                  label={''}
                  control={form.control}
                  name={`${props.weekday}.${index}.startTime`}
                />
                <div> - </div>
                <UiTimeCombobox
                  label={''}
                  control={form.control}
                  name={`${props.weekday}.${index}.endTime`}
                />
                {index === 0 && (
                  <UiButton
                    type='button'
                    label='Edit'
                    variant='light-gray'
                    icon={PlusSmallIcon}
                    iconOnly={true}
                    onClick={() =>
                      timeIntervalsFieldArray.append({
                        startTime: '08:00',
                        endTime: '17:00',
                      })
                    }
                  />
                )}
                {index !== 0 && (
                  <UiButton
                    type='button'
                    label='Delete'
                    variant='danger'
                    icon={TrashIcon}
                    iconOnly={true}
                    onClick={() => {
                      timeIntervalsFieldArray.remove(index)
                    }}
                  />
                )}
              </div>
            ))}
            {timeIntervalsFieldArray.fields.length === 0 && (
              <div className='h-14 flex items-center'>
                <span className='text-sm text-gray-500 italic opacity-50'>
                  Nicht verfügbar
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    />
  )
}
