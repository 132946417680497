import { useTranslation } from 'react-i18next'
import {
  CubeTransparentIcon,
  FolderIcon,
  PlusSmallIcon,
} from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import Paginated from '@/business/dto/responses/paginated.response'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import { findServiceCategories } from '@/business/api/service.service'
import UiButton from '../ui-kit/actions/UiButton'
import UiAvatar from '../ui-kit/layout/UiAvatar'
import UiPagination from '../ui-kit/presentation/UiPagination'
import UiEmptyState from '../ui-kit/presentation/UiEmptyState'
import UiNoSearchResults from '../ui-kit/presentation/UiNoSearchResults'
import { Link } from 'react-router-dom'
import ServicePopulatedResponse from '@/business/dto/responses/service-populated.response'
import CategoryResponse from '@/business/dto/responses/category.response'

const loadPage = (serviceId: string, page: number) =>
  findServiceCategories(serviceId, { page, limit: 10 })

export type ServiceCategoriesCardProps = {
  service: ServicePopulatedResponse
  setServiceDetails: React.Dispatch<React.SetStateAction<ServicePopulatedResponse>>
  paginatedCategories: Paginated<CategoryResponse>
  setPaginatedCategories: React.Dispatch<
    React.SetStateAction<Paginated<CategoryResponse>>
  >
}

const ServiceCategoriesCard = (props: ServiceCategoriesCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  const reload = (page: number) => {
    loadPage(props.service._id, page)
      .then((response) => {
        props.setPaginatedCategories(response)
        window.scrollTo(0, 0)
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  const handlePageChanged = (page: number) => {
    reload(page)
  }

  return (
    <UiCard>
      <UiCard.Header
        title={`Zugewiesene ${t('common.categories')} (${
          props.paginatedCategories.totalResults
        })`}
        description={t('page.description.serviceCategories')}
        icon={FolderIcon}
      >
        <>
          {(props.paginatedCategories.totalResults > 0 ||
            props.paginatedCategories.search) && (
            <>
              <UiButton
                as={Link}
                label={`${t('common.category')} zuweisen`}
                variant="default"
                icon={PlusSmallIcon}
                to={`/services/${props.service._id}/categories`}
              />
            </>
          )}
        </>
      </UiCard.Header>
      <UiCard.Body>
        <div className="space-y-6 md:space-y-8">
          <ul role="list" className="-my-2">
            {props.paginatedCategories.results.map((category) => (
              <li key={category._id} className="py-2">
                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    <UiAvatar
                      name={category.name}
                      color={category.color}
                      image={category.image}
                      shape="circle"
                      size={10}
                    />
                  </div>
                  <div className="min-w-0 flex-1">
                    <p className="truncate text-sm font-medium text-gray-900">
                      {category.name}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {props.paginatedCategories.totalPages > 1 && (
            <div className="flex justify-center w-full">
              <UiPagination
                page={props.paginatedCategories.page}
                totalPages={props.paginatedCategories.totalPages}
                onPageChanged={handlePageChanged}
              />
            </div>
          )}
          {props.paginatedCategories.totalResults === 0 &&
            !props.paginatedCategories.search && (
              <UiEmptyState
                icon={CubeTransparentIcon}
                title={`Es wurden noch keine ${t(
                  'common.categories'
                )} hinzugefügt`}
                description={`Bei einer ${t(
                  'common.category'
                )} handelt es sich um ...`}
                button={{
                  label: `${t('common.category')} zuweisen`,
                  to: `/services/${props.service._id}/categories`,
                }}
              />
            )}
          {props.paginatedCategories.totalResults === 0 &&
            props.paginatedCategories.search && <UiNoSearchResults />}
        </div>
      </UiCard.Body>
      <UiCard.Footer />
    </UiCard>
  )
}

export default ServiceCategoriesCard
