import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import {
  LoaderFunctionArgs,
  Navigate,
  defer,
  useLoaderData,
  useParams,
} from 'react-router-dom'
import { findUserById } from '@/business/api/user.service'
import { UserUpdateCard } from '@/components/modals/UserCreateUpdateCard'
import { loadAuth } from '@/business/api/auth.service'
import Role from '@/business/dto/types/role'

export const accountEditPageLoader = async ({ params }: LoaderFunctionArgs) => {
  const [user] = await Promise.all([loadAuth()])
  return defer({ user })
}

export const userEditPageLoader = async ({ params }: LoaderFunctionArgs) => {
  const [user] = await Promise.all([findUserById(params.userId!)])
  return defer({ user })
}

export const employeeEditPageLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const [user] = await Promise.all([findUserById(params.employeeId!)])
  return defer({ user })
}

const UserEditPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { userId, employeeId } = useParams()
  const { user } = useLoaderData() as any

  return (
    <>
      {user.role === Role.owner && user._id !== auth.user._id ? (
        <Navigate to={`/users/${user._id}`} replace={true} />
      ) : (
        <UiContainer size='small'>
          <UiHeader
            title={
              userId || employeeId
                ? t('page.title.userEdit')
                : t('page.title.accountEdit')
            }
            description={
              userId || employeeId
                ? t('page.description.userEditX', { x: user.name })
                : t('page.description.accountEdit')
            }
            desktopBackHref={
              userId
                ? `/users/${user._id}`
                : employeeId
                ? `/employees/${user._id}`
                : '/account'
            }
            mobileBackHref={
              userId
                ? `/users/${user._id}`
                : employeeId
                ? `/employees/${user._id}`
                : '/account'
            }
          />
          <div className='py-6 md:py-8'>
            <div className='space-y-6'>
              <UserUpdateCard
                user={user}
                userId={userId}
                employeeId={employeeId}
              />
            </div>
          </div>
        </UiContainer>
      )}
    </>
  )
}

export default UserEditPage
