import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import React, { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import UiInput from '../ui-kit/input/basic/UiInput'
import { useNavigate } from 'react-router-dom'
import UiComboboxLazy from '../ui-kit/input/basic/UiComboboxLazy'
import ReservationType from '@/business/dto/types/reservation-type'
import UiAvatar from '../ui-kit/layout/UiAvatar'
import Paginated from '@/business/dto/responses/paginated.response'
import {
  CalendarDaysIcon,
  CubeTransparentIcon,
  FaceSmileIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'
import UiCalendar from '../ui-kit/input/UiCalendar'
import ServiceDayAvailabilityResponse from '@/business/dto/responses/service-day-availability.response'
import {
  findAllServices,
  findDaysAvailabilities,
  findServiceResources,
  findSlotAvailabilities,
} from '@/business/api/service.service'
import { endOfMonth, set, startOfMonth } from 'date-fns'
import { format, toDate, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { getCurrentFnsLocale } from '@/business/date-utils'
import ServiceSlotAvailabilityResponse from '@/business/dto/responses/service-slot-availability.response'
import { findAllContacts } from '@/business/api/contact.service'
import ContactResponse from '@/business/dto/responses/contact.response'
import ServiceResponse from '@/business/dto/responses/service.response'
import ResourceResponse from '@/business/dto/responses/resource.response'
import { createReservation } from '@/business/api/reservation.service'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'

type CreateReservationRequestTemp = {
  type: ReservationType
  date: string
  time: string
  persons: number
  service: ServiceResponse
  duration: number
  resource?: ResourceResponse
  contact?: ContactResponse
  name: string
  email: string
}

const mapToFormValue = (
  service: ServiceResponse
): CreateReservationRequestTemp => {
  return {
    type: ReservationType.default,
    date: null!,
    time: null!,
    persons: 1,
    service: service,
    duration: 0,
    resource: null!,
    contact: null!,
    name: '',
    email: '',
  }
}

type ReservationCreateCardProps = {
  servicePaginated: Paginated<ServiceResponse>
}

export const ReservationCreateCard = (props: ReservationCreateCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<CreateReservationRequestTemp>({
    defaultValues: mapToFormValue(
      props.servicePaginated && props.servicePaginated.totalResults > 0
        ? props.servicePaginated.results[0]
        : null!
    ),
  })
  const selectedService = form.watch('service')
  const selectedPersons = form.watch('persons')
  const selectedResource = form.watch('resource')
  const selectedContact = form.watch('contact')
  const selectedDate = form.watch('date')
  const selectedTime = form.watch('time')

  const [servicePaginated, setServicePaginated] = useState<
    Paginated<ServiceResponse>
  >(props.servicePaginated)
  const [resourcePaginated, setResourcePaginated] =
    useState<Paginated<ResourceResponse>>()
  const [availableDays, setAvailableDays] =
    useState<ServiceDayAvailabilityResponse[]>()
  const [availableSlots, setAvailableSlots] =
    useState<ServiceSlotAvailabilityResponse[]>()
  const [contactPaginated, setContactPaginated] =
    useState<Paginated<ContactResponse>>()

  const onSubmit: SubmitHandler<CreateReservationRequestTemp> = (data) => {
    console.log('data:', data)
    const { contact, service, resource, date, time, ...other } = data

    setLoading(true)
    createReservation({
      contactId: undefined,
      serviceId: service?._id,
      resourceId: resource?._id || undefined,
      startDateTimeInUtc: zonedTimeToUtc(
        set(
          utcToZonedTime(
            toDate(date, {
              timeZone: auth.company.localization.timeZone,
            }),
            auth.company.localization.timeZone
          ),
          {
            hours: parseInt(time.split(':')[0]),
            minutes: parseInt(time.split(':')[1]),
            seconds: 0,
            milliseconds: 0,
          }
        ),
        auth.company.localization.timeZone
      ),
      ...other,
    })
      .then((response) => {
        navigate(`/reservations`)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.created')}
            description={t('toast.description.reservationCreated')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        handleValidationError(err, i18n)
      })
  }

  useEffect(() => {
    reloadContacts('')
  }, [])

  useEffect(() => {
    const nowInUtc = new Date()

    if (selectedService) {
      reloadDayAvailabilities(
        selectedService,
        format(
          startOfMonth(
            utcToZonedTime(nowInUtc, auth.company.localization.timeZone)
          ),
          'yyyy-MM-dd',
          {
            locale: getCurrentFnsLocale(),
            timeZone: auth.company.localization.timeZone,
            weekStartsOn: auth.company.localization.weekStartsOn,
          }
        ),
        format(
          endOfMonth(
            utcToZonedTime(nowInUtc, auth.company.localization.timeZone)
          ),
          'yyyy-MM-dd',
          {
            locale: getCurrentFnsLocale(),
            timeZone: auth.company.localization.timeZone,
            weekStartsOn: auth.company.localization.weekStartsOn,
          }
        ),
        undefined
      )
      reloadResources(selectedService, '')
      form.setValue('resource', undefined)
      form.setValue('duration', selectedService.duration)
    }
  }, [selectedService])

  useEffect(() => {
    if (selectedDate && selectedService) {
      reloadSlotAvailabilities(selectedService, selectedDate, selectedResource)
    }
  }, [selectedResource])

  useEffect(() => {
    if (selectedDate && selectedService) {
      reloadSlotAvailabilities(selectedService, selectedDate, selectedResource)
    }
  }, [selectedDate])

  const reloadServices = (search: string) => {
    findAllServices({ page: 1, limit: 50, search })
      .then((response) => {
        setServicePaginated(response)
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  const reloadResources = (service: ServiceResponse, search: string) => {
    findServiceResources(service._id, { page: 1, limit: 50, search })
      .then((response) => {
        setResourcePaginated(response)
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  const reloadDayAvailabilities = (
    service: ServiceResponse,
    startDate: string,
    endDate: string,
    resource?: ResourceResponse
  ) => {
    findDaysAvailabilities(service._id, {
      startDate,
      endDate,
      resourceId: resource?._id,
    })
      .then((response) => {
        setAvailableDays(response)
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  const reloadSlotAvailabilities = (
    service: ServiceResponse,
    date: string,
    resource?: ResourceResponse
  ) => {
    findSlotAvailabilities(service._id, {
      date,
      resourceId: resource?._id,
    })
      .then((response) => {
        setAvailableSlots(response)
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  const reloadContacts = (search: string) => {
    findAllContacts({ page: 1, limit: 50, search })
      .then((response) => {
        setContactPaginated(response)
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className='space-y-6'>
        <Timeline>
          <TimelineItem
            icon={FaceSmileIcon}
            iconBackground='bg-gray-700'
            title='Service'
            description='Wähle einen Service aus.'
            end={false}
          >
            <UiCard>
              <UiCard.Body>
                <div className='grid grid-cols-12 gap-6 max-w-md'>
                  <div className='col-span-12'>
                    <UiComboboxLazy
                      control={form.control}
                      name='service'
                      label={'Service'}
                      onSearchChanged={reloadServices}
                      paginated={servicePaginated}
                      view={(service) => (
                        <div className='flex items-center space-x-4'>
                          <div className='flex-shrink-0'>
                            <UiAvatar
                              name={service.name}
                              color={service.color}
                              image={service.image}
                              shape='circle'
                              size={10}
                            />
                          </div>
                          <div className='min-w-0 flex-1'>
                            <p className='truncate text-sm font-medium text-gray-900'>
                              {service.name}
                            </p>
                            <p className='truncate text-sm text-gray-500'>
                              {service.duration} {t('common.minutes')},{' '}
                              {service.price}
                            </p>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                  <div className='col-span-6'>
                    <UiInput
                      label='Dauer'
                      type='number'
                      placeholder='Eigentlich übernommen vom Service'
                      name='duration'
                      control={form.control}
                      rules={{
                        required: {
                          value: true,
                          message: t('form.validation.required'),
                        },
                      }}
                    />
                  </div>
                  <div className='col-span-6'>
                    <UiInput
                      label='Preis'
                      type='number'
                      placeholder='Eigentlich übernommen vom Service'
                      name='duration'
                      control={form.control}
                      rules={{
                        required: {
                          value: true,
                          message: t('form.validation.required'),
                        },
                      }}
                    />
                  </div>
                </div>
              </UiCard.Body>
              <UiCard.Footer />
            </UiCard>
          </TimelineItem>
          <TimelineItem
            icon={UserGroupIcon}
            iconBackground='bg-gray-700'
            title='Personen'
            description='Wähle die Anzahl der Personen aus.'
            end={false}
          >
            <UiCard>
              <UiCard.Body>
                <div className='max-w-md'>
                  <div className='grid grid-cols-6 gap-2 text-sm'>
                    {[1, 2, 3, 4].map((persons) => (
                      <button
                        type='button'
                        key={persons}
                        className={`relative flex w-full items-center space-x-4 rounded-full py-3 px-4 focus:outline-none ${
                          persons === selectedPersons
                            ? `bg-${auth.company.appearance.color}-600 text-white`
                            : 'bg-gray-100 hover:bg-gray-200'
                        }`}
                        onClick={() => {
                          form.setValue('persons', persons)
                        }}
                      >
                        <time className='w-full text-center text-sm'>
                          {persons}
                        </time>
                      </button>
                    ))}
                  </div>
                </div>
              </UiCard.Body>
              <UiCard.Footer />
            </UiCard>
          </TimelineItem>
          <TimelineItem
            icon={CubeTransparentIcon}
            iconBackground='bg-gray-700'
            title='Ressource'
            description='Wähle eine Ressource aus.'
            end={false}
          >
            <UiCard>
              <UiCard.Body>
                <div className='grid grid-cols-12 gap-6 max-w-md'>
                  <div className='col-span-12'>
                    <UiComboboxLazy
                      control={form.control}
                      name='resource'
                      label={'Resource'}
                      onSearchChanged={(search) => {
                        if (selectedService) {
                          reloadResources(selectedService, search)
                        }
                      }}
                      paginated={resourcePaginated}
                      view={(resource) => (
                        <div className='flex items-center space-x-4'>
                          <div className='flex-shrink-0'>
                            <UiAvatar
                              name={resource.name}
                              color={resource.color}
                              image={resource.image}
                              shape='circle'
                              size={10}
                            />
                          </div>
                          <div className='min-w-0 flex-1'>
                            <p className='truncate text-sm font-medium text-gray-900'>
                              {resource.name}
                            </p>
                            <p className='truncate text-sm text-gray-500'>
                              {resource.capacity} Person(en)
                            </p>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </UiCard.Body>
              <UiCard.Footer />
            </UiCard>
          </TimelineItem>
          <TimelineItem
            icon={CalendarDaysIcon}
            iconBackground='bg-gray-700'
            title='Datum & Uhrzeit'
            description='Wähle ein Datum und eine Uhrzeit aus.'
            end={false}
          >
            <UiCard>
              <UiCard.Body>
                <div className='grid grid-cols-12 gap-6'>
                  <div className='col-span-6'>
                    <Controller
                      name='date'
                      control={form.control}
                      rules={{
                        required: {
                          value: true,
                          message: t('form.validation.required'),
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <>
                          <UiCalendar
                            value={[field.value]}
                            onChanged={(e) => {
                              field.onChange(e[0])
                            }}
                            single={true}
                            disabledDates={availableDays
                              ?.filter((x) => !x.available)
                              .map((day) => day.date)}
                          />
                          {fieldState.error && (
                            <span
                              id={`${field.name}-error`}
                              role='alert'
                              className='mt-2 text-xs text-red-600'
                            >
                              {fieldState.error.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div className='col-span-6'>
                    {availableSlots && (
                      <div className='mx-auto max-w-md space-y-6'>
                        <div className='grid grid-cols-4 gap-2 text-sm'>
                          {availableSlots
                            ?.filter((availableSlot) => availableSlot.available)
                            .map((slotAvailability) => (
                              <button
                                type='button'
                                key={slotAvailability.dateTime.toString()}
                                className={`relative flex w-full items-center space-x-4 rounded-full py-3 px-4 focus:outline-none ${
                                  slotAvailability.time === selectedTime
                                    ? `bg-${auth.company.appearance.color}-600 text-white`
                                    : 'bg-gray-100 hover:bg-gray-200'
                                }`}
                                onClick={() => {
                                  form.setValue('time', slotAvailability.time)
                                }}
                              >
                                <time
                                  className='w-full text-center text-sm'
                                  dateTime={slotAvailability.dateTime}
                                >
                                  {slotAvailability.time}
                                </time>
                              </button>
                            ))}
                        </div>
                        {availableSlots.length === 0 && (
                          <>Keinen freien Slot.</>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </UiCard.Body>
              <UiCard.Footer />
            </UiCard>
          </TimelineItem>
          <TimelineItem
            icon={UserGroupIcon}
            iconBackground='bg-gray-700'
            title='Kontakt'
            description='Wähle einen Kontakt aus.'
            end={true}
          >
            <UiCard>
              <UiCard.Body>
                <div className='grid grid-cols-12 gap-6 max-w-md'>
                  <div className='col-span-12'>
                    <UiComboboxLazy
                      control={form.control}
                      name='contact'
                      label={'Kontakt'}
                      onSearchChanged={reloadContacts}
                      paginated={contactPaginated}
                      view={(contact) => (
                        <div className='flex items-center space-x-4'>
                          <div className='flex-shrink-0'>
                            <UiAvatar
                              name={contact.name}
                              color={contact.color}
                              image={contact.image}
                              shape='circle'
                              size={10}
                            />
                          </div>
                          <div className='min-w-0 flex-1'>
                            <p className='truncate text-sm font-medium text-gray-900'>
                              {contact.name}
                            </p>
                            <p className='truncate text-sm text-gray-500'>
                              {contact.email}
                            </p>
                          </div>
                        </div>
                      )}
                      onChange={(value) => {
                        console.log('onChange', value)
                      }}
                      createEmptyState={true}
                    />
                  </div>
                  <div className='col-span-12'>
                    <UiInput
                      label='Name'
                      type='text'
                      placeholder=''
                      name='name'
                      control={form.control}
                      rules={{
                        required: {
                          value: true,
                          message: t('form.validation.required'),
                        },
                        minLength: {
                          value: 3,
                          message: t('form.validation.minLength', { x: 3 }),
                        },
                        maxLength: {
                          value: 32,
                          message: t('form.validation.maxLength', { x: 32 }),
                        },
                      }}
                      disabled={
                        selectedContact == null || selectedContact._id != null
                      }
                    />
                  </div>
                  <div className='col-span-12'>
                    <UiInput
                      label='E-Mail'
                      type='text'
                      placeholder=''
                      name='email'
                      control={form.control}
                      rules={{
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: t('form.validation.email'),
                        },
                        maxLength: {
                          value: 64,
                          message: t('form.validation.maxLength', { x: 64 }),
                        },
                      }}
                      disabled={
                        selectedContact == null || selectedContact._id != null
                      }
                    />
                  </div>
                  {false && (
                    <div className='col-span-12'>
                      <UiInput
                        label={t('common.phoneNumber')}
                        type='tel'
                        name='email'
                        placeholder={t('form.placeholder.companyPhone')!}
                        control={form.control}
                        rules={{
                          maxLength: {
                            value: 64,
                            message: t('form.validation.maxLength', { x: 64 }),
                          },
                        }}
                      />
                    </div>
                  )}
                </div>
              </UiCard.Body>
              <UiCard.Footer />
            </UiCard>
          </TimelineItem>
        </Timeline>
        <div className='flex items-center justify-end gap-3'>
          <UiButton
            label={t('action.cancel')}
            type='button'
            variant='flat'
            onClick={() => navigate('/reservations')}
          />
          <UiButton
            label={t('action.create')}
            type='submit'
            variant='primary'
            loading={loading}
            disabled={!form.formState.isDirty}
          />
        </div>
      </div>
    </form>
  )
}

type TimelineProps = {
  children: React.ReactNode
}

const Timeline = (props: TimelineProps) => {
  return (
    <div className='flow-root'>
      <ul role='list' className='-mb-8'>
        {props.children}
      </ul>
    </div>
  )
}

type TimelineItemProps = {
  icon: React.ComponentType<any>
  iconBackground: string
  title: string
  description: string
  end: boolean
  children: React.ReactNode
}

const TimelineItem = (props: TimelineItemProps) => {
  return (
    <li>
      <div className='relative pb-8'>
        {!props.end ? (
          <span
            className='absolute left-5 top-4 -ml-px h-full w-0.5 bg-gray-200'
            aria-hidden='true'
          />
        ) : null}
        <div className='relative flex space-x-4'>
          <div>
            <span className='flex-shrink-0 flex items-center justify-center p-2 my-1 rounded-full bg-gray-700'>
              <props.icon className='h-6 w-6 text-white' aria-hidden='true' />
            </span>
          </div>
          <div className='w-full space-y-6'>
            <div className=''>
              <h3 className='text-base font-semibold leading-6 text-gray-900'>
                {props.title}
              </h3>
              <p className='mt-1 text-sm text-gray-500'>{props.description}</p>
            </div>
            <div className='w-full'>{props.children}</div>
          </div>
        </div>
      </div>
    </li>
  )
}
