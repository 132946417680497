import {
  EnvelopeIcon,
  KeyIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { SubmitHandler, ValidateResult, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { signUp } from '@/business/api/auth.service'
import { calcColor } from '@/business/api/image.service'
import SignUpRequest from '@/business/dto/requests/sign-up.request'
import Color from '@/business/dto/types/color'
import { handleValidationError } from '@/business/error-handler'
import { updatePageTitle } from '@/business/utils'
import UiInput from '@/components/ui-kit/input/basic/UiInput'
import { match } from '@formatjs/intl-localematcher'
import i18n from '@/i18n'
import Language from '@/business/dto/types/language'
import TimeZone from '@/business/dto/types/time-zone'
import {
  getCountryByZimezone,
  getWeekStartsOnByCountry,
} from '@/business/localization-utils'
import PublicLayout from '@/components/PublicLayout'

const SignUpPage = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const form = useForm<SignUpRequest>({
    defaultValues: {
      name: searchParams.get('name') || (location.state as any)?.name || '',
      email: searchParams.get('email') || (location.state as any)?.email || '',
      password: '',
      passwordConfirmation: '',
      color: Color.gray,
    },
  })
  const [formName, formEmail, formPassword, formPasswordConfirmation] =
    form.watch(['name', 'email', 'password', 'passwordConfirmation'])

  useEffect(() => {
    updatePageTitle(t('page.title.signUp'))
  }, [])

  const onSubmit: SubmitHandler<SignUpRequest> = (data) => {
    setLoading(true)

    const language = match(
      [i18n.language],
      Object.values(Language),
      'en'
    ) as Language

    const timezone = Intl.DateTimeFormat().resolvedOptions()
      .timeZone as TimeZone

    const country = getCountryByZimezone(timezone)

    signUp({
      ...data,
      color: calcColor(form.watch('name')),
      localization: {
        language,
        timeZone: timezone,
        weekStartsOn: getWeekStartsOnByCountry(country),
      },
      country,
    })
      .then((_) => {
        navigate({
          pathname: '/verify',
          search: `?email=${encodeURIComponent(formEmail)}`,
        })
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-6 w-auto"
          src="/img/resflow-logo.svg"
          alt={t('common.resflowLogo')!}
        />
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
          {t('page.title.signUp')}
        </h2>
        <p className="mt-2 text-center text-base text-gray-600">
          {t('page.description.signUp')}
        </p>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-6 shadow rounded-lg py-8 sm:px-10">
          <form className="space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
            <UiInput
              label={t('common.name')}
              type="text"
              icon={UserCircleIcon}
              placeholder={t('form.placeholder.name')!}
              autoComplete="name"
              name="name"
              autoFocus
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: t('form.validation.required'),
                },
                maxLength: {
                  value: 32,
                  message: t('form.validation.maxLength', { x: 32 }),
                },
              }}
            />
            <UiInput
              label={t('common.emailAddress')}
              type="text"
              icon={EnvelopeIcon}
              placeholder={t('form.placeholder.emailAddress')!}
              autoComplete="email"
              name="email"
              hint={t('form.hint.noSpam')}
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: t('form.validation.required'),
                },
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: t('form.validation.emailAddress'),
                },
                maxLength: {
                  value: 64,
                  message: t('form.validation.maxLength', { x: 64 }),
                },
              }}
            />
            <UiInput
              label={t('common.password')}
              type="password"
              icon={KeyIcon}
              placeholder={t('form.placeholder.password')!}
              autoComplete="password"
              name="password"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: t('form.validation.required'),
                },
                minLength: {
                  value: 8,
                  message: t('form.validation.minLength', { x: 8 }),
                },
                maxLength: {
                  value: 64,
                  message: t('form.validation.maxLength', { x: 64 }),
                },
              }}
            />
            <UiInput
              label={t('common.passwordConfirmation')}
              type="password"
              icon={KeyIcon}
              placeholder={t('form.placeholder.password')!}
              autoComplete="password"
              name="passwordConfirmation"
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: t('form.validation.required'),
                },
                validate: (value: string | undefined): ValidateResult => {
                  return (
                    value === formPassword ||
                    t('form.validation.passwordsDoNotMatch')!
                  )
                },
              }}
            />
            <p className="mt-2 text-xs text-gray-500">
              <Trans
                i18nKey="form.description.privacyAndTerms"
                components={{
                  privacyUrl: (
                    <a
                      className="font-medium text-rose-600 hover:text-rose-700"
                      href={`https://resflow.com/${i18n.language}/${t(
                        'href.terms'
                      )}/`}
                      target="_blank"
                      rel="noopener"
                    />
                  ),
                  termsUrl: (
                    <a
                      className="font-medium text-rose-600 hover:text-rose-700"
                      href={`https://resflow.com/${i18n.language}/${t(
                        'href.privacy'
                      )}/`}
                      target="_blank"
                      rel="noopener"
                    />
                  ),
                }}
              />
            </p>
            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-full border border-transparent bg-rose-600 disabled:bg-rose-600/50 py-2 px-4 text-sm font-medium leading-6 text-white shadow-sm hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
                disabled={loading}
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-rose-400 group-hover:text-rose-500 group-disabled:text-rose-400/50"
                    aria-hidden="true"
                  />
                </span>
                {loading && (
                  <div className="-ml-1 mr-3">
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                )}
                {t('action.signUp')}
              </button>
            </div>
          </form>
          <div className="mt-6 text-center">
            <p className="text-xs text-gray-600">
              <span>{t('description.alreadyHaveAnAccount')} </span>
              <Link
                to="/sign-in"
                state={{
                  name: formName,
                  email: formEmail,
                }}
                className="font-medium text-rose-600 hover:text-rose-700"
              >
                {t('action.goToSignIn')}
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUpPage
