import { Outlet, defer, useLoaderData, useNavigate } from 'react-router-dom'
import AuthContext from '../contexts/auth-context'
import { loadAuth } from '@/business/api/auth.service'
import i18n from '../i18n'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import UiToast from './ui-kit/presentation/UiToast'
import { loadCompany } from '@/business/api/company.service'
import UserResponse from '@/business/dto/responses/user.response'
import CompanyResponse from '@/business/dto/responses/company.response'

export const authLoader = async () => {
  const [user, company] = await Promise.all([loadAuth(), loadCompany()])

  if (company && company.localization.language !== i18n.language) {
    i18n.changeLanguage(company.localization.language)
  }

  return defer({ user, company })
}

const AuthProvider = () => {
  const loaderData = useLoaderData() as {
    user: UserResponse
    company: CompanyResponse
  }
  const [user, setUser] = useState<UserResponse>(loaderData.user)
  const [company, setCompany] = useState<CompanyResponse>(loaderData.company)
  const navigate = useNavigate()

  const impersonated =
    !!localStorage.getItem('resflow_staff_access_token') &&
    !!localStorage.getItem('resflow_staff_refresh_token')

  const onSignOut = () => {
    const resflowStaffTokenType = localStorage.getItem(
      'resflow_staff_token_type'
    )
    const resflowStaffAccessToken = localStorage.getItem(
      'resflow_staff_access_token'
    )
    const resflowStaffrefreshToken = localStorage.getItem(
      'resflow_staff_refresh_token'
    )

    localStorage.removeItem('resflow_staff_token_type')
    localStorage.removeItem('resflow_staff_access_token')
    localStorage.removeItem('resflow_staff_refresh_token')

    if (
      resflowStaffTokenType &&
      resflowStaffAccessToken &&
      resflowStaffrefreshToken
    ) {
      localStorage.setItem('token_type', resflowStaffTokenType)
      localStorage.setItem('access_token', resflowStaffAccessToken)
      localStorage.setItem('refresh_token', resflowStaffrefreshToken)
    } else {
      localStorage.removeItem('token_type')
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
    }

    navigate('/sign-in', { replace: true })
    toast.custom((t) => (
      <UiToast
        toast={t}
        type="success"
        title={i18n.t('toast.title.signedOut')}
        description={i18n.t('toast.description.signedOut')}
      />
    ))
  }

  useEffect(() => {
    if (company && company.localization.language !== i18n.language) {
      i18n.changeLanguage(company.localization.language)
    }
  }, [company])

  return (
    <>
      <AuthContext.Provider
        value={{
          user,
          setUser,
          company,
          setCompany,
          signOut: onSignOut,
          impersonated,
        }}
      >
        <Outlet />
      </AuthContext.Provider>
    </>
  )
}

export default AuthProvider
