import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useState } from 'react'
import UpdateCompanyRequest from '@/business/dto/requests/update-company.request'
import { updateCompany } from '@/business/api/company.service'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import UiLanguageCombobox from '../ui-kit/input/UiLanguageCombobox'
import UiTimezoneCombobox from '../ui-kit/input/UiTimezoneCombobox'
import UiCombobox from '../ui-kit/input/basic/UiCombobox'
import { GlobeEuropeAfricaIcon } from '@heroicons/react/24/outline'
import CompanyPopulatedResponse from '@/business/dto/responses/company-populated.response'

const mapToFormValue = (
  company: CompanyPopulatedResponse
): UpdateCompanyRequest => {
  return {
    localization: company.localization,
  }
}

export type CompanyLanguageCardProps = {
  company: CompanyPopulatedResponse
  setCompany: React.Dispatch<React.SetStateAction<CompanyPopulatedResponse>>
}

const CompanyLanguageCard = (props: CompanyLanguageCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<UpdateCompanyRequest>({
    defaultValues: mapToFormValue(props.company),
  })

  const onSubmit: SubmitHandler<UpdateCompanyRequest> = (data) => {
    setLoading(true)
    updateCompany(data)
      .then((response) => {
        props.setCompany(response)
        form.reset(mapToFormValue(response))
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.updated')}
            description={t('toast.description.companyUpdated')}
          />
        ))
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
      .finally(() => setLoading(false))
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <UiCard>
        <UiCard.Header
          title={t('common.languageAndRegion')}
          description={t('card.description.companyLanguageAndRegion')}
          icon={GlobeEuropeAfricaIcon}
        />
        <UiCard.Body>
          <div className='grid grid-cols-12 gap-6'>
            <div className='col-span-12 sm:col-span-6 space-y-6'>
              <UiLanguageCombobox
                label={t('common.language')}
                control={form.control}
                name='localization.language'
              />
              <hr className='border-gray-200' />
              {/* <UiToggle
                label="24-hour time"
                name="notifications.sendFollowUp"
                control={form.control}
              /> */}
              <UiTimezoneCombobox
                label={t('common.timeZone')}
                control={form.control}
                name='localization.timeZone'
              />
              <UiCombobox
                label={t('common.startOfTheWeek')}
                options={[
                  { value: 0, label: t('common.sunday') },
                  { value: 1, label: t('common.monday') },
                  { value: 2, label: t('common.tuesday') },
                  { value: 3, label: t('common.wednesday') },
                  { value: 4, label: t('common.thursday') },
                  { value: 5, label: t('common.friday') },
                  { value: 6, label: t('common.saturday') },
                ]}
                control={form.control}
                rules={{
                  required: {
                    value: true,
                    message: t('validation.required'),
                  },
                }}
                name='localization.weekStartsOn'
              />
            </div>
            <div className='hidden sm:block sm:col-span-6'>
              <UiCard.Image src='/img/rose/undraw_text_field_htlv.svg' />
            </div>
          </div>
        </UiCard.Body>
        <UiCard.Footer>
          <UiButton
            label={t('action.cancel')}
            variant='flat'
            type='button'
            disabled={!form.formState.isDirty}
            onClick={() => form.reset(mapToFormValue(props.company))}
          />
          <UiButton
            label={t('action.save')}
            variant='dark'
            type='submit'
            loading={loading}
            disabled={!form.formState.isDirty}
          />
        </UiCard.Footer>
      </UiCard>
    </form>
  )
}

export default CompanyLanguageCard
