import { Fragment, useState } from 'react'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import UiSearch from '@/components/ui-kit/input/UiSearch'
import {
  Link,
  LoaderFunctionArgs,
  defer,
  useLoaderData,
} from 'react-router-dom'
import {
  ArrowsPointingOutIcon,
  CubeTransparentIcon,
  FaceSmileIcon,
  PlusSmallIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import UiAvatar from '@/components/ui-kit/layout/UiAvatar'
import {
  findAllResources,
  updateResourceById,
} from '@/business/api/resource.service'
import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import Paginated from '@/business/dto/responses/paginated.response'
import UiButton from '@/components/ui-kit/actions/UiButton'
import classNames from 'classnames'
import UiBadge from '@/components/ui-kit/presentation/UiBadge'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { toast } from 'react-hot-toast'
import UiToast from '@/components/ui-kit/presentation/UiToast'
import UiPagination from '@/components/ui-kit/presentation/UiPagination'
import UiEmptyState from '@/components/ui-kit/presentation/UiEmptyState'
import UiNoSearchResults from '@/components/ui-kit/presentation/UiNoSearchResults'
import ResourceResponse from '@/business/dto/responses/resource.response'

const loadPage = (page: number, search?: string) =>
  findAllResources({ page, limit: 10, search })

export const resourcesPageLoader = async ({ params }: LoaderFunctionArgs) => {
  const [resources] = await Promise.all([loadPage(1)])

  return defer({
    resources,
  })
}

const ResourcesPage = () => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const loaderData = useLoaderData() as any
  const [paginated, setPaginated] = useState<Paginated<ResourceResponse>>(
    loaderData.resources
  )
  const [search, setSearch] = useState('')
  const [loadingTimeout, setLoadingTimeout] = useState<NodeJS.Timeout | null>(
    null
  )

  const reload = (page: number, search: string) => {
    loadPage(page, search)
      .then((response) => {
        setPaginated(response)
        setLoadingTimeout(null)
        window.scrollTo(0, 0)
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  const handleSearchChanged = (search: string) => {
    setSearch(search)

    if (loadingTimeout) {
      clearTimeout(loadingTimeout)
    }

    setLoadingTimeout(setTimeout(() => reload(1, search), 200))
  }

  const handlePageChanged = (page: number) => {
    reload(page, search)
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 2,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 2,
      },
    })
  )

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active && over && active.id !== over.id) {
      const index = paginated.results.findIndex((x) => x._id === active.id)
      const overIndex = paginated.results.findIndex((x) => x._id === over.id)

      if (index !== -1 && overIndex !== -1) {
        setPaginated((paginated) => {
          return {
            ...paginated,
            results: arrayMove(paginated.results, index, overIndex),
          }
        })

        updateResourceById(active.id.toString(), {
          position: paginated.results[overIndex]?.position,
        })
          .then(() => {
            toast.custom((toast) => (
              <UiToast
                toast={toast}
                type="success"
                title={t('toast.title.updated')}
                description={t('toast.description.positionUpdated')}
              />
            ))
          })
          .catch((err: AxiosError) => {
            setPaginated((paginated) => {
              return {
                ...paginated,
                results: arrayMove(paginated.results, overIndex, index),
              }
            })
            handleValidationError(err, i18n)
          })
      }
    }
  }

  return (
    <>
      <UiContainer>
        <UiHeader
          title={`${t('common.resources')} (${paginated.totalResults})`}
          description={t('page.description.resources')}
          mobileBackHref="/settings"
          mobileAction={{ icon: PlusSmallIcon, to: '/resources/new' }}
          desktopActions={
            <>
              {(paginated.totalResults > 0 || paginated.search) && (
                <UiButton
                  as={Link}
                  variant="primary"
                  label={t('action.newResource')}
                  icon={PlusSmallIcon}
                  type="button"
                  to="/resources/new"
                />
              )}
            </>
          }
          search={
            <>
              {(paginated.totalResults > 0 || paginated.search) && (
                <UiSearch
                  value={search}
                  onChanged={(e) => handleSearchChanged(e.target.value)}
                  placeholder={t('form.placeholder.searchResource')}
                  loadingTimeout={loadingTimeout}
                />
              )}
            </>
          }
        />
        <div className="py-6 space-y-6 md:py-8 md:space-y-8">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={paginated.results.map((x) => {
                return { id: x._id, ...x }
              })}
              strategy={rectSortingStrategy}
            >
              <ul
                role="list"
                className="grid grid-cols-1 gap-2 md:gap-6 sm:grid-cols-2 lg:grid-cols-3"
              >
                {paginated.results.map((resource) => (
                  <SortableResource resource={resource} key={resource._id} />
                ))}
              </ul>
            </SortableContext>
          </DndContext>
          {paginated.totalResults > 0 && (
            <p className="flex items-center text-xs text-gray-500">
              <ArrowsPointingOutIcon className="mx-1 w-4 h-4 text-gray-600" />
              {t('hint.description.resourcesDragAndDrop')}
            </p>
          )}
          {paginated.totalPages > 1 && (
            <div className="flex justify-center">
              <UiPagination
                page={paginated.page}
                totalPages={paginated.totalPages}
                onPageChanged={handlePageChanged}
              />
            </div>
          )}
          {paginated.totalResults === 0 && !paginated.search && (
            <UiEmptyState
              icon={CubeTransparentIcon}
              title={t('hint.title.resourceEmptyState')}
              description={t('hint.description.resourceEmptyState')}
              button={{
                label: t('action.newResource'),
                to: '/resources/new',
              }}
            />
          )}
          {paginated.totalResults === 0 && paginated.search && (
            <UiNoSearchResults />
          )}
        </div>
      </UiContainer>
    </>
  )
}

const SortableResource = (props: { resource: ResourceResponse }) => {
  const auth = useAuth()
  const { t } = useTranslation()
  const sortable = useSortable({ id: props.resource._id })
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = sortable

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={classNames(
        'relative col-span-1 bg-white shadow rounded-lg hover:bg-gray-50',
        isDragging && 'z-50'
      )}
      // onContextMenu={(e) => {
      //   e.preventDefault() // prevent the default behaviour when right clicked
      //   console.log('Right Click')
      // }}
    >
      <div className="flex items-center space-x-4 p-4 sm:px-6">
        <div className="flex-shrink-0">
          <UiAvatar
            name={props.resource.name}
            color={props.resource.color}
            image={props.resource.image}
            shape="circle"
            size={14}
          />
        </div>
        <div className="min-w-0 flex-1 overflow-hidden">
          <p className="truncate text-sm font-semibold text-gray-700">
            {props.resource.name}
          </p>
          {props.resource.description && (
            <p className="truncate text-sm text-gray-500">
              {props.resource.description}
            </p>
          )}
          {!props.resource.description && (
            <p className="truncate text-sm text-gray-500 italic">
              {t('common.noDescription')}
            </p>
          )}
          <ul className="mt-2 flex flex-wrap gap-1">
            <li>
              <UiBadge
                icon={UserIcon}
                label={t('common.countCapacity', {
                  count: props.resource.capacity,
                })}
              />
            </li>
            <li>
              <UiBadge
                icon={FaceSmileIcon}
                label={t('common.countServices', {
                  count: props.resource.serviceIds?.length || 0,
                })}
              />
            </li>
          </ul>
        </div>
      </div>
      {!isDragging && (
        <Link
          to={`/resources/${props.resource._id}`}
          className="absolute top-0 right-0 bottom-0 left-0"
        />
      )}
    </li>
  )
}

export default ResourcesPage
