const countryStuff: {
  country: string
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6
}[] = [
  { country: 'IN', weekStartsOn: 0 },
  { country: 'NO', weekStartsOn: 1 },
  { country: 'DK', weekStartsOn: 1 },
  { country: 'DO', weekStartsOn: 0 },
  { country: 'NZ', weekStartsOn: 1 },
  { country: 'KP', weekStartsOn: 0 },
  { country: 'KG', weekStartsOn: 1 },
  // { country: 'B symbol: '' },
  { country: 'TG', weekStartsOn: 1 },
  { country: 'MX', weekStartsOn: 0 },
  { country: 'PT', weekStartsOn: 1 },
  { country: 'TN', weekStartsOn: 1 },
  { country: 'SX', weekStartsOn: 1 },
  { country: 'TM', weekStartsOn: 1 },
  { country: 'MP', weekStartsOn: 0 },
  { country: 'AD', weekStartsOn: 1 },
  { country: 'BR', weekStartsOn: 0 },
  { country: 'MD', weekStartsOn: 1 },
  { country: 'MY', weekStartsOn: 1 },
  { country: 'GL', weekStartsOn: 1 },
  { country: 'KR', weekStartsOn: 0 },
  { country: 'LK', weekStartsOn: 0 },
  { country: 'PS', weekStartsOn: 0 },
  { country: 'IE', weekStartsOn: 1 },
  { country: 'UY', weekStartsOn: 0 },
  { country: 'NE', weekStartsOn: 1 },
  { country: 'TJ', weekStartsOn: 1 },
  { country: 'IT', weekStartsOn: 1 },
  { country: 'GF', weekStartsOn: 1 },
  { country: 'BD', weekStartsOn: 0 },
  { country: 'AR', weekStartsOn: 0 },
  { country: 'FJ', weekStartsOn: 0 },
  { country: 'MA', weekStartsOn: 1 },
  { country: 'HN', weekStartsOn: 0 },
  { country: 'MR', weekStartsOn: 1 },
  { country: 'AU', weekStartsOn: 0 },
  { country: 'DJ', weekStartsOn: 1 },
  { country: 'RS', weekStartsOn: 1 },
  { country: 'EE', weekStartsOn: 1 },
  { country: 'KW', weekStartsOn: 1 },
  { country: 'MC', weekStartsOn: 1 },
  { country: 'RU', weekStartsOn: 1 },
  { country: 'MG', weekStartsOn: 1 },
  { country: 'NR', weekStartsOn: 0 },
  { country: 'ZA', weekStartsOn: 0 },
  { country: 'GW', weekStartsOn: 1 },
  { country: 'QA', weekStartsOn: 6 },
  { country: 'ZM', weekStartsOn: 0 },
  { country: 'PG', weekStartsOn: 0 },
  { country: 'BQ', weekStartsOn: 0 },
  { country: 'GQ', weekStartsOn: 1 },
  { country: 'HK', weekStartsOn: 0 },
  { country: 'CG', weekStartsOn: 1 },
  { country: 'PL', weekStartsOn: 1 },
  { country: 'SJ', weekStartsOn: 1 },
  { country: 'PE', weekStartsOn: 1 },
  { country: 'PR', weekStartsOn: 0 },
  { country: 'EC', weekStartsOn: 0 },
  { country: 'CN', weekStartsOn: 1 },
  { country: 'SK', weekStartsOn: 1 },
  { country: 'VU', weekStartsOn: 1 },
  // { country: 'A symbol: '' },
  { country: 'HR', weekStartsOn: 1 },
  { country: 'CK', weekStartsOn: 1 },
  { country: 'GE', weekStartsOn: 1 },
  { country: 'TH', weekStartsOn: 0 },
  { country: 'AM', weekStartsOn: 1 },
  { country: 'AI', weekStartsOn: 0 },
  { country: 'GS', weekStartsOn: 0 },
  { country: 'LU', weekStartsOn: 1 },
  { country: 'LV', weekStartsOn: 1 },
  { country: 'GI', weekStartsOn: 1 },
  { country: 'LB', weekStartsOn: 1 },
  { country: 'BI', weekStartsOn: 1 },
  { country: 'CC', weekStartsOn: 1 },
  { country: 'ZW', weekStartsOn: 0 },
  { country: 'BG', weekStartsOn: 1 },
  { country: 'CW', weekStartsOn: 1 },
  { country: 'GN', weekStartsOn: 1 },
  { country: 'GG', weekStartsOn: 1 },
  { country: 'WS', weekStartsOn: 1 },
  { country: 'PM', weekStartsOn: 1 },
  { country: 'JO', weekStartsOn: 1 },
  { country: 'ME', weekStartsOn: 1 },
  { country: 'CL', weekStartsOn: 1 },
  { country: 'SE', weekStartsOn: 1 },
  { country: 'BW', weekStartsOn: 0 },
  { country: 'VE', weekStartsOn: 0 },
  { country: 'PK', weekStartsOn: 0 },
  { country: 'VN', weekStartsOn: 1 },
  { country: 'TF', weekStartsOn: 1 },
  { country: 'LS', weekStartsOn: 1 },
  { country: 'KN', weekStartsOn: 0 },
  { country: 'NP', weekStartsOn: 0 },
  { country: 'AG', weekStartsOn: 0 },
  { country: 'FM', weekStartsOn: 0 },
  { country: 'LA', weekStartsOn: 1 },
  { country: 'ER', weekStartsOn: 0 },
  { country: 'IL', weekStartsOn: 0 },
  { country: 'AS', weekStartsOn: 0 },
  { country: 'US', weekStartsOn: 0 },
  { country: 'ID', weekStartsOn: 1 },
  { country: 'KE', weekStartsOn: 0 },
  { country: 'KM', weekStartsOn: 1 },
  { country: 'VG', weekStartsOn: 0 },
  { country: 'CV', weekStartsOn: 0 },
  { country: 'CX', weekStartsOn: 1 },
  { country: 'XK', weekStartsOn: 1 },
  { country: 'SB', weekStartsOn: 0 },
  { country: 'MT', weekStartsOn: 1 },
  { country: 'MW', weekStartsOn: 0 },
  { country: 'CO', weekStartsOn: 0 },
  { country: 'JP', weekStartsOn: 0 },
  { country: 'MM', weekStartsOn: 1 },
  // { country: 'B '', weekStartsOn: },
  { country: 'TD', weekStartsOn: 1 },
  { country: 'KI', weekStartsOn: 0 },
  { country: 'TK', weekStartsOn: 0 },
  { country: 'FK', weekStartsOn: 0 },
  { country: 'GA', weekStartsOn: 1 },
  { country: 'GM', weekStartsOn: 0 },
  { country: 'GR', weekStartsOn: 1 },
  { country: 'MN', weekStartsOn: 1 },
  { country: 'GP', weekStartsOn: 1 },
  { country: 'JE', weekStartsOn: 1 },
  { country: 'PN', weekStartsOn: 0 },
  { country: 'SM', weekStartsOn: 1 },
  { country: 'VA', weekStartsOn: 1 },
  { country: 'FO', weekStartsOn: 1 },
  { country: 'TZ', weekStartsOn: 0 },
  { country: 'WF', weekStartsOn: 1 },
  { country: 'LR', weekStartsOn: 0 },
  { country: 'VI', weekStartsOn: 0 },
  { country: 'YE', weekStartsOn: 6 },
  { country: 'BY', weekStartsOn: 1 },
  { country: 'MV', weekStartsOn: 0 },
  { country: 'ML', weekStartsOn: 1 },
  { country: 'SO', weekStartsOn: 6 },
  { country: 'DZ', weekStartsOn: 6 },
  { country: 'SZ', weekStartsOn: 0 },
  { country: 'FR', weekStartsOn: 1 },
  // { country: 'H symbol: '' },
  { country: 'TL', weekStartsOn: 0 },
  { country: 'BL', weekStartsOn: 1 },
  { country: 'ET', weekStartsOn: 0 },
  { country: 'TV', weekStartsOn: 0 },
  { country: 'DM', weekStartsOn: 0 },
  { country: 'JM', weekStartsOn: 0 },
  { country: 'LI', weekStartsOn: 1 },
  { country: 'DE', weekStartsOn: 1 },
  { country: 'IR', weekStartsOn: 6 },
  { country: 'BH', weekStartsOn: 6 },
  { country: 'RO', weekStartsOn: 1 },
  { country: 'CR', weekStartsOn: 0 },
  { country: 'CA', weekStartsOn: 0 },
  { country: 'EG', weekStartsOn: 6 },
  { country: 'OM', weekStartsOn: 6 },
  { country: 'MH', weekStartsOn: 0 },
  { country: 'PF', weekStartsOn: 1 },
  { country: 'BE', weekStartsOn: 1 },
  { country: 'BN', weekStartsOn: 1 },
  { country: 'PH', weekStartsOn: 0 },
  { country: 'CM', weekStartsOn: 1 },
  { country: 'AL', weekStartsOn: 1 },
  { country: 'GU', weekStartsOn: 0 },
  { country: 'BZ', weekStartsOn: 0 },
  { country: 'ST', weekStartsOn: 1 },
  { country: 'AF', weekStartsOn: 6 },
  // { country: 'S '', weekStartsOn: },
  { country: 'CD', weekStartsOn: 1 },
  { country: 'TW', weekStartsOn: 0 },
  { country: 'MS', weekStartsOn: 0 },
  { country: 'SI', weekStartsOn: 1 },
  { country: 'LT', weekStartsOn: 1 },
  { country: 'KZ', weekStartsOn: 1 },
  { country: 'NC', weekStartsOn: 1 },
  { country: 'VC', weekStartsOn: 0 },
  { country: 'SG', weekStartsOn: 1 },
  { country: 'SV', weekStartsOn: 0 },
  { country: 'CU', weekStartsOn: 0 },
  { country: 'RW', weekStartsOn: 0 },
  { country: 'BB', weekStartsOn: 0 },
  { country: 'IS', weekStartsOn: 1 },
  { country: 'AW', weekStartsOn: 0 },
  { country: 'SY', weekStartsOn: 6 },
  { country: 'BJ', weekStartsOn: 1 },
  { country: 'FI', weekStartsOn: 1 },
  { country: 'AZ', weekStartsOn: 1 },
  { country: 'NI', weekStartsOn: 0 },
  { country: 'BT', weekStartsOn: 0 },
  { country: 'HU', weekStartsOn: 1 },
  { country: 'UZ', weekStartsOn: 1 },
  { country: 'SR', weekStartsOn: 0 },
  { country: 'CF', weekStartsOn: 1 },
  { country: 'BF', weekStartsOn: 1 },
  { country: 'AX', weekStartsOn: 1 },
  { country: 'BS', weekStartsOn: 0 },
  { country: 'MQ', weekStartsOn: 1 },
  { country: 'GT', weekStartsOn: 0 },
  { country: 'PY', weekStartsOn: 0 },
  { country: 'LC', weekStartsOn: 0 },
  { country: 'AT', weekStartsOn: 1 },
  { country: 'MO', weekStartsOn: 0 },
  { country: 'PW', weekStartsOn: 0 },
  { country: 'SL', weekStartsOn: 0 },
  { country: 'CY', weekStartsOn: 1 },
  { country: 'IM', weekStartsOn: 1 },
  { country: 'CZ', weekStartsOn: 1 },
  { country: 'MU', weekStartsOn: 0 },
  { country: 'KH', weekStartsOn: 0 },
  { country: 'RE', weekStartsOn: 1 },
  { country: 'TC', weekStartsOn: 0 },
  { country: 'AO', weekStartsOn: 0 },
  { country: 'KY', weekStartsOn: 0 },
  { country: 'HT', weekStartsOn: 0 },
  { country: 'IQ', weekStartsOn: 6 },
  { country: 'NL', weekStartsOn: 1 },
  { country: 'CI', weekStartsOn: 1 },
  { country: 'UA', weekStartsOn: 1 },
  { country: 'TR', weekStartsOn: 1 },
  { country: 'MZ', weekStartsOn: 1 },
  { country: 'UM', weekStartsOn: 0 },
  { country: 'GH', weekStartsOn: 1 },
  { country: 'SA', weekStartsOn: 0 },
  { country: 'BM', weekStartsOn: 0 },
  { country: 'UG', weekStartsOn: 0 },
  { country: 'NU', weekStartsOn: 0 },
  { country: 'MK', weekStartsOn: 1 },
  { country: 'GD', weekStartsOn: 0 },
  { country: 'ES', weekStartsOn: 1 },
  { country: 'GY', weekStartsOn: 0 },
  { country: 'NG', weekStartsOn: 0 },
  { country: 'NA', weekStartsOn: 0 },
  { country: 'MF', weekStartsOn: 1 },
  { country: 'YT', weekStartsOn: 1 },
  { country: 'LY', weekStartsOn: 0 },
  { country: 'BO', weekStartsOn: 0 },
  { country: 'IO', weekStartsOn: 0 },
  { country: 'EH', weekStartsOn: 1 },
  { country: 'CH', weekStartsOn: 1 },
  { country: 'GB', weekStartsOn: 1 },
  { country: 'SH', weekStartsOn: 0 },
  { country: 'AE', weekStartsOn: 6 },
  { country: 'TO', weekStartsOn: 0 },
  { country: 'NF', weekStartsOn: 0 },
  { country: 'TT', weekStartsOn: 0 },
  { country: 'SS', weekStartsOn: 0 },
  { country: 'PA', weekStartsOn: 0 },
  { country: 'SN', weekStartsOn: 1 },
  { country: 'SC', weekStartsOn: 0 },
]

export default countryStuff
