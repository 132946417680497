import { useTranslation } from 'react-i18next'
import { BuildingOffice2Icon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiImageUpload from '../ui-kit/input/basic/UiImageUpload'
import UiInput from '../ui-kit/input/basic/UiInput'
import { SubmitHandler, ValidateResult, useForm } from 'react-hook-form'
import { validateCompanyPublicId } from '@/business/api/public.service'
import UiCombobox from '../ui-kit/input/basic/UiCombobox'
import Industry from '@/business/dto/types/industry'
import { useState } from 'react'
import UpdateCompanyRequest from '@/business/dto/requests/update-company.request'
import { updateCompany } from '@/business/api/company.service'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import UiColorPicker from '../ui-kit/input/UiColorPicker'
import CompanyPopulatedResponse from '@/business/dto/responses/company-populated.response'
import { ca } from 'date-fns/locale'

const mapToFormValue = (
  company: CompanyPopulatedResponse
): UpdateCompanyRequest => {
  return {
    name: company.name,
    publicId: company.publicId || '',
    image: company.image,
    description: company.description || '',
    industry: company.industry,
    appearance: company.appearance,
  }
}

export type CompanyGeneralCardProps = {
  company: CompanyPopulatedResponse
  setCompany: React.Dispatch<React.SetStateAction<CompanyPopulatedResponse>>
}

const CompanyGeneralCard = (props: CompanyGeneralCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<UpdateCompanyRequest>({
    defaultValues: mapToFormValue(props.company),
  })

  const onSubmit: SubmitHandler<UpdateCompanyRequest> = (data) => {
    setLoading(true)
    updateCompany(data)
      .then((response) => {
        props.setCompany(response)
        form.reset(mapToFormValue(response))
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.updated')}
            description={t('toast.description.companyUpdated')}
          />
        ))
      })
      .catch((err: any) => handleValidationError(err, i18n))
      .finally(() => setLoading(false))
  }

  const validatePublicId = async (
    publicId: string | undefined
  ): Promise<ValidateResult> => {
    try {
      const response = await validateCompanyPublicId(publicId!)
      if (response.alreadyUsed) {
        return t('form.validation.alreadyUsed')!
      }

      return true
    } catch (err: any) {
      return t('form.validation.unexpectedError')!
    }
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <UiCard>
        <UiCard.Header
          title={t('common.general')}
          description={t('card.description.companyGeneral')}
          icon={BuildingOffice2Icon}
        />
        <UiCard.Body>
          <div className='grid grid-cols-12 gap-6'>
            <div className='col-span-12 sm:col-span-7 space-y-6'>
              <UiImageUpload
                label={t('common.logo')}
                avatar={{
                  name: form.watch('name')!,
                  color: props.company.appearance.color,
                  shape: 'rounded',
                }}
                name='image'
                control={form.control}
              />
              <UiInput
                label={t('common.companyName')}
                type='text'
                description={t('form.description.companyName')!}
                control={form.control}
                name='name'
                rules={{
                  required: {
                    value: true,
                    message: t('form.validation.required'),
                  },
                  minLength: {
                    value: 3,
                    message: t('form.validation.minLength', { x: 3 }),
                  },
                  maxLength: {
                    value: 32,
                    message: t('form.validation.maxLength', { x: 32 }),
                  },
                }}
              />
              <UiInput
                label='Beschreibung'
                type='text'
                description={t('form.description.companyDescription')!}
                name='description'
                control={form.control}
                rules={{
                  maxLength: {
                    value: 64,
                    message: t('form.validation.maxLength', { x: 64 }),
                  },
                }}
              />
              <UiColorPicker
                label={t('common.color')}
                description={t('form.description.companyColor')!}
                control={form.control}
                name='appearance.color'
                rules={{ required: true }}
              />
              <hr />
              <UiInput
                label={t('common.bookingPage')}
                type='text'
                leadingText={`resflow.com/`}
                description={t('form.description.companyReservationPage')!}
                control={form.control}
                name='publicId'
                rules={{
                  required: {
                    value: true,
                    message: t('form.validation.required'),
                  },
                  pattern: {
                    value: /^[a-z0-9-]+$/,
                    message: t(
                      'form.validation.lowercaseAlphanumericAndHyphen'
                    ),
                  },
                  maxLength: {
                    value: 32,
                    message: t('form.validation.maxLength', { x: 32 }),
                  },
                  validate: validatePublicId,
                }}
              />
              <UiCombobox
                control={form.control}
                name='industry'
                description={t('form.description.companyIndustry')!}
                rules={{
                  maxLength: {
                    value: 64,
                    message: t('form.validation.maxLength', { x: 64 }),
                  },
                }}
                label={t('common.industry')}
                options={Object.values(Industry).map((industry) => {
                  return {
                    label: t(`common.industry.${industry}`),
                    value: industry,
                  }
                })}
              />
            </div>
            <div className='hidden sm:block sm:col-span-5'>
              <UiCard.Image src='/img/rose/undraw_building_re_xfcm.svg' />
            </div>
          </div>
        </UiCard.Body>
        <UiCard.Footer>
          <UiButton
            label={t('action.cancel')}
            variant='flat'
            type='button'
            disabled={!form.formState.isDirty}
            onClick={() => form.reset(mapToFormValue(props.company))}
          />
          <UiButton
            label={t('action.save')}
            variant='dark'
            type='submit'
            loading={loading}
            disabled={!form.formState.isDirty}
          />
        </UiCard.Footer>
      </UiCard>
    </form>
  )
}

export default CompanyGeneralCard
