import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import {
  LoaderFunctionArgs,
  defer,
  useLoaderData,
  useParams,
} from 'react-router-dom'
import {
  findServiceById,
  updateServiceException,
} from '@/business/api/service.service'
import { ExceptionUpdateCard } from '@/components/modals/ExceptionCreateUpdateCard'
import CreateOrUpdateExceptionRequest from '@/business/dto/requests/create-or-update-exception.request'
import ServiceResponse from '@/business/dto/responses/service.response'

export const serviceExceptionEditPageLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const [service] = await Promise.all([findServiceById(params.serviceId!)])
  return defer({ service })
}

const ServiceExceptionEditPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const { exceptionId } = useParams()
  const { service } = useLoaderData() as { service: ServiceResponse }

  return (
    <UiContainer size='medium'>
      <UiHeader
        title={t('page.title.serviceExceptionEditX', {
          x: service.name,
        })}
        description={t('page.description.serviceExceptionEditX', {
          x: service.name,
        })}
        desktopBackHref={`/services/${service._id}`}
        mobileBackHref={`/services/${service._id}`}
      />
      <div className='py-6 md:py-8'>
        <div className='space-y-6'>
          <ExceptionUpdateCard
            exception={service.exceptions?.find((a) => a._id === exceptionId)!}
            updateFn={(
              exceptionId: string,
              data: CreateOrUpdateExceptionRequest
            ) => updateServiceException(service._id, exceptionId, data)}
            backHref={`/services/${service._id}`}
          />
        </div>
      </div>
    </UiContainer>
  )
}

export default ServiceExceptionEditPage
