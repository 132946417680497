import {
  BuildingOffice2Icon,
  Cog6ToothIcon,
  FaceSmileIcon,
  HeartIcon,
  SquaresPlusIcon,
  UserCircleIcon,
  UserGroupIcon,
  CubeTransparentIcon,
  SparklesIcon,
  ChevronRightIcon,
  FolderIcon,
  ArrowRightOnRectangleIcon,
  BookOpenIcon,
  EnvelopeIcon,
  BriefcaseIcon,
  WrenchIcon,
  UserIcon,
} from '@heroicons/react/24/outline'
import { Navigate, Outlet } from 'react-router-dom'
import i18n from './../i18n'
import UiContainer from './ui-kit/layout/UiContainer'
import WelcomePage from './../pages/intro/Welcome'
import UiHorizonNavigation from './ui-kit/layout/UiHorizonNavigation'
import UiMenu from './ui-kit/actions/UiMenu'
import { useTranslation } from 'react-i18next'
import SubscriptionStatus from '@/business/dto/types/subscription-status'
import { Transition } from '@headlessui/react'
import Checklist from './Checklist'
import useAuth from '@/contexts/use-auth'
import InternBottomNavigation from './InternBottomNavigation'
import Role from '@/business/dto/types/role'
import UiBadge from './ui-kit/presentation/UiBadge'
import Topbar from './Topbar'
import SignOut from './SignOut'

const InternalLayout = () => {
  const auth = useAuth()
  const { t } = useTranslation()

  if (auth.user.resflowStaff) {
    return <Navigate to="/employees" replace={true} />
  }

  if (!auth.company.subscription) {
    return <WelcomePage />
  }

  if (
    auth.company.subscription.status === SubscriptionStatus.trialing ||
    auth.company.subscription.status === SubscriptionStatus.active ||
    auth.company.subscription.status === SubscriptionStatus.incomplete ||
    auth.company.subscription.status === SubscriptionStatus.incompleteExpired ||
    auth.company.subscription.status === SubscriptionStatus.pastDue ||
    auth.company.subscription.status === SubscriptionStatus.unpaid
  ) {
    return (
      <>
        <div className="hidden md:block">
          <Topbar />
          <nav className="sticky top-0 left-0 right-0 z-40 backdrop-blur bg-gray-100/75 border-b border-gray-200">
            <UiContainer>
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center h-full">
                  <div className="flex-shrink-0">
                    <div className="h-6 w-[67px]">
                      <img
                        src="/img/resflow-logo.svg"
                        alt={t('common.resflowLogo')!}
                      />
                    </div>
                  </div>

                  {/* Links section */}
                  <div className="ml-10">
                    <UiHorizonNavigation>
                      <UiHorizonNavigation.Item
                        title={t('common.dashboard')}
                        href="/"
                        end={true}
                      />
                      <UiHorizonNavigation.Item
                        title={t('common.reservations')}
                        href="/reservations"
                        end={false}
                      />
                      <UiHorizonNavigation.Item
                        title={t('common.contacts')}
                        href="/contacts"
                        end={false}
                      />
                    </UiHorizonNavigation>
                  </div>
                </div>

                {/* Actions section */}
                <div className="ml-4">
                  <div className="flex items-center space-x-4">
                    {/* Company dropdown */}
                    <Transition
                      show={true}
                      enter="transition-opacity ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100 yolo"
                      leave="transition-opacity ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <a
                        href={`https://resflow.com/${auth.company.publicId}/`}
                        className="group px-3 py-2 rounded-md flex items-center text-sm font-medium"
                        target="_blank"
                        rel="noopener"
                      >
                        {false && (
                          <SparklesIcon className="text-gray-400 mr-2 h-6 w-6 group-hover:text-gray-500" />
                        )}
                        <span className="text-gray-700 group-hover:text-gray-900 transition group-hover:translate-x-0.5 truncate">
                          {t('common.yourResflowPage')}
                        </span>
                        {true && (
                          <ChevronRightIcon className="text-gray-400 ml-2 -mr-2 h-5 w-5 transition group-hover:text-gray-500 group-hover:translate-x-0.5" />
                        )}
                      </a>
                    </Transition>

                    {(auth.user.role === Role.admin ||
                      auth.user.role === Role.owner) && (
                      <UiMenu sr="Open settings menu" icon={Cog6ToothIcon}>
                        <UiMenu.Header
                          title={auth.company.name}
                          description={`resflow.com/${auth.company.publicId}`}
                        />
                        <UiMenu.Items>
                          <UiMenu.Item
                            name={t('common.company')}
                            href="/company/properties"
                            icon={BuildingOffice2Icon}
                          />
                          <UiMenu.Item
                            name={t('common.users')}
                            href="/users"
                            icon={UserGroupIcon}
                          />
                        </UiMenu.Items>
                        <UiMenu.Items>
                          <UiMenu.Item
                            name={t('common.categories')}
                            href="/categories"
                            icon={FolderIcon}
                          />
                          <UiMenu.Item
                            name={t('common.services')}
                            href="/services"
                            icon={FaceSmileIcon}
                          />
                          <UiMenu.Item
                            name={t('common.resources')}
                            href="/resources"
                            icon={CubeTransparentIcon}
                          />
                        </UiMenu.Items>
                        <UiMenu.Items>
                          <UiMenu.Item
                            name={t('common.integrations')}
                            href="/integrations"
                            icon={SquaresPlusIcon}
                          />
                        </UiMenu.Items>
                        {false && (
                          <UiMenu.Items>
                            <UiMenu.Item
                              name={t('common.recommend')}
                              href="/recommend"
                              icon={HeartIcon}
                            />
                          </UiMenu.Items>
                        )}
                      </UiMenu>
                    )}

                    <UiMenu
                      sr="Open account menu"
                      avatar={{
                        name: auth.user.name,
                        color: auth.user.color,
                        image: auth.user.image,
                        shape: 'circle',
                      }}
                    >
                      <UiMenu.Header
                        title={auth.user.name}
                        description={auth.user.email}
                      >
                        {auth.user.role === Role.owner && (
                          <UiBadge
                            icon={BriefcaseIcon}
                            bgClass="bg-rose-100"
                            textClass="text-rose-800"
                            label={t('common.owner')}
                          />
                        )}
                        {auth.user.role === Role.admin && (
                          <UiBadge
                            icon={WrenchIcon}
                            bgClass="bg-sky-100"
                            textClass="text-sky-800"
                            label={t('common.admin')}
                          />
                        )}
                        {auth.user.role === Role.member && (
                          <UiBadge icon={UserIcon} label={t('common.member')} />
                        )}
                      </UiMenu.Header>
                      <UiMenu.Items>
                        <UiMenu.Item
                          name={t('common.account')}
                          href="/account"
                          icon={UserCircleIcon}
                        />
                      </UiMenu.Items>
                      <UiMenu.Items>
                        <UiMenu.Item
                          name={t('common.documentation')}
                          href={`https://resflow.com/${i18n.language}/${t(
                            'href.documentation'
                          )}/`}
                          icon={BookOpenIcon}
                        />
                        <UiMenu.Item
                          name={t('common.support')}
                          href={`https://resflow.com/${i18n.language}/${t(
                            'href.contact'
                          )}/`}
                          icon={EnvelopeIcon}
                        />
                        <UiMenu.Item
                          name={t('common.feedback')}
                          href={`https://resflow.com/${i18n.language}/${t(
                            'href.contact'
                          )}/`}
                          icon={HeartIcon}
                        />
                      </UiMenu.Items>
                      <UiMenu.Items>
                        <UiMenu.Item
                          name={t('action.signOut')}
                          onClick={auth.signOut}
                          icon={ArrowRightOnRectangleIcon}
                        />
                      </UiMenu.Items>
                    </UiMenu>
                  </div>
                </div>
              </div>
            </UiContainer>
          </nav>
        </div>

        <Outlet />

        <Checklist />

        <InternBottomNavigation />

        {/* {navigation.state === 'loading' && (
          <div className="absolute inset-0 z-50 bg-gray-100/50"></div>
        )} */}
      </>
    )
  }

  /* No Subscription */
  return <SignOut />
}

export default InternalLayout
