import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useState } from 'react'
import UpdateCompanyRequest from '@/business/dto/requests/update-company.request'
import { updateCompany } from '@/business/api/company.service'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import { BellIcon } from '@heroicons/react/24/outline'
import UiToggle from '../ui-kit/input/basic/UiToggle'
import CompanyPopulatedResponse from '@/business/dto/responses/company-populated.response'
import UiInput from '../ui-kit/input/basic/UiInput'

const mapToFormValue = (
  company: CompanyPopulatedResponse
): UpdateCompanyRequest => {
  return {
    resource: {
      availability: company.resource.availability,
      capacity: company.resource.capacity,
    },
  }
}

export type CompanyDefaultsResourceCardProps = {
  company: CompanyPopulatedResponse
  setCompany: React.Dispatch<React.SetStateAction<CompanyPopulatedResponse>>
}

const CompanyDefaultsResourceCard = (
  props: CompanyDefaultsResourceCardProps
) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<UpdateCompanyRequest>({
    defaultValues: mapToFormValue(props.company),
  })

  const onSubmit: SubmitHandler<UpdateCompanyRequest> = (data) => {
    setLoading(true)
    updateCompany(data)
      .then((response) => {
        props.setCompany(response)
        form.reset(mapToFormValue(response))
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.updated')}
            description={t('toast.description.companyUpdated')}
          />
        ))
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <UiCard>
          <UiCard.Header
            title={t('common.resourceDefaults')}
            description={t('page.description.resourceDefaults')}
            icon={BellIcon}
          />
          <UiCard.Body>
            <div className='divide-y divide-gray-200 space-y-4'>
              {/* <div>
                <UiToggle
                  label={'Resource'}
                  description='Enable resource for this company'
                  name='category.enabled'
                  control={form.control}
                />
              </div> */}
              <div className='space-y-6'>
                <UiToggle
                  label={'Kapazitäten'}
                  description='Wenn deaktiviert, beträgt die Kapazität bei allen Resourcen 1.'
                  name='resource.availability'
                  control={form.control}
                />
                {form.watch('resource.availability') && (
                  <UiInput
                    label={t('common.capacity')}
                    type='number'
                    name='resource.capacity'
                    className='max-w-md'
                    control={form.control}
                    rules={{
                      valueAsNumber: true,
                      required: {
                        value: true,
                        message: t('form.validation.required'),
                      },
                      maxLength: {
                        value: 64,
                        message: t('form.validation.maxLength', { x: 64 }),
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </UiCard.Body>
          <UiCard.Footer>
            <UiButton
              label={t('action.cancel')}
              variant='flat'
              type='button'
              disabled={!form.formState.isDirty}
              onClick={() => form.reset(mapToFormValue(props.company))}
            />
            <UiButton
              label={t('action.save')}
              variant='dark'
              type='submit'
              loading={loading}
              disabled={!form.formState.isDirty}
            />
          </UiCard.Footer>
        </UiCard>
      </form>
    </>
  )
}

export default CompanyDefaultsResourceCard
