import React from 'react'
import UiButton from '@/components/ui-kit/actions/UiButton'
import { useTranslation } from 'react-i18next'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'

type UiPaginationProps = {
  page: number
  totalPages: number
  onPageChanged: (page: number) => void
}

const UiPagination = (props: UiPaginationProps) => {
  const { t } = useTranslation()
  const auth = useAuth()
  const color = auth?.company?.appearance?.color || 'rose'

  return (
    <nav className="flex items-center gap-1 md:gap-3">
      {false && (
        <UiButton
          variant="gray"
          type="button"
          label="Previous"
          icon={ChevronLeftIcon}
          iconOnly={true}
          disabled={props.page === 1}
          onClick={() => props.onPageChanged(props.page - 1)}
        />
      )}
      {true && (
        <>
          {props.page > 2 && (
            <UiButton
              variant="gray"
              type="button"
              label="1"
              iconOnly={true}
              onClick={() => props.onPageChanged(1)}
            />
          )}
          {props.page > 3 && (
            <span className="items-center px-2 pt-4 text-sm font-medium text-gray-500">
              ...
            </span>
          )}
          {props.page > 1 && (
            <UiButton
              variant="gray"
              type="button"
              label={`${props.page - 1}`}
              iconOnly={true}
              onClick={() => props.onPageChanged(props.page - 1)}
            />
          )}
          {props.totalPages > 0 && (
            <UiButton
              variant="primary"
              type="button"
              label={`${props.page}`}
              iconOnly={true}
              onClick={() => props.onPageChanged(props.page)}
            />
          )}
          {props.page < props.totalPages && (
            <UiButton
              variant="gray"
              type="button"
              label={`${props.page + 1}`}
              iconOnly={true}
              onClick={() => props.onPageChanged(props.page + 1)}
            />
          )}
          {props.page < props.totalPages - 2 && (
            <span className="items-center px-2 pt-4 text-sm font-medium text-gray-500">
              ...
            </span>
          )}
          {props.page < props.totalPages - 1 && (
            <UiButton
              variant="gray"
              type="button"
              label={`${props.totalPages}`}
              iconOnly={true}
              onClick={() => props.onPageChanged(props.totalPages)}
            />
          )}
        </>
      )}
      {false && (
        <UiButton
          variant="gray"
          type="button"
          label="Next"
          icon={ChevronRightIcon}
          iconOnly={true}
          disabled={props.page === props.totalPages}
          onClick={() => props.onPageChanged(props.page + 1)}
        />
      )}
    </nav>
  )
}

export default UiPagination
