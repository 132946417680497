import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import { useEffect, useState } from 'react'
import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form'
import UpdateServiceRequest from '@/business/dto/requests/update-service.request'
import {
  createService,
  updateServiceById,
} from '@/business/api/service.service'
import { calcColor } from '@/business/api/image.service'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import UiImageUpload from '../ui-kit/input/basic/UiImageUpload'
import UiInput from '../ui-kit/input/basic/UiInput'
import { useNavigate } from 'react-router-dom'
import CreateServiceRequest from '@/business/dto/requests/create-service.request'
import UiCombobox from '../ui-kit/input/basic/UiCombobox'
import { getCurrentFnsLocale } from '@/business/date-utils'
import Color from '@/business/dto/types/color'
import UiColorPicker from '../ui-kit/input/UiColorPicker'
import ServiceResponse from '@/business/dto/responses/service.response'

const mapToFormValue = (
  service?: ServiceResponse
): CreateServiceRequest | UpdateServiceRequest => {
  return {
    name: service?.name || '',
    description: service?.description || '',
    image: service?.image || null,
    color: service?.color || Color.gray,
    duration: service?.duration || 0,
    price: service?.price || '',
    position: service?.position || undefined,
    // auf Buchungsseite anzeigen / öffentlich anzeigen
    // minCapacity: service.minCapacity || 1,
    // maxCapacity: service.maxCapacity || 1,
  }
}

export const ServiceCreateCard = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<CreateServiceRequest>({
    defaultValues: mapToFormValue(undefined),
  })
  const name = form.watch('name')

  useEffect(() => {
    form.setValue('color', calcColor(name))
  }, [name])

  const onSubmit: SubmitHandler<CreateServiceRequest> = (data) => {
    setLoading(true)
    createService(data)
      .then((response) => {
        navigate(`/services/${response._id}`)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.created')}
            description={t('toast.description.serviceCreated')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        handleValidationError(err, i18n)
      })
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ServiceCreateUpdateCard loading={loading} service={undefined} />
      </form>
    </FormProvider>
  )
}

export type ServiceUpdateCardProps = {
  service: ServiceResponse
}

export const ServiceUpdateCard = (props: ServiceUpdateCardProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<UpdateServiceRequest>({
    defaultValues: mapToFormValue(props.service),
  })

  const onSubmit: SubmitHandler<UpdateServiceRequest> = (data) => {
    setLoading(true)
    updateServiceById(props.service._id, data)
      .then((response) => {
        navigate(`/services/${response._id}`)
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.updated')}
            description={t('toast.description.serviceUpdated')}
          />
        ))
      })
      .catch((err: AxiosError) => {
        handleValidationError(err, i18n)
        setLoading(false)
      })
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ServiceCreateUpdateCard loading={loading} service={props.service} />
      </form>
    </FormProvider>
  )
}

type ServiceCreateUpdateCardProps = {
  loading: boolean
  service?: ServiceResponse
}

const ServiceCreateUpdateCard = (props: ServiceCreateUpdateCardProps) => {
  const auth = useAuth()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const form = useFormContext<CreateServiceRequest | UpdateServiceRequest>()

  return (
    <UiCard>
      {/* <UiCard.Header
        title={'General'}
        description={t('page.contacts.properties.description')}
        icon={CubeTransparentIcon}
      /> */}
      <UiCard.Body>
        <div className='space-y-6'>
          <UiImageUpload
            label={t('common.image')}
            avatar={{
              name: form.watch('name')!,
              color: form.watch('color')!,
              shape: 'rounded',
            }}
            name='image'
            control={form.control}
          />
          <UiInput
            label={t('common.name')}
            type='text'
            name='name'
            control={form.control}
            autoFocus={true}
            rules={{
              required: {
                value: true,
                message: t('form.validation.required'),
              },
              minLength: {
                value: 3,
                message: t('form.validation.minLength', { x: 3 }),
              },
              maxLength: {
                value: 32,
                message: t('form.validation.maxLength', { x: 32 }),
              },
            }}
          />
          <UiInput
            label={t('common.description')}
            type='text'
            description='Eine kurze Beschreibung, die unter dem Namen erscheint.'
            name='description'
            control={form.control}
            rules={{
              maxLength: {
                value: 64,
                message: t('form.validation.maxLength', { x: 64 }),
              },
            }}
          />
          <UiColorPicker
            label={t('common.color')}
            control={form.control}
            name='color'
            rules={{ required: true }}
          />
          <hr className='bg-gray-100' />
          <UiInput
            label={t('common.duration')}
            type='number'
            name='duration'
            description='Legen Sie fest, wie lange Ihr Service dauert. Wird zur Berechnung der Verfügbarkeiten verwendet.'
            trailingText={t('common.minutes')!}
            control={form.control}
            rules={{
              valueAsNumber: true,
              required: {
                value: true,
                message: t('form.validation.required'),
              },
              maxLength: {
                value: 64,
                message: t('form.validation.maxLength', { x: 64 }),
              },
            }}
          />
          <UiInput
            label={t('common.price')}
            type='text'
            name='price'
            description='Legen Sie fest, wie viel Ihr Service kostet. Hierbei handelt es sich lediglich um einen Informationstext.'
            control={form.control}
            rules={{
              valueAsNumber: true,
              maxLength: {
                value: 12,
                message: t('form.validation.maxLength', { x: 12 }),
              },
            }}
          />
          {props.service && (
            <UiInput
              label={t('common.position')}
              type='number'
              name='position'
              control={form.control}
              rules={{
                valueAsNumber: true,
                required: {
                  value: true,
                  message: t('form.validation.required'),
                },
                maxLength: {
                  value: 64,
                  message: t('form.validation.maxLength', { x: 64 }),
                },
              }}
            />
          )}
        </div>
      </UiCard.Body>
      <UiCard.Footer>
        <UiButton
          label={t('action.cancel')}
          type='button'
          variant='flat'
          onClick={
            props.service
              ? () => navigate(`/services/${props.service?._id}`)
              : () => navigate('/services')
          }
        />
        <UiButton
          label={props.service ? t('action.save') : t('action.create')}
          type='submit'
          variant={props.service ? 'dark' : 'primary'}
          loading={props.loading}
          disabled={!form.formState.isDirty}
        />
      </UiCard.Footer>
    </UiCard>
  )
}
