import { useEffect, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  getDay,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  startOfDay,
} from 'date-fns'
import useAuth from '@/contexts/use-auth'
import { format, toDate, utcToZonedTime } from 'date-fns-tz'
import { getCurrentFnsLocale } from '@/business/date-utils'

const colStartClasses = [
  '',
  'col-start-2',
  'col-start-3',
  'col-start-4',
  'col-start-5',
  'col-start-6',
  'col-start-7',
]

export type UiCalendarProps = {
  value: string[]
  onChanged: (value: string[]) => void
  single?: boolean
  disabledDates?: string[]
}

const UiCalendar = (props: UiCalendarProps) => {
  const auth = useAuth()
  const color = auth?.company?.appearance?.color || 'rose'

  const nowInUtc = new Date()
  const zonedNow = utcToZonedTime(nowInUtc, auth.company.localization.timeZone)
  const zonedToday = startOfDay(zonedNow)

  const [selectedDates, setSelectedDates] = useState<string[]>(props.value)
  const [zonedCurrentMonth, setCurrentMonth] = useState<string>(
    format(zonedToday, 'yyyy-MM', {
      locale: getCurrentFnsLocale(),
      timeZone: auth.company.localization.timeZone,
      weekStartsOn: auth.company.localization.weekStartsOn,
    })
  )
  const zonedFirstDayCurrentMonth = toDate(zonedCurrentMonth, {
    locale: getCurrentFnsLocale(),
    timeZone: auth.company.localization.timeZone,
    weekStartsOn: auth.company.localization.weekStartsOn,
  })

  let zonedDates = eachDayOfInterval({
    start: zonedFirstDayCurrentMonth,
    end: endOfMonth(zonedFirstDayCurrentMonth),
  }).map((zonedDateTime) => ({
    zonedDateTime,
    zonedDate: format(zonedDateTime, 'yyyy-MM-dd', {
      locale: getCurrentFnsLocale(),
      timeZone: auth.company.localization.timeZone,
      weekStartsOn: auth.company.localization.weekStartsOn,
    }),
  }))

  const previousMonth = () => {
    let firstDayNextMonth = add(zonedFirstDayCurrentMonth, { months: -1 })
    setCurrentMonth(
      format(firstDayNextMonth, 'yyyy-MM', {
        locale: getCurrentFnsLocale(),
        timeZone: auth.company.localization.timeZone,
        weekStartsOn: auth.company.localization.weekStartsOn,
      })
    )
  }

  const nextMonth = () => {
    const firstDayNextMonth = add(zonedFirstDayCurrentMonth, { months: 1 })
    setCurrentMonth(
      format(firstDayNextMonth, 'yyyy-MM', {
        locale: getCurrentFnsLocale(),
        timeZone: auth.company.localization.timeZone,
        weekStartsOn: auth.company.localization.weekStartsOn,
      })
    )
  }

  useEffect(() => {
    props.onChanged(selectedDates)
  }, [selectedDates])

  return (
    <>
      <div className="flex items-center">
        <div className="flex-auto text-sm font-semibold text-gray-900">
          {format(zonedFirstDayCurrentMonth, 'MMMM yyyy', {
            locale: getCurrentFnsLocale(),
            timeZone: auth.company.localization.timeZone,
            weekStartsOn: auth.company.localization.weekStartsOn,
          })}
        </div>
        <button
          type="button"
          onClick={previousMonth}
          className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          onClick={nextMonth}
          className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
        <div>So</div>
        <div>Mo</div>
        <div>Di</div>
        <div>Mi</div>
        <div>Do</div>
        <div>Fr</div>
        <div>Sa</div>
      </div>
      <div className="h-[21rem]">
        <div className="mt-2 grid grid-cols-7 text-sm">
          {zonedDates.map(({ zonedDate, zonedDateTime }, i) => (
            <div
              key={zonedDate}
              className={classNames(
                i === 0 && colStartClasses[getDay(zonedDateTime)],
                'py-2'
              )}
            >
              <button
                type="button"
                disabled={props.disabledDates?.some(
                  (disabledDate) => disabledDate === zonedDate
                )}
                onClick={() => {
                  if (props.single) {
                    setSelectedDates([zonedDate])
                  } else {
                    if (
                      selectedDates.some(
                        (selectedDate) => selectedDate === zonedDate
                      )
                    ) {
                      setSelectedDates((selectedDates) =>
                        selectedDates.filter(
                          (selectedDate) => selectedDate !== zonedDate
                        )
                      )
                    } else {
                      setSelectedDates((selectedDates) => [
                        ...selectedDates,
                        zonedDate,
                      ])
                    }
                  }
                }}
                className={classNames(
                  selectedDates.some(
                    (selectedDate) => selectedDate === zonedDate
                  )
                    ? `text-white bg-${color}-600`
                    : 'bg-gray-100 hover:bg-gray-200 disabled:bg-white text-gray-900',
                  (selectedDates.some(
                    (selectedDate) => selectedDate === zonedDate
                  ) ||
                    isSameDay(zonedToday, zonedDateTime)) &&
                    'font-semibold',
                  'relative mx-auto flex h-10 w-10 items-center justify-center rounded-full'
                )}
              >
                <time dateTime={zonedDate}>
                  {format(zonedDateTime, 'd', {
                    locale: getCurrentFnsLocale(),
                    timeZone: auth.company.localization.timeZone,
                    weekStartsOn: auth.company.localization.weekStartsOn,
                  })}
                </time>
                {isSameDay(zonedToday, zonedDateTime) && (
                  <div className="absolute top-6 left-0 right-0 bottom-0 flex justify-center items-center">
                    <svg
                      className={classNames(
                        'h-1 w-1',
                        selectedDates.some(
                          (selectedDate) => selectedDate === zonedDate
                        )
                          ? 'fill-white'
                          : `fill-gray-900`
                      )}
                      viewBox="0 0 6 6"
                      aria-hidden="true"
                    >
                      <circle cx={3} cy={3} r={3} />
                    </svg>
                  </div>
                )}
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default UiCalendar
