import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { findQuota } from '@/business/api/company.service'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { useCompany } from './Company'
import { Navigate, useLoaderData } from 'react-router-dom'
import Role from '@/business/dto/types/role'
import Quota from '@/business/dto/responses/quota'
import CompanyContractCard from '@/components/cards/CompanyContractCard'
import CompanyContractHelpCard from '@/components/cards/CompanyContractHelpCard'
import CompanyContractDpaCard from '@/components/cards/CompanyContractDpaCard'

export const CompanyContractPageLoader = async () => findQuota()

const CompanyContractPage = () => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const { company, setCompany } = useCompany()
  const [quota, setQuota] = useState<Quota>(useLoaderData() as Quota)

  if (auth.user.role !== Role.owner) {
    return <Navigate to="/" replace={true} />
  }

  return (
    <>
      <UiHeader
        className="md:hidden"
        title={t('common.plansAndBilling')}
        description=""
        mobileBackHref="/company"
      />
      <div className="py-6 md:py-0">
        <div className="space-y-6">
          <CompanyContractCard
            company={company}
            setCompany={setCompany}
            maxQuota={quota.maxQuota}
          />
          <CompanyContractHelpCard
            company={company}
            setCompany={setCompany}
          />
          <CompanyContractDpaCard
            company={company}
            setCompany={setCompany}
          />
        </div>
      </div>
    </>
  )
}

export default CompanyContractPage
