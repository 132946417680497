import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import {
  EnvelopeIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'
import CompanyPopulatedResponse from '@/business/dto/responses/company-populated.response'

export type CompanyContractHelpProps = {
  company: CompanyPopulatedResponse
  setCompany: React.Dispatch<React.SetStateAction<CompanyPopulatedResponse>>
}

const CompanyContractHelpCard = (props: CompanyContractHelpProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()

  return (
    <UiCard>
      <UiCard.Header
        title={t('card.title.companyContractHelp')}
        description={t('card.description.companyContractHelp')}
        // title={'Hilfe bei Ihrem resflow Vertrag'}
        // description={
        //   'Irgendwo hinschreiben, dass auf Wunsch auch manuell auf Rechnung gezahlt werden kann. Für die Umstellung bitte an den Support wenden.'
        // }
        icon={QuestionMarkCircleIcon}
      />
      <UiCard.Body>
        <UiButton
          variant="default"
          type="button"
          label={t('action.contactSupport')}
          icon={EnvelopeIcon}
          as="a"
          href="mailto:support@resflow.com"
        />
      </UiCard.Body>
      <UiCard.Footer />
    </UiCard>
  )
}

export default CompanyContractHelpCard
