import { useTranslation } from 'react-i18next'
import { MapPinIcon } from '@heroicons/react/24/outline'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiInput from '../ui-kit/input/basic/UiInput'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useState } from 'react'
import UpdateCompanyRequest from '@/business/dto/requests/update-company.request'
import { updateCompany } from '@/business/api/company.service'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import UiCountryCombobox from '../ui-kit/input/UiCountryCombobox'
import CompanyPopulatedResponse from '@/business/dto/responses/company-populated.response'

const mapToFormValue = (company: CompanyPopulatedResponse): UpdateCompanyRequest => {
  return {
    location: {
      line1: company.location.line1,
      line2: company.location.line2,
      postalCode: company.location.postalCode,
      city: company.location.city,
      country: company.location.country,
    },
  }
}

export type CompanyLocationCardProps = {
  company: CompanyPopulatedResponse
  setCompany: React.Dispatch<React.SetStateAction<CompanyPopulatedResponse>>
}

const CompanyLocationCard = (props: CompanyLocationCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<UpdateCompanyRequest>({
    defaultValues: mapToFormValue(props.company),
  })

  const onSubmit: SubmitHandler<UpdateCompanyRequest> = (data) => {
    setLoading(true)
    updateCompany(data)
      .then((response) => {
        props.setCompany(response)
        form.reset(mapToFormValue(response))
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type="success"
            title={t('toast.title.updated')}
            description={t('toast.description.companyUpdated')}
          />
        ))
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
      .finally(() => setLoading(false))
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <UiCard>
        <UiCard.Header
          title={t('common.location')}
          description={t('card.description.companyLocation')}
          icon={MapPinIcon}
        />
        <UiCard.Body>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 sm:col-span-6 space-y-6">
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <UiInput
                    label={t('common.streetAndHouseNumber')}
                    type="text"
                    name="location.line1"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: t('form.validation.required'),
                      },
                      maxLength: {
                        value: 64,
                        message: t('form.validation.maxLength', { x: 64 }),
                      },
                    }}
                  />
                </div>
                <div className="sm:col-span-6">
                  <UiInput
                    label={t('common.addressLine2')}
                    type="text"
                    name="location.line2"
                    control={form.control}
                    rules={{
                      maxLength: {
                        value: 64,
                        message: t('form.validation.maxLength', { x: 64 }),
                      },
                    }}
                  />
                </div>
                <div className="sm:col-span-3">
                  <UiInput
                    label={t('common.zipCode')}
                    type="text"
                    name="location.postalCode"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: t('form.validation.required'),
                      },
                      maxLength: {
                        value: 64,
                        message: t('form.validation.maxLength', { x: 64 }),
                      },
                    }}
                  />
                </div>
                <div className="sm:col-span-3">
                  <UiInput
                    label={t('common.city')}
                    type="text"
                    name="location.city"
                    control={form.control}
                    rules={{
                      required: {
                        value: true,
                        message: t('form.validation.required'),
                      },
                      maxLength: {
                        value: 64,
                        message: t('form.validation.maxLength', { x: 64 }),
                      },
                    }}
                  />
                </div>
                <div className="sm:col-span-6">
                  <UiCountryCombobox
                    label={t('common.country')}
                    control={form.control}
                    name="location.country"
                  />
                </div>
              </div>
            </div>
            <div className="hidden sm:block sm:col-span-6">
              <UiCard.Image src="/img/rose/undraw_current_location_re_j130.svg" />
            </div>
          </div>
        </UiCard.Body>
        <UiCard.Footer>
          <UiButton
            label={t('action.cancel')}
            variant="flat"
            type="button"
            disabled={!form.formState.isDirty}
            onClick={() => form.reset(mapToFormValue(props.company))}
          />
          <UiButton
            label={t('action.save')}
            variant="dark"
            type="submit"
            loading={loading}
            disabled={!form.formState.isDirty}
          />
        </UiCard.Footer>
      </UiCard>
    </form>
  )
}

export default CompanyLocationCard
