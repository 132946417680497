import { useTranslation } from 'react-i18next'
import useAuth from '@/contexts/use-auth'
import UiCard from '@/components/ui-kit/layout/UiCard'
import { useState } from 'react'
import { SubmitHandler, ValidateResult, useForm } from 'react-hook-form'
import { updateUserById } from '@/business/api/user.service'
import toast from 'react-hot-toast'
import UiToast from '../ui-kit/presentation/UiToast'
import { AxiosError } from 'axios'
import { handleValidationError } from '@/business/error-handler'
import UiInput from '../ui-kit/input/basic/UiInput'
import UiButton from '../ui-kit/actions/UiButton'
import { ShieldCheckIcon } from '@heroicons/react/24/outline'
import UserPopulatedResponse from '@/business/dto/responses/user-populated.response'
import Role from '@/business/dto/types/role'

export type UserSecurityCardProps = {
  user: UserPopulatedResponse
  setUserDetails: React.Dispatch<React.SetStateAction<UserPopulatedResponse>>
}

const UserSecurityCard = (props: UserSecurityCardProps) => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const form = useForm<{ password: string; passwordConfirmation: string }>({
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  })
  const watchPassword = form.watch('password')

  const onSubmit: SubmitHandler<{
    password: string
    passwordConfirmation: string
  }> = (data) => {
    setLoading(true)
    updateUserById(props.user._id, { password: data.password })
      .then((_) => {
        form.reset({
          password: '',
          passwordConfirmation: '',
        })
        toast.custom((toast) => (
          <UiToast
            toast={toast}
            type='success'
            title={t('toast.title.updated')}
            description={
              props.user._id === auth.user._id
                ? t('toast.description.accountUpdated')
                : t('toast.description.userUpdated')
            }
          />
        ))
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
      .finally(() => setLoading(false))
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <UiCard>
        <UiCard.Header
          title={t('common.changePassword')}
          description={
            props.user._id === auth.user._id
              ? t('card.description.accountSecurity')
              : t('card.description.userSecurity')
          }
          icon={ShieldCheckIcon}
        />
        <UiCard.Body>
          <div className='grid grid-cols-12 gap-6'>
            <div className='col-span-12 sm:col-span-6 space-y-6'>
              <UiInput
                label={t('common.newPassword')}
                type='password'
                control={form.control}
                name='password'
                disabled={
                  props.user.role === Role.owner &&
                  props.user._id !== auth.user._id
                }
                rules={{
                  required: {
                    value: true,
                    message: t('form.validation.required'),
                  },
                  minLength: {
                    value: 8,
                    message: t('form.validation.minLength', { x: 8 }),
                  },
                  maxLength: {
                    value: 64,
                    message: t('form.validation.maxLength', { x: 64 }),
                  },
                }}
                description={
                  props.user.role === Role.owner &&
                  props.user._id !== auth.user._id
                    ? t('form.description.changeOwnerPassword')
                    : undefined
                }
              />
              <UiInput
                label={t('common.confirmPassword')}
                type='password'
                control={form.control}
                name='passwordConfirmation'
                disabled={
                  props.user.role === Role.owner &&
                  props.user._id !== auth.user._id
                }
                rules={{
                  required: {
                    value: true,
                    message: t('form.validation.required'),
                  },
                  minLength: {
                    value: 8,
                    message: t('form.validation.minLength', { x: 8 }),
                  },
                  maxLength: {
                    value: 64,
                    message: t('form.validation.maxLength', { x: 64 }),
                  },
                  validate: (value: string | undefined): ValidateResult => {
                    return (
                      value === watchPassword ||
                      t('form.validation.passwordsDoNotMatch')!
                    )
                  },
                }}
              />
            </div>
            <div className='hidden sm:block sm:col-span-6'>
              <UiCard.Image src='/img/rose/undraw_secure_login_pdn4.svg' />
            </div>
          </div>
        </UiCard.Body>
        <UiCard.Footer>
          <UiButton
            label={t('action.cancel')}
            variant='flat'
            type='button'
            disabled={!form.formState.isDirty}
            onClick={() =>
              form.reset({ password: '', passwordConfirmation: '' })
            }
          />
          <UiButton
            label={t('action.save')}
            variant='dark'
            type='submit'
            loading={loading}
            disabled={
              !form.formState.isDirty ||
              (props.user.role === Role.owner &&
                props.user._id !== auth.user._id)
            }
          />
        </UiCard.Footer>
      </UiCard>
    </form>
  )
}

export default UserSecurityCard
