import { findAllContacts } from '@/business/api/contact.service'
import ContactResponse from '@/business/dto/responses/contact.response'
import Paginated from '@/business/dto/responses/paginated.response'
import { handleValidationError } from '@/business/error-handler'
import UiButton from '@/components/ui-kit/actions/UiButton'
import UiSearch from '@/components/ui-kit/input/UiSearch'
import UiAvatar from '@/components/ui-kit/layout/UiAvatar'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import UiBadge from '@/components/ui-kit/presentation/UiBadge'
import UiEmptyState from '@/components/ui-kit/presentation/UiEmptyState'
import UiNoSearchResults from '@/components/ui-kit/presentation/UiNoSearchResults'
import UiPagination from '@/components/ui-kit/presentation/UiPagination'
import useAuth from '@/contexts/use-auth'
import {
  EnvelopeIcon,
  HashtagIcon,
  PhoneIcon,
  PlusSmallIcon,
  TagIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Link,
  LoaderFunctionArgs,
  defer,
  useLoaderData,
} from 'react-router-dom'

const loadPage = (page: number, search?: string) =>
  findAllContacts({ page, limit: 10, search })

export const contactsPageLoader = async ({ params }: LoaderFunctionArgs) => {
  const [contacts] = await Promise.all([loadPage(1)])

  return defer({
    contacts,
  })
}

const ContactsPage = () => {
  const auth = useAuth()
  const { t, i18n } = useTranslation()
  const loaderData = useLoaderData() as any
  const [paginated, setPaginated] = useState<Paginated<ContactResponse>>(
    loaderData.contacts
  )
  const [search, setSearch] = useState('')
  const [loadingTimeout, setLoadingTimeout] = useState<NodeJS.Timeout | null>(
    null
  )

  const reload = (page: number, search: string) => {
    loadPage(page, search)
      .then((response) => {
        setPaginated(response)
        setLoadingTimeout(null)
        window.scrollTo(0, 0)
      })
      .catch((err: AxiosError) => handleValidationError(err, i18n))
  }

  const handleSearchChanged = (search: string) => {
    setSearch(search)

    if (loadingTimeout) {
      clearTimeout(loadingTimeout)
    }

    setLoadingTimeout(setTimeout(() => reload(1, search), 200))
  }

  const handlePageChanged = (page: number) => {
    reload(page, search)
  }

  return (
    <UiContainer>
      <UiHeader
        title={`${t('common.contacts')} (${paginated.totalResults})`}
        description={t('page.description.contacts')}
        mobileAction={{ icon: PlusSmallIcon, to: '/contacts/new' }}
        desktopActions={
          <>
            {(paginated.totalResults > 0 || paginated.search) && (
              <UiButton
                as={Link}
                variant='primary'
                label={t('action.newContact')}
                icon={PlusSmallIcon}
                type='button'
                to='/contacts/new'
              />
            )}
          </>
        }
        search={
          <>
            {(paginated.totalResults > 0 || paginated.search) && (
              <UiSearch
                value={search}
                onChanged={(e) => handleSearchChanged(e.target.value)}
                placeholder={t('form.placeholder.searchContact')}
                loadingTimeout={loadingTimeout}
              />
            )}
          </>
        }
      />
      <div className='py-6 space-y-6 md:py-8 md:space-y-8'>
        <ul
          role='list'
          className='grid grid-cols-1 gap-2 md:gap-6 sm:grid-cols-2 lg:grid-cols-3'
        >
          {paginated.results.map((contact) => (
            <li
              key={contact._id}
              className='relative col-span-1 bg-white rounded-lg shadow hover:bg-gray-50'
            >
              <div className='flex items-center space-x-4 p-4 sm:px-6'>
                <div className='flex-shrink-0'>
                  <UiAvatar
                    name={contact.name}
                    image={contact.image}
                    color={contact.color}
                    shape='circle'
                    size={14}
                  />
                </div>
                <div className='min-w-0 flex-1 overflow-hidden'>
                  <p className='truncate text-sm font-semibold text-gray-700'>
                    {contact.name}
                  </p>
                  {contact.description && (
                    <p className='truncate text-sm text-gray-500'>
                      {contact.description}
                    </p>
                  )}
                  {!contact.description && (
                    <p className='truncate text-sm text-gray-500 italic'>
                      {t('common.noDescription')}
                    </p>
                  )}
                  <ul className='mt-2 flex flex-wrap gap-1'>
                    {false && (
                      <li>
                        <UiBadge
                          icon={TagIcon}
                          label={t('common.countReservations', { count: 5 })}
                        />
                      </li>
                    )}
                    {contact.identificationNumber && (
                      <li>
                        <UiBadge
                          icon={HashtagIcon}
                          label={contact.identificationNumber}
                        />
                      </li>
                    )}
                    {contact.email && (
                      <li>
                        <UiBadge icon={EnvelopeIcon} label={contact.email} />
                      </li>
                    )}
                    {contact.phone && (
                      <li>
                        <UiBadge icon={PhoneIcon} label={contact.phone} />
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <Link
                to={`/contacts/${contact._id}`}
                className='absolute top-0 right-0 bottom-0 left-0'
              />
            </li>
          ))}
        </ul>
        {paginated.totalPages > 1 && (
          <div className='flex justify-center'>
            <UiPagination
              page={paginated.page}
              totalPages={paginated.totalPages}
              onPageChanged={handlePageChanged}
            />
          </div>
        )}
        {paginated.totalResults === 0 && !paginated.search && (
          <UiEmptyState
            icon={UserGroupIcon}
            title={t('hint.title.contactEmptyState')}
            description={t('hint.description.contactEmptyState')}
            button={{
              label: t('action.newContact'),
              to: '/contacts/new',
            }}
          />
        )}
        {paginated.totalResults === 0 && paginated.search && (
          <UiNoSearchResults />
        )}
      </div>
    </UiContainer>
  )
}

export default ContactsPage
