import { Switch } from '@headlessui/react'
import classNames from 'classnames'
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form'
import useAuth from '@/contexts/use-auth'
import { Fragment } from 'react'

type UiToggleProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  label: string
  description?: string

  control: Control<TFieldValues>
  name: TName
  rules?: RegisterOptions<TFieldValues, TName>
}

const UiToggle = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: UiToggleProps<TFieldValues, TName>
) => {
  const auth = useAuth()
  const color = auth?.company?.appearance?.color || 'rose'

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { isTouched, isDirty, error },
        formState,
      }) => (
        <>
          <Switch.Group as="div" className="flex items-center">
            <Switch
              checked={value}
              onChange={(e) => onChange(e as any)}
              as={Fragment}
            >
              {({ checked }) => (
                /* Use the `checked` state to conditionally style the button. */
                <div className="sm:hover:bg-gray-50 flex cursor-pointer rounded-md p-2">
                  <button
                    className={classNames(
                      checked ? `bg-${color}-600` : 'bg-gray-200',
                      `relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-${color}-500 focus:ring-offset-2`
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        checked ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </button>
                </div>
              )}
            </Switch>
            {props.label && (
              <span className="ml-3 flex flex-grow flex-col">
                <Switch.Label
                  as="span"
                  className="text-sm font-medium text-gray-700"
                >
                  {props.label}
                </Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-500">
                  {props.description}
                </Switch.Description>
              </span>
            )}
          </Switch.Group>
        </>
      )}
    />
  )
}

export default UiToggle
