import Role from '@/business/dto/types/role'
import useAuth from '@/contexts/use-auth'
import { Link, LinkProps } from 'react-router-dom'

const AdminLink = (
  props: LinkProps & React.RefAttributes<HTMLAnchorElement>
) => {
  const auth = useAuth()

  return (
    <>
      {auth.user.role === Role.owner ? (
        <Link {...props}>{props.children}</Link>
      ) : (
        <span {...props}>{props.children}</span>
      )}
    </>
  )
}

export default AdminLink
