import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import UiMenu from '@/components/ui-kit/actions/UiMenu'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHorizonNavigation from '@/components/ui-kit/layout/UiHorizonNavigation'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import StaffBottomNavigation from './StaffBottomNavigation'

const StaffLayout = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()

  // useEffect(() => {
  //   if (!auth.user.resflowStaff) {
  //     navigate('/', { replace: true })
  //   }
  // }, [auth.user])

  if (!auth.user.resflowStaff) {
    return <Navigate to="/" replace={true} />
  }

  return (
    <>
      <div className="hidden md:block">
        <nav className="sticky top-0 left-0 right-0 z-40 backdrop-blur bg-gray-100/75 border-b border-gray-200">
          <UiContainer>
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center h-full">
                <div className="flex-shrink-0">
                  <div className="h-6 w-[67px]">
                    <img
                      src="/img/resflow-logo.svg"
                      alt={t('common.resflowLogo')!}
                    />
                  </div>
                </div>
                <div className="font-semibold text-rose-600 ml-2">Staff</div>

                {/* Links section */}
                <div className="ml-10">
                  <UiHorizonNavigation>
                    {false && (
                      <UiHorizonNavigation.Item
                        title="resflow Dashboard"
                        href="/staff"
                        end={true}
                      />
                    )}
                    <UiHorizonNavigation.Item
                      title="resflower"
                      href="/employees"
                      end={true}
                    />
                    <UiHorizonNavigation.Item
                      title={t('common.companies')}
                      href="/companies"
                      end={false}
                    />
                  </UiHorizonNavigation>
                </div>
              </div>

              {/* Actions section */}
              <div className="ml-4">
                <div className="flex items-center space-x-4">
                  <UiMenu
                    sr="Open account menu"
                    avatar={{
                      name: auth.user.name,
                      color: auth.user.color,
                      image: auth.user.image,
                      shape: 'circle',
                    }}
                  >
                    <UiMenu.Header
                      title={auth.user.name}
                      description={auth.user.email}
                    />
                    <UiMenu.Items>
                      <UiMenu.Item
                        name={t('action.signOut')}
                        onClick={auth.signOut}
                        icon={UserCircleIcon}
                      />
                    </UiMenu.Items>
                  </UiMenu>
                </div>
              </div>
            </div>
          </UiContainer>
        </nav>
      </div>

      <Outlet />

      <StaffBottomNavigation />
    </>
  )
}

export default StaffLayout
