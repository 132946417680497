import axios from 'axios'
import CreateCategoryRequest from '@/business/dto/requests/create-category.request'
import QueryPaginationRequest from '@/business/dto/requests/query-pagination.request'
import UpdateCategoryRequest from '@/business/dto/requests/update-category.request'
import Paginated from '@/business/dto/responses/paginated.response'
import CategoryResponse from '../dto/responses/category.response'
import CategoryPopulatedResponse from '../dto/responses/category-populated.response'
import ServiceResponse from '../dto/responses/service.response'

const ressourceUrl = `/api/categories`

export const createCategory = async (
  data: CreateCategoryRequest
): Promise<CategoryResponse> => {
  return axios.post(ressourceUrl, data).then((response) => response.data)
}

export const findAllCategories = async (
  query: QueryPaginationRequest
): Promise<Paginated<CategoryResponse>> => {
  return axios
    .get(ressourceUrl, {
      params: query,
    })
    .then((response) => response.data)
}

export const findCategoryById = async (
  categoryId: string
): Promise<CategoryResponse> => {
  return axios
    .get(`${ressourceUrl}/${categoryId}`, {
      params: { populate: false },
    })
    .then((response) => response.data)
}

export const findCategoryByIdPopulated = async (
  categoryId: string
): Promise<CategoryPopulatedResponse> => {
  return axios
    .get(`${ressourceUrl}/${categoryId}`, {
      params: { populate: true },
    })
    .then((response) => response.data)
}

export const updateCategoryById = async (
  id: string,
  data: UpdateCategoryRequest
): Promise<CategoryResponse> => {
  return axios
    .patch(`${ressourceUrl}/${id}`, data)
    .then((response) => response.data)
}

export const deleteCategoryById = async (categoryId: string): Promise<void> => {
  return axios
    .delete(`${ressourceUrl}/${categoryId}`)
    .then((response) => response.data)
}

export const findCategoryServices = async (
  categoryId: string,
  query: QueryPaginationRequest
): Promise<Paginated<ServiceResponse>> => {
  return axios
    .get(`${ressourceUrl}/${categoryId}/services`, {
      params: query,
    })
    .then((response) => response.data)
}
