import classNames from 'classnames'
import { ForwardRefExoticComponent, SVGProps } from 'react'

type UiBadgeProps = {
  label: string
  icon?: React.ElementType
  bgClass?: string
  textClass?: string
}

const UiBadge = (props: UiBadgeProps) => {
  return (
    <span
      className={classNames(
        'overflow-hidden inline-flex items-center rounded-full px-2 py-1 text-xs font-normal space-x-1',
        props.bgClass || 'bg-gray-100',
        props.textClass || 'text-gray-800'
      )}
    >
      {props.icon && (
        <div className="flex-shrink-0">
          <props.icon className="h-3.5 w-3.5 stroke-[2px]" />
        </div>
      )}
      {props.label && (
        <div className="min-w-0 flex-1 truncate">
          <p>{props.label}</p>
        </div>
      )}
    </span>
  )
}

export default UiBadge
