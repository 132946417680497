import { ServiceCreateCard } from '@/components/modals/ServiceCreateUpdateCard'
import UiContainer from '@/components/ui-kit/layout/UiContainer'
import UiHeader from '@/components/ui-kit/layout/UiHeader'
import useAuth from '@/contexts/use-auth'
import { useTranslation } from 'react-i18next'

const ServiceNewPage = () => {
  const auth = useAuth()
  const { t } = useTranslation()

  return (
    <UiContainer size="small">
      <UiHeader
        title={t('page.title.serviceNew')}
        description={t('page.description.serviceNew')}
        desktopBackHref="/services"
        mobileBackHref="/services"
      />
      <div className="py-6 md:py-8">
        <div className="space-y-6">
          <ServiceCreateCard />
        </div>
      </div>
    </UiContainer>
  )
}

export default ServiceNewPage
